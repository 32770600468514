import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Spinner } from "shared/components/controls";
import _ from "underscore";
import { breadcrumbStyle } from "shared/styles/styles";
import { setHtmlTitle } from "shared/utils/entity-utils";

const SITE_UPDATES_QUERY = gql`
    {
        siteInfo {
            updates {
                id
                timestamp
                title
                body
            }
        }
    }
`;

/**
 * A log of site updates
 */
const Updates = () => {
    // Set HTML title tag
    setHtmlTitle("Site Updates");

    const { loading, error, data } = useQuery(SITE_UPDATES_QUERY);

    const createBody = (html) => {
        return { __html: html };
    };

    const renderUpdatesList = () => {
        if (!loading && !error) {
            const updateList = _.sortBy(data.siteInfo.updates, (update) => {
                const d = new Date(update.timestamp);
                return d.getTime();
            });
            const rows = updateList.map((update) => {
                const timestamp = new Date(update.timestamp);
                return (
                    <div>
                        <h3>{update.title}</h3>
                        <b>{timestamp.toDateString()}</b>
                        <p />
                        <span dangerouslySetInnerHTML={createBody(update.body)} />
                        <hr />
                    </div>
                );
            });
            return <div>{rows.reverse()}</div>;
        } else {
            return <Spinner />;
        }
    };

    return (
        <Row>
            <Col md={2} />
            <Col md={8}>
                <div>
                    <div style={breadcrumbStyle}>
                        <a href="/">HOME</a>
                        <span> » </span>
                    </div>
                    <div className="esnet-portal-header">
                        <h2>Site Updates</h2>
                    </div>

                    <hr />

                    {renderUpdatesList()}
                </div>
            </Col>
            <Col md={2} />
        </Row>
    );
};

export default Updates;
