import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Spinner, TextFilter } from "shared/components/controls";
import { useUser, useQueryState } from "shared/hooks";
import _ from "underscore";
import SiteListRow from "./SiteListRow";
import { rowCountStyle, totalRowCountStyle } from "shared/styles/styles";
import { breadcrumbStyle } from "shared/styles/styles";
import { setHtmlTitle } from "shared/utils/entity-utils";

export const GET_SITES_QUERY = gql`
    query {
        networkEntities(entityType: "ESnet Site") {
            id
            type
            shortName
            fullName
            programOffice {
                shortName
            }
            programArea
            parent {
                shortName
            }
            tags
        }
    }
`;

export const UPDATE_SITE_FAVORITE_MUTATION = gql`
    mutation UpdateSiteFavorite($shortName: String, $isInterested: Boolean) {
        updateUserNetworkEntityFavorite(
            entityType: "ESnet Site"
            shortName: $shortName
            isInterested: $isInterested
        ) {
            success
            user {
                email
                firstName
                lastName
                isAnonymous
                favoriteNetworkEntities {
                    id
                    shortName
                    type
                }
                viewTopTalkers {
                    id
                    shortName
                    fullName
                }
            }
        }
    }
`;

/**
 * A list of ESnet sites rendered in a table
 */
export const SiteList = () => {
    // Set HTML title tag
    setHtmlTitle("Sites");

    // Get the user profile, containing the favorites
    const user = useUser();

    // Get the list of sites
    const { loading, data } = useQuery(GET_SITES_QUERY);

    // Get the text to filter the list of sites by
    const [text] = useQueryState("filter", "");

    // Queue up the updateFavorite mutation for when the user clicks the stars next to the site list
    const [updateFavorite] = useMutation(UPDATE_SITE_FAVORITE_MUTATION);

    // Build a new optimistic response starting with the user object
    // then set that on the Apollo Client cache until the server action has
    // been completed.
    const handleFavoriteChange = (site, isInterested) => {
        const response = user;

        // If we're not interested in an entity then traverse the
        // list and remove the one we don't want in there anymore, otherwise
        // add it to the end of the list of current favorites
        let newFavorites = [];
        if (!isInterested) {
            newFavorites = response.favoriteNetworkEntities.filter((fav) => fav.id !== site.id);
        } else {
            newFavorites = [...response.favoriteNetworkEntities, site];
        }
        response.favoriteNetworkEntities = newFavorites;

        updateFavorite({
            variables: { shortName: site.shortName, isInterested },
            optimisticResponse: {
                __typename: "Mutation",
                updateFavorite: response,
            },
        });
    };

    const renderSiteList = () => {
        const renderTotalRows = (rowCount, totalRowCount) => {
            if (rowCount < totalRowCount) {
                return <span style={totalRowCountStyle}>(of {totalRowCount})</span>;
            }
            return <span></span>;
        };

        if (!loading && data) {
            const searchText = text.toLowerCase().trim();

            // Get the favorites out of the user profile
            const { favoriteNetworkEntities = [] } = user;

            const siteList = data.networkEntities;

            // Use the siteList we loaded, but sorted alphabetically
            const sites = _.sortBy(siteList, (item) => item.shortName);

            // Render all the sites
            const rows = sites.map((site) => {
                const { shortName, fullName } = site;
                let isFavorite = false;
                favoriteNetworkEntities.forEach((entity) => {
                    if (site.shortName === entity.shortName && entity.type === "ESnet Site") {
                        isFavorite = true;
                    }
                });
                if (
                    shortName.toLowerCase().indexOf(searchText) >= 0 ||
                    fullName.toLowerCase().indexOf(searchText) >= 0
                ) {
                    return (
                        <SiteListRow
                            key={site.shortName}
                            site={site}
                            isFavorite={isFavorite}
                            onFavoriteChange={() => handleFavoriteChange(site, !isFavorite)}
                        />
                    );
                } else {
                    return null;
                }
            });

            // Rows remaining in the filtered list
            const rowCount = rows.filter((r) => r != null).length;

            return (
                <Row>
                    <Col md={12}>
                        <Row>
                            <Col md={12}>
                                <hr style={{ marginTop: 0 }} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <TextFilter />
                            </Col>
                            <Col />
                        </Row>
                        <Row>
                            <Col md={12}>
                                <span style={rowCountStyle}>
                                    Displaying {rowCount}{" "}
                                    {renderTotalRows(rowCount, data.networkEntities.length)} Results
                                </span>
                            </Col>
                        </Row>
                        <table className="table table-condensed">
                            <thead>
                                <tr>
                                    <th />
                                    <th />
                                    <th>Full Name</th>
                                </tr>
                            </thead>
                            <tbody>{rows}</tbody>
                        </table>
                    </Col>
                </Row>
            );
        } else {
            return (
                <div>
                    <Spinner />
                </div>
            );
        }
    };

    return (
        <Row>
            <Col md={2} />
            <Col md={8}>
                <div style={breadcrumbStyle}>
                    <a href="/">HOME</a>
                    <span> » </span>
                </div>
                <h2>Sites</h2>
                {renderSiteList()}
            </Col>
            <Col md={2} />
        </Row>
    );
};
