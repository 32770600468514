import React from "react";
import { Star, StarFill } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { useUser } from "shared/hooks";

/**
 * A single facility rendered as a table row
 */
const FacilityListRow = ({ facility, isFavorite, onFavoriteChange }) => {
    const { shortName, fullName, programArea, programOffice } = facility;
    const user = useUser();

    const star = isFavorite ? (
        <StarFill color="#4EC1E0" onClick={onFavoriteChange} />
    ) : (
        <Star color="#D0D0D0" onClick={onFavoriteChange} />
    );

    return (
        <tr>
            {user && !user.isAnonymous ? <td>{star}</td> : <td />}
            <td>
                <Link to={`/facilities/view/${shortName}`}>{shortName.toUpperCase()}</Link>
            </td>
            <td>
                <Link to={`/facilities/view/${shortName}`}>{fullName}</Link>
            </td>
            {facility.parent != null ? <td>{facility.parent.fullName}</td> : <td />}
            <td>{programArea}</td>
            <td>{programOffice.shortName}</td>
        </tr>
    );
};

export default FacilityListRow;
