/**
 * Circuit styles
 */

export const equipmentToEquipmentStyle = {
    node: {
        normal: {
            stroke: "#808080",
            strokeWidth: 4,
            fill: "none",
        },
        highlighted: {
            stroke: "#BEBEBE",
            strokeWidth: 4,
            fill: "#BEBEBE",
        },
    },
    line: {
        normal: {
            stroke: "#808080",
            strokeWidth: 3,
            fill: "none",
        },
        highlighted: {
            stroke: "#808080",
            strokeWidth: 4,
            fill: "none",
        },
    },
    label: {
        normal: {
            fill: "#999",
            fontFamily: "Roboto, sans-serif",
            fontSize: 10,
        },
    },
};

export const edgeStyle = {
    style: equipmentToEquipmentStyle,
    lineShape: "linear",
};

export const couplerStyle = {
    node: {
        normal: {
            stroke: "#808080",
            strokeWidth: 4,
            fill: "none",
        },
        highlighted: {
            stroke: "#BEBEBE",
            strokeWidth: 4,
            fill: "#BEBEBE",
        },
    },
    line: {
        normal: {
            stroke: "#808080",
            strokeWidth: 1,
            fill: "#D0D0D0",
        },
        highlighted: {
            stroke: "#4EC1E0",
            strokeWidth: 2,
            fill: "#D0D0D0",
        },
    },
    label: {
        normal: {
            fill: "#999",
            fontFamily: "Roboto, sans-serif",
            fontSize: 10,
        },
    },
};

export const endpointStyle = {
    node: {
        normal: { fill: "none", stroke: "#D0D0D0", strokeWidth: 4 },
        selected: { fill: "none", stroke: "#BEBEBE", strokeWidth: 6 },
        muted: {
            fill: "none",
            stroke: "#D0D0D0",
            strokeWidth: 2,
            opacity: 0.6,
            cursor: "pointer",
        },
    },
    label: {
        normal: {
            fill: "#999",
            fontSize: 10,
            fontFamily: "Roboto, sans-serif",
        },
        selected: { fill: "#222", stroke: "none", fontSize: 11 },
        muted: {
            fill: "#6D6E71",
            stroke: "none",
            fontSize: 9,
            opacity: 0.6,
        },
    },
};

export const routerStyle = {
    style: couplerStyle,
    lineShape: "square",
    size: 36,
    squareWidth: 25,
    noNavigate: true,
};
