import { TimeRange } from "pondjs";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import _ from "underscore";
import { ranges as defaultRanges, units } from "shared/constants/time";
import { useSearchParams } from "./useSearchParams";

/**
 * `useTimeRange()` is a hook which will return a TimeRange based on "t", "b" and "e"
 * search param variables, as well as a function to set the TimeRange back onto the
 * URL. The hook will auto refresh the `TimeRange` at an interval set by the first
 * arg to the hook (set in ms). This update will occur if the time is set to a relative
 * value. If the time (b and e) are set it will stop updating. The second arg is
 * the ranges expected and validated against, while the third arg gives a default
 * relative value if nothing is set in the URL. Can be used along side the `TimePicker`
 * component, which will provide UI for setting the time range.
 */
export const useTimeRange = (
    updateInterval = 1 * 1000,
    ranges = defaultRanges,
    defaultValue = "1d",
    delta = 0
) => {
    const [endTime, setEndTime] = useState(new Date());

    const query = useSearchParams();
    const history = useHistory();
    const { pathname } = useLocation();

    let time = query.get("t") || defaultValue;
    const begin = +query.get("b");
    const end = +query.get("e");

    // Get the begin and end times based on the URL.
    // If a "begin and "end" query parameter is set, those form the
    // begin and" end time, otherwise the relative "time" is parsed,
    // a duration determined, and begin and end time formed based on
    // the current time. If it's a relative time, refresh is set true.
    let b;
    let e;
    let refresh = false;
    if (begin && end) {
        b = new Date(begin);
        e = new Date(end);
    } else {
        refresh = true;
        const regex = /([0-9]+)([smhdwMY])/;
        const parts = regex.exec(time);
        if (parts && parts.length >= 3) {
            const multiplier = parseInt(parts[1], 10);
            const unit = parts[2];
            const duration = multiplier * units[unit];
            b = new Date(+endTime - duration);
            e = new Date(+endTime + delta);
        } else {
            ranges.forEach((r) => {
                if (r.key === time) {
                    const duration = r.duration;
                    b = new Date(+endTime - duration);
                    e = new Date(+endTime + delta);
                }
            });
        }
    }

    // Start an interval timer update the TimeRange every 10 sec
    // We only run this if the refresh boolean has changed, so we can assume
    // that everytime this runs we'll either:
    // 1) refresh is true, so we start the interval timer, with each trigger
    //    of that updating the end time state
    // 2) refresh is false, so we'll stop the interval timer
    useEffect(() => {
        let interval;
        if (refresh) {
            interval = setInterval(() => setEndTime(new Date()), updateInterval);
        } else {
            clearInterval(interval);
        }
        return () => {
            clearInterval(interval);
        };
    }, [refresh, updateInterval]);

    /**
     * Change the timerange using a begin and end time. Removes
     * the `b` and `e` query variabled and supplies new `t`,
     * then pushes that to the history to navigate there.
     */
    const handleChangeTime = (t) => {
        const targetQuery = new URLSearchParams(query);
        targetQuery.delete("b");
        targetQuery.delete("e");
        if (t === defaultValue) {
            targetQuery.delete("t");
        } else {
            targetQuery.set("t", t);
        }
        history.push(`${pathname}?${targetQuery.toString()}`);
    };

    /**
     * Change the timerange using a begin and end time. Removes
     * the `t` query variable and supplies new `b` and `e` values
     * then pushes that to the history to navigate there.
     */
    const handleCustomChange = (b, e) => {
        const targetQuery = new URLSearchParams(query);
        targetQuery.delete("t");
        targetQuery.set("b", +b);
        targetQuery.set("e", +e);
        history.push(`${pathname}?${targetQuery.toString()}`);
    };

    /**
     * The user exposed interface to changing the time. The user of the hook
     * can either specify a new timerange as relative (a string like "1d") or
     * as a Pond TimeRange.
     */
    const handleTimeRangeChange = (t) => {
        if (_.isUndefined(t)) {
            setEndTime(new Date());
        } else if (_.isString(t)) {
            handleChangeTime(t);
        } else if (t instanceof TimeRange) {
            handleCustomChange(t.begin(), t.end());
        }
    };

    const tr = b && e ? new TimeRange(b, e) : null;
    return [tr, handleTimeRangeChange];
};
