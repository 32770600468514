import gql from "graphql-tag";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Fillable, TextFilter } from "shared/components/controls";
import { useQueryState } from "shared/hooks";
import { breadcrumbStyle } from "shared/styles/styles";
import { OscarsTable } from "./OscarsTable";
import { setHtmlTitle } from "shared/utils/entity-utils";

export const GET_OSCARS_CONNECTIONS = gql`
    query {
        oscarsConnections {
            connectionId
            description
            begin
            end
            endAName
            endASite
            endZName
            endZSite
            state
            mode
            tags {
                category
                contents
            }
            fixtures {
                inMbps
                outMbps
            }
        }
    }
`;

/**
 * A list of Oscars circuits rendered in a table
 */
export const OscarsList = () => {
    // Set HTML title tag
    setHtmlTitle("OSCARS");

    const [text] = useQueryState("filter", "");

    return (
        <Col md={12}>
            <Row>
                <Col md={12}>
                    <div className="esnet-portal-header">
                        <div style={breadcrumbStyle}>
                            <a href="/">HOME</a>
                            <span> » </span>
                        </div>
                        <h2 style={{ marginLeft: "-2px" }}>OSCARS</h2>
                    </div>
                    <p>
                        ESnet's On-Demand Secure Circuits and Advance Reservation System (OSCARS)
                        provides multi-domain, high-bandwidth virtual circuits that guarantee
                        end-to-end network data transfer performance. OSCARS gives ESnet the ability
                        to engineer, manage and automate the network according to user-specified
                        requirements for using scientific instruments, computation, and
                        collaborations. More information is available at the{" "}
                        <a
                            href="http://www.es.net/engineering-services/oscars"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            OSCARS website
                        </a>
                        .
                    </p>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <hr style={{ marginTop: 0 }} />
                </Col>
            </Row>
            <Row>
                <Col md={3}>
                    <TextFilter />
                </Col>
                <Col />
            </Row>
            <Row>
                <Col md={12}>
                    <hr style={{ marginTop: 0, marginBottom: 14 }} />
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <Fillable>
                        <OscarsTable text={text} />
                    </Fillable>
                </Col>
            </Row>
        </Col>
    );
};
