import _ from "underscore";
import { formatPrefix } from "d3-format";
import { formatPercent, formatYearMonth } from "./format-utils";

/**
 * Collection of functions related to volume and traffic
 */

export function scaleUnits(value, unit) {
    unit = unit || "B";
    const prefix = formatPrefix(".2g", 1e15);
    return `${prefix(value)}${unit}`;
}

export function computePercentDifference(bytesNow, bytesThen) {
    if (_.isNull(bytesThen) || _.isUndefined(bytesThen)) {
        return "-";
    }

    let difference = (bytesNow - bytesThen) / bytesThen;

    return formatPercent(difference * 100, true);
}

export function getEarlierYearMonth(month, n) {
    const parts = month.toString().split("-");
    let y = parseInt(parts[0], 10),
        m = parseInt(parts[1], 10);

    const remainder = n % 12;
    const nyears = (n - remainder) / 12;

    if (remainder >= m) {
        m = 12 - (remainder - m);
        y = y - nyears - 1;
    } else {
        m -= remainder;
        y -= nyears;
    }

    return formatYearMonth(y, m);
}

export function dataForMonth(data, month) {
    for (let d of data.events()) {
        if (d.index().toString() === month.toString()) {
            return d;
        }
    }

    return null;
}

export function getInterfaceMapFromList(interfaces) {
    let r = {};

    _.each(interfaces, (d) => {
        const k = d.router + " " + d["interface"];
        r[k] = d;
    });

    return r;
}
