import gql from "graphql-tag";
import React from "react";
import { Spinner, TrafficBar } from "shared/components/controls";
import { formatBytes, formatCount } from "shared/utils/format-utils";
import { useMemoQuery } from "shared/hooks";

export const SITE_TOP_TALKERS_QUERY = gql`
    query getTopTalkers($shortName: String, $direction: String, $timeperiod: String) {
        networkEntity(shortName: $shortName, entityType: "ESnet Site") {
            topTalkers(direction: $direction, timeperiod: $timeperiod)
        }
    }
`;

/**
 * Displays our site's top talkers as a table
 */
const SiteTopTalkersTable = ({ siteId, direction, timeperiod }) => {
    const queryOptions = {
        variables: { shortName: siteId, direction, timeperiod },
        fetchPolicy: "no-cache",
    };

    const { isLoading, error, data } = useMemoQuery(
        SITE_TOP_TALKERS_QUERY,
        queryOptions,
        (d) => d.networkEntity,
        [siteId]
    );

    if (isLoading) {
        return <Spinner />;
    } else if (error) {
        const errorStyle = {
            marginTop: 20,
            padding: 10,
            borderLeftStyle: "solid",
            borderLeft: "#EEE",
            borderLeftWidth: 10,
        };
        return (
            <div className="bg-danger" style={errorStyle} height={100}>
                <span className="glyphicon glyphicon-warning-sign widget-info-button" />
                <span style={{ paddingLeft: 5 }}>
                    {`Top talker data for ${siteId} could not be loaded at this time.`}
                </span>
            </div>
        );
    } else {
        const topTalkers = JSON.parse(data.topTalkers);

        // The columns in topTalkers aren't always consistent. The code below ensures that
        let cols = new Map();
        topTalkers.columns.forEach((value, i) => {
            cols.set(value, i);
        });

        const points = topTalkers.points.map((point) => {
            return [
                point[cols.get("time")],
                point[cols.get("organization")],
                point[cols.get("total_volume")],
                point[cols.get("average_rate")],
                point[cols.get("flow_count")],
            ];
        });

        // Grab the max value in the largest flow column
        const avgFlowRates = points.map(([, , , value]) => value);
        const maxAvgFlowRate = Math.max(...avgFlowRates);

        const rows = points
            .sort(([, , a, ,], [, , b, ,]) => b - a)
            .map((point) => {
                const [, organization, totalVolume, averageRate, flowCount] = point;
                return (
                    <tr>
                        <td>{organization}</td>
                        <td>{formatBytes(totalVolume)}</td>
                        <td>
                            <TrafficBar
                                width={350}
                                height={25}
                                traffic={averageRate}
                                max={maxAvgFlowRate}
                                format={"bps"}
                            />
                        </td>
                        <td>{formatCount(flowCount)}</td>
                    </tr>
                );
            });
        return (
            <table className="table table-condensed">
                <thead>
                    <tr>
                        <th width={250}>
                            {direction === "incoming"
                                ? "Source Organization"
                                : "Destination Organization"}
                        </th>
                        <th width={250}>Total Volume</th>
                        <th width={250}>Average Rate</th>
                        <th width={250}>Flow Count</th>
                    </tr>
                </thead>
                <tbody>{rows}</tbody>
            </table>
        );
    }
};

export default SiteTopTalkersTable;
