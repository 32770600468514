import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";
import { Resizable } from "react-network-diagrams";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { AutoSizer, Column, Table, SortIndicator } from "react-virtualized";
import "react-virtualized/styles.css";
import { Spinner, TrafficBars } from "shared/components/controls";
import { rowCountStyle, totalRowCountStyle } from "shared/styles/styles";
import { useSortState } from "shared/hooks";
import { formatBytes } from "shared/utils/format-utils";
import _ from "underscore";

export const GET_INTERFACES_QUERY = gql`
    query getAllInterfacesDetailed {
        interfaces {
            name
            device
            description
            speed
            site
            remoteShortName
            remoteFullName
            entityType
            traffic {
                columns
                points
                name
            }
        }
    }
`;

export class FilterableInterfaceList {
    constructor(interfaces) {
        this.interfaces = interfaces;
    }

    isLoaded() {
        return !_.isUndefined(this.interfaces);
    }

    get(index) {
        return this.interfaces[index];
    }

    filterBySite(site) {
        if (!this.isLoaded() || _.isNull(site)) {
            return this;
        }

        return new FilterableInterfaceList(
            _.filter(this.interfaces, (iface) => {
                return (
                    site === "" ||
                    _.isUndefined(site) ||
                    iface.site.toLowerCase() === site.toLowerCase()
                );
            })
        );
    }

    filterByDevice(device) {
        if (!this.isLoaded() || _.isNull(device)) {
            return this;
        }

        return new FilterableInterfaceList(
            _.filter(this.interfaces, (iface) => {
                return device === "" || _.isUndefined(device) || iface.device === device;
            })
        );
    }

    filterByText(textFilter) {
        if (!this.isLoaded()) {
            return this;
        }

        if (!textFilter || _.isUndefined(textFilter) || textFilter.length === 0) {
            return this;
        }

        const lowerTextFilter = textFilter.toLowerCase();
        return new FilterableInterfaceList(
            _.filter(this.interfaces, (iface) => {
                const deviceName = iface.device || "";
                const i = iface.name || "";

                let tags = "";
                if (iface.description !== "-" && iface.description !== undefined) {
                    let tags_str = iface.description.split(":")[2];
                    if (tags_str !== "" && tags_str !== undefined) {
                        tags = tags_str.split("_");
                        tags = tags.join(" ") || "";
                    }
                }

                const remoteShortName = iface.remoteShortName;
                const remoteFullName = iface.remoteFullName;
                let connection = "";
                if (tags.includes("site")) {
                    connection = remoteShortName;
                } else {
                    connection = remoteFullName;
                }

                return (
                    deviceName.toString().toLowerCase().indexOf(lowerTextFilter) >= 0 ||
                    i.toString().toLowerCase().indexOf(lowerTextFilter) >= 0 ||
                    connection.toString().toLowerCase().indexOf(lowerTextFilter) >= 0 ||
                    tags.toString().toLowerCase().indexOf(lowerTextFilter) >= 0
                );
            })
        );
    }

    filterByCapacity(capacity) {
        if (!this.isLoaded()) {
            return this;
        }

        return new FilterableInterfaceList(
            _.filter(this.interfaces, (iface) => {
                switch (capacity) {
                    case "400":
                        return iface.speed === 400000;
                    case "200":
                        return iface.speed === 200000;
                    case "100":
                        return iface.speed === 100000;
                    case "lt100":
                        return iface.speed < 100000 && iface.speed >= 10000;
                    case "lt10":
                        return iface.speed < 10000 && iface.speed >= 1000;
                    case "lt1":
                        return iface.speed < 1000 || _.isNull(iface.speed);
                    default:
                        return true;
                }
            })
        );
    }

    filterByTraffic(showInterfaces) {
        // if the data isn't loaded or we don't want to filter out zero-traffic interfaces,
        if (!this.isLoaded() || !showInterfaces || (!!showInterfaces && showInterfaces === "all")) {
            return this;
        }

        return new FilterableInterfaceList(
            _.filter(this.interfaces, (iface) => {
                // we won't show this interface by default
                let show_interface = false;
                // if the interface has a traffic property
                if (!!iface.traffic) {
                    // find the input bytes and output bytes of the first item
                    let input_bytes = iface.traffic.points[0][1];
                    let output_bytes = iface.traffic.points[0][2];
                    // if either are > 0, we will show this interface
                    let total_traffic = input_bytes + output_bytes;
                    if (total_traffic > 0) {
                        show_interface = true;
                    }
                }
                return show_interface;
            })
        );
    }

    // Custom sorting based either on the device/interface, sub-sorted by capacity,
    // or sorting based on current traffic rate.
    sort(sortBy, sortDirection) {
        if (!this.isLoaded()) {
            return this;
        }

        let trafficCriterion = (value) => {
            if (value.traffic) {
                return value.traffic.points[0][1] + value.traffic.points[0][2];
            } else {
                return 0;
            }
        };

        // default behavior for below switch: no valid sort criterion. Don't sort the data, but still return it.
        let sorted = _.chain(this.interfaces);
        switch (sortBy) {
            case "speed":
                sorted = sorted.sortBy("speed");
                break;
            case "device":
                sorted = sorted.sortBy("device");
                break;
            case "traffic":
                sorted = sorted.sortBy(trafficCriterion);
                break;
            default:
                break;
        }
        if (sortDirection === "DESC") {
            sorted = sorted.reverse();
        }
        return new FilterableInterfaceList(sorted.value());
    }

    size() {
        if (!this.isLoaded()) {
            return 0;
        } else {
            return this.interfaces.length;
        }
    }

    // Returns the max traffic for the interfaces
    max() {
        if (!this.isLoaded()) {
            return 0;
        }

        const maxInList = this.interfaces.map((iface) => {
            if (iface.traffic) {
                return iface.traffic.points[0][1];
            } else {
                return 0;
            }
        });
        const maxOutList = this.interfaces.map((iface) => {
            if (iface.traffic) {
                return iface.traffic.points[0][2];
            } else {
                return 0;
            }
        });

        const maxIn = Math.max(...maxInList);
        const maxOut = Math.max(...maxOutList);

        return Math.max(maxIn, maxOut);
    }
}

export const InterfaceTable = ({ text, site, device, capacity, showInterfaces, height, tags }) => {
    const rowHeight = 30;

    const EVERY_60_SECONDS = 60 * 1000;

    const { loading, data } = useQuery(GET_INTERFACES_QUERY, {
        pollInterval: EVERY_60_SECONDS,
    });

    // Sorting settings are stored on the URL
    const [{ sortBy, sortDirection }, setSortState] = useSortState("traffic", "DESC");

    //
    // Renderers render a specific cell type in the table
    //

    // Renders a mini traffic barchart given the traffic array and the maxTraffic to
    // determine scale. Fits in it's parent's dimensions, but height is determined
    // by the height option.
    const renderInterfaceBarChart = (traffic, maxTraffic, height) => {
        if (traffic) {
            const [, trafficIn, trafficOut] = traffic.points[0];
            return (
                <Resizable>
                    <TrafficBars
                        trafficIn={trafficIn}
                        trafficOut={trafficOut}
                        max={maxTraffic}
                        height={height - 8}
                    />
                </Resizable>
            );
        } else {
            return <div />;
        }
    };

    const renderTraffic = (maxTraffic) => {
        return ({ rowData }) => {
            const traffic = rowData.traffic;
            return renderInterfaceBarChart(traffic, maxTraffic, rowHeight);
        };
    };

    const renderInterfaceLink = ({ rowData }) => {
        const device = encodeURIComponent(rowData.device);
        const iface = encodeURIComponent(rowData.name);
        return (
            <Link to={`/network/devices/${device}/interfaces/${iface}/traffic`}>
                {rowData.name}
            </Link>
        );
    };

    const renderConnectionLink = ({ rowData }) => {
        const remoteShortName = rowData.remoteShortName;
        const remoteFullName = rowData.remoteFullName;
        const entityType = rowData.entityType;

        if (entityType && remoteShortName) {
            return (
                <Link to={`/${entityType}/view/${remoteShortName}`}>{`${remoteShortName}`}</Link>
            );
        } else {
            return <span>{remoteFullName === "-" ? "" : remoteFullName}</span>;
        }
    };

    const renderCapacity = ({ rowData }) => {
        const speed = rowData.speed;
        if (speed === "-1") {
            return "";
        } else {
            return `${formatBytes(speed * 1000000, 0, "", 1000, false)}`; // multiply by 1m to get speed in bps rather than Mbps
        }
    };

    const renderTotalRows = (rowCount, totalRowCount) => {
        if (rowCount < totalRowCount) {
            return <span style={totalRowCountStyle}>(of {totalRowCount})</span>;
        }
        return <span></span>;
    };

    const renderTags = ({ rowData }) => {
        const tagStyle = {
            border: "1px solid #D0D0D0",
            borderRadius: "4px",
            padding: "2px 3px",
            fontSize: "smaller",
        };
        const tagDisplay = [];
        if (rowData.description !== "-" && rowData.description !== undefined) {
            const connection = rowData.description.split(":")[0];
            const tags_str = rowData.description.split(":")[2];

            if (tags_str !== "" && tags_str !== undefined) {
                const tags = tags_str.split("_");
                tags.forEach((tag, index) => {
                    if (tag === "oscars-l2circuit") {
                        const id = connection.split("-")[1];
                        tagDisplay.push(
                            <span style={tagStyle} key={index}>
                                <a href={`/oscars/view/${id}`}>{`${tag}`}</a>
                            </span>
                        );
                    } else if (tag === "") {
                        return;
                    } else {
                        tagDisplay.push(
                            <span style={tagStyle} key={`${index}`}>
                                {`${tag}`}
                            </span>
                        );
                    }
                    tagDisplay.push(<span key={`${index}-space`}>&nbsp;</span>);
                });
            }
        }
        return tagDisplay;
    };

    const tooltipHeaderRenderer = ({
        headerClassName,
        columnData,
        dataKey,
        disableSort,
        label,
        sortBy,
        sortDirection,
    }) => {
        const showSortIndicator = sortBy === dataKey;
        const renderSortIndicator = function () {
            if (showSortIndicator) {
                return <SortIndicator key="SortIndicator" sortDirection={sortDirection} />;
            } else {
                return <span />;
            }
        };
        let [title, tooltip] = label.split("::");
        return (
            <span>
                <span title={tooltip} className={headerClassName}>
                    {title}
                </span>
                {renderSortIndicator()}
            </span>
        );
    };

    if (loading) {
        return <Spinner />;
    } else if (data && data.interfaces) {
        // Filtered and sorted list of interfaces
        const interfaceList = new FilterableInterfaceList(data.interfaces)
            .filterByText(text)
            .filterBySite(site)
            .filterByDevice(device)
            .filterByCapacity(capacity)
            .filterByTraffic(showInterfaces)
            .sort(sortBy, sortDirection);

        // Max traffic for bar chart scaling
        const maxTraffic = interfaceList.max();

        // Rows remaining in the filtered list
        const rowCount = interfaceList.size();

        return (
            <div>
                <Row>
                    <Col md={12}>
                        <span style={rowCountStyle}>
                            Displaying {rowCount}{" "}
                            {renderTotalRows(rowCount, data.interfaces.length)} Results
                        </span>
                    </Col>
                </Row>
                <AutoSizer disableHeight>
                    {({ width }) => (
                        <div>
                            <Table
                                gridStyle={{ outline: 0 }}
                                width={width}
                                height={height}
                                headerHeight={20}
                                headerStyle={{ outline: 0 }}
                                rowHeight={rowHeight}
                                sort={({ sortBy, sortDirection }) =>
                                    setSortState(sortBy, sortDirection)
                                }
                                sortBy={sortBy}
                                sortDirection={sortDirection}
                                rowCount={rowCount}
                                rowGetter={({ index }) => interfaceList.get(index)}
                            >
                                <Column width={200} label="Device" dataKey="device" />

                                <Column
                                    width={300}
                                    label="Interface"
                                    dataKey="name"
                                    disableSort={true}
                                    cellRenderer={renderInterfaceLink}
                                />

                                <Column
                                    width={400}
                                    label="Traffic (in | out)::Presents the latest available 30-second average of traffic for this interface, in bits per second (bps). Refreshed every minute."
                                    dataKey="traffic"
                                    headerRenderer={tooltipHeaderRenderer}
                                    cellRenderer={renderTraffic(maxTraffic)}
                                />

                                <Column
                                    width={300}
                                    label="Connection"
                                    dataKey="connection"
                                    disableSort={true}
                                    cellRenderer={renderConnectionLink}
                                />
                                <Column
                                    width={300}
                                    label="Tags"
                                    dataKey="tags"
                                    disableSort={true}
                                    cellRenderer={renderTags}
                                />
                                <Column
                                    width={300}
                                    label="Capacity (bps)::Interface capacity, in bits per second (bps)"
                                    dataKey="speed"
                                    headerRenderer={tooltipHeaderRenderer}
                                    cellRenderer={renderCapacity}
                                />
                            </Table>
                        </div>
                    )}
                </AutoSizer>
            </div>
        );
    } else {
        return <div />;
    }
};
