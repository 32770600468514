import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { Legend, styler } from "react-timeseries-charts";
import { lastMonth, MonthPicker, toTimerange } from "shared/components/controls";
import { MultiChannelChartWidget } from "shared/components/widgets";
import Widget from "shared/components/widgets/Widget";
import { useQueryState, useMemoQuery } from "shared/hooks";
import { breadcrumbStyle, linkStyle, linkStyleActive } from "shared/styles/styles";
import { getLastMonth } from "shared/utils/interface-utils";
import _ from "underscore";
import { UtilizationChart } from "./UtilizationChart";
import { TimeSeries } from "pondjs";
import { formatBytes } from "shared/utils/format-utils";

const DESCRIPTION = `
## Utilization Chart

This chart shows the percentage of time at which the traffic on the selected interface is
above (log scale) or below (linear scale) a certain level of utilization in a given month.

When viewing the linear scale, the x-axis represents the percentile and the y-axis is
the utilization at that percentile, which is total traffic divided by the capacity of the
interface. At a particular percentile, the corresponding values on the y-axis represent 
the percent of samples that are less than that value.

For example, the vertical line shows the 95th percentile. At this percentile, the corresponding values
on the y-axis are the utilization that 95 percent of samples are less than.

Note: We are using the 99.9th percentile to represent 100th percentile in order to avoid collection outliers.

When viewing the log scale, the x-axis represents the percent of time and the y-axis is
the utilization. At a particular percent of time, the corresponding values on the y-axis 
represent that the link utilization is greater than that value.

For example, the vertical line shows 5 percent of the time. The corresponding values on the y-axis 
represent that 5 percent of the time the link utilization is greater than that value.
`;

const GET_INTERFACE_UTILIZATION_QUERY = gql`
    query getInterfaceUtilization(
        $device: String
        $iface: String
        $beginTime: String
        $endTime: String
    ) {
        interfaceUtilization(
            device: $device
            interface: $iface
            beginTime: $beginTime
            endTime: $endTime
        ) {
            interface
            device
            percentiles
        }
    }
`;

const FETCH_INTERFACE_TRAFFIC = gql`
    query getInterfaceTraffic(
        $device: String
        $iface: String
        $beginTime: String
        $endTime: String
    ) {
        interface(device: $device, interface: $iface) {
            speed
            traffic(beginTime: $beginTime, endTime: $endTime) {
                name
                columns
                points
            }
        }
    }
`;

const triggers = [
    {
        value: 0.3,
        label: "Capacity Policy: Study",
        color: "green",
    },
    {
        value: 0.5,
        label: "Resiliency Policy: Target for sites with 2 unprotected access links ",
        color: "blue",
    },
    {
        value: 0.6,
        label: "Capacity Policy: Target action threshold",
        color: "orange",
    },
    {
        value: 0.66,
        label: "Resiliency Policy: Target for sites with 3 unprotected access links ",
        color: "red",
    },
];

const convertPercentilesToCSV = (percentileData, speed) => {
    const csvData = [];
    if (percentileData && speed) {
        // Header
        csvData.push([
            "percentile",
            "in",
            "in_utilization (in value / capacity)",
            "out",
            "out_utilization (out value / capacity)",
        ]);
        const capacity = speed !== 0 ? speed * 1000000 : 1;
        percentileData.interfaceUtilization.percentiles.forEach((p) => {
            const pp = JSON.parse(p);
            csvData.push([pp.percentile, pp.in, pp.in / capacity, pp.out, pp.out / capacity]);
        });
    }
    return csvData;
};

const upDownStyle = styler([
    { key: "in", color: "#4A92DE", width: 3 },
    { key: "out", color: "#FD9420", width: 3 },
]);

export const InterfaceUtilization = () => {
    const { device, iface: interfaceName } = useParams();
    const iface = decodeURIComponent(interfaceName);

    const [month] = useQueryState("month", lastMonth());
    const timerange = toTimerange(month);

    const [scale, setScale] = useQueryState("scale", "linear");

    const beginTime = timerange.begin().toISOString();
    const endTime = timerange.end().toISOString();

    const queryOptions = {
        variables: { device, iface, beginTime, endTime },
    };

    const { loading, error, data } = useQuery(GET_INTERFACE_UTILIZATION_QUERY, queryOptions);

    const {
        status: trafficStatus,
        data: { speed, traffic },
    } = useMemoQuery(
        FETCH_INTERFACE_TRAFFIC,
        queryOptions,
        (d) => {
            return {
                speed: d.interface.speed,
                traffic: new TimeSeries(d.interface.traffic),
            };
        },
        [beginTime, endTime]
    );

    // NavItems
    const subSections = [];
    subSections.push({
        key: "Traffic",
        label: "Traffic",
        url: `/network/devices/${encodeURIComponent(device)}/interfaces/${encodeURIComponent(
            iface
        )}`,
    });
    subSections.push({
        key: "Utilization",
        label: "Utilization",
        url: `/network/devices/${encodeURIComponent(device)}/interfaces/${encodeURIComponent(
            iface
        )}/utilization?month=${getLastMonth()}`,
    });

    const navItems = _.map(subSections, (section) => {
        const className = "Utilization" === section.label ? "active" : "";
        const key = section.key;
        return (
            <li key={key} role="presentation" className={className}>
                <Link to={section.url}>{section.label}</Link>
            </li>
        );
    });

    const renderInterfaceHeader = (device, iface, speed) => {
        const capacity = speed !== 0 ? speed * 1000000 : "";
        return (
            <div>
                <div style={breadcrumbStyle}>
                    <a href="/">HOME</a>
                    <span> › </span>
                    <a href="/network/interfaces/list">INTERFACES</a>
                    <span> » </span>
                </div>
                <h2 style={{ marginBottom: "15px" }}>
                    {device && iface ? `${device} ${iface}` : ``}
                </h2>
                <dl className="row">
                    <dt className="col-sm-2">Total Link Capacity</dt>
                    <dd className="col-sm-10">{formatBytes(capacity, 0, "", 1000, false)}</dd>
                </dl>
            </div>
        );
    };

    const renderPercentileChart = (
        isLoading,
        percentileData,
        speed,
        triggers,
        scale,
        csvData,
        csvName
    ) => {
        const capacity = speed !== 0 ? speed * 1000000 : 1;
        const percentiles =
            percentileData && speed
                ? percentileData.interfaceUtilization.percentiles.map((p) => {
                      const pp = JSON.parse(p);
                      return [pp.percentile / 100, pp.in / capacity, pp.out / capacity];
                  })
                : [];
        return (
            <Widget
                error={error}
                loading={isLoading || !percentileData}
                height={400}
                downloadName={csvName}
                downloadableCSVData={() => csvData}
                downloadableAsImage
                info={DESCRIPTION}
            >
                <UtilizationChart
                    percentiles={percentiles}
                    capacity={capacity}
                    marker={0.95}
                    triggers={triggers}
                    scale={scale}
                    numTicks={10}
                />
            </Widget>
        );
    };

    const renderTrafficChart = (isLoading, traffic, timerange) => {
        // Defines the layout of the traffic chart, which in this case is just one
        // timeseries in one row, so pretty simple.
        const layout = [
            {
                height: 200,
                charts: [
                    {
                        id: "traffic",
                        series: traffic,
                        type: "area",
                        label: "Traffic (bps)",
                        colors: ["#4a92de", "#fd9420"],
                        mode: "up-down",
                        interpolation: "curveLinear",
                    },
                ],
            },
        ];

        return (
            <MultiChannelChartWidget
                layout={layout}
                title="Interface traffic"
                description="Shows interface traffic for the currently selected month."
                timeRange={timerange}
                isLoading={isLoading}
            />
        );
    };

    const csvData = convertPercentilesToCSV(data, speed);
    const csvName = `utilization_${month}_${device}_${iface}`;

    const isTrafficLoading = trafficStatus === true;

    return (
        <Col>
            <Row>
                <Col>
                    <Row>
                        <Col md={7}>{renderInterfaceHeader(device, iface, speed)}</Col>
                        <Col md={5} style={{ marginTop: 20 }}>
                            <ul className="nav nav-pills" style={{ float: "right" }}>
                                {navItems}
                            </ul>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row>
                <Col md={6}>
                    <div>
                        <Legend
                            type="line"
                            style={upDownStyle}
                            categories={[
                                { key: "in", label: "In traffic" },
                                { key: "out", label: "Out traffic" },
                            ]}
                        />
                    </div>
                </Col>
                <Col md={3}>
                    <div>
                        <span
                            style={scale === "log" ? linkStyleActive : linkStyle}
                            onClick={() => setScale("log")}
                        >
                            Log
                        </span>{" "}
                        |{" "}
                        <span
                            style={scale === "linear" ? linkStyleActive : linkStyle}
                            onClick={() => setScale("linear")}
                        >
                            Linear
                        </span>
                    </div>
                </Col>
                <Col md={3}>
                    <div style={{ width: "100%" }}>
                        <MonthPicker />
                    </div>
                </Col>
            </Row>

            <Row>
                <Col md={12}>
                    {renderPercentileChart(loading, data, speed, triggers, scale, csvData, csvName)}
                </Col>
            </Row>

            <Row>
                <Col>
                    <hr />
                </Col>
            </Row>

            <Row>
                <Col md={12}>{renderTrafficChart(isTrafficLoading, traffic, timerange)}</Col>
            </Row>
        </Col>
    );
};
