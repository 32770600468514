import React from "react";
import { Star, StarFill } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { useUser } from "shared/hooks";

/**
 * A single site rendered as a table row
 */
const SiteListRow = (props) => {
    const { shortName, fullName } = props.site;
    const { isFavorite } = props;
    const user = useUser();

    const star = isFavorite ? (
        <StarFill color="#4EC1E0" onClick={props.onFavoriteChange} />
    ) : (
        <Star color="#D0D0D0" onClick={props.onFavoriteChange} />
    );

    return (
        <tr>
            {user && !user.isAnonymous ? <td>{star}</td> : <td />}
            <td>
                <Link to={`/sites/view/${shortName}`}>{shortName}</Link>
            </td>
            <td>
                <Link to={`/sites/view/${shortName}`}>{fullName}</Link>
            </td>
        </tr>
    );
};

export default SiteListRow;
