import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";
import { Star, StarFill } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { AutoSizer, Column, Table } from "react-virtualized";
import "react-virtualized/styles.css";
import { Spinner } from "shared/components/controls";
import { rowCountStyle, totalRowCountStyle } from "shared/styles/styles";
import { useSortState } from "shared/hooks";
import _ from "underscore";

export const GET_COLLABORATORS_QUERY = gql`
    query {
        networkEntities(entityType: "LHCONE") {
            id
            type
            shortName
            fullName
            programOffice {
                shortName
            }
            programArea
            parent {
                shortName
            }
            tags
        }
    }
`;

export class FilterableCollaboratorList {
    constructor(collaborators) {
        this.collaborators = collaborators;
    }

    isLoaded() {
        return !_.isUndefined(this.collaborators);
    }

    get(index) {
        return this.collaborators[index];
    }

    filterByText(textFilter) {
        if (!this.isLoaded()) {
            return this;
        }
        if (!textFilter || _.isUndefined(textFilter) || textFilter.length === 0) {
            return this;
        }

        const lowerTextFilter = textFilter.toLowerCase();
        return new FilterableCollaboratorList(
            _.filter(this.collaborators, (collaborator) => {
                const { shortName = "", fullName = "" } = collaborator;
                return (
                    shortName.toLowerCase().indexOf(lowerTextFilter) >= 0 ||
                    fullName.toLowerCase().indexOf(lowerTextFilter) >= 0
                );
            })
        );
    }

    filterByExperiment(experiment) {
        if (!this.isLoaded()) {
            return this;
        }

        return new FilterableCollaboratorList(
            _.filter(this.collaborators, (collaborator) => {
                return experiment === "all" || _.contains(collaborator.tags, experiment);
            })
        );
    }

    // Custom sorting based either on the device/interface, sub-sorted by capacity,
    // or sorting based on current traffic rate.
    sort(sortBy, sortDirection) {
        if (!this.isLoaded()) {
            return this;
        }

        switch (sortBy) {
            case "fullName":
                if (sortDirection === "ASC") {
                    return new FilterableCollaboratorList(
                        _.chain(this.collaborators).sortBy("fullName").value()
                    );
                } else {
                    return new FilterableCollaboratorList(
                        _.chain(this.collaborators)
                            .sortBy("name")
                            .sortBy("device")
                            .reverse()
                            .sortBy("speed")
                            .value()
                    );
                }
            case "traffic":
                if (sortDirection === "ASC") {
                    return new FilterableCollaboratorList(
                        _.chain(this.collaborators)
                            .sortBy((value) => {
                                if (value.traffic) {
                                    return value.traffic.points[0][1] + value.traffic.points[0][2];
                                } else {
                                    return 0;
                                }
                            })
                            .reverse()
                            .value()
                    );
                } else {
                    return new FilterableCollaboratorList(
                        _.chain(this.collaborators)
                            .sortBy((value) => {
                                if (value.traffic) {
                                    return value.traffic.points[0][1] + value.traffic.points[0][2];
                                } else {
                                    return 0;
                                }
                            })
                            .value()
                    );
                }
            default:
                break;
        }
    }

    size() {
        if (!this.isLoaded()) {
            return 0;
        } else {
            return this.collaborators.length;
        }
    }

    // Returns the max traffic for the interfaces
    max() {
        if (!this.isLoaded()) {
            return 0;
        }

        const maxInList = this.collaborators.map((iface) => {
            if (iface.traffic) {
                return iface.traffic.points[0][1];
            } else {
                return 0;
            }
        });
        const maxOutList = this.collaborators.map((iface) => {
            if (iface.traffic) {
                return iface.traffic.points[0][2];
            } else {
                return 0;
            }
        });

        const maxIn = Math.max(...maxInList);
        const maxOut = Math.max(...maxOutList);

        return Math.max(maxIn, maxOut);
    }
}

export const CollaboratorTable = ({
    text,
    experiment,
    height,
    showFavorites,
    favorites,
    onFavoriteChange,
}) => {
    const rowHeight = 30;
    const { loading, data } = useQuery(GET_COLLABORATORS_QUERY);

    // Sorting settings are stored on the URL
    const [{ sortBy, sortDirection }, setSortState] = useSortState("fullName", "ASC");

    const renderFavorites = (favorites, onFavoriteChange) => {
        return ({ rowData }) => {
            // Determine if this row is a favorite of this user
            let isFavorite = false;
            favorites.forEach((entity) => {
                if (entity.shortName === rowData.shortName && entity.type === "LHCONE") {
                    isFavorite = true;
                }
            });

            // Filled or not filled star
            const star = isFavorite ? (
                <span
                    style={{ cursor: "pointer" }}
                    onClick={() => onFavoriteChange(rowData, false)}
                >
                    <StarFill color="#4EC1E0" />
                </span>
            ) : (
                <span style={{ cursor: "pointer" }} onClick={() => onFavoriteChange(rowData, true)}>
                    <Star color="#D0D0D0" />
                </span>
            );

            return <span>{star}</span>;
        };
    };

    const renderFullName = ({ rowData }) => {
        const fullName = rowData.fullName;
        const shortName = rowData.shortName;
        return <Link to={`/lhcone/view/${shortName}`}>{fullName}</Link>;
    };

    const renderShortName = ({ rowData }) => {
        const name = rowData.shortName;
        return `${name}`;
    };

    const renderExperiments = ({ rowData }) => {
        const tagStyle = {
            border: "1px solid #D0D0D0",
            borderRadius: "4px",
            padding: "2px 3px",
            fontSize: "smaller",
        };

        const tags = rowData.tags;

        const tagDisplay = [];
        tags.forEach((tag, index) => {
            tagDisplay.push(
                <span style={tagStyle} key={`${index}`}>
                    {`${tag}`}
                </span>
            );
            tagDisplay.push(<span key={`${index}-space`}>&nbsp;</span>);
        });
        return tagDisplay;
    };

    const renderTotalRows = (rowCount, totalRowCount) => {
        if (rowCount < totalRowCount) {
            return <span style={totalRowCountStyle}>(of {totalRowCount})</span>;
        }
        return <span></span>;
    };

    if (loading) {
        return <Spinner />;
    } else if (data) {
        // Filtered and sorted list of collaborators
        const CollaboratorList = new FilterableCollaboratorList(data.networkEntities)
            .filterByText(text)
            .filterByExperiment(experiment)
            .sort(sortBy, sortDirection);

        // Rows remaining in the filtered list
        const rowCount = CollaboratorList.size();

        return (
            <div>
                <Row>
                    <Col md={12}>
                        <span style={rowCountStyle}>
                            Displaying {rowCount}{" "}
                            {renderTotalRows(rowCount, data.networkEntities.length)} Results
                        </span>
                    </Col>
                </Row>

                <AutoSizer disableHeight>
                    {({ width }) => (
                        <Table
                            gridStyle={{ outline: 0 }}
                            width={width}
                            height={height}
                            headerHeight={20}
                            headerStyle={{ outline: 0 }}
                            rowHeight={rowHeight}
                            sort={({ sortBy, sortDirection }) =>
                                setSortState(sortBy, sortDirection)
                            }
                            sortBy={sortBy}
                            sortDirection={sortDirection}
                            rowCount={rowCount}
                            rowGetter={({ index }) => CollaboratorList.get(index)}
                        >
                            <Column
                                width={showFavorites ? 20 : 0}
                                label=" "
                                dataKey="favorite"
                                cellRenderer={
                                    showFavorites
                                        ? renderFavorites(favorites, onFavoriteChange)
                                        : () => <div />
                                }
                            />
                            <Column
                                width={300}
                                label="Short name"
                                dataKey="shortName"
                                disableSort={true}
                                cellRenderer={renderShortName}
                            />

                            <Column
                                width={400}
                                label="Full name"
                                dataKey="fullName"
                                cellRenderer={renderFullName}
                            />

                            <Column
                                width={300}
                                label="Experiments"
                                dataKey="tags"
                                disableSort={true}
                                cellRenderer={renderExperiments}
                            />
                        </Table>
                    )}
                </AutoSizer>
            </div>
        );
    } else {
        return <div />;
    }
};
