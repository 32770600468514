import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { EdgeView } from "./components/EdgeView";

const NoMatch = () => (
    <div>
        <h2>Whoops</h2>
        <p>Sorry but this edge URL didn’t find any pages</p>
    </div>
);

const Edges = () => {
    let { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={`${path}/view/:edgeType/:edge`}>
                <EdgeView />
            </Route>
            <Route path="*">
                <NoMatch />
            </Route>
        </Switch>
    );
};

export default Edges;
