export default `
## Frequently asked questions

---

**Which browsers are supported?**

We do not target our development towards specific browsers. Instead we develop to open standards and strive to work in as many modern browsers as possible. We are a small team and do not have the resources to handle compatibility with older browsers.

In general the site should work with any browser that has been released in the last 12-18 months. We routinely test against Chrome, Firefox and Safari.

---

**What technologies were used to build the portal?**

At a high level the ESnet Portal can be broken down into the following components: the backend data collection system known as Stardust; the time series 
manipulation library named Pond that helps prepare and transport this data; and the web application itself, built largely on our publicly available software 
components (see next question). 

Stardust is developed by ESnet and borrows many concepts from the [NetSage](https://www.netsage.global/) project. It combines multiple open source technologies to collect metrics 
from a variety of network data streams such as SNMP and flow. These metrics are ultimately stored in an [Elasticsearch](https://www.elastic.co/elasticsearch/) cluster where they are made 
available to applications such as the portal. More information on Stardust can be found in the video [here](https://www.youtube.com/watch?v=aooGaT0eers).

---

**Is the ESnet Portal software available as open source?**

Some of it.

The ESnet Portal itself is not an open source project, but many of the components ESnet developed to implement it are. For example:

 * [React Network Diagrams](http://software.es.net/react-network-diagrams) - This is used for the front page network map as well as circuit diagrams elsewhere on the site.
 * [React TimeSeries Charts](http://software.es.net/react-timeseries-charts) - This is used for various TimeSeries visualizations on the site.
 * [Pond.js](http://software.es.net/pond) - A JavaScript version of the timeseries abstraction library we used to unify timeseries structures and processing throughout the site.
 * [pypond](http://software.es.net/pypond) - A Python version of the timeseries abstraction library we used to unify timeseries structures and processing throughout the site.

Most of the front-end code (the charts, maps, etc.) is built on top of [React](https://facebook.github.io/react/).

Currently our back-end code is heavily tied to ESnet, so is unlikely to be useful outside of ESnet.

Please contact the ESnet Software Engineering Group if you are interested in using the ESnet Portal software and we can help you get started. There are also some tech talks on this software:

 * [CHI-NOG 06 Talk - Modern Tools for Visualizing Network Traffic](https://www.youtube.com/watch?v=86hhxwPCmQk)
 * [Internet2 Joint Techs 2015 Talk](https://meetings.internet2.edu/2015-technology-exchange/detail/10003924/)
 * [CHI-NOG 07 Talk - Flexible Network Analytics In The Cloud](http://x1024.net/blog/2017/05/chinog-flexible-network-analytics-in-the-cloud/)
`.trim();
