import { sum, TimeRange, TimeSeries } from "pondjs";
import PropTypes from "prop-types";
import React from "react";
import { AreaChart, ChartContainer, ChartRow, Charts, YAxis } from "react-timeseries-charts";
import _ from "underscore";

/**
 * Renders the an up/down stacked area chart for a series.
 *
 * Pass in the `series` (a Pond TimeSeries), as well as which columns should be mapped
 * to the up and down directions (`up` and `down` props) and it will take care
 * of the rest.
 *
 * You can also pass in `selectedSeries` which will render visually in front
 * of the background `series`, which is nice for displaying a component part
 * of the series.
 */
export default class extends React.Component {
    static propTypes = {
        style: PropTypes.object,
        width: PropTypes.number,
        series: PropTypes.instanceOf(TimeSeries),
        selectedSeries: PropTypes.instanceOf(TimeSeries),
        timerange: PropTypes.instanceOf(TimeRange),
        up: PropTypes.arrayOf(PropTypes.string).isRequired,
        down: PropTypes.arrayOf(PropTypes.string).isRequired,
    };

    static defaultProps = {
        up: ["in"],
        down: ["out"],
    };

    updateChartScale = (timeseries, up, down) => {
        const downSums = timeseries.collapse({
            name: "value",
            fieldSpecList: down,
            reducer: sum(),
            append: false,
        });
        const upSums = timeseries.collapse({
            name: "value",
            fieldSpecList: up,
            reducer: sum(),
            append: false,
        });
        this._max = _.max([upSums.max(), downSums.max()]);
    };

    handleTrackerChanged = (t) => {
        if (this.props.onTrackerChanged) {
            this.props.onTrackerChanged(t);
        }
    };

    handleTimeRangeChanged = (tr) => {
        if (this.props.onTimeRangeChanged) {
            this.props.onTimeRangeChanged(tr);
        }
    };

    UNSAFE_componentWillMount() {
        const { series, up, down } = this.props;
        this.updateChartScale(series, up, down);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { series, up, down, timerange } = nextProps;
        if (
            (series && !TimeSeries.is(series, this.props.series)) ||
            !timerange.equals(this.props.timerange)
        ) {
            this.updateChartScale(series, up, down);
        }
    }

    render() {
        const { series, selectedSeries, up, down, width, style, mutedStyle } = this.props;
        const timerange = this.props.timerange || series.range();
        if (up.length > 0) {
            const columns = {
                up: this.props.up,
                down: this.props.down,
            };
            let charts, axis;
            if (selectedSeries) {
                charts = (
                    <Charts>
                        <AreaChart
                            key="bg"
                            axis="series"
                            series={series}
                            columns={columns}
                            style={mutedStyle}
                        />
                        <AreaChart
                            key="fg"
                            axis="series"
                            series={selectedSeries}
                            columns={columns}
                            style={style}
                        />
                    </Charts>
                );
            } else {
                charts = (
                    <Charts>
                        <AreaChart axis="series" series={series} columns={columns} style={style} />
                    </Charts>
                );
            }

            if (down.length > 0) {
                axis = (
                    <YAxis
                        id="series"
                        width="60"
                        absolute={true}
                        transition={200}
                        min={-this._max}
                        max={this._max}
                        type={"linear"}
                    />
                );
            } else {
                axis = (
                    <YAxis
                        id="series"
                        width="60"
                        transition={200}
                        min={0}
                        max={this._max}
                        type={"linear"}
                    />
                );
            }

            return (
                <ChartContainer
                    width={width}
                    timeRange={timerange}
                    trackerPosition={this.props.tracker}
                    enablePanZoom={true}
                    onTrackerChanged={this.handleTrackerChanged}
                    onTimeRangeChanged={this.handleTimeRangeChanged}
                >
                    <ChartRow height="220">
                        {charts}
                        {axis}
                    </ChartRow>
                </ChartContainer>
            );
        } else {
            return <div />;
        }
    }
}
