import { useHistory, useLocation } from "react-router-dom";
import _ from "underscore";
import { useSearchParams } from "./useSearchParams";

/**
 * useEntitySelection() is a react hook used to get and set a tuple of selection and selectionType into the URL.
 * In certain cases when an edge is clicked, it also holds the edge type. This corresponds to the map code
 * which enables selection of sites, hubs and edges. That selection handler can then use setSelection(type, selection)
 * to update, while selection will hold the current state.
 *
 * The source of truth for the selection and selectionType is on the URL's query string, using the query variables
 * `s` and `st` respectively. If an edge is clicked, then the query string also holds the type of the edge in the
 * variable `et`
 */
export const useEntitySelection = () => {
    const query = useSearchParams();
    const history = useHistory();
    const { pathname } = useLocation();

    const selection = query.get("s");
    const selectionType = query.get("st");
    const edgeType = query.get("et");

    const handleChangeSelection = (s, st, edge) => {
        const targetQuery = new URLSearchParams(query);
        if (_.isUndefined(s) || _.isUndefined(st)) {
            targetQuery.delete("s");
            targetQuery.delete("st");
            targetQuery.delete("et");
        } else {
            targetQuery.set("s", s);
            targetQuery.set("st", st);

            if (!_.isUndefined(edge)) {
                targetQuery.set("et", edge);
            }
        }
        // Transition URL
        history.push(`${pathname}?${targetQuery.toString()}`);
    };

    return [{ selection, selectionType, edgeType }, handleChangeSelection];
};
