/**
 * Collection of useful functions related to month picking
 */

/**
 * Utility function to get the current month
 */
export function getCurrentMonth() {
    const d = new Date();
    const month = ("0" + (d.getMonth() + 1)).slice(-2);
    const year = d.getFullYear();
    return `${year}-${month}`;
}

/**
 * Utility function to get the previous month
 */
export function getLastMonth() {
    const d = new Date();
    const month = ("0" + d.getMonth()).slice(-2);
    const year = d.getFullYear();
    return `${year}-${month}`;
}
