import React, { useRef, useState } from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import { Download, ExclamationTriangle, InfoCircle, XCircle } from "react-bootstrap-icons";
import Markdown from "react-markdown";
import useDimensions from "react-use-dimensions";
import { saveSvgAsPng } from "save-svg-as-png";
import _ from "underscore";
import { Spinner } from "shared/components/controls/Spinner";

const saveDataAsCSV = (filename, rows) => {
    const processRow = (row) => {
        var finalVal = "";
        for (var j = 0; j < row.length; j++) {
            var innerValue = row[j] === null ? "" : row[j].toString();
            if (row[j] instanceof Date) {
                innerValue = row[j].toLocaleString();
            }
            var result = innerValue.replace(/"/g, '""');
            if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
            if (j > 0) finalVal += ",";
            finalVal += result;
        }
        return finalVal + "\n";
    };

    let csvFile = "";
    for (var i = 0; i < rows.length; i++) {
        csvFile += processRow(rows[i]);
    }

    let blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, filename);
    } else {
        let link = document.createElement("a");
        if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            let url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
};

const DownloadMenu = React.forwardRef(({ children, onClick }, ref) => (
    <span
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        <Download color="#222" />
    </span>
));

class WidgetErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error("Error boundary in widget", errorInfo);
    }

    render() {
        if (this.state.hasError) {
            const style = {
                color: "#A94442",
                background: "#EEEEEE",
                padding: 10,
                borderStyle: "solid",
                borderRadius: 3,
                borderColor: "#D0D0D0",
                borderWidth: 1,
                height: 100,
            };
            // You can render any custom fallback UI
            return (
                <div style={style}>
                    <span>
                        <ExclamationTriangle />
                    </span>
                    <span style={{ paddingLeft: 5 }}>
                        Error: An unhandled error occured in this widget
                    </span>
                </div>
            );
        } else {
            return this.props.children;
        }
    }
}

/**
 * Creates a generic container around the child. The child will
 * have a `width` prop injected, similar to the Resizable component.
 *
 * Additionally, you can provide a `info` prop, the text of which
 * will be displayed when you click the (i) info button, a `loading`
 * flag which will display a spinner while true (rather than the
 * child), and an `error` string.
 *
 * Props:
 *   height: PropTypes.number,
 *   info: PropTypes.string,
 *   error: PropTypes.string,
 *   loading: PropTypes.bool,
 *   children: PropTypes.element.isRequired,
 */
const Widget = ({
    height = 200,
    info,
    error,
    loading,
    noBorder = false,
    padding = false,
    downloadName,
    downloadBackgroundColor,
    downloadableAsImage,
    downloadableCSVData,
    downloadableJSON,
    aspect,
    children,
    contentPadding = true,
}) => {
    // State if the user has clicked on the info button and the page is showing the overlay
    const [showInfo, setShowInfo] = useState(false);

    // Get the dimensions of our container
    const [ref, { width }] = useDimensions();

    // Reference to the content inside this widget
    const contentRef = useRef(null);

    // Children have the width and height injected into them
    const child = React.Children.only(children);
    const childElement = width
        ? React.cloneElement(child, { width, height: aspect ? width / aspect : height })
        : null;
    const h = height;

    //
    // Styles
    //

    const errorStyle = {
        position: "absolute",
        bottom: 5,
        left: 5,
        zIndex: 1,
    };

    const buttonOverlayStyle = {
        position: "absolute",
        bottom: 5,
        right: 5,
    };

    const loadingStyle = {
        position: "absolute",
        top: h ? parseInt(height / 2, 10) - 5 : 100,
        right: width ? parseInt(width / 2, 10) - 10 : 0,
    };

    const placeholderContainerStyle = {
        position: "relative",
        background: "#FFF",
        borderStyle: noBorder ? "none" : "solid",
        borderRadius: 3,
        borderColor: "#EEE",
        borderWidth: 1,
        height,
    };

    const overlayStyle = {
        position: "absolute",
        top: 0,
        padding: 20,
        width: "100%",
        height: "100%",
        borderRadius: 3,
        opacity: 0.9,
        background: "#FFF",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#D0D0D0",
    };

    const contentStyle = {
        padding: padding ? 10 : 0,
        paddingBottom: padding ? 20 : 0,
        width,
    };

    const infoButton = (
        <span
            key="info"
            style={{ marginLeft: 4, cursor: "pointer" }}
            onClick={() => setShowInfo(true)}
        >
            <InfoCircle />
        </span>
    );

    let downloadButton = <span key="download" />;

    const handleCSVDownload = () => {
        const csvData = _.isFunction(downloadableCSVData)
            ? downloadableCSVData()
            : downloadableCSVData;
        const filename = `${downloadName ? downloadName : "download"}.csv`;
        saveDataAsCSV(filename, csvData);
    };

    const handlePngDownload = (contentRef) => {
        const filename = `${downloadName ? downloadName : "download"}.png`;
        if (contentRef && contentRef.current) {
            const innerSVGElements = contentRef.current.getElementsByTagName("svg");
            if (innerSVGElements.length > 0) {
                saveSvgAsPng(innerSVGElements[0], filename, {
                    backgroundColor: downloadBackgroundColor ? downloadBackgroundColor : null,
                });
            }
        }
    };

    const handleJSONDownload = () => {
        const jsonData = _.isFunction(downloadableJSON) ? downloadableJSON() : downloadableJSON;
        const filename = `${downloadName ? downloadName : "download"}.json`;
        const json = JSON.stringify(jsonData, null, 3);
        const blob = new Blob([json], { type: "application/json" });
        const href = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = href;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const downloadMenuItems = [];
    if (downloadableAsImage) {
        downloadMenuItems.push(
            <Dropdown.Item onClick={() => handlePngDownload(contentRef)}>
                Download as image
            </Dropdown.Item>
        );
    }

    if (downloadableCSVData) {
        downloadMenuItems.push(
            <Dropdown.Item onClick={() => handleCSVDownload()}>Download as CSV</Dropdown.Item>
        );
    }

    if (downloadableJSON) {
        downloadMenuItems.push(
            <Dropdown.Item onClick={() => handleJSONDownload()}>Download as JSON</Dropdown.Item>
        );
    }

    downloadButton = (
        <span key="download" style={{ marginLeft: 4, cursor: "pointer" }}>
            <Dropdown style={{ display: "inline" }}>
                <Dropdown.Toggle as={DownloadMenu} id="dropdown-menu" />
                <Dropdown.Menu>{downloadMenuItems}</Dropdown.Menu>
            </Dropdown>
        </span>
    );

    const dismissButton = (
        <span style={{ cursor: "pointer" }} onClick={() => setShowInfo(false)}>
            <XCircle />
        </span>
    );

    let infoOverlay;
    if (showInfo) {
        const infoText = info || "";
        infoOverlay = (
            <div style={overlayStyle} onClick={() => setShowInfo(false)}>
                <Row>
                    <Col>
                        <Markdown source={infoText} />
                    </Col>
                </Row>
                <div style={buttonOverlayStyle}>{dismissButton}</div>
            </div>
        );
    } else {
        infoOverlay = null;
    }

    //
    // Main contents
    //

    let contents;
    let usedContainerStyle = placeholderContainerStyle;

    if (error) {
        contents = (
            <div width={width} height={height}>
                <div style={errorStyle} height={height}>
                    <span>
                        <ExclamationTriangle />
                    </span>
                    <span style={{ paddingLeft: 5 }}>{error}</span>
                </div>
            </div>
        );
    } else if (loading) {
        contents = (
            <div style={loadingStyle}>
                <Spinner />
            </div>
        );
    } else {
        const { height: h, ...autoHeightStyle } = placeholderContainerStyle;
        usedContainerStyle = autoHeightStyle;
        contents = <div style={contentStyle}>{childElement}</div>;
    }

    const buttons = [];
    if (downloadMenuItems.length > 0) {
        buttons.push(downloadButton);
    }
    if (info) {
        buttons.push(infoButton);
    }
    const infoButtonContainer = <div style={buttonOverlayStyle}>{buttons}</div>;

    return (
        <WidgetErrorBoundary>
            <div style={usedContainerStyle} ref={ref}>
                <div
                    ref={contentRef}
                    style={{
                        paddingTop: contentPadding ? 5 : 0,
                        paddingBottom: contentPadding ? 5 : 0,
                    }}
                >
                    {contents}
                </div>
                {infoButtonContainer}
                {infoOverlay}
            </div>
        </WidgetErrorBoundary>
    );
};

export default Widget;
