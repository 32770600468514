import { useQuery } from "@apollo/react-hooks";
import { NetworkStatus } from "apollo-client";
import { useMemo, useRef } from "react";
import _ from "underscore";

export const GRAPHQL_INITIAL_LOADING = 1;
export const GRAPHQL_VARIABLES_RELOADING = 2;
export const GRAPHQL_REFETCH = 4;
export const GRAPHQL_POLLING = 6;
export const GRAPHQL_READY = 7;

/**
 * Requests data using a GraphQL query then memorizes the result between renders.
 * The data is updated if the deps are changed, or the network status changes.
 *
 * Specifiy the GraphQL `query` as the first arg. Specifiy the GraphQL query options (e.g.
 * variables) in the second arg.  For the third arg you should supply an extraction function
 * to isolate and post-process data within the query result. The final arg is the dependencies
 * that should be considered when memorizing the result.
 *
 * The hook returns the data from the post-process extraction function, along with the
 * load status and error status.
 */
export const useMemoQuery = (query, queryOptions = {}, extract = (d) => d, deps = []) => {
    const variablesRef = useRef({});

    // Load the query from the GraphQL server
    const queryResult = useQuery(query, queryOptions, extract);

    // Get the resulting data, along with an error, and the current network status
    const { data: d, error, networkStatus } = queryResult;

    // Loading states
    const isInitialLoading = networkStatus === NetworkStatus.loading;
    const isReady = networkStatus === NetworkStatus.ready;
    const isVariableUpdate = networkStatus === NetworkStatus.setVariables;

    // Determine if the update to the variables (based on the variables in the last
    // ready state) is not just a time range change. If so it's considered a noisy
    // variable update and the loading state will be shown (meaning this is not just
    // a time series refresh with new begin and end times but rather something the
    // user would expect to refresh the data completely and show something new.
    const prev = _.omit(variablesRef.current, ["beginTime", "endTime"]);
    const current = _.omit(queryOptions.variables, ["beginTime", "endTime"]);
    const isNoisyVariableUpdate = isVariableUpdate && !_.isEqual(prev, current);

    // Our memorized data is built with this function. The function returns the result of the
    // user extraction function if the data loaded, and we're not in an initial loading or error state
    // TODO: why does two cases here return an object and one case probably a timeseries
    const build = (data, error) => {
        if (isInitialLoading || !data) {
            return { data: null };
        } else if (error) {
            return { data: null, error: "Could not load data from server" };
        } else {
            return extract(data);
        }
    };

    // Memorized data is recalculated when we either leave the ready state or re-enter it
    // meaning that data is done loading. However, if we leave the ready state for a silent
    // update then we don't change the data memo here

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const data = useMemo(() => build(d, error), [isReady, build, d, error, ...deps]);

    // If we're in the ready state, keep the variables ref up to data. When out of this state
    // the ref will tell us if this is a time range (silent) update or if it's a visbile
    // loading state to the user.
    if (isReady) {
        variablesRef.current = queryOptions.variables;
    }

    return { isLoading: isInitialLoading || isNoisyVariableUpdate, error, data };
};
