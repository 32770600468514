import React from "react";
import { Col, Row } from "react-bootstrap";
import Markdown from "react-markdown";
import text from "./faq.md.js";
import { breadcrumbStyle } from "shared/styles/styles";
import { setHtmlTitle } from "shared/utils/entity-utils";

export default () => (
    <Row>
        {/* Set HTML title tag */}
        {setHtmlTitle("FAQ")}
        <Col md={2} />
        <Col md={8}>
            <div style={breadcrumbStyle}>
                <a href="/">HOME</a>
                <span> » </span>
            </div>

            <Markdown source={text} />
        </Col>
        <Col md={2} />
    </Row>
);
