import gql from "graphql-tag";
import React from "react";
import Card from "shared/components/cards/Card";
import { Spinner } from "shared/components/controls";
import { useMemoQuery } from "shared/hooks";
import {
    cardStyle,
    cardStyleActive,
    cardStyleError,
    moreInfoLinkStyle,
} from "shared/styles/styles";
import { setHtmlTitle } from "shared/utils/entity-utils";

export const SITE_INFO_QUERY = gql`
    query siteInfo($shortName: String) {
        networkEntity(shortName: $shortName, entityType: "ESnet Site") {
            id
            shortName
            fullName
            type
            description
            parent {
                shortName
                fullName
            }
        }
    }
`;

/**
 * Renders a card which shows the site name and a link to the site page. This
 * could be expanded to show current traffic to/from the site etc.
 */
export const SiteCard = ({ siteName, isSelected }) => {
    const queryOptions = {
        variables: { shortName: siteName },
    };

    // prettier-ignore
    const {
        isLoading,
        error,
        data: entity,
    } = useMemoQuery(SITE_INFO_QUERY, queryOptions, (d) => d.networkEntity, [siteName]);

    if (isLoading) {
        return (
            <Card overLevel={2} outLevel={1} level={1} width="100%" style={cardStyle}>
                <Spinner />
            </Card>
        );
    } else if (error || !entity || entity.data === null) {
        return (
            <Card
                overLevel={2}
                outLevel={1}
                level={1}
                width="100%"
                height={100}
                style={cardStyleError}
            >
                Unable to load site information
            </Card>
        );
    } else {
        // Set HTML title tag
        const pageTitle = `${entity.type} : ${entity.fullName}`;
        setHtmlTitle("Map", pageTitle, 80);

        return (
            <Card
                overLevel={2}
                outLevel={1}
                level={1}
                width="100%"
                style={isSelected ? cardStyleActive : cardStyle}
            >
                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <b>{entity.type}</b>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>{entity.fullName}</b>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ marginTop: "80px" }}>{entity.description}</td>
                        </tr>
                        <tr>
                            <td>
                                {" "}
                                <a
                                    style={moreInfoLinkStyle}
                                    href={`/sites/view/${entity.shortName}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <span> MORE INFO » </span>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Card>
        );
    }
};
