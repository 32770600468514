import gql from "graphql-tag";
import React, { useRef } from "react";
import TimeSeriesListBarChartWidget from "shared/components/widgets/TimeseriesListBarchartWidget";
import { useMemoQuery } from "shared/hooks";
import { chartBlue, chartOrange, selectionColor } from "shared/styles/styles";
import { toTimeSeriesList } from "shared/utils/timeseries-utils";

export const FLOW_BREAKDOWN_TRAFFIC_QUERY = gql`
    query getLHCONEFlowTraffic(
        $collaboratorName: String
        $breakdown: String
        $beginTime: String
        $endTime: String
    ) {
        networkEntity(shortName: $collaboratorName, entityType: "LHCONE") {
            id
            flow(breakdown: $breakdown, beginTime: $beginTime, endTime: $endTime) {
                traffic
            }
        }
    }
`;

const style = {
    in: { fill: chartBlue },
    out: { fill: chartOrange },
};

const DESCRIPTION = `
#### Traffic breakdown (by flow type)\n\n
After filtering the traffic flowing in and out of this network entity, 
this chart groups the top flows by the breakdown selected such as 
the protocol, tcp ports or lhcone participants. in descending order of volume.

The set of values returned is then used by the Total Site Traffic chart above to 
display the individual incoming and outgoing traffic.
`;

const CHANNELS = ["in", "out"];

export const CollaboratorFlowBarChart = ({
    collaboratorName,
    timerange,
    timestamp,
    breakdown,
    selected,
    onSelectionChanged,
}) => {
    const beginTime = timerange.begin().toISOString();
    const endTime = timerange.end().toISOString();
    let totalSeries = useRef(null);
    let seriesList = useRef([]);

    const queryOptions = {
        variables: { collaboratorName, breakdown, beginTime, endTime },
        fetchPolicy: "no-cache",
    };

    const {
        isLoading,
        isReloading,
        error,
        data: { timeSeriesList, timeSeries },
    } = useMemoQuery(
        FLOW_BREAKDOWN_TRAFFIC_QUERY,
        queryOptions,
        (d) => {
            const { totalSeries, seriesList } = toTimeSeriesList(
                "traffic",
                d.networkEntity.flow,
                { name: "Flow", channels: CHANNELS },
                (d) => JSON.parse(d.traffic)
            );

            return {
                timeSeriesList: seriesList,
                timeSeries: totalSeries,
            };
        },
        [beginTime, endTime, breakdown]
    );

    const title = breakdown && !isReloading ? `Top flows by ${breakdown}` : `Top flows`;

    if (timeSeries !== undefined) {
        totalSeries.current = timeSeries;
    }

    if (timeSeriesList !== undefined) {
        seriesList.current = timeSeriesList;
    }

    let errMsg;
    if (error) {
        errMsg = "Unable to load flow data from server";
    }

    // Sorting by name since the list returned by GraphQL is in descending order of total volume
    return (
        <TimeSeriesListBarChartWidget
            title={title}
            description={DESCRIPTION}
            selected={selected}
            onSelectionChanged={onSelectionChanged}
            selectionColor={selectionColor}
            seriesList={seriesList.current}
            totalSeries={totalSeries.current}
            style={style}
            timerange={timerange}
            timestamp={timestamp}
            isLoading={isLoading}
            error={errMsg}
            sortBy={"name"}
        />
    );
};

export default CollaboratorFlowBarChart;
