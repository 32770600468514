import gql from "graphql-tag";
import React, { useRef } from "react";
import { styler } from "react-timeseries-charts";
import { TimeseriesAreachartWidget } from "shared/components/widgets";
import { useMemoQuery } from "shared/hooks";
import { toTimeSeriesNoJSON } from "shared/utils/timeseries-utils";
import { chartBlue, chartOrange } from "shared/styles/styles";
import { useSearchParams } from "shared/hooks/useSearchParams";
import { sidebarRanges } from "shared/constants/time";
import { getChartTimePeriod } from "shared/utils/param-utils";
import { formatDate } from "shared/utils/format-utils";

export const GET_ENTITY_TRAFFIC = gql`
    query getSiteTraffic($siteName: String, $beginTime: String, $endTime: String) {
        networkEntity(shortName: $siteName, entityType: "ESnet Site") {
            id
            traffic(beginTime: $beginTime, endTime: $endTime)
        }
    }
`;

// Build a options option which can be passed to the area chart
const DESCRIPTION = `
#### Total Traffic\n\n
This chart shows the total traffic flowing in and out of this network entity
based on the SNMP collection of interfaces and saps facing this site. \n
Hovering over the chart with your mouse will provide details about that specific point in time.
`;

const STYLE = styler([
    { key: "ztoa", color: chartBlue },
    { key: "atoz", color: chartOrange },
]);

const STACK_UP = ["ztoa"];
const STACK_DOWN = ["atoz"];
const LABELS = ["To site", "From site"];

export const SiteSummaryChart = ({
    siteName,
    selected,
    timerange,
    timestamp,
    onTrackerChanged,
}) => {
    const beginTime = timerange.begin().toISOString();
    const endTime = timerange.end().toISOString();
    let series = useRef(null);

    const queryOptions = {
        variables: { siteName, beginTime, endTime },
        fetchPolicy: "no-cache",
    };

    let title;
    const query = useSearchParams();
    const timePeriod = getChartTimePeriod(query, sidebarRanges);
    if (typeof timePeriod === "string") {
        title = `Site Traffic over the last ${timePeriod}`;
    } else {
        title = `Site Traffic from ${formatDate(timePeriod.begin)} to ${formatDate(
            timePeriod.end
        )}`;
    }

    // prettier-ignore
    let {
        isLoading,
        error,
        data: newSeries,
    } = useMemoQuery(
        GET_ENTITY_TRAFFIC,
        queryOptions,
        (d) => {
            // if d.mapTopologyEdge.traffic is defined and truthy
            if (Boolean(d) && Boolean(d.networkEntity) && Boolean(d.networkEntity.traffic)) {
                // Build list of Interfaces timeseries from GraphQL data
                const ts = JSON.parse(d.networkEntity.traffic);
                ts["labels"] = LABELS;
                return toTimeSeriesNoJSON(`topologyedge::${siteName}`, ts);
            } else {
                return null;
            }
        },
        [siteName, beginTime, endTime]
    );

    if (newSeries && newSeries.data !== null) {
        series.current = newSeries;
    }

    return (
        <TimeseriesAreachartWidget
            title={title}
            description={DESCRIPTION}
            series={series.current}
            timerange={timerange}
            timestamp={timestamp}
            onTrackerChanged={onTrackerChanged}
            stackUp={STACK_UP}
            stackDown={STACK_DOWN}
            style={STYLE}
            isLoading={isLoading}
            error={error}
        />
    );
};
