import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";
import { ConcatenatedCircuit, Resizable } from "react-network-diagrams";
import { Spinner } from "shared/components/controls";
import { edgeStyle, endpointStyle, routerStyle } from "shared/styles/circuit-styles";

export const GET_OSCARS_CONNECTION_PATH_QUERY = gql`
    query getOscarsConnectionPath($id: String!) {
        oscarsConnection(connectionId: $id) {
            endAName
            endZName
            fixtures {
                junction
                inMbps
                outMbps
                port
                vlan
            }
            pipes {
                ero
                a
                z
            }
        }
    }
`;

/**
 * Requests the OSCARS connection info from GraphQL given the connectionId
 * provided as a props, then renders a circuit diagram with the result.
 */
export const OscarsConnectionDiagram = ({ connectionId }) => {
    // Load Oscars connection info
    const { loading, error, data } = useQuery(GET_OSCARS_CONNECTION_PATH_QUERY, {
        variables: { id: connectionId },
    });

    if (!loading && !error) {
        const memberList = [];
        const endpoints = [];
        const routers = [];

        const connection = data.oscarsConnection;
        if (!connection) {
            return <div />;
        }

        let ero;
        let check_if_reversed = false;
        if (connection.pipes[0]) {
            check_if_reversed = true;
            ero = connection.pipes[0].ero;
        } else {
            ero = [connection.fixtures[0].junction];
        }

        // the fixtures array is unsorted, while the ERO array is sorted.
        // we will reverse the ERO as needed before rendering
        if (check_if_reversed) {
            const junction_a = connection.fixtures[0].junction;
            const ero_a = ero[0];
            const ero_z = ero[ero.length - 1];
            if (ero_z === junction_a) {
                const ero_reverse = [...ero].reverse();
                ero = ero_reverse;
            } else if (ero_a !== junction_a) {
                console.log("ERO error detected for [" + connectionId + "]");
            }
        }

        const portA = connection.fixtures[0].port.split(":")[1];
        const vlanA = connection.fixtures[0].vlan;
        const portZ = connection.fixtures[1].port.split(":")[1];
        const vlanZ = connection.fixtures[1].vlan;
        const ifaceA = `${portA}.${vlanA}`;
        const ifaceZ = `${portZ}.${vlanZ}`;

        endpoints.push(connection.endAName);
        endpoints.push(ifaceA);
        endpoints.push(ifaceA);

        ero.forEach((name) => {
            if (name.includes(":")) {
                const port = name.split(":")[1];
                endpoints.push(port);
                endpoints.push(port);
            } else {
                routers.push(name);
            }
        });

        endpoints.push(ifaceZ);
        endpoints.push(ifaceZ);
        endpoints.push(connection.endZName);

        const numMembers = routers.length * 2 + 1;
        for (let i = 0; i < numMembers; i++) {
            let member;
            const a = endpoints.shift();
            const z = endpoints.shift();
            if (i % 2) {
                const router = routers.shift();
                member = {
                    styleProperties: routerStyle,
                    endpointStyle,
                    endpointLabelA: a,
                    endpointLabelZ: z,
                    circuitLabel: router,
                    navTo: "Member 2",
                };
                memberList.push(member);
            } else {
                member = {
                    styleProperties: edgeStyle,
                    endpointStyle,
                    endpointLabelA: a,
                    endpointLabelZ: z,
                    circuitLabel: "",
                };
                memberList.push(member);
            }
        }
        return (
            <Resizable>
                <ConcatenatedCircuit
                    hadTitle={true}
                    memberList={memberList}
                    endpointLabelPosition="bottomleftangled"
                    yOffset={7}
                    endpointLabelOffset={18}
                />
            </Resizable>
        );
    } else {
        return (
            <div style={{ padding: 180 }}>
                <Spinner />
            </div>
        );
    }
};
