import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { TimePicker } from "shared/components/controls";
import "shared/css/bootstrapxl.css";
import "shared/css/main.css";
import { useQueryState, useTimeRange } from "shared/hooks";
import { CollaboratorInterfacesBarChart } from "./CollaboratorInterfacesBarChart";
import { CollaboratorInterfacesChart } from "./CollaboratorInterfacesChart";
import { CollaboratorSummaryInfo } from "./CollaboratorInfo";
import { handleNavigate } from "shared/utils/timeseries-utils";

const EVERY_60_SECONDS = 60 * 1000;

const CollaboratorInterfaces = ({ collaborator: collaboratorName }) => {
    const [timestamp, setTimestamp] = useState(null);
    const [selected, setSelected] = useQueryState("s", null);

    // Timerange as determined by the URL search params and auto-updating
    const [timerange] = useTimeRange(EVERY_60_SECONDS);

    return (
        <Col md={12}>
            <Row>
                <Col>
                    <CollaboratorSummaryInfo collaboratorName={collaboratorName} />
                </Col>
            </Row>

            <hr />

            <Row>
                <Col md={12}>
                    <div style={{ float: "right" }}>
                        <TimePicker />
                    </div>
                </Col>
            </Row>

            <hr />

            <Row>
                <Col>
                    <Row>
                        <Col md={12}>
                            <CollaboratorInterfacesChart
                                collaboratorName={collaboratorName}
                                timerange={timerange}
                                timestamp={timestamp}
                                selected={selected}
                                onTrackerChanged={(ts) => setTimestamp(ts)}
                            />
                        </Col>
                    </Row>

                    <hr />

                    <Row>
                        <Col md={12}>
                            <CollaboratorInterfacesBarChart
                                collaboratorName={collaboratorName}
                                timerange={timerange}
                                timestamp={timestamp}
                                selected={selected}
                                onSelectionChanged={(s) => setSelected(s)}
                                onNavigate={(n) => handleNavigate(n)}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Col>
    );
};

export default CollaboratorInterfaces;
