import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import Immutable from "immutable";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Field, Form, FormEditStates, Schema, TextEdit } from "react-dynamic-forms";
import { useParams } from "react-router-dom";
import { SubmitButton, UserMessage } from "shared/components/controls";

export const RESET_PASSWORD_MUTATION = gql`
    mutation ResetPassword($uidb64: String, $token: String, $password: String) {
        resetPassword(uidb64: $uidb64, token: $token, newPassword: $password) {
            success
            message
        }
    }
`;

const initialValues = {
    password1: "",
    password2: "",
};

const schema = (
    <Schema>
        <Field name="password1" label="Password" placeholder="Password" required={true} />
        <Field name="password2" label="Re-enter password" placeholder="Password" required={true} />
    </Schema>
);

const ResetPasswordForm = ({ uidb64, token }) => {
    const [value, setValue] = useState(Immutable.fromJS(initialValues));
    const [hasMissing, setHasMissing] = useState(true);
    const [hasErrors, setHasErrors] = useState(true);
    const [isComplete, setComplete] = useState(false);

    const [resetPassword, { loading, error }] = useMutation(RESET_PASSWORD_MUTATION, {
        onCompleted: () => {
            setComplete(true);
        },
    });

    const isPending = !!loading;
    const isError = !!error;

    const handleSubmit = (e) => {
        e.preventDefault();

        resetPassword({
            variables: {
                uidb64,
                token,
                password: value.get("password1"),
            },
        });
    };

    const renderSuccessMessage = () => {
        return (
            <UserMessage title="Password Reset Successful">
                <div>
                    You may now <a href="/user/login">log in</a>
                </div>
            </UserMessage>
        );
    };

    const renderError = () => {
        let msg;
        const pwd1 = value.get("password1");
        const pwd2 = value.get("password2");
        const isPasswordMismatch = pwd1 !== pwd2 && pwd2 !== "";

        if (isError) {
            msg = "Password reset failed: invalid token.";
        } else if (isPasswordMismatch) {
            msg = "Passwords do not match.";
        }

        if (msg) {
            return (
                <span key={msg} style={{ color: "#a94442", paddingLeft: 10 }}>
                    {msg}
                </span>
            );
        } else {
            return <span />;
        }
    };

    const renderSubmit = () => {
        const pwd1 = value.get("password1");
        const pwd2 = value.get("password2");
        const passwordsNotEqual = pwd1 !== pwd2;
        const disableSubmitButton = hasErrors || hasMissing || passwordsNotEqual;
        return (
            <div style={{ marginLeft: 235 }}>
                <SubmitButton
                    label="Reset"
                    pending={isPending ? "Resetting..." : null}
                    onClick={(e) => handleSubmit(e)}
                    disabled={disableSubmitButton}
                />
                <span style={{ color: "#a94442", paddingLeft: 10 }}>{renderError}</span>
            </div>
        );
    };

    const renderForm = () => {
        return (
            <div>
                <h2>Password Reset</h2>
                <hr />
                <Form
                    name="basic"
                    edit={FormEditStates.ALWAYS}
                    labelWidth={200}
                    schema={schema}
                    value={value}
                    onChange={(name, value) => setValue(value)}
                    onMissingCountChange={(name, missing) => setHasMissing(missing > 0)}
                    onErrorCountChange={(name, errors) => setHasErrors(errors > 0)}
                >
                    <TextEdit field="password1" type="password" width={200} />
                    <TextEdit field="password2" type="password" width={200} />
                </Form>
                <hr />
                {renderSubmit()}
            </div>
        );
    };

    return <div>{isComplete ? renderSuccessMessage() : renderForm()}</div>;
};

export const ResetPassword = () => {
    const params = useParams();
    return (
        <Row>
            <Col md={2} />
            <Col md={8}>
                <ResetPasswordForm {...params} />
            </Col>
            <Col md={2} />
        </Row>
    );
};
