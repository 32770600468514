import _ from "underscore";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ChoiceSelector, Fillable, Selector, TextFilter } from "shared/components/controls";
import { useQueryState, useSearchParams } from "shared/hooks";
import { breadcrumbStyle } from "shared/styles/styles";
import { SapTable } from "./SapTable";
import { setHtmlTitle } from "shared/utils/entity-utils";

export const GET_DEVICES_QUERY = gql`
    query getAllDevices {
        saps {
            device
        }
    }
`;

export const GET_SITES_QUERY = gql`
    query getAllSites {
        networkEntities(entityType: "ESnet Site") {
            id
            shortName
            fullName
        }
    }
`;

export const SapList = () => {
    // Set HTML title tag
    setHtmlTitle("SAPs");

    //
    // State held in the URL query string
    //

    const [textFilter] = useQueryState("filter", "");
    const [device] = useQueryState("device", null);
    const [site] = useQueryState("site", null);
    const [show] = useQueryState("show", "with-traffic");

    //
    // Data for the filter controls
    //

    const { loading: deviceListLoading, data: deviceList } = useQuery(GET_DEVICES_QUERY);
    const { loading: sitesLoading, data: sitesData } = useQuery(GET_SITES_QUERY);

    const devices = !deviceListLoading
        ? _.unique(deviceList.saps.map((iface) => iface.device)).map((item) => ({
              value: item,
              label: item,
          }))
        : [];

    const sites = !sitesLoading
        ? sitesData.networkEntities.map((entity) => ({
              value: entity.shortName,
              label: `${entity.fullName} (${entity.shortName})`,
          }))
        : [];

    const showOptions = [
        { key: "active", label: "Active Only" },
        { key: "all", label: "All SAPs" },
    ];

    //
    // Tabs at the top of the page, either Interfaces or SAPs
    //

    const searchParams = useSearchParams();
    const search = `?${searchParams.toString()}`;

    const subSections = [];
    subSections.push({
        key: "interfaces",
        label: "Interfaces",
        url: "/network/interfaces/list",
    });
    subSections.push({
        key: "saps",
        label: "SAPs",
        url: "/network/saps/list",
    });

    const navItems = subSections.map((section) => {
        const className = "saps" === section.key ? "active" : "";
        const key = section.key;
        return (
            <li key={key} role="presentation" className={className}>
                <Link
                    to={{
                        pathname: section.url,
                        search,
                    }}
                >
                    {section.label}
                </Link>
            </li>
        );
    });

    //
    // Build the table
    //

    return (
        <Col md={12}>
            <Row>
                <Col md={7}>
                    <div style={breadcrumbStyle}>
                        <a href="/">HOME</a>
                        <span> » </span>
                    </div>
                    <h2>SAPs</h2>
                </Col>
                <Col md={5} style={{ marginTop: 20 }}>
                    <ul className="nav nav-pills" style={{ float: "right" }}>
                        {navItems}
                    </ul>
                </Col>
            </Row>
            <Row style={{ paddingTop: 20 }}>
                <Col md={3}>
                    <TextFilter toolTip="Filter by DEVICE, SAP, CONNECTION, or TAGS" />
                </Col>
                <Col md={3}>
                    <Selector
                        style={{ marginBottom: 10 }}
                        options={devices}
                        queryVariable="device"
                        isLoading={deviceListLoading}
                        placeholder={deviceListLoading ? "" : "Select device..."}
                    />
                </Col>
                <Col md={3}>
                    <Selector
                        options={sites}
                        queryVariable="site"
                        isLoading={sitesLoading}
                        placeholder={sitesLoading ? "" : "Select site..."}
                    />
                </Col>
                <Col md={3} style={{ marginTop: 5 }}>
                    <ChoiceSelector
                        label="SHOW"
                        variableName="show"
                        defaultValue="active"
                        options={showOptions}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <hr />
                    <Fillable>
                        <SapTable text={textFilter} device={device} showSAPs={show} site={site} />
                    </Fillable>
                </Col>
            </Row>
        </Col>
    );
};
