import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import Immutable from "immutable";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Field, Form, FormEditStates, Schema, TextEdit } from "react-dynamic-forms";
import { SubmitButton, UserMessage } from "shared/components/controls";
import { useUser } from "shared/hooks";
import { setHtmlTitle } from "shared/utils/entity-utils";

export const CHANGE_USER_PASSWORD_MUTATION = gql`
    mutation ChangePassword($email: String, $password: String, $newPassword: String) {
        changePassword(email: $email, password: $password, newPassword: newPassword) {
            success
            message
        }
    }
`;

const initialValues = {
    oldPassword: "",
    newPassword1: "",
    newPassword2: "",
};

const schema = (
    <Schema>
        <Field name="oldPassword" label="Old Password" placeholder="Old Password" required={true} />
        <Field
            name="newPassword1"
            label="New Password"
            placeholder="New Password"
            required={true}
        />
        <Field
            name="newPassword2"
            label="Re-enter password"
            placeholder="New Password (again)"
            required={true}
        />
    </Schema>
);

/**
 * ChangeForm component is responsible for rendering the user change password form
 * As a single prop it is passed the user object of the currently logged in user
 */
export const ChangeForm = ({ user }) => {
    const [value, setValue] = useState(Immutable.fromJS(initialValues));
    const [hasMissing, setHasMissing] = useState(true);
    const [hasErrors, setHasErrors] = useState(true);
    const [isComplete, setComplete] = useState(false);

    // Queue up the change password mutation
    const [changePassword, { loading, error }] = useMutation(CHANGE_USER_PASSWORD_MUTATION, {
        onCompleted: () => {
            setComplete(true);
        },
    });

    const isPending = !!loading;
    const isError = !!error;

    const handleSubmit = (e) => {
        e.preventDefault();

        // Currently logged in user's email
        const { email } = user;

        changePassword({
            variables: {
                email,
                password: value.get("oldPassword"),
                newPassword: value.get("newPassword1"),
            },
        });
    };

    const renderSuccessMessage = () => {
        return (
            <UserMessage title="Password Change Successful">
                You may now <a href="/user/login">log in</a>.
            </UserMessage>
        );
    };

    const renderError = () => {
        let msg;
        const pwd1 = value.get("newPassword1");
        const pwd2 = value.get("newPassword2");
        const isPasswordMismatch = pwd1 !== pwd2 && pwd2 !== "";

        if (isError) {
            msg = "Password change failed.";
        } else if (isPasswordMismatch) {
            msg = "Passwords do not match.";
        }
        if (msg) {
            return (
                <span key={msg} style={{ color: "#a94442", paddingLeft: 10 }}>
                    {msg}
                </span>
            );
        } else {
            return <span />;
        }
    };

    const renderSubmit = () => {
        const pwd1 = value.get("newPassword1");
        const pwd2 = value.get("newPassword2");
        const passwordsNotEqual = pwd1 !== pwd2;

        const disableSubmitButton = hasErrors || hasMissing || passwordsNotEqual;

        return (
            <div style={{ float: "right" }}>
                <span style={{ color: "#a94442", paddingRight: 10 }}>{renderError()}</span>
                <SubmitButton
                    label="Change"
                    pending={isPending ? "Changing..." : null}
                    disabled={disableSubmitButton}
                    onClick={(e) => handleSubmit(e)}
                />
            </div>
        );
    };

    const renderForm = () => {
        return (
            <div>
                <h2>Password Change</h2>
                <hr />
                <Form
                    name="basic"
                    edit={FormEditStates.ALWAYS}
                    labelWidth={200}
                    schema={schema}
                    value={value}
                    onSubmit={handleSubmit}
                    onChange={(name, value) => setValue(value)}
                    onMissingCountChange={(name, missing) => setHasMissing(missing > 0)}
                    onErrorCountChange={(name, errors) => setHasErrors(errors > 0)}
                >
                    <TextEdit field="oldPassword" type="password" width={200} />
                    <TextEdit field="newPassword1" type="password" width={200} />
                    <TextEdit field="newPassword2" type="password" width={200} />
                </Form>
                <hr />
                {renderSubmit()}
            </div>
        );
    };

    return <div>{isComplete ? renderSuccessMessage() : renderForm()}</div>;
};

/**
 * Change user password page, which lets a logged in user submit a request
 * to change their password.
 */
export const ChangePassword = () => {
    // Set HTML title tag
    setHtmlTitle("Password Change");

    const user = useUser();

    const renderNotLoggedInMessage = () => {
        return (
            <UserMessage title="Password Change" isError>
                You must be logged in to change passwords. <a href="/user/login">log in</a> first.
            </UserMessage>
        );
    };

    // If the user isn't logged in they can't change their password. If
    // they are logged in, then show them the change password form.
    let content = null;
    if (!user || user.isAnonymous) {
        content = renderNotLoggedInMessage();
    } else {
        content = <ChangeForm user={user} />;
    }

    return (
        <Row>
            <Col md={2} />
            <Col md={8}>{content}</Col>
            <Col md={2} />
        </Row>
    );
};
