import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { ChoiceSelector, TimePicker } from "shared/components/controls";
import { useQueryState, useTimeRange } from "shared/hooks";
import { CollaboratorFlowBarChart } from "./CollaboratorFlowBarChart";
import { CollaboratorFlowChart } from "./CollaboratorFlowChart";
import { CollaboratorSummaryInfo } from "./CollaboratorInfo";

const MIN = 60 * 1000;
const HOUR = 60 * MIN;
const DAY = 24 * HOUR;
const EVERY_60_SECONDS = MIN;

const breakdowns = [
    { key: "vpnsite", label: "LHCONE Participants" },
    { key: "protocol", label: "Protocols" },
    { key: "tcp_port", label: "TCP Ports" },
];

const TIME_RANGES = [
    { key: "1h", label: "1h", duration: 1 * HOUR },
    { key: "6h", label: "6h", duration: 6 * HOUR },
    { key: "1d", label: "day", duration: DAY },
    { key: "7d", label: "week", duration: 7 * DAY },
    { key: "30d", label: "month", duration: 30 * DAY },
];

const CollaboratorFlow = ({ collaborator: collaboratorName }) => {
    // Tracker position for the page
    const [timestamp, setTimestamp] = useState(null);

    // Timerange as determined by the URL search params and auto-updating
    const [timerange] = useTimeRange(EVERY_60_SECONDS, TIME_RANGES, "1d");

    // Selected flow within the breakdown
    const [selected, setSelected] = useQueryState("s", null);

    // Breakdown option selection
    const [breakdown] = useQueryState("by", "protocol");

    return (
        <Col md={12}>
            <Row>
                <Col>
                    <CollaboratorSummaryInfo collaboratorName={collaboratorName} />
                </Col>
            </Row>

            <hr />

            <Row>
                <Col md={6}>
                    <div style={{ float: "left" }}>
                        <ChoiceSelector
                            label="BREAKDOWN"
                            variableName="by"
                            defaultValue="protocol"
                            options={breakdowns}
                        />
                    </div>
                </Col>
                <Col md={6}>
                    <div style={{ float: "right" }}>
                        <TimePicker ranges={TIME_RANGES} defaultValue="1d" />
                    </div>
                </Col>
            </Row>

            <hr />

            <Row>
                <Col md={12}>
                    <Row>
                        <Col md={12}>
                            <CollaboratorFlowChart
                                collaboratorName={collaboratorName}
                                breakdown={breakdown}
                                timerange={timerange}
                                timestamp={timestamp}
                                selected={selected}
                                onTrackerChanged={(ts) => setTimestamp(ts)}
                            />
                        </Col>
                    </Row>

                    <hr />

                    <Row>
                        <Col md={12}>
                            <CollaboratorFlowBarChart
                                collaboratorName={collaboratorName}
                                breakdown={breakdown}
                                timerange={timerange}
                                timestamp={timestamp}
                                selected={selected}
                                onSelectionChanged={(s) => setSelected(s)}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Col>
    );
};

export default CollaboratorFlow;
