import _ from "lodash";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Spinner } from "shared/components/controls";
import { useUser } from "shared/hooks";
import { headerStyle, tagStyle } from "shared/styles/styles";
import { setHtmlTitle } from "shared/utils/entity-utils";

//
// Allow the user to see what their settings are
//
const UserFavorites = ({ favorites }) => {
    const favs = favorites
        .sort((a, b) => (a.type > b.type ? 1 : -1))
        .map((entity) => {
            let link;
            switch (entity.type) {
                case "ESnet Site":
                    link = "sites";
                    break;
                case "Facility":
                    link = "facilities";
                    break;
                case "LHCONE":
                    link = "lhcone";
                    break;
                default:
                    break;
            }
            return (
                <div style={{ marginBottom: 10 }}>
                    <b>{entity.type}</b>: {entity.fullName} (
                    <a
                        href={`/${link}/view/${entity.shortName}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {entity.shortName.toUpperCase()}
                    </a>
                    )
                </div>
            );
        });
    return favs;
};

const UserPermissions = ({ permissions }) => {
    const availablePermissions = Object.keys(permissions).map((shortName) => {
        const { fullName, viewTopTalkers } = permissions[shortName];
        return (
            <tr>
                <td width={500}>{fullName}</td>
                <td>
                    {viewTopTalkers ? <span style={tagStyle}>View top talkers</span> : <span />}
                </td>
            </tr>
        );
    });
    return availablePermissions;
};

const Profile = () => {
    const user = useUser();

    if (!user) {
        return (
            <Row>
                <Col md={2} />
                <Col md={8}>
                    <h2>User Profile</h2>
                    <hr />
                    <Spinner />
                </Col>
                <Col md={2} />
            </Row>
        );
    } else if (user.isAnonymous) {
        return (
            <Row>
                <Col md={2} />
                <Col md={8}>
                    <h2>User Profile</h2>
                    <hr />
                    <p>No user is logged in.</p>
                </Col>
                <Col md={2} />
            </Row>
        );
    } else {
        // Build permissions map
        const permissions = {};
        user.viewTopTalkers.forEach((entity) => {
            if (!_.has(permissions, entity.shortName)) {
                permissions[entity.shortName] = {
                    id: entity.id,
                    fullName: entity.fullName,
                    viewTopTalkers: false,
                };
            }
            permissions[entity.shortName].viewTopTalkers = true;
        });
        // Set HTML title tag
        setHtmlTitle("My Profile", user.firstName + " " + user.lastName);

        return (
            <Row>
                <Col md={2} />
                <Col md={8}>
                    <h2>My Profile</h2>
                    <hr />
                    <div>
                        <dl className="row">
                            <dt className="col-sm-2">First Name</dt>
                            <dd className="col-sm-10">{user.firstName}</dd>

                            <dt className="col-sm-2">Last Name</dt>
                            <dd className="col-sm-10">{user.lastName}</dd>

                            <dt className="col-sm-2">Email</dt>
                            <dd className="col-sm-10">{user.email}</dd>
                        </dl>
                    </div>
                    <hr />
                    <div style={headerStyle}>Favorites</div>
                    {user.favoriteNetworkEntities.length > 0 ? (
                        <UserFavorites favorites={user.favoriteNetworkEntities} />
                    ) : (
                        <div>None Yet</div>
                    )}
                    <hr />
                    <div style={headerStyle}>Permissions</div>
                    <table>
                        {Object.keys(permissions).length > 0 ? (
                            <UserPermissions permissions={permissions} />
                        ) : (
                            <span>Standard</span>
                        )}
                    </table>
                    <hr />
                </Col>
                <Col md={2} />
            </Row>
        );
    }
};

export default Profile;
