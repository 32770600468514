import { ApolloProvider } from "@apollo/react-hooks";
import { ApolloClient, InMemoryCache, HttpLink, ApolloLink } from "@apollo/client";
import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import ReactDOM from "react-dom";
import App from "app/App";
import { getCSRFToken } from "shared/utils/csrf-utils";
import reportWebVitals from "./reportWebVitals";
import ReactGA from "react-ga4";

const AuthLink = (operation, next) => {
    operation.setContext((context) => ({
        ...context,
        headers: {
            ...context.headers,
            "X-CSRFToken": getCSRFToken(),
        },
        credentials: "same-origin",
    }));

    return next(operation);
};

const link = ApolloLink.from([AuthLink, new HttpLink({ uri: "/graphql" })]);

const client = new ApolloClient({
    link,
    cache: new InMemoryCache({
        dataIdFromObject: (obj) => {
            switch (obj.__typename) {
                case "UserType":
                    return `user`;
                case "NetworkEntityType":
                    return `entity::${obj.id}::${obj.shortName}`;
                case "MapTopologyEdgeNode":
                    return `edge::${obj.id}::${obj.name}`;
                case "MapTopologyNodeNode":
                    return `node::${obj.name}`;
                case "NetworkInterfaceType":
                    return `interface::${obj.device}::${obj.name}`;
                case "NetworkSAPType":
                    return `sap::${obj.device}::${obj.name}`;
                default:
                    return obj.id || obj._id;
            }
        },
    }),
});

// ESnet Portal hostname
const ESNET_PORTAL = "my.es.net";

// Portal Staging GA4 Measurement ID
const portalStagingID = "G-MMEBLS543R";
// ESnet Portal GA4 Measurement ID
const esnetPortalID = "G-CHNCEWY14P";

// Send non-production analytics data to Portal Staging GA4 Measurement ID
const measurementID = window.location.hostname === ESNET_PORTAL ? esnetPortalID : portalStagingID;

//Initialize GA4
ReactGA.initialize(measurementID);

ReactDOM.render(
    <ApolloProvider client={client}>
        <App />
    </ApolloProvider>,
    document.getElementById("root")
);

// Set the hitType as "pageview"
const SendAnalytics = () => {
    ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname,
    });
};

// Send to GA4
reportWebVitals(SendAnalytics);
