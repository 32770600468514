import gql from "graphql-tag";
import React from "react";
import Card from "shared/components/cards/Card";
import { Spinner } from "shared/components/controls";
import { useMemoQuery } from "shared/hooks";
import { cardStyle, cardStyleError, moreInfoLinkStyle } from "shared/styles/styles";

export const COLLABORATOR_INFO_QUERY = gql`
    query collaboratorInfo($shortName: String) {
        networkEntity(shortName: $shortName, entityType: "LHCONE") {
            id
            shortName
            fullName
            type
            description
            parent {
                shortName
                fullName
            }
        }
    }
`;

/**
 * Renders a card which shows the collaborator name and a link to the collaborator page. This
 * could be expanded to show current traffic to/from the site etc.
 */
export const CollaboratorCard = ({ collaboratorId, showDescription = true, height = 75 }) => {
    const queryOptions = {
        variables: { shortName: collaboratorId },
    };

    // prettier-ignore
    const {
        isLoading,
        error,
        data: entity,
    } = useMemoQuery(COLLABORATOR_INFO_QUERY, queryOptions, (d) => d.networkEntity, [
        collaboratorId,
    ]);

    if (isLoading) {
        return (
            <Card overLevel={2} outLevel={1} level={1} width="100%" style={cardStyle}>
                <Spinner />
            </Card>
        );
    } else if (error) {
        return (
            <Card
                overLevel={2}
                outLevel={1}
                level={1}
                width="100%"
                height={100}
                style={cardStyleError}
            >
                Unable to load LHCONE collaborator information
            </Card>
        );
    } else {
        return (
            <Card overLevel={2} outLevel={1} level={1} width="100%" style={cardStyle}>
                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <b>{entity.type + " Collaborator"}</b>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>{entity.fullName}</b>
                            </td>
                        </tr>
                        {showDescription ? (
                            <tr>
                                <td style={{ marginTop: "80px" }}>{entity.description}</td>
                            </tr>
                        ) : (
                            <tr />
                        )}
                        <tr>
                            <td>
                                {" "}
                                <a
                                    style={moreInfoLinkStyle}
                                    href={`/lhcone/view/${entity.shortName}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <span> MORE INFO » </span>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Card>
        );
    }
};
