import { useHistory, useLocation } from "react-router-dom";
import _ from "underscore";
import { useSearchParams } from "./useSearchParams";

/**
 * useSortState() is a react hook used to get and set a tuple of sort by field and sort direction into the URL.
 *
 * The source of truth for the sort by and sort direction is on the URL's query string, using the query variables
 * `by` and `order` respectively.
 */
export const useSortState = (defaultSortBy, defaultSortDirection) => {
    const query = useSearchParams();
    const history = useHistory();
    const { pathname } = useLocation();

    const sortBy = query.get("by") || defaultSortBy;
    const sortDirection = query.get("order") || defaultSortDirection;

    const handleChangeSort = (by, order) => {
        const targetQuery = new URLSearchParams(query);
        if (_.isUndefined(by) || _.isUndefined(order)) {
            targetQuery.delete("by");
            targetQuery.delete("order");
        } else {
            targetQuery.set("by", by);
            targetQuery.set("order", order);
        }
        // Transition URL
        history.push(`${pathname}?${targetQuery.toString()}`);
    };

    return [{ sortBy, sortDirection }, handleChangeSort];
};
