import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Spinner } from "shared/components/controls";
import { formatCount } from "shared/utils/format-utils";
import { setHtmlTitle } from "shared/utils/entity-utils";

export const GET_EDGE_INFO_QUERY = gql`
    query edgeInfo($edgeName: String, $edgeType: String) {
        mapTopologyEdge(name: $edgeName, edgeType: $edgeType) {
            name
            attr
        }
    }
`;

/**
 * Render a the edge title only
 */
const EdgeTitle = (props) => {
    const { edgeName, edgeType } = props;

    // Load edge info
    const { loading, data } = useQuery(GET_EDGE_INFO_QUERY, {
        variables: { edgeName, edgeType },
    });

    if (loading || !data) {
        return <div style={{ height: 36 }} />;
    } else if (data) {
        const edge = data.mapTopologyEdge;
        const edgeName = edge.name.replace("--", " ⇄ ");

        // Set HTML title tag
        setHtmlTitle("Edge", edgeName);

        return (
            <Row>
                <Col md={12}>
                    <h2>{edgeName}</h2>
                </Col>
            </Row>
        );
    } else {
        return <div />;
    }
};

/**
 * Render a the edge capacity and other info if we provide more here
 */
const EdgeSummaryInfo = (props) => {
    const { edgeName } = props;

    // Load edge info
    const { loading, data } = useQuery(GET_EDGE_INFO_QUERY, {
        variables: { edgeName },
    });

    if (loading || !data || !data.mapTopologyEdge) {
        return <Spinner />;
    } else {
        const edgeInfo = JSON.parse(data.mapTopologyEdge.attr);

        const ifaces = edgeInfo.ifaces.map((iface) => (
            <div key={`${iface.device_source}-${iface.iface_source}`}>
                <a
                    href={`/network/devices/${encodeURIComponent(
                        iface.device_source
                    )}/interfaces/${encodeURIComponent(iface.iface_source)}/traffic/`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <b>{`${iface.is_neaar ? "NEAAR Link: " : ""}`}</b>{" "}
                    {` ${iface.device_source} ${iface.iface_source}`}
                </a>
            </div>
        ));

        var reverse_ifaces = null;

        if (edgeInfo && edgeInfo.reverse && edgeInfo.reverse.ifaces) {
            reverse_ifaces = edgeInfo.reverse.ifaces.map((iface) => (
                <div key={`${iface.device_source}-${iface.iface_source}`}>
                    <a
                        href={`/network/devices/${encodeURIComponent(
                            iface.device_source
                        )}/interfaces/${encodeURIComponent(iface.iface_source)}/traffic/`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <b>{`${iface.is_neaar ? "NEAAR Link: " : ""}`}</b>{" "}
                        {` ${iface.device_source} ${iface.iface_source}`}
                    </a>
                </div>
            ));
        }

        if (reverse_ifaces) {
            reverse_ifaces = (
                <dl className="row">
                    <dt className="col-sm-1">Reciprocal Interfaces</dt>
                    <dd className="col-sm-11">{reverse_ifaces}</dd>
                </dl>
            );
        }

        const saps = edgeInfo.saps.map((sap) => (
            <div key={`${sap.device_source}-${sap.sap_source}`}>
                <a
                    href={`/network/devices/${encodeURIComponent(
                        sap.device_source
                    )}/saps/${encodeURIComponent(sap.sap_source)}/traffic/`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {` ${sap.device_source} ${sap.sap_source}`}
                </a>
            </div>
        ));

        return (
            <div>
                <dl className="row">
                    <dt className="col-sm-1">Capacity</dt>
                    <dd className="col-sm-11">{formatCount(edgeInfo.total_capacity, 1)}</dd>
                    <dt className="col-sm-1">Interfaces</dt>
                    <dd className="col-sm-11">{ifaces}</dd>
                    <dt className="col-sm-1"></dt>
                    <dd className="col-sm-11">{saps}</dd>
                </dl>
                {reverse_ifaces}
            </div>
        );
    }
};

export { EdgeSummaryInfo, EdgeTitle };
