import moment from "moment";
import { TimeRange } from "pondjs";
import React, { forwardRef } from "react";
import { ChevronLeft, ChevronRight } from "react-bootstrap-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { months } from "shared/constants/time";
import { useQueryState } from "shared/hooks";
import { labelStyle, linkStyleActive } from "shared/styles/styles";

/**
 * Takes a string of the form YYYY-MM and returns
 * a prettier string like "October 2019"
 */
export const toPrettyString = (monthStr) => {
    const [y, m] = monthStr.split("-");
    return `${months[m - 1]} ${y}`;
};

/**
 * Returns a tuple containing the month as a number 0 to 11
 * and the year, given a string of the form YYYY-MM
 */
export const parseMonth = (monthStr) => {
    const [y, m] = monthStr.split("-");
    const month = parseInt(m, 10);
    const year = parseInt(y, 10);
    return [month === 0 ? 11 : month - 1, year];
};

/**
 * Converts a month string like YYYY-MM into a Date
 */
export const toDate = (monthStr) => {
    const [month, year] = parseMonth(monthStr);
    return new Date(year, month, 1);
};

/**
 * Converts a Date into a month string YYYY-MM
 */
export const fromDate = (d) => {
    return generateMonthString(d.getMonth(), d.getFullYear());
};

/**
 * Takes a month and year and concats them together into the month string
 * form of YYYY-MM, handling the padding of the month
 */
export const generateMonthString = (month, year) => `${year}-${("00" + (month + 1)).slice(-2)}`;

/**
 * Returns the current month as an object containing the month (0 - 11) and
 * the year.
 */
export const currentMonth = () => {
    const current = moment();
    const month = current.month();
    const year = current.year();
    return generateMonthString(month, year);
};

/**
 * Returns the last month as a month string YYYY-MM
 */
export const lastMonth = () => {
    const d = moment();
    const last = d.subtract(1, "month");
    const month = last.month();
    const year = last.year();
    return generateMonthString(month, year);
};

/**
 * Utility to return the month string YYYY-MM as a Pond Timerange.
 */
export const toTimerange = (monthStr) => {
    const [month, year] = parseMonth(monthStr);
    const startDate = new moment([+year, +month, 1]);
    const endDate = moment(startDate).endOf("month");
    return new TimeRange(startDate.toDate().getTime(), endDate.toDate().getTime());
};

const incrementMonth = (monthStr) => {
    const [m, y] = parseMonth(monthStr);
    return generateMonthString(m < 11 ? m + 1 : 0, m < 11 ? y : y + 1);
};

const decrementMonth = (monthStr) => {
    const [m, y] = parseMonth(monthStr);
    return generateMonthString(m > 0 ? m - 1 : 11, m > 0 ? y : y - 1);
};

const isLessThan = (monthStr1, monthStr2) => {
    const [m1, y1] = parseMonth(monthStr1);
    const [m2, y2] = parseMonth(monthStr2);
    return y1 < y2 || (y1 === y2 && m1 < m2);
};

/**
 * Displays a month e.g. "May 2018" with a couple of simple controls on
 * either side that let you change the month forward or backwards
 */
export const MonthPicker = ({
    queryVariable = "month",
    defaultMonth = lastMonth(),
    maxMonth = lastMonth(),
    showMaxMonthButton = false,
}) => {
    const [month, setMonth] = useQueryState(queryVariable, defaultMonth);
    const canIncrement = isLessThan(month, maxMonth);

    const Input = forwardRef(({ value, onClick }, ref) => {
        return (
            <span
                style={{
                    padding: 10,
                    width: 85,
                    cursor: "pointer",
                    userSelect: "none",
                    ...linkStyleActive,
                }}
                onClick={onClick}
            >
                {toPrettyString(value)}
            </span>
        );
    });

    const resetMonthToMax = () => {
        setMonth(null);
    };

    const renderMaxMonthButton = () => {
        if (!showMaxMonthButton || month === defaultMonth) {
            return (
                <span
                    style={{
                        ...linkStyleActive,
                        display: "block",
                        paddingLeft: 0,
                        cursor: "pointer",
                    }}
                >
                    &nbsp;
                </span>
            );
        } else {
            return (
                <span
                    style={{
                        ...linkStyleActive,
                        display: "block",
                        paddingLeft: 0,
                        cursor: "pointer",
                    }}
                >
                    <span onClick={resetMonthToMax}>Show Most Recent Month</span>
                </span>
            );
        }
    };

    return (
        <span>
            <span style={labelStyle}>MONTH</span>

            <ChevronLeft
                style={{
                    cursor: "pointer",
                    color: "#337AB7",
                    userSelect: "none",
                    marginLeft: 10,
                }}
                onClick={() => setMonth(decrementMonth(month))}
            />

            <ChevronRight
                style={{
                    userSelect: "none",
                    cursor: "pointer",
                    color: canIncrement ? "#337AB7" : "#D0D0D0",
                    marginLeft: 20,
                    marginRight: 10,
                }}
                onClick={() => {
                    if (canIncrement) setMonth(incrementMonth(month));
                }}
            />

            <DatePicker
                showMonthYearPicker
                dateFormat="yyyy-MM"
                maxDate={toDate(maxMonth)}
                selected={toDate(month)}
                onChange={(date) => setMonth(fromDate(date))}
                customInput={<Input />}
            />

            {renderMaxMonthButton()}
        </span>
    );
};
