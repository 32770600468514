import React, { useRef, useState } from "react";
import { Button } from "react-bootstrap";

export const SubmitButton = ({ label = "Submit", pending, disabled, onClick, ...other }) => {
    const [hover, setHover] = useState(false);
    const button = useRef(null);

    let bgcolor = "#33ABCC";
    if (disabled) {
        bgcolor = "#D0D0D0";
    } else if (pending) {
        bgcolor = "#6D6E71";
    } else if (hover) {
        bgcolor = "#4EC1E0";
    }
    const style = {
        backgroundColor: bgcolor,
        borderColor: "transparent",
        borderRadius: 15,
        height: 30,
        paddingTop: 2,
        paddingBottom: 2,
        outline: null,
    };

    const handleClick = (e) => {
        button.current.blur();
        if (onClick) {
            onClick(e);
        }
    };

    return (
        <Button
            ref={button}
            style={style}
            {...other}
            onMouseOver={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={(e) => handleClick(e)}
        >
            {pending ? pending : label}
        </Button>
    );
};
