import { useApolloClient, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useState } from "react";
import { Navbar } from "react-bootstrap";
import { List, PersonFill } from "react-bootstrap-icons";
import { Link, useHistory } from "react-router-dom";
import { useUser } from "shared/hooks";
import logo from "shared/img/esnet-logo.png";
import { esnetDarkGrey, selectionColor } from "shared/styles/styles";

const USER_STYLE = {
    float: "right",
    color: "#6D6E71",
    background: "#eee",
    padding: 5,
    paddingRight: 12,
    borderRadius: 14,
};

const LOGIN_STYLE = {
    float: "right",
    color: "white",
    padding: 5,
    paddingRight: 12,
    borderRadius: 14,
    cursor: "pointer",
};

export const LOGOUT_USER_MUTATION = gql`
    mutation logout {
        logoutUser {
            success
        }
    }
`;

export const USER_QUERY = gql`
    query {
        user {
            email
            firstName
            lastName
            isAnonymous
            favoriteNetworkEntities {
                id
                shortName
                type
            }
            viewTopTalkers {
                id
                shortName
                fullName
            }
        }
    }
`;

/**
 * Creates the top Portal navigation header, containing the main branding along
 * with sign in, sign out and profile links. The sidebar menu hanburger menu
 * is also located here. If that is clicked it evokes a callback prop onShowSidebar
 *
 * TODO: handle messages
 */
const Header = ({ showingSidebar = false, onShowSidebar, onMessageClick }) => {
    // Hovering over the sidebar menu state or the login button will highlight them
    const [hoverMenuIcon, setHoverMenuIcon] = useState(false);
    const [hoverLogin, setHoverLogin] = useState(false);
    const [hoverURL, setHoverURL] = useState(false);

    // The currently logged in user
    const user = useUser();

    // React router history object
    const history = useHistory();

    const handleShowSidebar = () => onShowSidebar?.(!showingSidebar);

    let menuIconColor = showingSidebar || hoverMenuIcon ? selectionColor : esnetDarkGrey;
    const menuIcon = (
        <List
            color={menuIconColor}
            size={30}
            onClick={handleShowSidebar}
            onMouseOver={() => setHoverMenuIcon(true)}
            onMouseLeave={() => setHoverMenuIcon(false)}
        />
    );

    // Setup the logout user mutation
    const client = useApolloClient();
    const [logoutUser, { loading: loggingOut }] = useMutation(LOGOUT_USER_MUTATION, {
        onCompleted() {
            // When we complete logout we clear the apollo cache immediately
            client.writeQuery({
                query: USER_QUERY,
                data: {
                    user: {
                        __typename: "UserType",
                        email: null,
                        firstName: null,
                        lastName: null,
                        isAnonymous: true,
                        favoriteNetworkEntities: [],
                        viewTopTalkers: [],
                    },
                },
            });
        },
    });

    // Called when the logout button is clicked
    const handleLogout = () => {
        // Call the logout mutation
        logoutUser();

        // Redirect to front page
        history.replace("/");
    };

    // TODO: Move this to user code and storybook?
    const renderUserStatus = () => {
        if (user && !user.isAnonymous) {
            return (
                <div style={USER_STYLE}>
                    <span style={{ paddingLeft: 5, paddingRight: 5 }}>
                        <Link to={`/user/profile`}>
                            <PersonFill color="#4EC1E0" />
                        </Link>
                    </span>

                    <Link
                        style={{ color: "#337AB7", textDecoration: "none", cursor: "pointer" }}
                        to={`/user/profile`}
                    >
                        <span>{`Logged in as ${user.firstName}`}</span>
                    </Link>
                    <span style={{ paddingLeft: 5, paddingRight: 5 }}>|</span>
                    {loggingOut ? (
                        <span key="logout" style={{ color: "#337AB7" }}>
                            Logging out...
                        </span>
                    ) : (
                        <span
                            key="logout"
                            style={{ color: "#337AB7", cursor: "pointer" }}
                            onClick={handleLogout}
                        >
                            Logout
                        </span>
                    )}
                </div>
            );
        } else {
            const redirect = window.location.pathname;
            const url = `/user/login?redirect=${redirect}`;
            return (
                <div
                    style={{
                        background: hoverLogin ? "#4EC1E0" : "#33ABCC",
                        ...LOGIN_STYLE,
                    }}
                    onMouseOver={() => setHoverLogin(true)}
                    onMouseLeave={() => setHoverLogin(false)}
                >
                    <span>
                        <Link
                            to={url}
                            style={{
                                color: "#ffffff",
                                paddingLeft: 5,
                                textDecoration: "none",
                            }}
                        >
                            Login
                        </Link>
                    </span>
                </div>
            );
        }
    };

    const renderExternalLink = () => {
        return (
            <div
                style={{
                    background: hoverURL ? "#4EC1E0" : "#33ABCC",
                    ...LOGIN_STYLE,
                }}
                onMouseOver={() => setHoverURL(true)}
                onMouseLeave={() => setHoverURL(false)}
            >
                <span>
                    <Link
                        to={{ pathname: "https://csp.es.net" }}
                        target="_blank"
                        style={{
                            color: "#ffffff",
                            paddingLeft: 5,
                            textDecoration: "none",
                        }}
                    >
                        Customer Service Portal
                    </Link>
                </span>
            </div>
        );
    };

    return (
        <Navbar bg="light" expand="md" fixed="top">
            {menuIcon}
            <Navbar.Brand href="/">
                <img
                    style={{ marginLeft: 20 }}
                    src={logo}
                    height={50}
                    className="d-inline-block align-top"
                    alt="ESnet Logo"
                />
            </Navbar.Brand>
            <Navbar.Collapse className="justify-content-end">
                <Navbar.Text className="px-2">{renderExternalLink()}</Navbar.Text>
                <Navbar.Text>{renderUserStatus()}</Navbar.Text>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default Header;
