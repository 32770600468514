import React from "react";
import { MapLegend, Resizable } from "react-network-diagrams";
import { nodeColorMap } from "shared/styles/map-styles";
import _ from "underscore";

export default class extends React.Component {
    render() {
        const colorSwatches = _.map(nodeColorMap, (color) => {
            return {
                text: color.label,
                stroke: color.color,
                fill: color.color,
            };
        });

        return (
            <Resizable>
                <svg height={this.props.height} style={{ fontSize: 12 }}>
                    <MapLegend
                        x={10}
                        y={10}
                        itemsPerColumn={this.props.itemsPerColumn}
                        colorSwatches={colorSwatches}
                        edgeColor={"#6D6E71"}
                        columnWidth={95}
                        exampleWidth={15}
                    />
                </svg>
            </Resizable>
        );
    }
}
