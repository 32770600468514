var PropTypes = require("prop-types");
//
// From:
// https://raw.githubusercontent.com/stoeffel/react-material-card/master/index.js
//
var React = require("react");
var deepcopy = require("deepcopy");

var levels = [
    {
        boxShadow: "none",
    },
    {
        boxShadow: "0 1px 3px rgba(0,0,0,0.12),0 1px 2px rgba(0,0,0,0.24)",
    },
    {
        boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
    },
    {
        boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
    },
    {
        boxShadow: "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
    },
    {
        boxShadow: "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)",
    },
];

export default class Card extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        style: PropTypes.object,
        width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        level: PropTypes.number,
        onLevelChange: PropTypes.func,
        onClick: PropTypes.func,
        onOver: PropTypes.func,
        onOut: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        overLevel: PropTypes.number,
        outLevel: PropTypes.number,
        focusLevel: PropTypes.number,
        animationDuration: PropTypes.number,
        noAnimation: PropTypes.bool,
        noBorderRadius: PropTypes.bool,
        borderRadius: PropTypes.number,
        noBackgroundColor: PropTypes.bool,
        levels: PropTypes.array,
    };

    static defaultProps = {
        className: "rcard",
        style: {},
        level: 1,
        onLevelChange: function () {},
        onClick: function () {},
        onOver: function () {},
        onOut: function () {},
        onFocus: function () {},
        onBlur: function () {},
        animationDuration: 250,
        noAnimation: false,
        noBorderRadius: false,
        borderRadius: 2,
        noBackgroundColor: false,
        levels: levels,
    };

    state = {
        over: false,
        hasFocus: false,
        level: this.props.level,
    };

    UNSAFE_componentWillReceiveProps(next) {
        if (this.state.level !== next.level) {
            this.setLevel(next.level);
        }
    }

    onClick = () => {
        this.props.onClick(this.getApi());
    };

    onOver = () => {
        this.setState({ over: true });
        if (!this.state.hasFocus) {
            this.props.onOver(this.getApi());
            if (this.props.overLevel) this.setLevel(this.props.overLevel);
        }
    };

    onOut = () => {
        this.setState({ over: false });
        if (!this.state.hasFocus) {
            this.props.onOut(this.getApi());
            if (this.props.outLevel) this.setLevel(this.props.outLevel);
        }
    };

    onFocus = () => {
        this.setState({ hasFocus: true });
        this.props.onFocus(this.getApi());
        if (this.props.focusLevel) this.setLevel(this.props.focusLevel);
    };

    onBlur = () => {
        this.setState({ hasFocus: false });
        this.props.onBlur(this.getApi());
        if (this.state.over) {
            this.props.onOver(this.getApi());
            if (this.props.overLevel) this.setLevel(this.props.overLevel);
        } else {
            this.props.onOut(this.getApi());
            if (this.props.outLevel) this.setLevel(this.props.outLevel);
        }
    };

    getApi = () => {
        return {
            elevate: this.elevate,
            lower: this.lower,
            setLevel: this.setLevel,
            getLevel: this.getLevel,
        };
    };

    elevate = (n) => {
        this.setLevel(this.getNextLevel(n));
    };

    lower = (n) => {
        this.setLevel(this.getPrevLevel(n));
    };

    getLevel = () => {
        return this.state.level;
    };

    setLevel = (n) => {
        this.props.onLevelChange(n);
        this.setState({ level: n });
    };

    getNextLevel = (n) => {
        n = n || 1;
        return this.state.level < this.props.levels.length - 1
            ? this.state.level + n
            : this.state.level;
    };

    getPrevLevel = (n) => {
        n = n || 1;
        return this.state.level > 0 ? this.state.level - n : this.state.level;
    };

    render() {
        var props = this.props;
        var state = this.state;
        var baseStyle = deepcopy(props.style);

        if (props.width) baseStyle.width = props.width;
        if (props.height) baseStyle.height = props.height;
        baseStyle.boxShadow = props.levels[state.level].boxShadow;
        if (!props.noBorderRadius) baseStyle.borderRadius = props.borderRadius;
        if (!props.noBackgroundColor) baseStyle.backgroundColor = "#fff";
        if (!props.noAnimation)
            baseStyle.transition = "box-shadow " + props.animationDuration + "ms";

        return (
            <div
                className={props.className}
                style={baseStyle}
                onClick={this.onClick}
                onMouseOver={this.onOver}
                onMouseOut={this.onOut}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
            >
                {props.children}
            </div>
        );
    }
}
