import { format } from "d3-format";
import gql from "graphql-tag";
import React, { useState, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { Legend, styler } from "react-timeseries-charts";
import { TrackerTime } from "shared/components/widgets/components/TrackerTime";
import { ChoicePicker, TimePicker } from "shared/components/controls";
import { MultiChannelChartWidget } from "shared/components/widgets";
import { useQueryState, useTimeRange, useMemoQuery } from "shared/hooks";
import { breadcrumbStyle } from "shared/styles/styles";
import _ from "underscore";
import { InterfaceInfo } from "./InterfaceInfo";
import { TimeSeries } from "pondjs";
import {
    legendInTraffic,
    legendOutTraffic,
    legendErrors,
    legendDiscards,
    inTraffic,
    outTraffic,
    inErrors,
    outErrors,
    inDiscards,
    outDiscards,
} from "shared/styles/styles";
import { setHtmlTitle } from "shared/utils/entity-utils";

// Refresh the page every minute
const EVERY_60_SECONDS = 60 * 1000;

const FETCH_INTERFACE_TRAFFIC = gql`
    query getInterfaceTraffic(
        $device: String
        $iface: String
        $beginTime: String
        $endTime: String
    ) {
        interface(device: $device, interface: $iface) {
            traffic(beginTime: $beginTime, endTime: $endTime) {
                name
                columns
                points
            }
        }
    }
`;

const FETCH_INTERFACE_DISCARDS = gql`
    query getInterfaceDiscards(
        $device: String
        $iface: String
        $beginTime: String
        $endTime: String
    ) {
        interface(device: $device, interface: $iface) {
            discards(beginTime: $beginTime, endTime: $endTime) {
                name
                columns
                points
            }
        }
    }
`;

const FETCH_INTERFACE_ERRORS = gql`
    query getInterfaceErrors(
        $device: String
        $iface: String
        $beginTime: String
        $endTime: String
    ) {
        interface(device: $device, interface: $iface) {
            errors(beginTime: $beginTime, endTime: $endTime) {
                name
                columns
                points
            }
        }
    }
`;

const options = [
    { key: "stacked", label: "Stack" },
    { key: "overlay", label: "Overlay" },
];

const Interface = () => {
    const { device, iface: interfaceName, page = "traffic" } = useParams();
    const iface = decodeURIComponent(interfaceName);

    let trafficSeries = useRef(null);
    let discardSeries = useRef(null);
    let errorSeries = useRef(null);

    // Set HTML title tag
    const pageTitle = device && iface ? `${device} ${iface}` : ``;
    setHtmlTitle("Interface", pageTitle);

    const [values, setValues] = useState({});
    const [timestamp, setTimestamp] = useState(null);

    // Overlay or stacked chart layout
    const [layout] = useQueryState("layout", "stacked");

    // Timerange for the page
    const [timerange] = useTimeRange(EVERY_60_SECONDS);
    const [axisTimerange, setAxisTimerange] = useTimeRange(200);

    const beginTime = timerange.begin().toISOString();
    const endTime = timerange.end().toISOString();

    const queryOptions = {
        variables: { device, iface, beginTime, endTime },
        fetchPolicy: "no-cache",
    };

    const { isLoading: trafficStatus, data: traffic } = useMemoQuery(
        FETCH_INTERFACE_TRAFFIC,
        queryOptions,
        (d) => {
            return new TimeSeries(d.interface.traffic);
        },
        [beginTime, endTime]
    );

    const { isLoading: discardStatus, data: discards } = useMemoQuery(
        FETCH_INTERFACE_DISCARDS,
        queryOptions,
        (d) => {
            return new TimeSeries(d.interface.discards);
        },
        [beginTime, endTime]
    );

    const { isLoading: errorStatus, data: errors } = useMemoQuery(
        FETCH_INTERFACE_ERRORS,
        queryOptions,
        (d) => {
            return new TimeSeries(d.interface.errors);
        },
        [beginTime, endTime]
    );

    if (traffic && traffic.data !== null) {
        trafficSeries.current = traffic;
    }

    if (discards && discards.data !== null) {
        discardSeries.current = discards;
    }

    if (errors && errors.data !== null) {
        errorSeries.current = errors;
    }

    const renderInterfaceHeader = (device, iface) => {
        return (
            <div>
                <div style={breadcrumbStyle}>
                    <a href="/">HOME</a>
                    <span> › </span>
                    <a href="/network/interfaces/list">INTERFACES</a>
                    <span> » </span>
                </div>
                <h2>{pageTitle}</h2>
            </div>
        );
    };

    const renderDetails = (device, iface) => {
        return <InterfaceInfo device={device} iface={iface} />;
    };

    const renderLegend = () => {
        const fmt3s = format(".3s");
        const categories = [
            {
                key: "in",
                label: "Traffic (in)",
                value:
                    values && _.has(values, "traffic") ? `${fmt3s(values.traffic.in)}bps` : "- bps",
            },
            {
                key: "out",
                label: "Traffic (out)",
                value:
                    values && _.has(values, "traffic")
                        ? `${fmt3s(values.traffic.out)}bps`
                        : "- bps",
            },
            {
                key: "discards",
                label: "Discards (in, out)",
                value:
                    values && _.has(values, "discards")
                        ? `${fmt3s(values.discards.in)}/s, ${fmt3s(values.discards.out)}/s`
                        : "-/s",
            },
            {
                key: "errors",
                label: "Errors (in, out)",
                value:
                    values && _.has(values, "errors")
                        ? `${fmt3s(values.errors.in)}/s, ${fmt3s(values.errors.out)}/s`
                        : "-/s",
            },
        ];

        const style = styler([
            { key: "in", color: legendInTraffic },
            { key: "out", color: legendOutTraffic },
            { key: "errors", color: legendErrors },
            { key: "discards", color: legendDiscards },
        ]);

        return <Legend categories={categories} style={style} />;
    };

    const renderChart = () => {
        // Defines the layout of the multi-channel chart
        const stackedLayout = [
            {
                height: 200,
                charts: [
                    {
                        id: "traffic",
                        series: trafficSeries.current,
                        type: "area",
                        label: "Traffic (bps)",
                        colors: [inTraffic, outTraffic],
                        mode: "up-down",
                        interpolation: "curveLinear",
                    },
                ],
            },
            {
                height: 100,
                charts: [
                    {
                        id: "discards",
                        series: discardSeries.current,
                        type: "area",
                        label: "Discards/s",
                        format: "",
                        mode: "up-down",
                        colors: [inDiscards, outDiscards],
                        interpolation: "curveStep",
                    },
                ],
            },
            {
                height: 100,
                charts: [
                    {
                        id: "errors",
                        series: errorSeries.current,
                        type: "area",
                        label: "Errors/s",
                        format: "",
                        mode: "up-down",
                        colors: [inErrors, outErrors],
                        interpolation: "curveStep",
                    },
                ],
            },
        ];

        const overlayLayout = [
            {
                height: 340,
                charts: [
                    {
                        id: "traffic",
                        series: trafficSeries.current,
                        type: "area",
                        label: "Traffic (bps)",
                        colors: [inTraffic, outTraffic],
                        mode: "up-down",
                        interpolation: "curveLinear",
                    },
                    {
                        id: "discards",
                        series: discardSeries.current,
                        type: "line",
                        label: "Discards (/sec)",
                        format: "",
                        mode: "up-down",
                        colors: [inDiscards, outDiscards],
                        interpolation: "curveStep",
                    },
                    {
                        id: "errors",
                        series: errorSeries.current,
                        type: "line",
                        label: "Errors (/sec)",
                        format: "",
                        mode: "up-down",
                        colors: [inErrors, outErrors],
                        interpolation: "curveStep",
                    },
                ],
            },
        ];

        const chartLayout = layout === "stacked" ? stackedLayout : overlayLayout;
        const isLoading = trafficStatus === true || discardStatus === true || errorStatus === true;

        return (
            <MultiChannelChartWidget
                layout={chartLayout}
                title="Interface traffic"
                description="About this chart"
                timeRange={axisTimerange}
                onTimeRangeChange={setAxisTimerange}
                onTrackerChange={setTimestamp}
                onValuesChange={setValues}
                loading={isLoading}
            />
        );
    };

    // NavItems
    const subSections = [];
    subSections.push({
        key: "Traffic",
        label: "Traffic",
        url: `/network/devices/${encodeURIComponent(device)}/interfaces/${encodeURIComponent(
            iface
        )}/traffic`,
    });
    subSections.push({
        key: "Utilization",
        label: "Utilization",
        url: `/network/devices/${encodeURIComponent(device)}/interfaces/${encodeURIComponent(
            iface
        )}/utilization`,
    });

    let active;
    if (page === "utilization") {
        active = "Utilization";
    } else if (page === "traffic" || page === "undefined") {
        active = "Traffic";
    }

    const navItems = _.map(subSections, (section) => {
        const className = active === section.label ? "active" : "";
        const key = section.key;
        return (
            <li key={key} role="presentation" className={className}>
                <Link to={section.url}>{section.label}</Link>
            </li>
        );
    });

    return (
        <Col>
            <Row>
                <Col>
                    <Row>
                        <Col md={7}>{renderInterfaceHeader(device, iface)}</Col>
                        <Col md={5} style={{ marginTop: 20 }}>
                            <ul className="nav nav-pills" style={{ float: "right" }}>
                                {navItems}
                            </ul>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row>
                <Col>
                    <hr />
                </Col>
            </Row>

            <Row>
                <Col>{renderDetails(device, iface)}</Col>
            </Row>

            <Row>
                <Col>
                    <hr />
                </Col>
            </Row>

            <Row>
                <Col md={4} style={{ textAlign: "left" }}>
                    {renderLegend()}
                </Col>
                <Col md={3} style={{ textAlign: "center" }}>
                    <TrackerTime time={timestamp} series={traffic} />
                </Col>
                <Col md={3} style={{ textAlign: "center" }}>
                    <TimePicker />
                </Col>
                <Col md={2} style={{ textAlign: "right" }}>
                    <ChoicePicker
                        label="LAYOUT"
                        variableName="layout"
                        defaultValue="stacked"
                        options={options}
                    />
                </Col>
            </Row>

            <Row>
                <Col md={12}>{renderChart(timerange, traffic, traffic, traffic)}</Col>
            </Row>
        </Col>
    );
};

export { Interface };
