import _ from "underscore";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { SiteCard } from "app/sites/components/SiteCard";
import { FacilityCard } from "app/facilities/components/FacilityCard";
import { CollaboratorCard } from "app/lhcone/components/CollaboratorCard";
import { useEntitySelection, useUser } from "shared/hooks";

export const DashboardFavorites = () => {
    // Get the logged-in User from the context
    const user = useUser();

    const showFavorites = !_.isUndefined(user) && user.favoriteNetworkEntities.length > 0;

    // Get the current map selection from the URL query string
    const [mapSelection, setMapSelection] = useEntitySelection();

    if (user && showFavorites) {
        const { favoriteNetworkEntities } = user;
        const favorites = favoriteNetworkEntities.map((entity, i) => {
            if (entity.type === "ESnet Site") {
                return (
                    <Col md={3}>
                        <SiteCard
                            siteName={entity.shortName}
                            isSelected={entity.shortName === mapSelection}
                            onClicked={() => (selected) => setMapSelection(selected, "esnet_site")}
                        />
                    </Col>
                );
            } else if (entity.type === "Facility") {
                return (
                    <Col md={3}>
                        <FacilityCard
                            facilityId={entity.shortName}
                            showDescription={false}
                            onClicked={() => (selected) => setMapSelection(selected, "facility")}
                        />
                    </Col>
                );
            } else if (entity.type === "LHCONE") {
                return (
                    <Col md={3}>
                        <CollaboratorCard
                            collaboratorId={entity.shortName}
                            showDescription={false}
                            onClicked={() => (selected) => setMapSelection(selected, "lhcone")}
                        />
                    </Col>
                );
            } else {
                return null;
            }
        });

        return (
            <div style={{ marginLeft: "5px" }}>
                <h4 style={{ fontSize: 16 }}>Favorites</h4>
                <Row>{favorites}</Row>
            </div>
        );
    } else {
        return <div />;
    }
};
