import keymirror from "keymirror";

export const ActionTypes = keymirror({
    // Submit ok, error, pending...
    RECEIVED_SUBMIT_STATUS: null,
    // Portal data
    // (FAQ and updates)
    RECEIVED_PORTAL_UPDATES: null,
    RECEIVED_PORTAL_FAQ: null,
    // Network Entities
    // (sites, facilities, collaborators etc)
    SHOW_NETWORK_ENTITY_LIST: null,
    RECEIVED_RAW_NETWORK_ENTITY_LIST: null,
    ERROR_NETWORK_ENTITY_LIST: null,
    SHOW_NETWORK_ENTITY: null,
    RECEIVED_RAW_NETWORK_ENTITY: null,
    ERROR_NETWORK_ENTITY: null,
    // Event lists
    RECEIVED_RAW_EVENT_LIST: null,
    SHOW_EVENT_LIST: null,
    // Circuits
    SHOW_CONNECTION_LIST: null,
    RECEIVED_RAW_CONNECTION_LIST: null,
    ERROR_CONNECTION_LIST: null,
    SHOW_CONNECTION: null,
    RECEIVED_RAW_CONNECTION: null,
    ERROR_CONNECTION: null,
    // LHCONE collaborator view actions
    SHOW_COLLABORATOR: null,
    SHOW_COLLABORATOR_LIST: null,
    // Map topology
    FETCH_TOPOLOGY: null,
    RECEIVED_RAW_TOPOLOGY: null,
    ERROR_RAW_TOPOLOGY: null,
    // Map topology edges
    FETCH_TOPOLOGY_EDGE: null,
    RECEIVED_RAW_TOPOLOGY_EDGE: null,
    ERROR_RAW_TOPOLOGY_EDGE: null,
    // General data fetch
    FETCH_DATA: null,
    RECEIVED_RAW_DATA: null,
    ERROR_RAW_DATA: null,
    // Traffic Volume Interfaces
    FETCH_TRAFFIC_VOLUME_INTERFACES: null,
    RECEIVED_TRAFFIC_VOLUME_INTERFACES: null,
    ERROR_TRAFFIC_VOLUME_INTERFACES: null,
    // User
    UPDATE_USER: null,
    RECEIVED_USER: null,
    UPDATE_USER_FAVORITE: null,
    // Global errors
    ERROR: null,
    CLEAR_ERROR: null,
});

export const PayloadSources = keymirror({ SERVER_ACTION: null, VIEW_ACTION: null });

export const LoadStates = keymirror({ INIT: null, LOADING: null, LOADED: null });

export const SubmitStates = keymirror({
    INIT: null,
    PENDING: null,
    SUCCESS: null,
    ERROR: null,
});

export const CHANGE_EVENT = "change";

export const AggregationTypes = [
    {
        value: "avg",
        label: "Average",
        tooltip: "Average link utilization observed over the selected duration",
    },
    {
        value: "max",
        label: "Maximum",
        tooltip: "Maximum link utilization observed over the selected duration",
    },
    {
        value: "percentile",
        label: "95th Percentile",
        tooltip: "95th Percentile link utilization observed over the selected duration",
    },
];
