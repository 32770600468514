import { format } from "d3-format";
import React from "react";
import { Col, Row } from "react-bootstrap";
import HorizontalBarChart from "./components/TimeseriesListBarchart";
import Widget from "./Widget";
import {
    sectionHeaderStyle,
    clearSelectionButtonStyle,
    closeWidgetStyle,
} from "shared/styles/styles";

function formatter(value) {
    const f = format(".2s");
    return `${f(value)}bps`;
}

/**
 * Displays source timeseries list as a horizontal bar chart. Expects to
 * be passed an array of TimeSeries objects as `seriesList`.
 */
export default class extends React.Component {
    renderTitle = () => {
        if (this.props.title) {
            return <h4 style={sectionHeaderStyle}>{this.props.title}</h4>;
        } else {
            return null;
        }
    };

    renderClearSelection = () => {
        let clearSelection = () => {
            this.props.onSelectionChanged(null);
        };
        if (this.props.selected) {
            return (
                <div style={clearSelectionButtonStyle} onClick={clearSelection}>
                    <span style={closeWidgetStyle}>&times;</span>
                    Clear Selection
                </div>
            );
        }
        return <div />;
    };

    render() {
        const {
            description,
            seriesList,
            isLoading,
            error,
            sortBy = "avg",
            columns = ["in", "out"],
            top = 10,
            extraFields = [],
        } = this.props;

        let style = {};
        let timestamp = this.props.timestamp;
        if (!isLoading && !error && !timestamp && seriesList && seriesList.length) {
            style = columns.map((column) => this.props.style[column]);
            style.forEach((s, k) => {
                if (!s) {
                    console.error("No style specified for bar chart column", k);
                }
            });
            seriesList.forEach((series) => {
                if (series.size()) {
                    timestamp = series.timerange().end();
                }
            });
        }

        return (
            <Row>
                <Col md={10}>{this.renderTitle()}</Col>
                <Col md={2}>{this.renderClearSelection()}</Col>
                <Col md={12}>
                    <Row>
                        <Col md={12} style={{ fontSize: "smaller" }}>
                            <Widget
                                padding
                                info={description}
                                error={error}
                                loading={isLoading}
                                height={400}
                            >
                                <HorizontalBarChart
                                    display="range"
                                    seriesList={seriesList}
                                    columns={columns}
                                    top={top}
                                    sortBy={sortBy}
                                    timestamp={timestamp}
                                    format={formatter}
                                    style={style}
                                    selected={this.props.selected}
                                    onSelectionChanged={this.props.onSelectionChanged}
                                    onNavigate={this.props.onNavigate}
                                    selectionColor={this.props.selectionColor}
                                    extraFields={extraFields}
                                />
                            </Widget>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}
