import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams, useLocation, useHistory } from "react-router-dom";

import { EdgeSummaryChart } from "app/edges/components/EdgeSummaryChart";
import { SiteSummaryChart } from "app/sites/components/SiteSummaryChart";
import { DashboardAbout, DashboardLogos } from "./DashboardDescription";
import { DashboardFavorites } from "./DashboardFavorites";
import { DashboardTotalTrafficChart } from "./DashboardTotalTrafficChart";
import { DashboardSelection } from "./DashboardSelection";
import { GeographicMap, GeographicMapSidebar } from "./GeographicMap";
import { LogicalMap, LogicalMapSidebar } from "./LogicalMap";
import { getAgg, checkIfLatest } from "../utils/utils";

import { useEntitySelection, useTimeRange, useUser, useViewport } from "shared/hooks";

import Widget from "shared/components/widgets/Widget";
import { useSearchParams } from "shared/hooks/useSearchParams";
import { trackerTimeStyle } from "shared/styles/styles";

import "esnet-networkmap-panel/src/components/MapCanvas.component.js";
import "shared/css/main.css";
import { formatDate } from "shared/utils/format-utils";

const EVERY_60_SECONDS = 60 * 1000;
const MIN = 60 * 1000;
const HOUR = 60 * MIN;
const DAY = 24 * HOUR;

const TIME_RANGES = [
    { key: "latest", label: "Latest", duration: DAY }, // because we want to show 24h timerange in the charts in this case
    { key: "5m", label: "5m", duration: 5 * MIN },
    { key: "15m", label: "15m", duration: 15 * MIN },
    { key: "1h", label: "1h", duration: 1 * HOUR },
    { key: "6h", label: "6h", duration: 6 * HOUR },
    { key: "24h", label: "24h", duration: DAY },
    { key: "week", label: "week", duration: 7 * DAY },
    { key: "month", label: "month", duration: 30 * DAY },
];

const Dashboard = (props) => {
    const { page = "logical" } = useParams();
    const query = useSearchParams();
    const history = useHistory();
    const { pathname } = useLocation();

    // Size of window
    const { width } = useViewport();

    // Get the logged-in User from the context
    const user = useUser();

    // Current timestamp on the chart
    const [timestamp, setTimestamp] = useState(null);

    // Timerange for the charts
    const [timerange, handleTimeRangeChange] = useTimeRange(
        EVERY_60_SECONDS,
        TIME_RANGES,
        "latest"
    );

    // Get the current map selection from the URL query string
    const [mapSelection] = useEntitySelection();

    // When the timerange is changed from the sidebar, trigger a refresh of the begin and end time
    useEffect(() => {
        const timeRangeListener = history.listen(() => {
            handleTimeRangeChange();
        });

        return timeRangeListener;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Render the site's total traffic as sum of interfaces as an area chart.
     */
    const renderSiteTrafficChart = (selection) => {
        return (
            <SiteSummaryChart
                siteName={selection}
                timerange={timerange}
                timestamp={timestamp}
                onTrackerChanged={(ts) => setTimestamp(ts)}
            />
        );
    };

    /**
     * Render the networkConnection traffic for the selected edge
     */
    const renderEdgeTrafficChart = (selection, edgeType) => {
        return (
            <EdgeSummaryChart
                edgeName={selection}
                edgeType={edgeType}
                timerange={timerange}
                timestamp={timestamp}
                onTrackerChanged={(ts) => setTimestamp(ts)}
            />
        );
    };

    /**
     * Render the networkConnection traffic for the selected edge
     */
    const renderHubTrafficChart = () => {
        return <div>Traffic not available for hubs.</div>;
    };

    /**
     * Render a network traffic chart.
     *
     * We might render 1 of 3 possible charts here, depending on selection:
     *     - A site traffic chart
     *     - A link traffic chart
     *     - A total chart for networkwide ESnet traffic
     * Or for hubs:
     *     - we currently render a message saying that we don't show
     *       traffic for them yet.
     */
    const renderChart = ({ selection, selectionType, edgeType }) => {
        // edgeType is only set when an edge is clicked on the map
        // For now, it helps distinguish whether a site-hub edge or
        // a hub-hub edge is clicked
        if (selection) {
            if (selectionType === "esnet_site") {
                return renderSiteTrafficChart(selection);
            } else if (selectionType === "edge") {
                return renderEdgeTrafficChart(selection, edgeType);
            } else if (selectionType === "hub") {
                return renderHubTrafficChart();
            }
        } else {
            return (
                <DashboardTotalTrafficChart
                    timerange={timerange}
                    timestamp={timestamp}
                    onTrackerChanged={(ts) => setTimestamp(ts)}
                />
            );
        }
    };

    // State for "Display Options" accordion when an edge is selected on the map
    const [accordionCollapsed, setAccordionCollapsed] = useState(false);

    const renderSidebar = (columnWidth) => {
        switch (page) {
            case "geographic":
                return (
                    <GeographicMapSidebar
                        columnWidth={columnWidth}
                        accordionCollapsed={accordionCollapsed}
                    />
                );
            default:
                return (
                    <LogicalMapSidebar
                        columnWidth={columnWidth}
                        accordionCollapsed={accordionCollapsed}
                    />
                );
        }
    };

    const { selectionType } = mapSelection;

    const renderSubpage = () => {
        switch (page) {
            case "geographic":
                return (
                    <GeographicMap
                        timerange={timerange}
                        setAccordionCollapsed={setAccordionCollapsed}
                    />
                );
            default:
                return (
                    <LogicalMap
                        timerange={timerange}
                        setAccordionCollapsed={setAccordionCollapsed}
                    />
                );
        }
    };

    // Handle the text to display on the main page
    let mapType = "";
    if (pathname === "/") {
        mapType = "Logical Map";
    } else if (pathname === "/map/geographic") {
        mapType = "Geographic Map";
    }

    // Handle the text to display regarding what the map is displaying
    let mapInfo = "";
    let isLatest = checkIfLatest(query);

    if (isLatest) {
        mapInfo = `Displaying the last observed traffic sample`;
    } else {
        const queryInfo = {
            t: query.get("t"),
            b: query.get("b"),
            e: query.get("e"),
        };
        if (queryInfo.t) {
            if (getAgg(query) === "percentile") {
                mapInfo = `Displaying the 95th percentile rate of traffic (in bps) observed over the last ${queryInfo.t}`;
            } else {
                mapInfo = `Displaying the ${getAgg(
                    query
                )} rate of traffic (in bps) observed over the last ${queryInfo.t}`;
            }
        } else if (queryInfo.b && queryInfo.e) {
            const formattedTimeBounds = {
                b: formatDate(new Date(parseInt(queryInfo.b))),
                e: formatDate(new Date(parseInt(queryInfo.e))),
            };
            if (getAgg(query) === "percentile") {
                mapInfo = `Displaying the 95th percentile rate of traffic (in bps) observed from ${formattedTimeBounds.b} to ${formattedTimeBounds.e}`;
            } else {
                mapInfo = `Displaying the ${getAgg(query)} rate of traffic (in bps) observed from ${
                    formattedTimeBounds.b
                } to ${formattedTimeBounds.e}`;
            }
        }
    }

    // For the Map Legend to not get cut off for various screen sizes
    let columnWidth;
    if (width > 1950 || width < 1650) {
        // columnWidth = 115;
        columnWidth = 125; // Since below 1650, we display the legend on a separate row
    } else if (width > 1800) {
        // columnWidth = 105;
        columnWidth = 115;
    } else {
        // columnWidth = 95;
        columnWidth = 115;
    }

    if (user && !user.isAnonymous) {
        if (width > 1650) {
            // Largest screen (and including on TV displays), with user logged in
            return (
                <Col md={12}>
                    <Row>
                        <Col md={12} style={{ marginTop: 20 }}>
                            <DashboardFavorites />
                            <br />
                        </Col>
                    </Row>

                    <Row>
                        <Col md={9}>
                            <Row>
                                <Col md={12}>
                                    <Row>
                                        <Col
                                            md={3}
                                            style={{
                                                marginTop: 20,
                                                marginLeft: 5,
                                                marginBottom: 5,
                                                textAlign: "left",
                                            }}
                                        >
                                            <h4>{mapType}</h4>
                                        </Col>
                                        <Col style={{ marginTop: 20, textAlign: "right" }}>
                                            <span style={trackerTimeStyle}>{mapInfo}</span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} style={{ lineHeight: 0 }}>
                                    <Widget height={600} contentPadding={false} aspect={2.0}>
                                        {renderSubpage()}
                                    </Widget>
                                </Col>
                            </Row>
                            <hr />
                            {renderChart(mapSelection)}
                        </Col>
                        <Col md={3}>
                            <DashboardAbout />
                            <hr />
                            {renderSidebar(columnWidth)}
                            <hr style={{ marginTop: "0px" }} />
                            {selectionType ? (
                                <div>
                                    <DashboardSelection />
                                    <hr />
                                </div>
                            ) : (
                                <div />
                            )}
                            <DashboardLogos />
                        </Col>
                    </Row>
                </Col>
            );
        } else if (width > 900) {
            // Second largest breakpoint, typically laptop screens etc (with user)
            return (
                <Col md={12}>
                    <Row>
                        <Col md={12}>
                            <DashboardAbout />
                            <hr />
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12}>
                            <DashboardFavorites />
                            <br />
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12}>
                            <Row>
                                <Col md={12}>
                                    <Row>
                                        <Col
                                            md={3}
                                            style={{
                                                marginTop: 20,
                                                marginLeft: 5,
                                                marginBottom: 5,
                                                textAlign: "left",
                                            }}
                                        >
                                            <h4>{mapType}</h4>
                                        </Col>
                                        <Col style={{ marginTop: 20, textAlign: "right" }}>
                                            <span style={trackerTimeStyle}>{mapInfo}</span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} style={{ lineHeight: 0 }}>
                                    <Widget height={600} contentPadding={false} aspect={2.0}>
                                        {renderSubpage()}
                                    </Widget>
                                </Col>
                            </Row>
                            <hr />
                            {renderChart(mapSelection)}
                            <br />
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12}>{renderSidebar(columnWidth)}</Col>
                    </Row>

                    <Row>
                        <Col md={12} style={{ marginTop: 20 }}>
                            {selectionType ? (
                                <div>
                                    <hr />
                                    <DashboardSelection />
                                </div>
                            ) : (
                                <div />
                            )}
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12}>
                            <hr />
                            {renderChart(mapSelection)}
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12} style={{ height: 200, paddingTop: 20 }}>
                            <DashboardLogos />
                        </Col>
                    </Row>
                </Col>
            );
        } else {
            // User logged in, mobile size
            return (
                <Col md={12}>
                    <Row>
                        <Col md={12}>{renderChart(mapSelection)}</Col>
                    </Row>

                    <Row>
                        <Col md={12} style={{ marginTop: 20 }}>
                            <hr />
                            <DashboardFavorites />
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12} style={{ height: 200, marginTop: 50 }}>
                            <DashboardLogos />
                        </Col>
                    </Row>
                </Col>
            );
        }
    } else {
        if (width > 1650) {
            // Largest screen (and including on TV displays), with no user logged in
            return (
                <Col md={12}>
                    <Row>
                        <Col md={9}>
                            <Row>
                                <Col md={12}>
                                    <Row>
                                        <Col
                                            md={3}
                                            style={{
                                                marginTop: 20,
                                                marginLeft: 5,
                                                marginBottom: 5,
                                                textAlign: "left",
                                            }}
                                        >
                                            <h4>{mapType}</h4>
                                        </Col>
                                        <Col style={{ marginTop: 20, textAlign: "right" }}>
                                            <span style={trackerTimeStyle}>{mapInfo}</span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} style={{ lineHeight: 0 }}>
                                    <Widget height={600} contentPadding={false} aspect={2.0}>
                                        {renderSubpage()}
                                    </Widget>
                                </Col>
                            </Row>
                            <hr />
                            {renderChart(mapSelection)}
                        </Col>
                        <Col md={3}>
                            <DashboardAbout />
                            <hr />
                            {renderSidebar(columnWidth)}
                            <hr style={{ marginTop: "0px" }} />
                            {selectionType ? (
                                <div>
                                    <DashboardSelection />
                                    <hr />
                                </div>
                            ) : (
                                <div />
                            )}
                            <DashboardLogos />
                        </Col>
                    </Row>
                </Col>
            );
        } else if (width > 900) {
            // Second largest breakpoint, typically laptop screens etc (no user)
            return (
                <Col md={12}>
                    <Row>
                        <Col md={12}>
                            <DashboardAbout />
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12}>
                            <Row>
                                <Col md={12}>
                                    <Row>
                                        <Col
                                            md={3}
                                            style={{
                                                marginTop: 20,
                                                marginLeft: 5,
                                                marginBottom: 5,
                                                textAlign: "left",
                                            }}
                                        >
                                            <h4>{mapType}</h4>
                                        </Col>
                                        <Col style={{ marginTop: 20, textAlign: "right" }}>
                                            <span style={trackerTimeStyle}>{mapInfo}</span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} style={{ lineHeight: 0 }}>
                                    <Widget height={600} contentPadding={false} aspect={2.0}>
                                        {renderSubpage()}
                                    </Widget>
                                </Col>
                            </Row>
                            <br />
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12}>{renderSidebar(columnWidth)}</Col>
                    </Row>

                    <Row>
                        <Col md={12} style={{ marginTop: 20 }}>
                            {selectionType ? (
                                <div>
                                    <hr />
                                    <DashboardSelection />
                                </div>
                            ) : (
                                <div />
                            )}
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12}>
                            <hr />
                            {renderChart(mapSelection)}
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12} style={{ height: 200, paddingTop: 20 }}>
                            <DashboardLogos />
                        </Col>
                    </Row>
                </Col>
            );
        } else {
            // Smallest display, phones etc. We remove the map because it doesn't scale down
            // to this size reasonably.
            return (
                <Col md={12}>
                    <Row>
                        <Col md={12}>
                            <DashboardAbout />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>{renderChart(mapSelection)}</Col>
                    </Row>
                    <Row>
                        <Col md={12} style={{ height: 200, marginTop: 50 }}>
                            <DashboardLogos />
                        </Col>
                    </Row>
                </Col>
            );
        }
    }
};

export { Dashboard };
