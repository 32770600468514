import { format } from "d3-format";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Legend } from "react-timeseries-charts";
import TimeSeriesAreaChart from "./components/TimeseriesAreachart";
import { TrackerTime } from "./components/TrackerTime";
import Widget from "./Widget";
import { sectionHeaderStyle } from "shared/styles/styles";

/**
 * Wraps the TimeSeriesAreaChart in a widget control. This adds:
 *   - automatic width scaling
 *   - info button for information about the widget (based on the description prop)
 *   - standard error display
 *   - standard loading state
 *
 * This supports a selected item from the seriesList to highlight
 * on the total area chart that is based on the totalSeries.
 *
 * This will also display a title for the chart, along with tracker
 * and legend display.
 */
export const TimeseriesListAreachartWidget = ({
    title,
    totalSeries,
    seriesList,
    description,
    isLoading,
    error,
    timerange,
    labels,
    stackUp = [],
    stackDown = [],
    selected,
    style,
    mutedStyle,
    onTrackerChanged,
}) => {
    const [tracker, setTracker] = useState(null);

    const handleTrackerChanged = (tracker) => {
        setTracker(tracker);
        if (onTrackerChanged) {
            onTrackerChanged(tracker);
        }
    };

    const renderTitle = () => {
        if (title) {
            return <h4 style={sectionHeaderStyle}>{title}</h4>;
        } else {
            return null;
        }
    };

    let errorMsg = error;
    let selectedSeries;

    const infoRowStyle = {
        marginBottom: 10,
    };

    const chartkey = `key-${isLoading}-${error}`;

    let legendCategories = [];
    if (!isLoading && !error) {
        if (selected) {
            seriesList.forEach((series) => {
                if (series.name() === selected) {
                    selectedSeries = series;
                }
            });
        }

        const categories = [];
        stackUp.forEach((column) => categories.push(column));
        stackDown.forEach((column) => categories.push(column));

        const stack = categories.map((layer, i) => {
            return {
                pos: i,
                column: layer,
            };
        });

        categories.forEach((_, i) => {
            stack[i].label = labels[stack[i].column];
        });

        // Tracker values
        if (totalSeries) {
            if (tracker) {
                const pos = totalSeries.bisect(tracker);
                categories.forEach((_, i) => {
                    const v = totalSeries.at(pos).get(stack[i].column);
                    stack[i].value = `${format(".3s")(v)}bps`;
                });
            }
        }

        legendCategories = stack.map((layer) => {
            return {
                key: layer.column,
                label: layer.label,
                value: layer.value,
            };
        });
    }

    if (!error && !isLoading && !totalSeries) {
        console.warn("Rendering TimeSeries chart with no timeseries data");
    }

    if (totalSeries && !error && !isLoading && totalSeries.size() === 0) {
        errorMsg = "Empty dataset was returned by server";
    }

    return (
        <Row key={chartkey}>
            <Col md={12}>
                <Row style={infoRowStyle}>
                    <Col md={4} style={{ height: 35 }}>
                        {renderTitle()}
                    </Col>

                    <Col md={4} style={{ height: 35 }}>
                        <div style={{ textAlign: "center" }}>
                            <TrackerTime time={tracker} series={totalSeries} />
                        </div>
                    </Col>

                    <Col md={4} style={{ height: 35 }}>
                        <div style={{ float: "right" }}>
                            <Legend categories={legendCategories} style={style} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Widget
                            info={description}
                            error={errorMsg}
                            loading={isLoading}
                            height={275}
                        >
                            <TimeSeriesAreaChart
                                height={275}
                                style={style}
                                mutedStyle={mutedStyle}
                                timerange={timerange}
                                series={totalSeries}
                                selectedSeries={selectedSeries}
                                up={stackUp}
                                down={stackDown}
                                tracker={tracker}
                                onTrackerChanged={handleTrackerChanged}
                            />
                        </Widget>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};
