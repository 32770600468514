import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { SiteList } from "./components/SiteList";
import { SiteView } from "./components/SiteView";

const NoMatch = () => (
    <div>
        <h2>Whoops</h2>
        <p>Sorry but this Sites URL didn’t find any pages</p>
    </div>
);

const Sites = () => {
    let { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={`${path}/list`}>
                <SiteList />
            </Route>
            <Route path={`${path}/view/:site/:page?`}>
                <SiteView />
            </Route>
            <Route path="*">
                <NoMatch />
            </Route>
        </Switch>
    );
};

export default Sites;
