import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { TimePicker, ChoiceSelector } from "shared/components/controls";
import "shared/css/bootstrapxl.css";
import "shared/css/main.css";
import { useQueryState, useTimeRange } from "shared/hooks";
import { infoStyle } from "shared/styles/styles";
import { SiteSummaryInfo } from "./SiteInfo";
import { SiteInterfacesBarChart } from "./SiteInterfacesBarChart";
import { SiteInterfacesChart } from "./SiteInterfacesChart";
import { handleNavigate } from "shared/utils/timeseries-utils";
import { HOUR, DAY, EVERY_60_SECONDS } from "shared/constants/time";

const trafficOptions = [
    { key: "all", label: "All" },
    { key: "OSCARS", label: "OSCARS" },
    { key: "LHCONE", label: "LHCONE" },
    { key: "Other", label: "Other" },
];

const TIME_RANGES = [
    { key: "1h", label: "1h", duration: 1 * HOUR },
    { key: "6h", label: "6h", duration: 6 * HOUR },
    { key: "1d", label: "day", duration: DAY },
    { key: "7d", label: "week", duration: 7 * DAY },
    { key: "30d", label: "month", duration: 30 * DAY },
];

const SiteInterfaces = ({ site: siteName }) => {
    // Current timestamp the user is hovering over on the chart
    const [timestamp, setTimestamp] = useState(null);

    // Which type of traffic to show
    const [traffic] = useQueryState("traffic", "all");

    // The selected interface
    const [selected, setSelected] = useQueryState("s", null);

    // Timerange as determined by the URL search params and auto-updating
    const [timerange] = useTimeRange(EVERY_60_SECONDS);

    return (
        <Col md={12}>
            <Row style={infoStyle}>
                <Col>
                    <SiteSummaryInfo siteName={siteName} />
                </Col>
            </Row>

            <hr />

            <Row>
                <Col md={3}>
                    <ChoiceSelector
                        label="Traffic"
                        variableName="traffic"
                        defaultValue="all"
                        options={trafficOptions}
                    />
                </Col>
                <Col md={9}>
                    <div style={{ float: "right" }}>
                        <TimePicker ranges={TIME_RANGES} defaultValue="1d" />
                    </div>
                </Col>
            </Row>

            <hr />

            <Row>
                <Col>
                    <Row>
                        <Col md={12}>
                            <SiteInterfacesChart
                                siteName={siteName}
                                timerange={timerange}
                                timestamp={timestamp}
                                selected={selected}
                                traffic={traffic}
                                onTrackerChanged={(ts) => setTimestamp(ts)}
                            />
                        </Col>
                    </Row>

                    <hr />

                    <Row>
                        <Col md={12}>
                            <SiteInterfacesBarChart
                                siteName={siteName}
                                timerange={timerange}
                                timestamp={timestamp}
                                selected={selected}
                                traffic={traffic}
                                onSelectionChanged={(s) => setSelected(s)}
                                onNavigate={(n) => handleNavigate(n)}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Col>
    );
};

export default SiteInterfaces;
