import { useMutation } from "@apollo/react-hooks";
import { writeStorage } from "@rehooks/local-storage";
import gql from "graphql-tag";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Sidebar from "react-sidebar";
import { useUser } from "shared/hooks";

const LOGOUT_USER_MUTATION = gql`
    mutation logout {
        logoutUser {
            success
        }
    }
`;

const sidebarStyle = {
    root: {
        position: "absolute",
        top: 80,
        left: 0,
        right: 0,
        bottom: 0,
        overflow: "auto",
    },
    sidebar: { zIndex: 200, width: 256, height: "100%" },
    overlay: { backgroundColor: "rgba(181, 181, 181, 0.298039)" },
};

const sidebarPanelStyle = {
    root: {
        overflow: "hidden",
        fontFamily: "Roboto, sans-serif",
        fontWeight: 300,
    },
    header: {
        backgroundColor: "#33ABCC",
        color: "#E9E9E9",
        padding: "16px",
        fontSize: "1.5em",
    },
};

const styles = {
    sidebar: { zIndex: 200, width: 256, height: "100%" },
    sidebarLink: {
        display: "block",
        fontWeight: 500,
        fontFamily: "Roboto, sans-serif",
        fontSize: 14,
        padding: "5px 0px",
        color: "#808080",
        textDecoration: "none",
    },
    sidebarHeading: {
        display: "none",
        fontWeight: 300,
        fontFamily: "Roboto, sans-serif",
        fontSize: 14,
        padding: "5px 0px",
        color: "#999",
        backgroundColor: "#E9E9E9",
        textTransform: "uppercase",
    },
    divider: { margin: "8px 0", height: 1, backgroundColor: "#CCC" },
    content: {
        marginTop: 0,
        padding: 16,
        height: "100%",
        //,
        //boxShadow: "inset 0px 8px 8px -6px rgba(0,0,0,0.28)"
        backgroundColor: "#E9E9E9",
    },
};

const SidebarPanel = (props) => {
    const rootStyle = props.style
        ? { ...sidebarPanelStyle.root, ...props.style }
        : sidebarPanelStyle.root;
    return (
        <div style={rootStyle}>
            <div style={styles.header}>{props.title}</div>
            {props.children}
        </div>
    );
};

const links = [
    {
        section: "network",
        links: [
            { label: "Network", url: "/network/interfaces/list", pageset: "/network" },
            // { label: "Edges", url: "/edges/list", pageset: "/edges" },
            {
                label: "Volume",
                url: "/traffic-volume",
                pageset: "/traffic-volume",
            },
        ],
    },
    {
        section: "entities",
        links: [
            { label: "Sites", url: "/sites/list", pageset: "/sites" },
            { label: "Facilities", url: "/facilities/list", pageset: "/facilities" },
            { label: "LHCONE", url: "/lhcone/list", pageset: "/lhcone" },
            { label: "OSCARS", url: "/oscars/list", pageset: "/oscars" },
        ],
    },
    {
        section: "about",
        links: [
            { label: "FAQ", url: "/faq", pageset: "/faq" },
            { label: "Updates", url: "/updates", pageset: "/updates" },
        ],
    },
];

const SidebarContent = (props) => {
    const { onNavigated } = props;
    const user = useUser();

    const style = props.style ? { ...styles.sidebar, ...props.style } : styles.sidebar;
    const elements = [];
    const active = props.active || "home";

    const closeSidebar = () => {
        onNavigated();
    };

    const [logoutUser] = useMutation(LOGOUT_USER_MUTATION, {
        onCompleted: () => {
            writeStorage("user", {
                user: {
                    email: null,
                    firstName: null,
                    lastName: null,
                    isAnonymous: true,
                    favoriteNetworkEntities: [],
                    viewTopTalkers: [],
                },
            });
            window.location.reload();
        },
    });

    let homeClass = active === "/" ? "sidebar-link active" : "sidebar-link";
    elements.push(
        <Link to="/" key="home" onClick={() => closeSidebar()} className={homeClass}>
            Home
        </Link>
    );

    links.forEach((section, j) => {
        const divideKey = `section-${j}`;
        const headerKey = `header-${j}`;
        elements.push(<div key={divideKey} style={styles.divider} />);
        elements.push(
            <span key={headerKey} style={styles.sidebarHeading}>
                {section.section}
            </span>
        );
        section.links.forEach((link, i) => {
            let linkClass = "sidebar-link";
            if (active.startsWith(link.pageset)) {
                linkClass += " active";
            }
            const url = `${link.url}`;
            const key = `link-${j}-${i}`;
            elements.push(
                <Link to={url} key={key} onClick={() => closeSidebar()} className={linkClass}>
                    {link.label}
                </Link>
            );
        });
    });

    elements.push(<div key="user-divide" style={styles.divider} />);

    if (!user || user.isAnonymous) {
        let linkClass = "sidebar-link";
        if (active === "login") {
            linkClass += " active";
        }
        const url = `/user/login?redirect=${props.redirect}`;
        elements.push(
            <Link to={url} key="login" onClick={() => closeSidebar()} className={linkClass}>
                Login
            </Link>
        );

        // If the user isn't logged in, they may also want to register
        elements.push(
            <Link
                to="/user/register"
                key="register"
                onClick={() => closeSidebar()}
                className={linkClass}
            >
                Register
            </Link>
        );
    } else {
        elements.push(
            <Link
                to="/user/profile"
                key="profile"
                onClick={() => closeSidebar()}
                className={"sidebar-link" + (active === "/user/profile" ? " active" : "")}
            >
                Profile
            </Link>
        );
        elements.push(
            <Link
                to="/user/change_password"
                key="change_password"
                onClick={() => closeSidebar()}
                className={"sidebar-link" + (active === "user/change_password" ? " active" : "")}
            >
                Change Password
            </Link>
        );
        elements.push(
            <span key="logout" className="sidebar-link" onClick={() => logoutUser()}>
                Logout
            </span>
        );
    }

    return (
        <SidebarPanel style={style}>
            <div style={styles.content}>{elements}</div>
        </SidebarPanel>
    );
};

export default ({ onShowSidebar, showingSidebar, children }) => {
    const handleSetOpen = (sidebarOpen) => {
        if (onShowSidebar) {
            onShowSidebar(sidebarOpen);
        }
    };

    const sidebarContent = (
        <SidebarContent
            redirect={window.location.pathname}
            active={window.location.pathname}
            onNavigated={() => handleSetOpen(false)}
        />
    );

    const sidebarProps = {
        sidebar: sidebarContent,
        open: showingSidebar,
        onSetOpen: () => handleSetOpen(),
        styles: sidebarStyle,
    };

    return (
        <Sidebar {...sidebarProps}>
            <Container fluid>
                <Row style={{ margin: 10, paddingTop: 5 }}>
                    <Col md={12}>{children}</Col>
                </Row>
            </Container>
        </Sidebar>
    );
};
