import React, { useState } from "react";
import { formatBytes } from "shared/utils/format-utils";

/**
 * Renders a mini-barchart, showing traffic as two bars. Used in list views to give a
 * a traffic comparison between items in the list.
 */
export const TrafficBars = ({ width, height, trafficIn, trafficOut, max, yPadding = 5 }) => {
    const [hover, setHover] = useState(false);

    const renderElements = () => {
        // Find the mid point of the chart
        const mid = width / 2;

        const percentIn = trafficIn / max;
        const percentOut = trafficOut / max;
        const lengthIn = percentIn * mid;
        const lengthOut = percentOut * mid;

        let styles;
        let midlineStyle;
        let textStyles;

        if (hover) {
            styles = {
                in: { fill: "#4A92DE", opacity: ".25" }, // blue
                out: { fill: "#FD9420", opacity: ".25" }, // orange
            };
            midlineStyle = { stroke: "#999", strokeWidth: 1 };
            textStyles = {
                in: { fill: "#6D6E71", fontSize: 12, textAnchor: "end" },
                out: { fill: "#6D6E71", fontSize: 12 },
            };
        } else {
            styles = {
                in: { fill: "#4A92DE" }, // blue
                out: { fill: "#FD9420" }, // orange
            };
            midlineStyle = { stroke: "#999", strokeWidth: 1 };
            textStyles = {
                in: { fill: "#6D6E71", fontSize: 12, textAnchor: "end", opacity: "0" },
                out: { fill: "#6D6E71", fontSize: 12, opacity: "0" },
            };
        }

        const midline = <line x1={mid} y1={0} x2={mid} y2={height} style={midlineStyle} />;

        const inBar = (
            <g>
                <rect
                    rx={2}
                    ry={2}
                    x={mid - lengthIn}
                    y={yPadding}
                    width={lengthIn}
                    height={height - yPadding * 2}
                    style={styles.in}
                />
                <text x={mid - 10} y={height / 2 + 5} style={textStyles.in}>
                    {formatBytes(trafficIn, 1, "bps", 1000, false)}
                </text>
            </g>
        );

        const outBar = (
            <g>
                <rect
                    rx={2}
                    ry={2}
                    x={mid}
                    y={yPadding}
                    width={lengthOut}
                    height={height - yPadding * 2}
                    style={styles.out}
                />
                <text x={mid + 10} y={height / 2 + 5} style={textStyles.out}>
                    {formatBytes(trafficOut, 1, "bps", 1000, false)}
                </text>
            </g>
        );

        return (
            <g>
                {inBar}
                {outBar}
                {midline}
            </g>
        );
    };

    return (
        <div>
            <svg
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                width={width}
                height={height}
            >
                {renderElements()}
            </svg>
        </div>
    );
};
