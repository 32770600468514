import React, { useState } from "react";
import { esnetDarkGrey } from "shared/styles/styles";
import { formatBPS, formatBytes, formatCount } from "shared/utils/format-utils";

/**
 * Renders a mini-barchart, showing some traffic measure as a single bar
 */
export const TrafficBar = ({
    width,
    height = 30,
    traffic,
    max,
    yPadding = 5,
    format = "speed",
}) => {
    const [hover, setHover] = useState(false);

    const renderElements = () => {
        const percent = traffic / max;
        const length = percent * width;

        let style;
        let text;
        let textStyle;

        if (hover) {
            style = { fill: esnetDarkGrey, opacity: ".25" };
            textStyle = { fill: "#6D6E71", fontSize: 12, textAnchor: "start" };
        } else {
            style = { fill: esnetDarkGrey };
            textStyle = { fill: "#6D6E71", fontSize: 12, textAnchor: "start", opacity: "0" };
        }

        if (format === "bps") {
            text = formatBPS(traffic);
        } else if (format === "bytes") {
            text = formatBytes(traffic);
        } else if (format === "count") {
            text = formatCount(traffic);
        } else if (format === "speed") {
            text = formatBytes(traffic, 1, "bps", 1000, false);
        }

        const bar = (
            <g>
                <rect
                    rx={2}
                    ry={2}
                    x={0}
                    y={yPadding}
                    width={length}
                    height={height - yPadding * 2}
                    style={style}
                />
                <text x={10} y={height / 2 + 5} style={textStyle}>
                    {text}
                </text>
            </g>
        );

        return <g>{bar}</g>;
    };

    return (
        <div>
            <svg
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                width={width}
                height={height}
            >
                {renderElements()}
            </svg>
        </div>
    );
};
