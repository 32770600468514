import React from "react";
import doeLogo from "shared/img/doe-logo.png";
import lbnlLogo from "shared/img/lbnl-logo.png";

export const DashboardAbout = () => {
    return (
        <div style={{ marginTop: "15px" }}>
            <h2>About ESnet</h2>
            <p style={{ textAlign: "justify" }}>
                ESnet is a high-performance, unclassified network built to support scientific
                research. Funded by the U.S. Department of Energy’s Office of Science (SC) and
                managed by Lawrence Berkeley National Laboratory, ESnet provides services to more
                than 50 DOE research sites, including the entire National Laboratory system, its
                supercomputing facilities, and its major scientific instruments.
            </p>
        </div>
    );
};

export const DashboardLogos = () => {
    return (
        <div style={{ float: "right" }}>
            <span>
                <img
                    src={lbnlLogo}
                    alt="Berkeley lab logo"
                    style={{ marginRight: 10, marginLeft: 0, height: "60px" }}
                />
            </span>
            <span>
                <img src={doeLogo} alt="Department of Energy logo" height={30} />
            </span>
        </div>
    );
};
