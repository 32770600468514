import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { UserMessage } from "shared/components/controls";

const ACTIVATE_USER_MUTATION = gql`
    mutation ActivateUser($activationKey: String) {
        activateUser(activationKey: $activationKey) {
            success
            message
        }
    }
`;

// After a user registers they need to activate by clicking on a
// link that is emailed to them.  This is where that happens.
const ActivateUser = () => {
    const { activationKey } = useParams();
    const [isComplete, setComplete] = useState(false);

    // Queue up the mutation to try and active the user on the server
    const [activateUser, { loading, error }] = useMutation(ACTIVATE_USER_MUTATION, {
        onCompleted: () => {
            setComplete(true);
        },
    });

    // We only want to do this once, so we use effect and only depend on the activationKey changing
    // i.e. if the key changes we try again, otherwise this only runs once.
    useEffect(() => {
        activateUser({ variables: { activationKey } });
    }, [activationKey, activateUser]);

    const isPending = !!loading;
    const isError = !!error;

    const renderChecking = () => {
        return (
            <Row>
                <Col md={3} />
                <Col md={6}>
                    <center>
                        <h2 style={{ padding: 100 }}>Checking activation key...</h2>
                    </center>
                </Col>
                <Col md={3} />
            </Row>
        );
    };

    const renderFail = () => {
        return (
            <Row>
                <Col md={3} />
                <Col md={6}>
                    <UserMessage title="Activation failed" isError>
                        <p>
                            The user account could not be activated. Perhaps the activation key was
                            invalid.
                        </p>
                    </UserMessage>
                </Col>
                <Col md={3} />
            </Row>
        );
    };

    const renderSuccess = () => {
        return (
            <Row>
                <Col md={3} />
                <Col md={6}>
                    <UserMessage title="Activation succeeded!">
                        <p>
                            You may now <a href="/user/login">log in</a>
                        </p>
                    </UserMessage>
                </Col>
                <Col md={3} />
            </Row>
        );
    };

    if (isPending) {
        return renderChecking();
    } else if (isComplete) {
        return renderSuccess();
    } else if (isError) {
        return renderFail();
    } else {
        return <div />;
    }
};

export { ActivateUser, ACTIVATE_USER_MUTATION };
