import React from "react";
import { Dropdown } from "react-bootstrap";
import { ChevronDown } from "react-bootstrap-icons";
import { useQueryState } from "shared/hooks";
import { labelStyle, linkStyleActive, dropdownStyle } from "shared/styles/styles";

const chevronStyle = {
    cursor: "pointer",
    color: "steelblue",
    userSelect: "none",
    marginLeft: 10,
};

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
        style={{ cursor: "pointer", padding: 10, ...linkStyleActive }}
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        <ChevronDown style={chevronStyle} />
    </span>
));

export const ChoiceSelector = ({ label, options, variableName, defaultValue }) => {
    const [value, setValue] = useQueryState(variableName, defaultValue);

    const handleSelection = (key) => {
        setValue(key, { s: null });
    };

    const items = options.map((option) => {
        return (
            <Dropdown.Item
                key={option.key}
                active={option.key === value}
                eventKey={option.key}
                onClick={() => handleSelection(option.key)}
            >
                {option.label}
            </Dropdown.Item>
        );
    });

    let valueLabel = "None";
    options.forEach((option) => {
        if (option.key === value) {
            valueLabel = option.label;
        }
    });

    return (
        <Dropdown style={dropdownStyle}>
            <span style={labelStyle}>{label}</span>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                {valueLabel}
            </Dropdown.Toggle>
            <Dropdown.Menu>{items}</Dropdown.Menu>
        </Dropdown>
    );
};
