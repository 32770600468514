import React, { useLayoutEffect, useRef, useState, forwardRef, useEffect } from "react";
import { Form, Overlay, Popover } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory, useLocation } from "react-router-dom";
import { ranges as defaultRanges, units } from "shared/constants/time";
import { useSearchParams } from "shared/hooks";
import Select from "react-select";

import {
    buttonContainer,
    primaryButtonEnabled,
    primaryButtonDisabled,
    secondaryButtonEnabled,
    labelStyle,
    linkStyle,
    linkStyleActive,
    linkStyleActiveClickable,
    timeRangeBkg,
} from "shared/styles/styles";

const cancelStyle = { paddingRight: 10, color: "#808080", cursor: "pointer" };
const okStyle = { ...primaryButtonEnabled, fontSize: "14px" };
const disabledStyle = { ...primaryButtonDisabled, fontSize: "14px" };
const customTimeRangeDropdownBtnMargin = { marginBottom: 9 };

const timePickerStyling = {
    backgroundColor: "#fff",
    paddingLeft: "9px",
    paddingRight: "9px",
    paddingBottom: "0px",
    paddingTop: "13px",
    border: "1px solid #C4C5C7",
    marginTop: "7px",
    borderRadius: "4px",
};

/**
 * Renders a CustomTimeForm component implemented in TimePicker controls.
 *
 * By default, the control analyzes the current page's URL parameters on the initial render to determine either a relative
 * (to the current time) or absolute time bounds. If there is such a bound specified in the URL parameters, the default
 * controls populate with those bounds and the appropriate panel specifying the bounds is displayed. The dropdown populates
 * with one of the predefined bounds if there is a match, or "custom" if there are bounds defined, but not matching one of
 * the predefined bounds.
 *
 * If no URL paremeters define a bound, a global default of 24 hours (1 day) is displayed as the current time bounds and
 * the selected default from the dropdown is displayed as "latest".
 *
 * @param {{
 *  initialStartTime: number,
 *  initialEndTime: number,
 *  initialRelativeTime: number,
 *  onAbsoluteTimeChange: (t) => void,
 *  onRelativeTimeChange: (t) => void,
 *  onOpen?: () => void
 *  onClose?: () => void
 *  options: {[key: string]: any}
 * }} param                                 The initialization parameters for the CustomTimeForm such that
 *                                          param.initialStartTime is the lower bound for the time picker
 *                                          param.initialEndTime is the upper bound for the time picker
 *                                          param.initialRelativeTime is relative time
 *                                          param.onAbsoluteTimeChange is a callback function for changes in the bounds
 *                                          param.onRelativeTimeChange is a callback function for changes in relative time
 *                                          param.onOpen is a callback function for the form's open event
 *                                          param.onClose is a callback function for the form's close event
 *                                          param.options is an object of optional properties with the following keys
 *                                              {boolean} isDropDown            Tells the renderer that the form is part of a drop down.
 * @returns
 */
const CustomTimeForm = ({
    initialStartTime,
    initialEndTime,
    initialRelativeTime,
    onAbsoluteTimeChange,
    onRelativeTimeChange,
    onOpen,
    onClose,
    options,
}) => {
    // Uncommitted relative time string
    const query = useSearchParams();
    const [relativeTime, setRelativeTime] = useState(
        initialRelativeTime === "custom"
            ? query.get("t") || ""
            : initialRelativeTime || query.get("t") || ""
    );
    const [isRelativeUpdated, setIsRelativeUpdated] = useState(true);
    const [currentRenderedRelativeTime, setCurrentRenderedRelativeTime] = useState(relativeTime);

    // Uncommitted absolute time range
    const [startTime, setStartTime] = useState(initialStartTime);
    const [endTime, setEndTime] = useState(initialEndTime);
    const [isAbsoluteUpdated, setIsAbsoluteUpdated] = useState(true);
    const [currentRenderedAbsoluteTime, setCurrentRenderedAbsoluteTime] = useState({
        startTime,
        endTime,
    });

    // Is the form currently in an error state?
    const [hasError, setHasError] = useState(false);

    // Is the user editing the relative time string or the absolute time range?
    const [isRelative, setIsRelative] = useState(query.get("t") !== null);

    // invoke onOpen when displaying component for first time, and onClose() if about to unmount
    useLayoutEffect(() => {
        if (onOpen) {
            onOpen();
        }
        return () => {
            // no need for onClose, invoked at button
        };
    }, [onOpen]);

    /**
     * Change handler for relative time text field. Trigger on any 'change' event from that text field or a 'keyup' event originating from
     * releasing the 'Enter' key.
     *
     * Valid changes are not applied unless invokeApplyChange is set to true upon invocation or applyChanges() is invoked at a later point.
     * If a change is invalid, an error is detected and changes are not applied.
     *
     * @param {*} e
     * @param {boolean} invokeApplyChanges           Optional. Set to true if the changes are to be applied
     * @see applyChanges
     */
    const handleRelativeChange = (e, invokeApplyChanges = false) => {
        const time = e.target.value;
        // Check the text to see if it's a valid timerange
        const regex = /([0-9]+)([smhdwMY])/;
        const parts = regex.exec(time);
        let errorDetected = false;
        if (parts && parts.length >= 3) {
            const multiplier = parseInt(parts[1], 10);
            const unit = parts[2];
            const duration = multiplier * units[unit];
            if (duration >= 1000) {
                setHasError(false);
                errorDetected = false;
            } else {
                setHasError(true);
                errorDetected = true;
            }
        } else {
            setHasError(true);
            errorDetected = true;
        }
        setRelativeTime(e.target.value);
        if (currentRenderedRelativeTime !== e.target.value) {
            setIsRelativeUpdated(false);
        }
        if (invokeApplyChanges && !errorDetected) {
            const methodName = "TimePicker.CustomTimeForm.handleCharge";
            console.debug(
                `${methodName}: Cannot apply changes due to error in relative time input ${e.target.value}`
            );
            setCurrentRenderedRelativeTime(e.target.value);
            setIsRelativeUpdated(true);
            onRelativeTimeChange(e.target.value);
        }
    };

    /**
     * Apply changes from the currrent selected relative or absolute time bounds. Handler for OK or Update button.
     */
    const applyChanges = () => {
        if (!hasError) {
            if (isRelative) {
                setCurrentRenderedRelativeTime(relativeTime);
                setIsRelativeUpdated(true);
                onRelativeTimeChange(relativeTime);
            } else {
                setCurrentRenderedAbsoluteTime({ startTime, endTime });
                setIsAbsoluteUpdated(true);
                onAbsoluteTimeChange(startTime, endTime);
            }
        } else {
            console.debug(
                `TimePicker.CustomTimeForm.applyChanges: Cannot apply changes due to error in relative time input ${relativeTime}`
            );
        }
    };

    let content;
    if (isRelative) {
        content = (
            <table>
                <tbody>
                    <tr>
                        <td width={80}>
                            <Form.Label style={{ marginTop: 7 }} column="sm">
                                Relative
                            </Form.Label>
                        </td>
                        <td>
                            <Form.Control
                                type="text"
                                size="sm"
                                placeholder=""
                                value={relativeTime}
                                isInvalid={hasError}
                                onKeyUp={(e) => {
                                    if (e.key === "Enter") {
                                        handleRelativeChange(e, true);
                                    }
                                }}
                                onChange={(e) => handleRelativeChange(e)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <Form.Text className="text-muted">
                                Enter a relative time, for example "6h" for 6 hours. Use days (d),
                                hours (h), and minutes (m).
                            </Form.Text>
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    } else {
        // absolute times start and end times via two datepickers
        content = (
            <table>
                <tbody>
                    <tr>
                        <td width={80}>
                            <Form.Label style={{ marginTop: 7 }} column="sm">
                                Begin
                            </Form.Label>
                        </td>
                        <td>
                            <DatePicker
                                className="form-control form-control-sm"
                                placeholderText="MM/dd/yyyy h:mm"
                                selected={startTime}
                                onChange={(t) => {
                                    setStartTime(t);
                                    if (t !== currentRenderedRelativeTime.startTime) {
                                        setIsAbsoluteUpdated(false);
                                    }
                                }}
                                timeInputLabel="@"
                                dateFormat="MM/dd/yyyy h:mm aa"
                                popperPlacement="bottom"
                                maxDate={endTime}
                                showTimeInput
                            />
                        </td>
                    </tr>
                    <tr>
                        <td width={80}>
                            <Form.Label style={{ marginTop: 7 }} column="sm">
                                End
                            </Form.Label>
                        </td>
                        <td>
                            <DatePicker
                                className="form-control form-control-sm"
                                placeholderText="MM/dd/yyyy h:mm"
                                selected={endTime}
                                onChange={(t) => {
                                    setEndTime(t);
                                    if (t !== currentRenderedAbsoluteTime.endTime) {
                                        setIsAbsoluteUpdated(false);
                                    }
                                }}
                                timeInputLabel="@"
                                dateFormat="MM/dd/yyyy h:mm aa"
                                popperPlacement="bottom"
                                minDate={startTime}
                                showTimeInput
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }

    let cancelStyling;
    if (options && options?.isDropDown) {
        cancelStyling = { display: "none" };
    } else {
        cancelStyling = {
            ...secondaryButtonEnabled,
            cancelStyle,
        };
    }

    let isSelectedCustomTimeUpdated = true;
    if (!isRelative && !isAbsoluteUpdated) {
        isSelectedCustomTimeUpdated = false;
    } else if (isRelative && !isRelativeUpdated) {
        isSelectedCustomTimeUpdated = false;
    }

    let appliedDisabledStyle = { ...disabledStyle };
    let appliedOkStyle = { ...okStyle };
    if (options?.isDropDown) {
        appliedDisabledStyle = { ...disabledStyle, ...customTimeRangeDropdownBtnMargin };
        appliedOkStyle = { ...okStyle, ...customTimeRangeDropdownBtnMargin };
    }

    return (
        <div>
            <div>
                <span
                    style={isRelative ? linkStyleActive : linkStyle}
                    onClick={() => setIsRelative(true)}
                >
                    Relative
                </span>
                <span>|</span>
                <span
                    style={!isRelative ? linkStyleActive : linkStyle}
                    onClick={() => setIsRelative(false)}
                >
                    Absolute
                </span>
            </div>
            <hr />
            <div>{content}</div>
            <hr />
            <div style={{ ...buttonContainer, flexFlow: "row-reverse" }}>
                {!options?.isDropDown && (
                    <button
                        type="button"
                        style={cancelStyling}
                        onClick={() => onClose && onClose()}
                    >
                        CANCEL
                    </button>
                )}
                <button
                    type="button"
                    style={
                        hasError || isSelectedCustomTimeUpdated
                            ? appliedDisabledStyle
                            : appliedOkStyle
                    }
                    onClick={applyChanges}
                >
                    {options?.isDropDown ? "Update" : "OK"}
                </button>
            </div>
        </div>
    );
};

/**
 * Renders a Time Picker control. It's style of rendering, controlled by the
 * timePickerStyle prop, defaults to a list of clickable text as in the following:
 *
 * TIME   latest 5m  15m  1h  6h  day  week  month  custom
 *
 * The control may also be set to render in "dropdown" style, using the same above
 * options. All options are considered "relative", meaning relative to the time
 * a selection is made or updated (aka the "current time").
 *
 * The "latest" option is special in that the topology rendered by this option
 * will be guaranteed to be the latest sample while the data visualization will
 * cover a period of 24 hours since the current time.
 *
 * When clicking "custom", it expands another control permitting
 * either a custom relative selection (using time notation, refer to script
 * time.units).
 *
 *
 * You can customize by providing a custom set of preset ranges, and also picking which one is the default.
 * The results of the control are written into the URL. You can use the useTimeRange() hook to get a live
 * version of the resulting timerange (which may be relative or absolute).
 *
 * @param {{value: string, label: string, duration: number}} ranges     Defines the available set of options each define upper and lower bounds for ranges.
 * @param {string} defaultValue                                         The default range to set when first rendering the component
 * @param {string} timePickerStyle                                      When defined as 'list', it will render the styling of the timer
 * @param {any} containerRefObj                                         A React Ref object reference to an element to style gray when 'custom' is selected.
 * @param {(selectedValue) => void | undefined} onChange                Callback to invoke once a time has been picked from one of the dropdown selections.
 * @see esnet-portal/myesnet/ui/src/shared/constants/time.js
 */
export const TimePicker = forwardRef(
    (
        {
            ranges = defaultRanges,
            defaultValue = "1d",
            timePickerStyle = "list",
            containerRefObj,
            onDropDownChange,
        },
        ref
    ) => {
        const [showCustomOverlay, setShowCustomOverlay] = useState(false);
        const [isCustomDatePickersRendered, setIsCustomDatePickersRendered] = useState(false);
        const [target, setTarget] = useState(null);
        const [defaultValueState] = useState(defaultValue);

        ref = useRef(null);

        useEffect(() => {
            handleChangeTime(defaultValue);
            // eslint-disable-next-line
        }, []);

        /**
         * Handler for change to time bound
         * @param {string} t                The value selected from the drop down
         */
        const handleChangeTime = (t) => {
            const defaultValue = defaultValueState;
            // Perform callback if set
            if (onDropDownChange) {
                onDropDownChange(t);
            }
            // set flag if custom time
            if (t === "custom") {
                if (containerRefObj) {
                    for (const [key, val] of Object.entries(timeRangeBkg)) {
                        containerRefObj.style[key] = val;
                    }
                }
                setIsCustomDatePickersRendered(true);
                if (!isCustom) {
                    isCustom = true;
                }
                return;
            } else {
                if (containerRefObj) {
                    Object.keys(timeRangeBkg).forEach((key) => {
                        containerRefObj.style[key] = "";
                    });
                }
                setIsCustomDatePickersRendered(false);
                isCustom = false;
            }

            // Build URL search params from t only
            const targetQuery = new URLSearchParams(query);
            targetQuery.delete("b");
            targetQuery.delete("e");

            // TODO: This is a very specific case, should it be handled differently?
            if (t === "latest") {
                targetQuery.delete("agg"); // agg shouldn't exist when timeperiod is latest
            }

            if (t === defaultValue || t === defaultValue.value) {
                targetQuery.delete("t");
            } else if (t.key) {
                targetQuery.set("t", t.key);
            } else {
                targetQuery.set("t", t);
            }

            // Transition URL
            history.push(`${pathname}?${targetQuery.toString()}`);
        };

        /**
         * Handler for absolute change
         * @param {string|number} b             Lower bound for custom time range
         * @param {string|number} e             Upper bound for custom time range
         */
        const handleCustomAbsoluteChange = (b, e) => {
            setShowCustomOverlay(false);

            // Build URL search params from t only
            const targetQuery = new URLSearchParams(query);
            targetQuery.delete("t");
            targetQuery.set("b", +b);
            targetQuery.set("e", +e);

            // Transition URL
            history.push(`${pathname}?${targetQuery.toString()}`);
        };

        /**
         * Handler for custom relative change
         * @param {number} rel          The new relative time.
         */
        const handleCustomRelativeChange = (rel) => {
            setShowCustomOverlay(false);

            // Build URL search params from t only
            const targetQuery = new URLSearchParams(query);
            targetQuery.delete("b");
            targetQuery.delete("e");
            targetQuery.set("t", rel);

            // Transition URL
            history.push(`${pathname}?${targetQuery.toString()}`);
        };

        const query = useSearchParams();
        const history = useHistory();
        let { pathname } = useLocation();

        // Based on the query string, we either have a time such as "6h" which
        // is relative to now, or a custom time range defined by b and e. If
        // we have a custom time in the URL that wins over the time t, though the
        // UI shouldn't generally put the URL in that state itself.
        let time = query.get("t") || defaultValue;
        const begin = +query.get("b");
        const end = +query.get("e");

        let customBegin;
        let customEnd;
        let isCustom = true;
        if (begin && end) {
            time = "custom";
            defaultValue = ranges.find((r) => r.value === "custom");
            customBegin = new Date(begin);
            customEnd = new Date(end);
        } else {
            const now = new Date();

            // See if the relative timerange is in our presets
            const rangeSize = ranges.length;
            const targetRange = ranges.find((r, rangeIdx) => {
                if (r.key === time || r.value === time || time === undefined) {
                    customBegin = new Date(+now - r.duration);
                    customEnd = now;
                    isCustom = false;
                    return true;
                } else if (rangeIdx + 1 === rangeSize && r.value === "custom") {
                    isCustom = true;
                    return true;
                }
                return false;
            });

            const regex = /([0-9]+)([smhdw])/;
            const parts = regex.exec(time);
            if (parts && parts.length >= 3) {
                const multiplier = parseInt(parts[1], 10);
                const unit = parts[2];
                const duration = multiplier * units[unit];
                customBegin = new Date(+now - duration);
                customEnd = now;
                if (isCustom) {
                    time = "custom";
                    defaultValue = targetRange;
                } else {
                    defaultValue = targetRange;
                }
            }
        }

        let content = [];
        if (timePickerStyle === "dropdown") {
            // dropdown style control (with custom as dynamic hide/show form)
            content.push(
                <Select
                    key={"timepicker-dropdown-control"}
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={defaultValue}
                    name="timerange"
                    options={ranges}
                    onChange={(e) => handleChangeTime(e.value)}
                />
            );
            // append the dual datepicker controls for start and end if on custom
            if (isCustomDatePickersRendered) {
                customBegin = customBegin || begin || Date.now() - units.d;
                customEnd = customEnd || end || Date.now();
                const customRelativeTime = time.value === "latest" ? "1d" : time.value;
                content.push(
                    <Form
                        key={"timepicker-custom-controls"}
                        className={`custom-time-form`}
                        onSubmit={(e) => {
                            e.preventDefault();
                        }}
                        style={timePickerStyling}
                    >
                        <Form.Group>
                            <CustomTimeForm
                                initialStartTime={new Date(customBegin)}
                                initialEndTime={new Date(customEnd)}
                                initialRelativeTime={customRelativeTime}
                                onAbsoluteTimeChange={handleCustomAbsoluteChange}
                                onRelativeTimeChange={handleCustomRelativeChange}
                                options={{ isDropDown: true }}
                            />
                        </Form.Group>
                    </Form>
                );
            }
        } else {
            // list style control (with custom as popover)
            content.push(
                <div style={{ width: "100%" }} ref={ref} key={"timepicker-list-control"}>
                    <span style={labelStyle}>TIME</span>
                    {ranges.map((r) => {
                        return (
                            <span
                                key={r.key}
                                style={time === r.key ? linkStyleActive : linkStyle}
                                onClick={() => handleChangeTime(r.key)}
                            >
                                {r.label}
                            </span>
                        );
                    })}

                    <span
                        style={isCustom ? linkStyleActiveClickable : linkStyle}
                        onClick={(e) => {
                            setShowCustomOverlay(true);
                            setTarget(e.target);
                        }}
                        data-tip="Pan and zoom the chart to set a custom time range"
                    >
                        custom
                    </span>

                    <Overlay
                        show={showCustomOverlay}
                        placement="bottom"
                        target={target}
                        container={ref.current}
                        containerPadding={20}
                    >
                        <Popover id="popover-basic">
                            <Popover.Header as="h2">Custom date range</Popover.Header>
                            <Popover.Body>
                                <CustomTimeForm
                                    initialStartTime={new Date(customBegin)}
                                    initialEndTime={new Date(customEnd)}
                                    initialRelativeTime={time}
                                    onAbsoluteTimeChange={handleCustomAbsoluteChange}
                                    onRelativeTimeChange={handleCustomRelativeChange}
                                    onClose={() => setShowCustomOverlay(false)}
                                />
                            </Popover.Body>
                        </Popover>
                    </Overlay>
                </div>
            );
        }

        return content;
    }
);
