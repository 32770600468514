export const MIN = 60 * 1000;
export const HOUR = 60 * MIN;
export const DAY = 24 * HOUR;
export const EVERY_60_SECONDS = MIN;

export const ranges = [
    { key: "5m", label: "5m", duration: 5 * MIN },
    { key: "15m", label: "15m", duration: 15 * MIN },
    { key: "1h", label: "1h", duration: 1 * HOUR },
    { key: "6h", label: "6h", duration: 6 * HOUR },
    { key: "1d", label: "day", duration: DAY },
    { key: "7d", label: "week", duration: 7 * DAY },
    { key: "30d", label: "month", duration: 30 * DAY },
];

/**
 * Options used to populate sidebar dropdown time pickers.
 * @see {TimePicker}
 */
export const sidebarRanges = [
    { value: "latest", label: "Latest", duration: DAY }, // because we want to show 24h timerange in the charts in this case - last 24hr relative to now?
    { key: "5m", value: "5m", label: "5m", duration: 5 * MIN },
    { key: "15m", value: "15m", label: "15m", duration: 15 * MIN },
    { key: "1h", value: "1h", label: "1h", duration: 1 * HOUR },
    { key: "6h", value: "6h", label: "6h", duration: 6 * HOUR },
    { key: "1d", value: "24h", label: "24h", duration: DAY },
    { key: "7d", value: "7d", label: "week", duration: 7 * DAY },
    { key: "30d", value: "30d", label: "month", duration: 30 * DAY },
    { value: "custom", label: "custom" },
];

/**
 * Units used for interpreting relative time strings, with values measured in milliseconds. Examples:
 *
 * <dl>
 *  <dt>35s</dt>
 *  <dd>The past 30 seconds</dd>
 *  <dt>15m</dt>
 *  <dd>The past 15 minutes</dd>
 *  <dt>3M</dt>
 *  <dd>The past 3 months</dd>
 * </dl>
 */
export const units = {
    s: 1000,
    m: 1000 * 60,
    h: 1000 * 60 * 60,
    d: 1000 * 60 * 60 * 24,
    w: 1000 * 60 * 60 * 24 * 7,
    M: 1000 * 60 * 60 * 24 * 30,
    Y: 1000 * 60 * 60 * 24 * 365,
};

export const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];
