import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Resizable } from "react-network-diagrams";
import { Link } from "react-router-dom";
import { AutoSizer, Column, Table, SortIndicator } from "react-virtualized";
import "react-virtualized/styles.css";
import { Spinner, TrafficBars } from "shared/components/controls";
import { useSortState } from "shared/hooks";
import { rowCountStyle, totalRowCountStyle } from "shared/styles/styles";
import _ from "underscore";

export const GET_SAPS_QUERY = gql`
    query getAllSapsDetailed {
        saps {
            name
            device
            description
            site
            remoteShortName
            remoteFullName
            entityType
            traffic {
                columns
                points
                name
            }
        }
    }
`;

export class FilterableSAPList {
    constructor(saps) {
        this.saps = saps;
    }

    isLoaded() {
        return !_.isUndefined(this.saps);
    }

    get(index) {
        return this.saps[index];
    }

    filterBySite(site) {
        if (!this.isLoaded() || _.isNull(site)) {
            return this;
        }

        return new FilterableSAPList(
            _.filter(this.saps, (sap) => {
                return (
                    site === "" ||
                    _.isUndefined(site) ||
                    sap.site.toLowerCase() === site.toLowerCase()
                );
            })
        );
    }

    filterByDevice(device) {
        if (!this.isLoaded() || _.isNull(device)) {
            return this;
        }

        return new FilterableSAPList(
            _.filter(this.saps, (sap) => {
                return device === "" || _.isUndefined(device) || sap.device === device;
            })
        );
    }

    filterByText(textFilter) {
        if (!this.isLoaded()) {
            return this;
        }

        if (!textFilter || _.isUndefined(textFilter) || textFilter.length === 0) {
            return this;
        }

        const lowerTextFilter = textFilter.toLowerCase();
        return new FilterableSAPList(
            _.filter(this.saps, (sap) => {
                const deviceName = sap.device || "";
                const i = sap.name || "";

                let tags = "";
                if (sap.description !== "-" && sap.description !== undefined) {
                    let tags_str = sap.description.split(":")[2];
                    if (tags_str !== "" && tags_str !== undefined) {
                        tags = tags_str.split("_");
                        tags = tags.join(" ") || "";
                    }
                }

                const remoteShortName = sap.remoteShortName;
                const remoteFullName = sap.remoteFullName;
                let connection = "";
                if (tags.includes("site")) {
                    connection = remoteShortName;
                } else {
                    connection = remoteFullName;
                }

                return (
                    deviceName.toString().toLowerCase().indexOf(lowerTextFilter) >= 0 ||
                    i.toString().toLowerCase().indexOf(lowerTextFilter) >= 0 ||
                    connection.toString().toLowerCase().indexOf(lowerTextFilter) >= 0 ||
                    tags.toString().toLowerCase().indexOf(lowerTextFilter) >= 0
                );
            })
        );
    }

    filterByTraffic(showSAPs) {
        // if the data isn't loaded or we don't want to filter out zero-traffic interfaces,
        if (!this.isLoaded() || !showSAPs || (!!showSAPs && showSAPs === "all")) {
            return this;
        }

        return new FilterableSAPList(
            _.filter(this.saps, (sap) => {
                // we won't show this interface by default
                let show_sap = false;
                // if the interface has a traffic property
                if (!!sap.traffic) {
                    // find the input bytes and output bytes of the first item
                    let input_bytes = sap.traffic.points[0][1];
                    let output_bytes = sap.traffic.points[0][2];
                    // if either are > 0, we will show this interface
                    let total_traffic = input_bytes + output_bytes;
                    if (total_traffic > 0) {
                        show_sap = true;
                    }
                }
                return show_sap;
            })
        );
    }

    // Custom sorting based either on the device/interface, sub-sorted by capacity,
    // or sorting based on current traffic rate.
    sort(sortBy, sortDirection) {
        if (!this.isLoaded()) {
            return this;
        }

        let trafficCriterion = (value) => {
            if (value.traffic) {
                return value.traffic.points[0][1] + value.traffic.points[0][2];
            } else {
                return 0;
            }
        };

        // default behavior for below switch: no valid sort criterion. Don't sort the data, but still return it.
        let sorted = _.chain(this.saps);
        switch (sortBy) {
            case "device":
                sorted = sorted.sortBy("device");
                break;
            case "traffic":
                sorted = sorted.sortBy(trafficCriterion);
                break;
            default:
                break;
        }
        if (sortDirection === "DESC") {
            sorted = sorted.reverse();
        }
        return new FilterableSAPList(sorted.value());
    }

    size() {
        if (!this.isLoaded()) {
            return 0;
        } else {
            return this.saps.length;
        }
    }

    // Returns the max traffic for the SAPs
    max() {
        if (!this.isLoaded()) {
            return 0;
        }

        const maxInList = this.saps.map((sap) => {
            if (sap.traffic) {
                return sap.traffic.points[0][1];
            } else {
                return 0;
            }
        });
        const maxOutList = this.saps.map((sap) => {
            if (sap.traffic) {
                return sap.traffic.points[0][2];
            } else {
                return 0;
            }
        });

        const maxIn = Math.max(...maxInList);
        const maxOut = Math.max(...maxOutList);

        return Math.max(maxIn, maxOut);
    }
}

export const SapTable = ({ text, site, device, showSAPs, height, tags }) => {
    const rowHeight = 30;

    const EVERY_60_SECONDS = 60 * 1000;

    const { loading, data } = useQuery(GET_SAPS_QUERY, {
        pollInterval: EVERY_60_SECONDS,
    });

    // Sorting settings are stored on the URL
    const [{ sortBy, sortDirection }, setSortState] = useSortState("traffic", "DESC");

    //
    // Renderers render a specific cell type in the table
    //

    // Renders a mini traffic barchart given the traffic array and the maxTraffic to
    // determine scale. Fits in it's parent's dimensions, but height is determined
    // by the height option.
    const renderCurrentTrafficBarChart = (traffic, maxTraffic, height) => {
        if (traffic) {
            const [, trafficIn, trafficOut] = traffic.points[0];
            return (
                <Resizable>
                    <TrafficBars
                        trafficIn={trafficIn}
                        trafficOut={trafficOut}
                        max={maxTraffic}
                        height={height - 8}
                    />
                </Resizable>
            );
        } else {
            return <div />;
        }
    };

    const renderTraffic = (maxTraffic) => {
        return ({ rowData }) => {
            const traffic = rowData.traffic;
            return renderCurrentTrafficBarChart(traffic, maxTraffic, rowHeight);
        };
    };

    const renderSapLink = ({ rowData }) => {
        const device = encodeURIComponent(rowData.device);
        const sap = encodeURIComponent(rowData.name);
        return <Link to={`/network/devices/${device}/saps/${sap}/traffic`}>{rowData.name}</Link>;
    };

    const renderVLAN = ({ rowData }) => {
        const sap = rowData.name;
        const sap_parts = sap.split("-");
        return <span>{sap_parts[sap_parts.length - 1]}</span>;
    };

    const renderTotalRows = (rowCount, totalRowCount) => {
        if (rowCount < totalRowCount) {
            return <span style={totalRowCountStyle}>(of {totalRowCount})</span>;
        }
        return <span></span>;
    };

    const renderConnectionLink = ({ rowData }) => {
        const remoteShortName = rowData.remoteShortName;
        const entityType = rowData.entityType;

        if (entityType && remoteShortName) {
            return (
                <Link to={`/${entityType}/view/${remoteShortName}`}>{`${remoteShortName}`}</Link>
            );
        } else {
            return <span>{remoteShortName ? remoteShortName : ""}</span>;
        }
    };

    const renderTags = ({ rowData }) => {
        const tagStyle = {
            border: "1px solid #D0D0D0",
            borderRadius: "4px",
            padding: "2px 3px",
            fontSize: "smaller",
        };
        const tagDisplay = [];
        if (rowData.description !== "-" && rowData.description !== undefined) {
            const connection = rowData.description.split(":")[0];
            const tags_str = rowData.description.split(":")[2];
            if (tags_str !== "" && tags_str !== undefined) {
                const tags = tags_str.split("_");
                tags.forEach((tag, index) => {
                    if (tag === "oscars-l2circuit") {
                        const id = connection.split("-")[1];
                        tagDisplay.push(
                            <span style={tagStyle} key={index}>
                                <a href={`/oscars/view/${id}`}>{`${tag}`}</a>
                            </span>
                        );
                    } else if (tag === "") {
                        return;
                    } else {
                        tagDisplay.push(
                            <span style={tagStyle} key={`${index}`}>
                                {`${tag}`}
                            </span>
                        );
                    }
                    tagDisplay.push(<span key={`${index}-space`}>&nbsp;</span>);
                });
            }
        }
        return tagDisplay;
    };

    const tooltipHeaderRenderer = ({
        headerClassName,
        columnData,
        dataKey,
        disableSort,
        label,
        sortBy,
        sortDirection,
    }) => {
        const showSortIndicator = sortBy === dataKey;
        const renderSortIndicator = function () {
            if (showSortIndicator) {
                return <SortIndicator key="SortIndicator" sortDirection={sortDirection} />;
            } else {
                return <span />;
            }
        };
        let [title, tooltip] = label.split("::");
        return (
            <span>
                <span title={tooltip} className={headerClassName}>
                    {title}
                </span>
                {renderSortIndicator()}
            </span>
        );
    };

    if (loading) {
        return <Spinner />;
    } else if (data) {
        // Filtered and sorted list of SAPs
        const sapList = new FilterableSAPList(data.saps)
            .filterByText(text)
            .filterBySite(site)
            .filterByDevice(device)
            .filterByTraffic(showSAPs)
            .sort(sortBy, sortDirection);

        // Max traffic for bar chart scaling
        const maxTraffic = sapList.max();

        // Rows remaining in the filtered list
        const rowCount = sapList.size();

        return (
            <div>
                <Row>
                    <Col md={12}>
                        <span style={rowCountStyle}>
                            Displaying {rowCount} {renderTotalRows(rowCount, data.saps.length)}{" "}
                            Results
                        </span>
                    </Col>
                </Row>
                <AutoSizer disableHeight>
                    {({ width }) => (
                        <Table
                            gridStyle={{ outline: 0 }}
                            width={width}
                            height={height}
                            headerHeight={20}
                            headerStyle={{ outline: 0 }}
                            rowHeight={rowHeight}
                            sort={({ sortBy, sortDirection }) =>
                                setSortState(sortBy, sortDirection)
                            }
                            sortBy={sortBy}
                            sortDirection={sortDirection}
                            rowCount={rowCount}
                            rowGetter={({ index }) => sapList.get(index)}
                        >
                            <Column width={200} label="Device" dataKey="device" />

                            <Column
                                width={300}
                                label="SAP"
                                dataKey="name"
                                disableSort={true}
                                cellRenderer={renderSapLink}
                            />

                            <Column
                                width={150}
                                label="VLAN"
                                dataKey="vlan"
                                disableSort={true}
                                cellRenderer={renderVLAN}
                            />

                            <Column
                                width={450}
                                label="Traffic (in | out)::Presents the latest available 30-second average of traffic for this SAP, in bits per second. Refreshed every minute."
                                dataKey="traffic"
                                headerRenderer={tooltipHeaderRenderer}
                                cellRenderer={renderTraffic(maxTraffic)}
                            />

                            <Column
                                width={300}
                                label="Connection"
                                dataKey="connection"
                                disableSort={true}
                                cellRenderer={renderConnectionLink}
                            />
                            <Column
                                width={300}
                                label="Tags"
                                dataKey="tags"
                                disableSort={true}
                                cellRenderer={renderTags}
                            />
                        </Table>
                    )}
                </AutoSizer>
            </div>
        );
    } else {
        return <div />;
    }
};
