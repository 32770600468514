import { useQuery } from "@apollo/react-hooks";
import { format } from "d3-format";
import gql from "graphql-tag";
import moment from "moment";
import React from "react";
import { Col, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Spinner } from "shared/components/controls";
import _ from "underscore";
import { setHtmlTitle } from "shared/utils/entity-utils";

export const GET_OSCARS_TITLE_QUERY = gql`
    query getOscarsConnectionTitle($id: String!) {
        oscarsConnection(connectionId: $id) {
            description
        }
    }
`;

export const GET_OSCARS_CONNECTION_INFO_QUERY = gql`
    query getOscarsConnectionInfo($id: String!) {
        oscarsConnection(connectionId: $id) {
            begin
            description
            end
            endAName
            endASite
            endZName
            endZSite
            connectionId
            fixtures {
                junction
                inMbps
                outMbps
                port
                vlan
            }
            pipes {
                ero
                a
                z
            }
            multipoint
            state
            mode
            tags {
                category
                contents
            }
        }
    }
`;

/**
 * Render a the just the title of the OSCARS connection, given
 * the connectionId provided as a prop.
 */
export const OscarsConnectionTitle = ({ connectionId }) => {
    // Load minimal Oscars connection info
    const { loading, data } = useQuery(GET_OSCARS_TITLE_QUERY, {
        variables: { id: connectionId },
    });

    if (loading || !data) {
        return <div />;
    } else {
        const connection = data.oscarsConnection;
        // Set HTML title tag
        setHtmlTitle("OSCARS", connectionId);

        return (
            <Row>
                <Col md={12}>
                    <h2>{connection && connection.description ? connection.description : ""}</h2>
                </Col>
            </Row>
        );
    }
};

/**
 * Renders an OSCARS circuit's details as a table, given the connectionId
 * of the circuit provided as a prop.
 */
export const OscarsConnectionDetails = ({ connectionId }) => {
    // Load Oscars connection info
    const { loading, error, data } = useQuery(GET_OSCARS_CONNECTION_INFO_QUERY, {
        variables: { id: connectionId },
    });

    if (!loading && !error) {
        const connection = data.oscarsConnection;
        if (!connection) {
            return <div />;
        }

        let endA = connection.endAName;
        if (connection.endASite) {
            endA = <Link to={`/sites/view/${endA}`}>{endA}</Link>;
        }

        let endZ = connection.endZName;
        if (connection.endZSite) {
            endZ = <Link to={`/sites/view/${endZ}`}>{endZ}</Link>;
        }

        const tags = connection.tags;
        const fixtures = connection.fixtures;

        const type = _.find(tags, function (x) {
            return x.category === "production" && x.contents === "true";
        })
            ? "Production"
            : "n/a";
        const capacityIn = fixtures[0].inMbps * 1e6;
        const capacityOut = fixtures[0].outMbps * 1e6;
        let formattedCapacity;
        if (capacityIn === capacityOut) {
            formattedCapacity = `${format("~s")(capacityIn)}bps`;
        } else {
            formattedCapacity = `${format("~s")(capacityIn)}bps ${format("~s")(capacityOut)}bps`;
        }

        const beginMoment = moment(connection.begin);
        const begin = `${new Date(
            connection.begin
        ).toLocaleDateString()} (${beginMoment.fromNow()})`;
        const endMoment = moment(connection.end);
        const end = `${new Date(connection.end).toLocaleDateString()} (${endMoment.fromNow()})`;

        const formattedTags = connection.tags.map(({ category, contents }) => (
            <dl key={category}>
                <dt>{category}</dt>
                <dd>{contents}</dd>
            </dl>
        ));

        return (
            <div style={{ paddingTop: 10 }}>
                <Table size="sm" striped borderless>
                    <tbody>
                        <tr>
                            <th width={150}>Connection ID</th>
                            <td>{connection.connectionId}</td>
                        </tr>
                        <tr>
                            <th>Description</th>
                            <td>{connection.description}</td>
                        </tr>
                        <tr>
                            <th>Capacity</th>
                            <td>{formattedCapacity}</td>
                        </tr>
                        <tr>
                            <th>End A</th>
                            <td>{endA}</td>
                        </tr>
                        <tr>
                            <th>End Z</th>
                            <td>{endZ}</td>
                        </tr>
                        <tr>
                            <th>Type</th>
                            <td>{type}</td>
                        </tr>
                        <tr>
                            <th>State</th>
                            <td>{connection.state}</td>
                        </tr>
                        <tr>
                            <th>Mode</th>
                            <td>{connection.mode}</td>
                        </tr>
                        <tr>
                            <th>Begin Time</th>
                            <td>{begin}</td>
                        </tr>
                        <tr>
                            <th>End Time</th>
                            <td>{end}</td>
                        </tr>
                        <tr>
                            <th>Tags</th>
                            <td>{formattedTags}</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        );
    } else {
        return (
            <div style={{ padding: 180 }}>
                <Spinner />
            </div>
        );
    }
};
