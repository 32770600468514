/**
 * Map styles used for ESnet Portal topology maps
 */
export const hubStyle = {
    node: {
        normal: { fill: "#999999", stroke: "#808080", cursor: "pointer" },
        selected: {
            fill: "#33ABCC",
            stroke: "rgba(51, 171, 204, 0.22)",
            strokeWidth: 10,
            cursor: "pointer",
        },
        muted: {
            fill: "#999999",
            stroke: "#808080",
            opacity: 0.6,
            cursor: "pointer",
        },
    },
    label: {
        normal: { fill: "#6D6E71", stroke: "none", fontSize: 9 },
        selected: { fill: "#222", stroke: "none", fontSize: 11 },
        muted: {
            fill: "#6D6E71",
            stroke: "none",
            fontSize: 8,
            opacity: 0.6,
        },
    },
};

export const siteStyle = {
    node: {
        normal: {
            fill: "#D0D0D0",
            stroke: "#BEBEBE",
            cursor: "pointer",
        },
        selected: {
            fill: "#33ABCC",
            stroke: "rgba(51, 171, 204, 0.22)",
            strokeWidth: 10,
            cursor: "pointer",
        },
        muted: {
            fill: "#D0D0D0",
            stroke: "#BEBEBE",
            opacity: 0.6,
            cursor: "pointer",
        },
    },
    label: {
        normal: { fill: "#6D6E71", stroke: "none", fontSize: 9 },
        selected: { fill: "#222", stroke: "none", fontSize: 11 },
        muted: {
            fill: "#6D6E71",
            stroke: "none",
            fontSize: 8,
            opacity: 0.6,
        },
    },
};

//
// Edge thickness small, normal and large
//

// Maps the capacity band that the edge falls into
// to the line thickness
export const edgeThicknessMapSm = {
    "800+G": 2,
    "400-800G": 1.6,
    "100-400G": 1.4,
    "10-100G": 1.2,
    "1-10G": 1,
};

export const edgeThicknessMapNm = {
    "800+G": 3.5,
    "400-800G": 3,
    "100-400G": 2,
    "10-100G": 1.5,
    "1-10G": 1,
};

export const edgeThicknessMapLg = {
    "800+G": 4,
    "400-800G": 3,
    "100-400G": 2,
    "10-100G": 1.5,
    "1-10G": 1,
};

// Maps edge name to edge shape. Current options are linear (default)
// or curved. If curved you can specify the direction and offset
// to control the curve.
export const edgeShapeMap = {
    "AMST--BOST": {
        shape: "curved",
        direction: "right",
        offset: 15,
    },
    "BOST--EQXAM3": {
        shape: "curved",
        direction: "right",
        offset: 15,
    },
    "CERN513--WASH": {
        shape: "curved",
        direction: "right",
        offset: 15,
    },
    "SACR--SALT": {
        shape: "curved",
        direction: "left",
        offset: 15,
    },
    "LOND--NEWY32AOA": {
        shape: "curved",
        direction: "right",
        offset: 15,
    },
    "ELPA--SUNN": {
        shape: "curved",
        direction: "right",
        offset: 15,
    },
    "NASH--WASH": {
        shape: "curved",
        direction: "right",
        offset: 15,
    },
    "DENV--SEAT": {
        shape: "curved",
        direction: "right",
        offset: 120,
    },
    "HOUS--KANS": {
        shape: "curved",
        direction: "left",
        offset: 15,
    },
    "CHIC--WASH": {
        shape: "curved",
        direction: "left",
        offset: 35,
    },
    "ATLA--CHIC": {
        shape: "curved",
        direction: "right",
        offset: 15,
    },
};

// The color map maps an edge value (within the range) to a color
export const edgeColorMap = [
    { color: "#184D04", label: "800+ Gbps", range: [800, 10000] },
    { color: "#24660A", label: "400 - 800", range: [400, 800] },
    { color: "#328013", label: "200 - 400", range: [200, 400] },
    { color: "#41991F", label: "100 - 200", range: [100, 200] },
    { color: "#1C96B8", label: "50 - 100", range: [50, 100] },
    { color: "#33ABCC", label: "10 - 50", range: [10, 50] },
    { color: "#4EC1E0", label: "1 - 10", range: [1, 10] },
    { color: "#7DDFFA", label: "0 - 1", range: [0, 1] },
];

// The color map maps an node value (within the range) to a color
// used for facilities map
export const nodeColorMap = [
    { color: "#184D04", label: "800+ Gbps", range: [800, 10000] },
    { color: "#24660A", label: "400 - 800", range: [400, 800] },
    { color: "#328013", label: "200 - 400", range: [200, 400] },
    { color: "#41991F", label: "100 - 200", range: [100, 200] },
    { color: "#1C96B8", label: "50 - 100", range: [50, 100] },
    { color: "#33ABCC", label: "10 - 50", range: [10, 50] },
    { color: "#4EC1E0", label: "1 - 10", range: [1, 10] },
    { color: "#7DDFFA", label: "0 - 1", range: [0, 1] },
    { color: "#6D6E71", label: "No data" },
];

//
// Node sizes large and small
//

export const nodeSizeMapSm = {
    hub: 3,
    esnet_site: 2,
};

export const nodeSizeMap = {
    hub: 5.5,
    esnet_site: 5,
};

export const nodeSizeMapLg = {
    hub: 7,
    esnet_site: 6,
};

//
// Mapping of node type to style (large and small versions)
//

export const stylesMap = {
    hub: hubStyle,
    esnet_site: siteStyle,
};
