import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { TimePicker } from "shared/components/controls";
import { useTimeRange } from "shared/hooks";
import { EdgeSummaryChart } from "./EdgeSummaryChart";
import { EdgeSummaryInfo } from "./EdgeSummaryInfo";

const EVERY_15_SECONDS = 15 * 1000;

const EdgeSummary = ({ edgeName, edgeType }) => {
    // Tracker position for the page
    const [timestamp, setTimestamp] = useState(null);

    // Timerange for the page
    const [timerange] = useTimeRange(EVERY_15_SECONDS);

    return (
        <Col md={12}>
            <Row>
                <Col>
                    <EdgeSummaryInfo edgeName={edgeName} edgeType={edgeType} />
                </Col>
            </Row>

            <hr />

            <Row>
                <Col>
                    <div style={{ float: "right" }}>
                        <TimePicker />
                    </div>
                </Col>
            </Row>

            <hr />

            <Row>
                <Col>
                    <EdgeSummaryChart
                        edgeName={edgeName}
                        edgeType={edgeType}
                        timerange={timerange}
                        timestamp={timestamp}
                        onTrackerChanged={(t) => setTimestamp(t)}
                    />
                </Col>
            </Row>
        </Col>
    );
};

export { EdgeSummary };
