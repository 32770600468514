import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { breadcrumbStyle } from "shared/styles/styles";
import _ from "underscore";
import CollaboratorFlow from "./CollaboratorFlow";
import { CollaboratorTitle } from "./CollaboratorInfo";
import CollaboratorInterfaces from "./CollaboratorInterfaces";

/**
 * Container page for all LHCONE Collaborator pages.
 */
const CollaboratorView = (props) => {
    const { collaborator, page = "interfaces" } = useParams();

    const renderBreadcrumb = () => {
        return (
            <div>
                <div style={breadcrumbStyle}>
                    <a href="/">HOME</a>
                    <span> › </span>
                    <a href="/lhcone/list">COLLABORATORS</a>
                    <span> » </span>
                </div>
                <CollaboratorTitle collaboratorName={collaborator} />
            </div>
        );
    };

    const renderSubpage = () => {
        switch (page) {
            case "interfaces":
                return <CollaboratorInterfaces collaborator={collaborator} {...props} />;
            case "flow":
                return <CollaboratorFlow collaborator={collaborator} {...props} />;
            default:
                return <CollaboratorInterfaces collaborator={collaborator} {...props} />;
        }
    };

    // NavItems
    const subSections = [];
    subSections.push({
        key: "Interfaces",
        label: "Interfaces",
        url: `/lhcone/view/${collaborator}/interfaces`,
    });
    subSections.push({
        key: "flow",
        label: "Flow",
        url: `/lhcone/view/${collaborator}/flow`,
    });

    let active;
    if (page === "flow") {
        active = "Flow";
    } else if (page === "interfaces" || page === undefined) {
        active = "Interfaces";
    }

    const navItems = _.map(subSections, (section) => {
        const klass = active === section.label ? "active" : "";
        const key = section.key;
        return (
            <li key={key} role="presentation" className={klass}>
                <Link to={section.url}>{section.label}</Link>
            </li>
        );
    });

    return (
        <Row>
            <Col md={1} />
            <Col md={10}>
                <Row>
                    <Col md={12}>
                        <Row>
                            <Col md={7}>{renderBreadcrumb()}</Col>
                            <Col md={5} style={{ marginTop: 20 }}>
                                <ul className="nav nav-pills" style={{ float: "right" }}>
                                    {navItems}
                                </ul>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>{renderSubpage()}</Row>
            </Col>
            <Col md={1} />
        </Row>
    );
};

export { CollaboratorView };
