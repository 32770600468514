import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { ChoiceSelector, TimePicker } from "shared/components/controls";
import { useQueryState, useTimeRange } from "shared/hooks";
import { infoStyle } from "shared/styles/styles";
import { FacilityFlowBarChart } from "./FacilityFlowBarChart";
import { FacilityFlowChart } from "./FacilityFlowChart";
import { FacilitySummaryInfo } from "./FacilityInfo";

const MIN = 60 * 1000;
const HOUR = 60 * MIN;
const DAY = 24 * HOUR;
const EVERY_60_SECONDS = MIN;

const FLOW_BREAKDOWN_VAR = "by";
const DEFAULT_FLOW_BREAKDOWN = "protocol";

const FLOW_BREAKDOWNS = [
    { key: "protocol", label: "Protocol" },
    { key: "application", label: "Applications" },
    { key: "as_origin", label: "Autonomous Systems (origin)" },
    { key: "as_peer", label: "Autonomous Systems (peer)" },
    { key: "country", label: "Country" },
    { key: "site", label: "Sites" },
    { key: "facility", label: "Other Facilities" },
];

const TIME_RANGES = [
    { key: "1h", label: "1h", duration: 1 * HOUR },
    { key: "6h", label: "6h", duration: 6 * HOUR },
    { key: "1d", label: "day", duration: DAY },
    { key: "7d", label: "week", duration: 7 * DAY },
    { key: "30d", label: "month", duration: 30 * DAY },
];

export const FacilitySummary = ({ facilityName }) => {
    // Tracker position for the page
    const [timestamp, setTimestamp] = useState(null);

    // Timerange for the page
    const [timerange] = useTimeRange(EVERY_60_SECONDS, TIME_RANGES, "1d");

    // Selected flow within the breakdown
    const [selected, setSelected] = useQueryState("s", null);

    // Breakdown option selection
    const [breakdown] = useQueryState(FLOW_BREAKDOWN_VAR, DEFAULT_FLOW_BREAKDOWN);

    return (
        <Col md={12}>
            <Row style={infoStyle}>
                <Col>
                    <FacilitySummaryInfo facilityName={facilityName} />
                </Col>
            </Row>
            <hr />
            <Row>
                <Col md={6}>
                    <div style={{ float: "left" }}>
                        <ChoiceSelector
                            label="BREAKDOWN"
                            variableName={FLOW_BREAKDOWN_VAR}
                            defaultValue={DEFAULT_FLOW_BREAKDOWN}
                            options={FLOW_BREAKDOWNS}
                        />
                    </div>
                </Col>
                <Col md={6}>
                    <div style={{ float: "right" }}>
                        <TimePicker ranges={TIME_RANGES} defaultValue="1d" />
                    </div>
                </Col>
            </Row>
            <hr />
            <Row>
                <Col md={12}>
                    <Row>
                        <Col md={12}>
                            <FacilityFlowChart
                                facilityName={facilityName}
                                breakdown={breakdown}
                                timerange={timerange}
                                timestamp={timestamp}
                                onTrackerChanged={(ts) => setTimestamp(ts)}
                                selected={selected}
                            />
                        </Col>
                    </Row>

                    <hr />

                    <Row>
                        <Col md={12}>
                            <FacilityFlowBarChart
                                facilityName={facilityName}
                                breakdown={breakdown}
                                timerange={timerange}
                                timestamp={timestamp}
                                selected={selected}
                                onSelectionChanged={(s) => setSelected(s)}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Col>
    );
};
