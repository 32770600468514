/**
 * Collection of useful functions related to setting favorites in entities
 *  - Sites
 *  - Facilities
 *  - Collaborators
 */

import gql from "graphql-tag";
import React from "react";
import { Star, StarFill } from "react-bootstrap-icons";
import { starStyle } from "shared/styles/styles";

/**
 * Mutation to update the user's favorites, called by all entity pages
 */
export const UPDATE_FAVORITE_MUTATION = gql`
    mutation UpdateFavorite($entityType: String, $shortName: String, $isInterested: Boolean) {
        updateUserNetworkEntityFavorite(
            entityType: $entityType
            shortName: $shortName
            isInterested: $isInterested
        ) {
            success
            user {
                email
                firstName
                lastName
                isAnonymous
                favoriteNetworkEntities {
                    id
                    shortName
                    type
                }
                viewTopTalkers {
                    id
                    shortName
                    fullName
                }
            }
        }
    }
`;

/**
 * Build a new optimistic response starting with the user object
 * then set that on the Apollo Client cache until the server action has
 * been completed.
 *
 * Triggered when a logged in user clicks the star next to an entity title
 */
export const handleFavoriteChange = ([updateFavorite], user, entity, isInterested) => {
    const response = user;

    // If we're not interested in an entity then traverse the
    // list and remove the one we don't want in there anymore, otherwise
    // add it to the end of the list of current favorites
    let newFavorites = [];
    if (!isInterested) {
        newFavorites = response.favoriteNetworkEntities.filter((fav) => fav.id !== entity.id);
    } else {
        newFavorites = [...response.favoriteNetworkEntities, entity];
    }
    response.favoriteNetworkEntities = newFavorites;

    updateFavorite({
        variables: { entityType: entity.type, shortName: entity.shortName, isInterested },
        optimisticResponse: {
            __typename: "Mutation",
            updateFavorite: response,
        },
    });
};

/**
 *  Display a star next to an entity's title and color it
 *  based on that entity's favorite status for the user
 */
export const star = ([updateFavorite], user, curEntity, entityType) => {
    // Determine whether the current site is marked as favorite
    let isFavorite = false;
    if (user) {
        const { favoriteNetworkEntities = [] } = user;
        favoriteNetworkEntities.forEach((entity) => {
            if (curEntity.shortName === entity.shortName && entity.type === entityType) {
                isFavorite = true;
            }
        });
    }
    // Style the star based on favorite status
    return isFavorite ? (
        <span title="Added to your favorites">
            <StarFill
                style={starStyle}
                color="#4EC1E0"
                onClick={() => handleFavoriteChange([updateFavorite], user, curEntity, !isFavorite)}
            />
        </span>
    ) : (
        <span title="Click to add to your favorites">
            <Star
                style={starStyle}
                color="#DDD"
                onClick={() => handleFavoriteChange([updateFavorite], user, curEntity, !isFavorite)}
            />
        </span>
    );
};

/**
 * Set the HTML title tag of any page.
 * Base title, argument 1, and argument 2 are delimited by a single colon (:).
 *
 * Truncates title to the first MAX_TITLE_LENGTH characters
 * and appends ellipses (...).
 *
 * @export
 * @param {string} [entityType=""]
 * @param {string} [title=""]
 * @param {number} [MAX_TITLE_LENGTH=60]
 */
export function setHtmlTitle(entityType = "", title = "", MAX_TITLE_LENGTH = 60) {
    const titleText = window.$htmlTitle + " : " + entityType + (title ? " : " + title : "");
    document.title =
        titleText.length > MAX_TITLE_LENGTH
            ? titleText.slice(0, MAX_TITLE_LENGTH - 1) + "..."
            : titleText;
}
