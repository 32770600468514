import React from "react";
import spinner from "./img/loader.gif";

/**
 * Displays a simple spinner used throughout the Portal while data is loading. Use simply as:
 * ```
 * <Spinner />
 * ```
 */
export const Spinner = ({ style = {} }) => {
    return (
        <div style={style}>
            <img className="loader" src={spinner} alt="Loading..." />
        </div>
    );
};
