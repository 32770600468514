import PropTypes from "prop-types";
import React from "react";
import _ from "underscore";
import { TimeSeries } from "pondjs";

import TimeseriesBarchartRow from "./TimeseriesListBarchartRow";

/**
The HorizontalBarChart takes a list of `TimeSeries` objects and displays a bar chart
visualization summarizing those. As an example, let's say we have a set of interfaces, which
together carry the entire network traffic to a particular location. We want to see which
interfaces contribute the most to the total traffic.
To display this we render the HorizontalBarChart in our page:
 
    <HorizontalBarChart
        display="range"
        seriesList={interfaces}
        columns={["out", "in"]}
        top={5} sortBy="max"
        timestamp={this.state.tracker}
        format={formatter}
        selected={this.state.selected}
        onSelectionChanged={this.handleSelectionChange}
        selectionColor="#37B6D3"
        style={[{fill: "#1F78B4"}, {fill: "#FF7F00"}]} />
Our first prop `display` tells the component how to draw the bars. In this case we use the
"range", which will draw from min to max (with additional drawing to show 1 stdev away from
the center).
Next we specify the `seriesList` itself. This should be an array of Pond TimeSeries objects.
The `columns` prop tells us which columns within the TimeSeries should be displayed as a bar.
In this case we have `in` and `out` traffic columns, so we'll get two bars for each series.
`top` and `sortBy` are used to order and trim the list of TimeSeries. Here we order by the max
values in the specified columns, then just display the top 5.
The `timestamp` lets the component know the current value. You could display the last timestamp
in the series, or perhaps a time being interacted with in the UI.
The `format` can either be a d3 format string of a function. In this case we have our own
formatter function to display values:
    function formatter(value) {
        const prefix = d3.formatPrefix(value);
        return `${prefix.scale(value).toFixed()} ${prefix.symbol}bps`;
    }
Selection is handled with `selected`, which gives the name of the TimeSeries currently selected.
If the user selects a different row the callback passed to `onSelectionChanged` will be called
with the name of the TimeSeries represented in the newly selected row. We also specify a color
to mark the selected item with the `selectionColor` prop.
Next we specify the `style`. This is the css style of each column's bars. Typically you would
just want to specify the fill color. Each bar is a svg rect.
*/
export default class extends React.Component {
    static displayName = "HorizontalBarChart";

    static propTypes = {
        /**
         * Sort by either "max", "avg" or "name"
         */
        display: PropTypes.oneOf(["avg", "max", "range"]),

        /**
         * A list of [TimeSeries](http://software.es.net/pond#timeseries) objects to visualize
         */
        seriesList: PropTypes.arrayOf(PropTypes.instanceOf(TimeSeries)).isRequired,

        /**
         * Columns in each timeseries to display
         */
        columns: PropTypes.arrayOf(PropTypes.string),

        /**
         * Sort by either "name", "max", or "avg"
         */
        sortBy: PropTypes.oneOf(["name", "max", "avg"]),

        /**
         * Display only the top n
         */
        top: PropTypes.number,

        /**
         * The height or thickness of each bar
         */
        size: PropTypes.number,

        /**
         * The spacing between each bar (column) of the series
         */
        spacing: PropTypes.number,

        /**
         * The spacing above and below each series
         */
        padding: PropTypes.number,

        /**
         * The width of the label area
         */
        labelWidth: PropTypes.number,

        /**
         * Callback for when the selection changes. The callback function will be called
         * with the name of the TimeSeries selected.
         */
        onSelectionChanged: PropTypes.func,

        /**
         * Specify which TimeSeries is selected by providing the name of the selected
         * series.
         */
        selected: PropTypes.string,

        /**
         * Color to mark the selected row with.
         */
        selectionColor: PropTypes.string,

        /**
         * Renders the series name as a link and calls this callback function when it is clicked.
         */
        onNavigate: PropTypes.func,

        /**
         * Color to render the series name if navigate is enabled
         */
        navigateColor: PropTypes.string,

        /**
         * The format is used to format the display text for the bar. It can be specified as a d3
         * format string (such as ".2f") or a function. The function will be called with the value
         * and should return a string.
         */
        format: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),

        /**
         * A single child which will be rendered when the item is selected. The child will have
         * a couple of additional props injected onto it when rendered:
         *  * `series` - the TimeSeries of the row being rendered
         *  * `timestamp` - the current timestamp being shown
         */
        children: PropTypes.element,

        /**
         * List of fields that we want to render as columns additionally
         */
        extraFields: PropTypes.arrayOf(PropTypes.object),
    };

    static defaultProps = {
        display: "avg",
        size: 14,
        spacing: 2,
        padding: 5,
        labelWidth: 240,
        style: [{ fill: "steelblue" }],
        seriesList: [],
        columns: ["value"],
        sortBy: "max",
        selectionColor: "steelblue",
        navigateColor: "steelblue",
        extraFields: [],
    };

    renderRows = (seriesList) => {
        let max = 0;
        let maxLabelLength = 0;
        let longestLabel = "";

        const {
            columns,
            spacing,
            padding,
            size,
            style,
            format,
            display,
            timestamp,
            onSelectionChanged,
            selectionColor,
            onNavigate,
            navigateColor,
            extraFields,
        } = this.props;

        //
        // Get the max value in the series list, for overall scale
        // and get the longest label name in the series for sizing the "Top Flows" column.
        //

        seriesList.forEach((series) => {
            this.props.columns.forEach((column) => {
                const smax = series.max(column);
                const smaxLabelLength = series.name().length;
                if (smax > max) max = smax;
                if (smaxLabelLength > maxLabelLength) {
                    maxLabelLength = smaxLabelLength;
                    longestLabel = series.name();
                }
            });
        });

        // Get a width estimate (in pixels) for sizing the text column in "Top Flows"
        // to be passed to TimeseriesBarchartRow.
        const textWidth = this.getTextWidth(longestLabel, "normal 10pt sans-serif");

        //
        // Get the 0 or 1 children for the expanded area
        //

        let child;
        if (React.Children.count(this.props.children) === 1) {
            child = React.Children.only(this.props.children);
        }

        //
        // Render a <RowStack> for each series in the seriesList
        //

        return seriesList.map((series, i) => (
            <TimeseriesBarchartRow
                key={i}
                rowNumber={i}
                series={series}
                display={display}
                max={max}
                textWidth={textWidth}
                selected={this.props.selected === series.name()}
                onSelectionChanged={onSelectionChanged}
                selectionColor={selectionColor}
                onNavigate={onNavigate}
                navigateColor={navigateColor}
                columns={columns}
                spacing={spacing}
                padding={padding}
                size={size}
                style={style}
                format={format}
                timestamp={timestamp}
                child={child}
                extraFields={extraFields}
            />
        ));
    };

    /**
     * Use a canvas element to estimate the width in pixels of text.
     *
     * @param {*} text
     * @param {*} font
     * @return {*} number
     */
    getTextWidth(text, font) {
        // Reuse canvas object for better performance
        const canvas =
            this.getTextWidth.canvas ||
            (this.getTextWidth.canvas = document.createElement("canvas"));
        const context = canvas.getContext("2d");
        context.font = font;
        const metrics = context.measureText(text);
        return metrics.width;
    }

    render() {
        //
        // Sort the list by the criteria specified in the "sortBy" prop:
        // name, avg or max.
        //
        const sortedList = _.sortBy(this.props.seriesList, (series) => {
            switch (this.props.sortBy) {
                case "name":
                    return series.name;
                case "avg":
                    return -_.reduce(
                        this.props.columns.map((column) => series.avg(column)),
                        (memo, num) => memo + num,
                        0
                    );
                case "max":
                    return -_.max(this.props.columns.map((column) => series.max(column)));
                default:
                    throw new Error("unknown sort prop", this.props.sortBy);
            }
        });

        //
        // Keep just the top n, where n is specified by the "top" prop.
        //

        const list = this.props.top ? sortedList.slice(0, this.props.top) : sortedList;

        const containerStyle = {
            borderBottomStyle: "solid",
            borderBottomWidth: 1,
            borderBottomColor: "#D0D0D0",
        };

        return <div style={containerStyle}>{this.renderRows(list)}</div>;
    }
}
