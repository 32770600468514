import { fromJS } from "immutable";
import React from "react";
import { TrafficMap } from "react-network-diagrams";
import Widget from "shared/components/widgets/Widget";
import { useEntitySelection } from "shared/hooks";
import { nodeColorMap } from "shared/styles/map-styles";
import { esnetDarkGrey } from "shared/styles/styles";
import _ from "underscore";

const BOUNDS = {
    x1: -5,
    y1: 20,
    x2: 250,
    y2: 105,
};

const highlightColor = esnetDarkGrey;

const HUB_STYLE = {
    node: {
        normal: { fill: "#D0D0D0", stroke: "#BEBEBE", cursor: "pointer" },
        selected: {
            fill: "#33ABCC",
            stroke: "rgba(55, 182, 211, 0.22)",
            strokeWidth: 10,
            cursor: "pointer",
        },
        muted: {
            fill: "#D0D0D0",
            stroke: "#BEBEBE",
            opacity: 0.6,
            cursor: "pointer",
        },
    },
    label: {
        normal: { fill: "none", stroke: "none", fontSize: 0 },
        selected: { fill: "#222", stroke: "none", fontSize: 11 },
        muted: {
            fill: "none",
            stroke: "none",
            fontSize: 8,
            opacity: 0.6,
        },
    },
};

const SITE_STYLE = {
    node: {
        normal: { fill: "#D0D0D0", stroke: "#BEBEBE", cursor: "pointer" },
        selected: {
            fill: "#33ABCC",
            stroke: "rgba(55, 182, 211, 0.22)",
            strokeWidth: 10,
            cursor: "default",
        },
        muted: {
            fill: "#D0D0D0",
            stroke: "#BEBEBE",
            opacity: 0.6,
            cursor: "default",
        },
    },
    label: {
        normal: { fill: "#6D6E71", stroke: "none", fontSize: 9 },
        selected: { fill: "#222", stroke: "none", fontSize: 11 },
        muted: {
            fill: "#6D6E71",
            stroke: "none",
            fontSize: 8,
            opacity: 0.6,
        },
    },
};

const FACILITY_STYLE = {
    node: {
        normal: { fill: highlightColor, stroke: "none", cursor: "pointer" },
        selected: {
            fill: highlightColor,
            stroke: highlightColor,
            strokeWidth: 2,
            cursor: "pointer",
        },
        muted: {
            fill: "#D0D0D0",
            stroke: "#BEBEBE",
            opacity: 0.6,
            cursor: "pointer",
        },
    },
    label: {
        normal: { fill: "#6D6E71", stroke: "none", fontSize: 9 },
        selected: { fill: "#222", stroke: "none", fontSize: 10 },
        muted: {
            fill: "#6D6E71",
            stroke: "none",
            fontSize: 8,
            opacity: 0.6,
        },
    },
};

/**
 * Takes in the topology and traffic, along with widget props (isLoading, error and
 * description) and renders a map using the network diagrams TrafficMap (wrapped
 * in the widget).
 */
export const FacilityMapWidget = ({ topology, traffic, isLoading, error, description }) => {
    // Get the current map selection from the URL query string
    const [{ selection, selectionType }, setSelection] = useEntitySelection();

    // Updates the selection and selection type on the URL when the user
    // selects different entities on the map
    const handleSelectionChanged = (selectionType, selection) => {
        if (selectionType === "node") {
            const nodes = topology.nodes;
            nodes.forEach((n) => {
                if (n.name === selection && n.type === "facility") {
                    setSelection(selection, selectionType);
                }
            });
        } else {
            setSelection();
        }
    };

    let facilityTopology = fromJS(topology);

    let nodeSizeMap = {
        hub: 3,
        esnet_site: 3,
        facility: 4.5,
    };

    let stylesMap = {
        hub: HUB_STYLE,
        esnet_site: SITE_STYLE,
        facility: FACILITY_STYLE,
    };

    const edgeThicknessMap = {
        "100G": 1.5,
        "10G": 1.5,
        "1G": 1.5,
        subG: 1,
        unknown: 3,
    };

    let nodeShapeMap = {};
    facilityTopology.get("nodes").map((node) => {
        const name = node.get("name");
        const type = node.get("type");
        if (type === "facility") {
            nodeShapeMap[name] = "square";
        }
        return node;
    });

    _.each(nodeColorMap, (colorMap) => {
        let name;
        if (!colorMap.range) {
            // Case when there is no data
            name = "facility";
        } else {
            const bottom = colorMap.range[0];
            name = "facility-" + bottom;
        }
        nodeSizeMap[name] = 4.5;
        stylesMap[name] = {
            node: {
                normal: { fill: colorMap.color, stroke: "none", cursor: "pointer" },
                selected: {
                    fill: colorMap.color,
                    stroke: colorMap.color,
                    strokeWidth: 2,
                    cursor: "pointer",
                },
                muted: {
                    fill: "#D0D0D0",
                    stroke: "none",
                    opacity: 0.6,
                    cursor: "pointer",
                },
            },
            label: {
                normal: { fill: "#6D6E71", stroke: "none", fontSize: 9 },
                selected: { fill: "#222", stroke: "none", fontSize: 10 },
                muted: {
                    fill: "#6D6E71",
                    stroke: "none",
                    fontSize: 8,
                    opacity: 0.6,
                },
            },
        };
    });

    const selectNodeType = (bps) => {
        if (bps) {
            const gbps = bps / 1.0e9;
            for (let i = 0; i < nodeColorMap.length; i++) {
                const row = nodeColorMap[i];
                if (gbps >= row.range[0]) {
                    return "facility-" + row.range[0];
                }
            }
        }
        return "facility";
    };

    if (!isLoading && traffic) {
        facilityTopology = facilityTopology.update("nodes", (nodes) =>
            nodes.map((node) => {
                let name = node.get("name").toLowerCase();
                const type = node.get("type");
                if (type === "facility") {
                    let nodeTraffic = undefined;

                    // Data is only tagged for ARM
                    if (name === "arm-anl" || name === "arm-ornl" || name === "arm-pnnl") {
                        name = "arm";
                    }

                    if (traffic.has(name)) {
                        nodeTraffic = traffic.get(name).atLast().data().max();
                    }
                    node = node.set("type", selectNodeType(nodeTraffic));
                }
                return node;
            })
        );
    }

    // Build topology
    let mapSelection = {
        nodes: [],
        edges: [],
    };

    if (selectionType === "node") {
        mapSelection.nodes.push(selection);
    }

    return (
        <Widget
            info={description}
            error={error}
            height={275}
            loading={isLoading}
            contentPadding={false}
            noBorder
        >
            <TrafficMap
                topology={facilityTopology.toJS()}
                style={{
                    background: "#fff",
                }}
                edgeDrawingMethod="simple"
                autoSize={true}
                bounds={BOUNDS}
                stylesMap={stylesMap}
                edgeThicknessMap={edgeThicknessMap}
                nodeShapeMap={nodeShapeMap}
                nodeSizeMap={nodeSizeMap}
                onSelectionChange={handleSelectionChanged}
                selection={mapSelection}
            />
        </Widget>
    );
};
