export function getEdgeColor(value) {
    if (value >= 0 && value < 1000000000) {
        return "#7DDFFA"; // 0 - 1 Gbps
    } else if (value >= 1000000000 && value < 10000000000) {
        return "#4EC1E0"; // 1 - 10 Gbps
    } else if (value >= 10000000000 && value < 50000000000) {
        return "#33ABCC"; // 10 - 50 Gbps
    } else if (value >= 50000000000 && value < 100000000000) {
        return "#1C96B8"; // 50 - 100 Gbps
    } else if (value >= 100000000000 && value < 200000000000) {
        return "#41991F"; // 100 - 200 Gbps
    } else if (value >= 200000000000 && value < 400000000000) {
        return "#328013"; // 200 - 400 Gbps
    } else if (value >= 400000000000 && value < 800000000000) {
        return "#24660A"; // 400 - 800 Gbps
    } else if (value >= 800000000000 && value < 100000000000000000000) {
        return "#184D04"; // 800+ Gbps
    } else {
        return "#ffffff";
    }
}

export function getDisplayName(nodeName, mapJson) {
    var displayName = nodeName;
    mapJson.nodes.forEach((node) => {
        if (node.name === nodeName && node.meta.display_name) {
            displayName = node.meta.display_name;
        }
    });
    return displayName;
}

export function getAgg(query) {
    // Parse out aggregation from the query string
    let agg = "avg";
    if (query !== undefined) {
        agg = query.get("agg") !== null ? query.get("agg") : "avg";
    }
    return agg;
}

export function checkIfLatest(query) {
    // Parse out if we're looking at the latest timestamp
    let isLatest = false;
    if (query !== undefined) {
        const absoluteTimeBounds = {
            b: query.get("b"),
            e: query.get("e"),
        };
        const isQueryAbsoluteTimeBounded = !!absoluteTimeBounds.b && !!absoluteTimeBounds.e;
        if (
            !isQueryAbsoluteTimeBounded &&
            (query.get("t") === null || query.get("t") === "latest")
        ) {
            isLatest = true;
        }
    }
    return isLatest;
}

/**
 * This returns a string indicating the time period indicated, parsed from the browser's URL query parameters, for relative time.
 * If the query is not a relative time query, an object is returned with begin and ending times.
 *
 * Otherwise 'latest' is returned, which is equivolent to '1d'.
 * @param {URLSearchParams} query
 * @returns {string|{t?: Date, b?: Date, e?: Date}}
 */
export function getTimePeriod(query) {
    // Parse out aggregation from the query string
    let timePeriod = "latest";
    if (query !== undefined) {
        const queryParams = {
            t: query.get("t"),
            b: query.get("b"),
            e: query.get("e"),
        };
        const { t, b, e } = queryParams;
        if ((t === null || t === "latest") && !b && !e) {
            timePeriod = "latest";
        } else if (!t && b !== null && e !== null) {
            timePeriod = {
                begin: b,
                end: e,
            };
        } else if (t !== null) {
            timePeriod = query.get("t");
        } else {
            throw new Error(`dashboard.utils: getTimePeriod query is time ambiguous: ${query}`);
        }
    }
    return timePeriod;
}
