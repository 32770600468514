import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { breadcrumbStyle } from "shared/styles/styles";
import { EdgeSummary } from "./EdgeSummary";
import { EdgeTitle } from "./EdgeSummaryInfo";

//
// Edges view
//

const EdgeView = (props) => {
    const { edgeType, edge } = useParams();

    const renderBreadcrumb = () => {
        return (
            <div>
                <div style={breadcrumbStyle}>
                    <a href="/">HOME</a>
                    <span> › </span>
                    <a href="/edges/list">EDGES</a>
                    <span> » </span>
                </div>
                <EdgeTitle edgeName={edge} />
            </div>
        );
    };

    return (
        <Row>
            <Col md={1} />
            <Col md={10}>
                <Row>
                    <Col md={12}>
                        <Row>
                            <Col md={7}>{renderBreadcrumb()}</Col>
                            <Col md={5} style={{ marginTop: 20 }}>
                                <ul className="nav nav-pills" style={{ float: "right" }}>
                                    <li key="summary" role="presentation" className="active">
                                        <Link to={`/edges/view/${edgeType}/${edge}`}>Summary</Link>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <hr />
                    </Col>
                </Row>
                <Row>
                    <EdgeSummary edgeName={edge} edgeType={edgeType} {...props} />
                </Row>
            </Col>
            <Col md={1} />
        </Row>
    );
};

export { EdgeView };
