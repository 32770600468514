import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";
import { Link } from "react-router-dom";
import { Spinner } from "shared/components/controls";
import _ from "underscore";

const GET_SAP_INFO_QUERY = gql`
    query getSAPInfo($device: String, $sap: String) {
        sap(device: $device, sap: $sap) {
            device
            name
            tags
            site
            port
            entityType
        }
    }
`;

export const SapInfo = ({ sap, device }) => {
    const tagStyle = {
        border: "1px solid #D0D0D0",
        borderRadius: "4px",
        padding: "2px 3px",
        fontSize: "smaller",
    };

    // Fetch the sap info from the server
    const { loading, error, data } = useQuery(GET_SAP_INFO_QUERY, {
        variables: { device, sap },
    });

    // Render the different load states
    if (loading) {
        return <Spinner style={{ padding: 100 }} />;
    } else if (error) {
        return <div>Unable to load SAP details</div>;
    } else {
        const split_tags = data?.sap?.tags?.split("_");
        const tags = _.map(split_tags, (tag, index) => {
            if (tag === "") {
                return;
            } else {
                return (
                    <span key={index}>
                        <span style={tagStyle}>
                            <Link to={`/network/saps/list?filter=${tag}`}>{`${tag}`}</Link>
                        </span>
                        &nbsp;{" "}
                    </span>
                );
            }
        });

        const site =
            data?.sap?.site && data?.sap?.entityType ? (
                <Link
                    to={`/${data?.sap?.entityType}/view/${data?.sap?.site}`}
                >{`${data?.sap?.site}`}</Link>
            ) : (
                <div>Not site facing</div>
            );

        const port = data?.sap?.port?.trim() ? `${data?.sap?.port}` : "-";
        const sap_parts = data?.sap.name?.split("-") || [];
        const vlan = sap_parts[sap_parts.length - 1];

        return (
            <div>
                <dl className="row">
                    <dt className="col-sm-1">Name</dt>
                    <dd className="col-sm-11">{data?.sap?.name}</dd>
                    <dt className="col-sm-1">Device</dt>
                    <dd className="col-sm-11">{data?.sap?.device}</dd>
                    <dt className="col-sm-1">Port</dt>
                    <dd className="col-sm-11">{port}</dd>
                    <dt className="col-sm-1">VLAN</dt>
                    <dd className="col-sm-11">{vlan}</dd>
                    <dt className="col-sm-1">Site</dt>
                    <dd className="col-sm-11">{site}</dd>
                    <dt className="col-sm-1">Tags</dt>
                    <dd className="col-sm-11">{tags}</dd>
                </dl>
            </div>
        );
    }
};
