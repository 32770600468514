import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { ChoiceSelector, Fillable, TextFilter } from "shared/components/controls";
import { useQueryState, useUser } from "shared/hooks";
import { breadcrumbStyle } from "shared/styles/styles";
import { CollaboratorTable } from "./CollaboratorTable";
import { setHtmlTitle } from "shared/utils/entity-utils";

export const BASE_USER_QUERY = gql`
    query {
        user {
            email
            firstName
            lastName
            isAnonymous
            favoriteNetworkEntities {
                id
                shortName
                type
            }
            viewTopTalkers {
                id
                shortName
                fullName
            }
        }
    }
`;

export const GET_COLLABORATOR_QUERY = gql`
    query {
        networkEntities(entityType: "LHCONE") {
            id
            type
            shortName
            fullName
            programOffice {
                shortName
            }
            programArea
            parent {
                shortName
            }
            tags
        }
    }
`;

export const UPDATE_FAVORITE_MUTATION = gql`
    mutation UpdateFacilityFavorite($shortName: String, $isInterested: Boolean) {
        updateUserNetworkEntityFavorite(
            entityType: "LHCONE"
            shortName: $shortName
            isInterested: $isInterested
        ) {
            success
            user {
                email
                firstName
                lastName
                isAnonymous
                favoriteNetworkEntities {
                    id
                    shortName
                    type
                }
                viewTopTalkers {
                    id
                    shortName
                    fullName
                }
            }
        }
    }
`;

const experimentList = [
    { key: "all", label: "All" },
    { key: "ATLAS", label: "ATLAS" },
    { key: "CMS", label: "CMS" },
    { key: "Belle II", label: "Belle II" },
];

/**
 * A list of LHCONE collaborators rendered in a table
 */
export const CollaboratorList = () => {
    // Set HTML title tag
    setHtmlTitle("LHCONE Collaborators");

    const user = useUser();

    const [updateFavorite] = useMutation(UPDATE_FAVORITE_MUTATION);
    const [text] = useQueryState("filter", "");
    const [experiment] = useQueryState("experiment", "all");

    let favorites = [];
    let showFavorites = false;
    if (user && !user.isAnonymous) {
        favorites = user.favoriteNetworkEntities;
        showFavorites = true;
    }

    // Build a new optimistic response starting with the user object
    // then set that on the Apollo Client cache until the server action has
    // been completed.
    const handleFavoriteChange = (collaborator, state) => {
        const response = user;

        // If we're not interested in an entity then traverse the
        // list and remove the one we don't want in there anymore, otherwise
        // add it to the end of the list of current favorites
        let newFavorites = [];
        if (!state) {
            newFavorites = response.favoriteNetworkEntities.filter(
                (fav) => fav.id !== collaborator.id
            );
        } else {
            newFavorites = [...response.favoriteNetworkEntities, collaborator];
        }
        response.favoriteNetworkEntities = newFavorites;

        updateFavorite({
            variables: { shortName: collaborator.shortName, isInterested: state },
            optimisticResponse: {
                __typename: "Mutation",
                updateFavorite: response,
            },
        });
    };

    return (
        <Col md={12}>
            <Row>
                <Col md={12}>
                    <div style={breadcrumbStyle}>
                        <a href="/">HOME</a>
                        <span> » </span>
                    </div>
                    <h2>LHCONE Collaborators</h2>
                </Col>
            </Row>
            <Row style={{ paddingTop: 20 }}>
                <Col md={3}>
                    <TextFilter />
                </Col>
                <Col md={3} style={{ marginTop: 5 }}>
                    <ChoiceSelector
                        label="Experiment"
                        variableName="experiment"
                        defaultValue="all"
                        options={experimentList}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <hr />
                    <Fillable>
                        <CollaboratorTable
                            showFavorites={showFavorites}
                            favorites={favorites}
                            onFavoriteChange={handleFavoriteChange}
                            text={text}
                            experiment={experiment}
                        />
                    </Fillable>
                </Col>
            </Row>
        </Col>
    );
};
