import { useQuery } from "@apollo/react-hooks";
import { format } from "d3-format";
import gql from "graphql-tag";
import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { AutoSizer, Column, Table } from "react-virtualized";
import "react-virtualized/styles.css";
import { Spinner } from "shared/components/controls";
import { rowCountStyle, totalRowCountStyle } from "shared/styles/styles";
import { useSortState } from "shared/hooks";
import _ from "underscore";

export const GET_OSCARS_CONNECTIONS_QUERY = gql`
    query {
        oscarsConnections {
            connectionId
            description
            begin
            end
            endAName
            endASite
            endZName
            endZSite
            state
            mode
            tags {
                category
                contents
            }
            fixtures {
                inMbps
                outMbps
            }
        }
    }
`;

export class FilterableOscarsList {
    constructor(connections) {
        this.connections = connections;
    }

    isLoaded() {
        return !_.isUndefined(this.connections);
    }

    get(index) {
        return this.connections[index];
    }

    filterByText(textFilter) {
        if (!this.isLoaded()) {
            return this;
        }
        if (!textFilter || _.isUndefined(textFilter) || textFilter.length === 0) {
            return this;
        }

        const lowerTextFilter = textFilter.toLowerCase();
        return new FilterableOscarsList(
            _.filter(this.connections, (connection) => {
                const {
                    connectionId = "",
                    description = "",
                    endAName = "",
                    endZName = "",
                } = connection;
                return (
                    connectionId.toLowerCase().indexOf(lowerTextFilter) >= 0 ||
                    endAName.toLowerCase().indexOf(lowerTextFilter) >= 0 ||
                    endZName.toLowerCase().indexOf(lowerTextFilter) >= 0 ||
                    description.toLowerCase().indexOf(lowerTextFilter) >= 0
                );
            })
        );
    }

    // Custom sorting based either on the device/interface, sub-sorted by capacity,
    // or sorting based on current traffic rate.
    sort(sortBy, sortDirection) {
        if (!this.isLoaded()) {
            return this;
        }

        switch (sortBy) {
            case "connectionId":
                if (sortDirection === "DESC") {
                    return new FilterableOscarsList(
                        _.chain(this.connections).sortBy("connectionId").reverse().value()
                    );
                } else {
                    return new FilterableOscarsList(
                        _.chain(this.connections).sortBy("connectionId").value()
                    );
                }
            case "description":
                if (sortDirection === "DESC") {
                    return new FilterableOscarsList(
                        _.chain(this.connections).sortBy("description").reverse().value()
                    );
                } else {
                    return new FilterableOscarsList(
                        _.chain(this.connections).sortBy("description").value()
                    );
                }
            case "endAName":
                if (sortDirection === "DESC") {
                    return new FilterableOscarsList(
                        _.chain(this.connections).sortBy("endAName").reverse().value()
                    );
                } else {
                    return new FilterableOscarsList(
                        _.chain(this.connections).sortBy("endAName").value()
                    );
                }
            case "endZName":
                if (sortDirection === "DESC") {
                    return new FilterableOscarsList(
                        _.chain(this.connections).sortBy("endZName").reverse().value()
                    );
                } else {
                    return new FilterableOscarsList(
                        _.chain(this.connections).sortBy("endZName").value()
                    );
                }
            case "capacity":
                if (sortDirection === "DESC") {
                    return new FilterableOscarsList(
                        _.chain(this.connections)
                            .sortBy("endAName")
                            .reverse()
                            .sortBy((item) => item.fixtures[0].inMbps)
                            .reverse()
                            .value()
                    );
                } else {
                    return new FilterableOscarsList(
                        _.chain(this.connections)
                            .sortBy("endAName")
                            .reverse()
                            .sortBy((item) => item.fixtures[0].inMbps)
                            .value()
                    );
                }

            case "begin":
                if (sortDirection === "DESC") {
                    return new FilterableOscarsList(
                        _.chain(this.connections)
                            .sortBy((item) => +new Date(item.begin))
                            .reverse()
                            .value()
                    );
                } else {
                    return new FilterableOscarsList(
                        _.chain(this.connections)
                            .sortBy((item) => +new Date(item.begin))
                            .value()
                    );
                }

            case "end":
                if (sortDirection === "DESC") {
                    return new FilterableOscarsList(
                        _.chain(this.connections)
                            .sortBy((item) => +new Date(item.end))
                            .reverse()
                            .value()
                    );
                } else {
                    return new FilterableOscarsList(
                        _.chain(this.connections)
                            .sortBy((item) => +new Date(item.end))
                            .value()
                    );
                }

            default:
                break;
        }
    }

    size() {
        if (!this.isLoaded()) {
            return 0;
        } else {
            return this.connections.length;
        }
    }
}

export const OscarsTable = ({ text, height }) => {
    const rowHeight = 30;
    const { loading, data } = useQuery(GET_OSCARS_CONNECTIONS_QUERY);

    // Sorting settings are stored on the URL
    const [{ sortBy, sortDirection }, setSortState] = useSortState("connectionId", "ASC");

    const renderConnectionId = ({ rowData }) => {
        const id = rowData.connectionId;
        return <Link to={`/oscars/view/${id}`}>{id}</Link>;
    };

    const renderCapacity = ({ rowData }) => {
        const capacityIn = rowData.fixtures[0].inMbps * 1e6;
        const capacityOut = rowData.fixtures[0].outMbps * 1e6;
        let formattedCapacity;
        if (capacityIn === capacityOut) {
            formattedCapacity = `${format("~s")(capacityIn)}bps`;
        } else {
            formattedCapacity = `${format("~s")(capacityIn)}bps ${format("~s")(capacityOut)}bps`;
        }
        return formattedCapacity;
    };

    const renderType = ({ rowData }) => {
        const tags = rowData.tags;
        const type = _.find(tags, function (x) {
            return x.category === "production" && x.contents === "true";
        })
            ? "Production"
            : "n/a";
        return type;
    };

    const renderAEndpoint = ({ rowData }) => {
        const name = rowData.endAName;
        const isSite = rowData.endASite;
        if (isSite) {
            return <Link to={`/sites/view/${name}`}>{name}</Link>;
        } else {
            return `${name}`;
        }
    };

    const renderZEndpoint = ({ rowData }) => {
        const name = rowData.endZName;
        const isSite = rowData.endZSite;
        if (isSite) {
            return <Link to={`/sites/view/${name}`}>{name}</Link>;
        } else {
            return `${name}`;
        }
    };

    const renderBeginDate = ({ rowData }) => {
        const d = new Date(rowData.begin);
        return `${d.toLocaleDateString()}`;
    };

    const renderEndDate = ({ rowData }) => {
        const d = new Date(rowData.end);
        return `${d.toLocaleDateString()}`;
    };

    const renderTotalRows = (rowCount, totalRowCount) => {
        if (rowCount < totalRowCount) {
            return <span style={totalRowCountStyle}>(of {totalRowCount})</span>;
        }
        return <span></span>;
    };

    if (loading) {
        return <Spinner />;
    } else if (data) {
        // Filtered and sorted list of Oscars connections
        const OscarsList = new FilterableOscarsList(data.oscarsConnections)
            .filterByText(text)
            .sort(sortBy, sortDirection);

        // Rows remaining in the filtered list
        const rowCount = OscarsList.size();

        return (
            <div>
                <Row>
                    <Col md={12}>
                        <span style={rowCountStyle}>
                            Displaying {rowCount}{" "}
                            {renderTotalRows(rowCount, data.oscarsConnections.length)} Results
                        </span>
                    </Col>
                </Row>
                <AutoSizer disableHeight>
                    {({ width }) => (
                        <div>
                            <Table
                                gridStyle={{ outline: 0 }}
                                width={width}
                                height={height}
                                headerHeight={20}
                                headerStyle={{ outline: 0 }}
                                rowHeight={rowHeight}
                                sort={({ sortBy, sortDirection }) =>
                                    setSortState(sortBy, sortDirection)
                                }
                                sortBy={sortBy}
                                sortDirection={sortDirection}
                                rowCount={rowCount}
                                rowGetter={({ index }) => OscarsList.get(index)}
                            >
                                <Column
                                    width={100}
                                    label="Name"
                                    dataKey="connectionId"
                                    disableSort={false}
                                    cellRenderer={renderConnectionId}
                                />

                                <Column width={500} label="Description" dataKey="description" />

                                <Column
                                    width={100}
                                    label="Capacity"
                                    dataKey="capacity"
                                    disableSort={false}
                                    cellRenderer={renderCapacity}
                                />

                                <Column
                                    width={100}
                                    label="Type"
                                    dataKey="type"
                                    disableSort={true}
                                    cellRenderer={renderType}
                                />

                                <Column
                                    width={200}
                                    label="From"
                                    dataKey="endAName"
                                    disableSort={false}
                                    cellRenderer={renderAEndpoint}
                                />

                                <Column
                                    width={200}
                                    label="To"
                                    dataKey="endZName"
                                    disableSort={false}
                                    cellRenderer={renderZEndpoint}
                                />

                                <Column
                                    width={100}
                                    label="Begin"
                                    dataKey="begin"
                                    disableSort={false}
                                    cellRenderer={renderBeginDate}
                                />

                                <Column
                                    width={100}
                                    label="End"
                                    dataKey="end"
                                    disableSort={false}
                                    cellRenderer={renderEndDate}
                                />
                            </Table>
                        </div>
                    )}
                </AutoSizer>
            </div>
        );
    } else {
        return <div />;
    }
};
