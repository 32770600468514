import React from "react";
import _ from "underscore";
import Flexbox from "flexbox-react";
import { Resizable } from "react-timeseries-charts";
import Stack from "./TimeseriesListBarchartStack";
import { splitSeriesName, interfaceOrSap } from "shared/utils/timeseries-utils";

/**
 * Each series in the series list has a list of columns to display. So here
 * we render the series label, the bars (one for each column) and the child
 * if there is one for expanded info about the series.
 */
export default class extends React.Component {
    static displayName = "Row";

    state = {
        hover: false,
    };

    handleClick = () => {
        if (this.props.onSelectionChanged) {
            if (this.props.selected) {
                this.props.onSelectionChanged(null);
            } else {
                this.props.onSelectionChanged(this.props.series.name());
            }
        }
    };

    handleNavigate = () => {
        if (this.props.onNavigate) {
            this.props.onNavigate(this.props.series.name());
        }
    };

    renderLabel = () => {
        const style = {
            marginTop: 5,
            cursor: this.props.onNavigate ? "pointer" : "default",
        };

        if (this.props.onNavigate) {
            style.color = this.props.navigateColor;
            return (
                <span style={style} onClick={this.handleNavigate}>
                    {splitSeriesName(this.props.series.name()).toUpperCase()}
                </span>
            );
        } else {
            return <span style={style}>{this.props.series.name().toUpperCase()}</span>;
        }
    };

    renderExtraFields = (rowWidth, labelStyle, spanStyle) => {
        const fields = this.props.extraFields.map((item) => {
            const name = this.props.series.meta()[item["name"]];
            let text = name;
            if (typeof name === "string") {
                text = name.toUpperCase();
            }
            const appendedText = item["label"] ? (
                item["label"]
            ) : (
                <i>({interfaceOrSap(this.props.series.name())})</i>
            );
            const style = item["style"] ? item["style"] : spanStyle;
            return (
                <Flexbox width={rowWidth} style={labelStyle} key={item["name"]}>
                    <span style={style}>
                        {text} {appendedText}
                    </span>
                </Flexbox>
            );
        });
        return fields;
    };

    renderBars = () => {
        // prettier-ignore
        const {
            display,
            series,
            max,
            columns,
            spacing,
            padding,
            size,
            style,
            format,
            timestamp,
        } = this.props;

        const rowStyle = {
            width: "100%",
            paddingLeft: "7px",
        };

        const resizableStyle = {};

        return (
            <div
                style={rowStyle}
                onMouseEnter={() => this.setState({ hover: true })}
                onMouseLeave={() => this.setState({ hover: false })}
            >
                <Resizable style={resizableStyle}>
                    <Stack
                        display={display}
                        series={series}
                        max={max}
                        columns={columns}
                        spacing={spacing}
                        padding={padding}
                        size={size}
                        style={style}
                        format={format}
                        timestamp={timestamp}
                    />
                </Resizable>
            </div>
        );
    };

    render() {
        const { series } = this.props;
        let rowStyle = {
            borderTopStyle: "solid",
            borderTopWidth: 1,
            borderTopColor: "#D0D0D0",
        };

        const spanStyle = {
            marginTop: 5,
            cursor: this.props.onNavigate ? "pointer" : "default",
        };

        const extraFields = this.props.extraFields;
        let rowWidth;

        let labelStyle;
        if (this.props.selected) {
            rowStyle = {
                paddingLeft: 2,
                borderTopStyle: "solid",
                borderTopWidth: 1,
                borderTopColor: "#D0D0D0",
                borderLeftStyle: "solid",
                borderLeftWidth: 5,
                borderLeftColor: this.props.selectionColor,
            };
            extraFields.map(
                (item) =>
                    (rowWidth = item["widthWhenSelected"] ? item["widthWhenSelected"] : "220px")
            );
        } else {
            labelStyle = {
                background: "#FFF",
                paddingLeft: 7,
                borderRightStyle: "solid",
                borderRightColor: "#D0D0D0",
            };
            extraFields.map((item) => (rowWidth = item["width"] ? item["width"] : "220px"));
        }

        if (this.state.hover && (_.isUndefined(this.props.selected) || !this.props.selected)) {
            labelStyle.background = "#EEE";
        }

        return (
            <Flexbox
                key={this.props.rowNumber}
                style={rowStyle}
                flexDirection="row"
                onMouseEnter={() => this.setState({ hover: true })}
                onMouseLeave={() => this.setState({ hover: false })}
                onClick={this.handleClick}
            >
                <Flexbox minWidth="200px" width={this.props.textWidth + "px"} style={labelStyle}>
                    {this.renderLabel(series)}
                </Flexbox>
                {this.renderExtraFields(rowWidth, labelStyle, spanStyle)}
                <Flexbox flexGrow={1}>{this.renderBars()}</Flexbox>
            </Flexbox>
        );
    }
}
