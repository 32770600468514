import gql from "graphql-tag";
import React, { useRef } from "react";
import { styler } from "react-timeseries-charts";
import { TimeseriesListAreachartWidget } from "shared/components/widgets";
import { useMemoQuery } from "shared/hooks";
import { chartBlue, chartOrange, mutedChartBlue, mutedChartOrange } from "shared/styles/styles";
import { toTimeSeriesList } from "shared/utils/timeseries-utils";

export const COLLABORATOR_FLOW_QUERY = gql`
    query getLHCONEFlowTraffic(
        $collaboratorName: String
        $breakdown: String
        $beginTime: String
        $endTime: String
    ) {
        networkEntity(shortName: $collaboratorName, entityType: "LHCONE") {
            id
            flow(breakdown: $breakdown, beginTime: $beginTime, endTime: $endTime) {
                traffic
            }
        }
    }
`;

const style = styler([
    { key: "in", color: chartBlue },
    { key: "out", color: chartOrange },
]);

const mutedStyle = styler([
    { key: "in", color: mutedChartBlue },
    { key: "out", color: mutedChartOrange },
]);

// Build a options option which can be passed to the area chart
const TITLE = "Total Collaborator Traffic";
const DESCRIPTION = `
#### Total Traffic\n\n
This chart shows the traffic flowing in and out of this network entity
filtered on the values returned by the top flows query by the breakdown. Hovering over the
chart with your mouse will provide details about that specific point in time.
`;

const LABELS = {
    in: "To",
    out: "From",
};

export const CollaboratorFlowChart = ({
    collaboratorName,
    selected,
    breakdown,
    timerange,
    timestamp,
    onTrackerChanged,
}) => {
    let totalSeries = useRef(null);
    let seriesList = useRef([]);

    const beginTime = timerange.begin().toISOString();
    const endTime = timerange.end().toISOString();

    const queryOptions = {
        variables: { collaboratorName, breakdown, beginTime, endTime },
        fetchPolicy: "no-cache",
    };

    const {
        isLoading,
        error,
        data: { timeSeriesList, timeSeries },
    } = useMemoQuery(
        COLLABORATOR_FLOW_QUERY,
        queryOptions,
        (d) => {
            const { totalSeries, seriesList } = toTimeSeriesList(
                "traffic",
                d.networkEntity.flow,
                { name: "traffic", labels: LABELS },
                (d) => JSON.parse(d.traffic)
            );
            return {
                timeSeriesList: seriesList,
                timeSeries: totalSeries,
            };
        },
        [beginTime, endTime, breakdown]
    );

    if (timeSeries !== undefined) {
        totalSeries.current = timeSeries;
    }

    if (timeSeriesList !== undefined) {
        seriesList.current = timeSeriesList;
    }

    let errMsg;
    if (error) {
        errMsg = "Unable to load flow data from server";
    }

    return (
        <TimeseriesListAreachartWidget
            title={TITLE}
            description={DESCRIPTION}
            totalSeries={totalSeries.current}
            seriesList={seriesList.current}
            timerange={timerange}
            timestamp={timestamp}
            stackUp={["in"]}
            stackDown={["out"]}
            labels={{ in: "To", out: "From" }}
            selected={selected}
            style={style}
            mutedStyle={mutedStyle}
            onTrackerChanged={onTrackerChanged}
            isLoading={isLoading}
            error={errMsg}
        />
    );
};
