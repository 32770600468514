import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { FacilityList } from "./components/FacilityList";
import { FacilityView } from "./components/FacilityView";

const NoMatch = () => (
    <div>
        <h2>Whoops</h2>
        <p>Sorry but this DOE facility URL didn’t find any pages</p>
    </div>
);

const Facilities = () => {
    let { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={`${path}/list`}>
                <FacilityList />
            </Route>
            <Route path={`${path}/view/:facility/:page?`}>
                <FacilityView />
            </Route>
            <Route path="*">
                <NoMatch />
            </Route>
        </Switch>
    );
};

export default Facilities;
