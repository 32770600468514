import React from "react";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import { ActivateUser } from "./components/ActivateUser";
import { ChangePassword } from "./components/ChangePassword";
import { Login } from "./components/Login";
import Profile from "./components/Profile";
import { Register } from "./components/Register";
import { ResetEmail } from "./components/ResetEmail";
import { ResetPassword } from "./components/ResetPassword";

const NoMatch = () => (
    <div>
        <h2>Whoops</h2>
        <p>Sorry but this User URL didn’t find any pages</p>
    </div>
);

const User = () => {
    let { path } = useRouteMatch();
    let pathname = window.location.pathname;
    return (
        <div>
            <Switch>
                <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
                <Route path={`${path}/login`}>
                    <Login />
                </Route>
                <Route path={`${path}/register`}>
                    <Register />
                </Route>
                <Route path={`${path}/activate/:activationKey`}>
                    <ActivateUser />
                </Route>
                <Route path={`${path}/change_password`}>
                    <ChangePassword />
                </Route>
                <Route path={`${path}/reset_email`}>
                    <ResetEmail />
                </Route>
                <Route path={`${path}/reset_password/:uidb64/:token`}>
                    <ResetPassword />
                </Route>
                <Route path={`${path}/profile`}>
                    <Profile />
                </Route>
                <Route path="*">
                    <NoMatch />
                </Route>
            </Switch>
        </div>
    );
};

export default User;
