import { format } from "d3-format";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Legend } from "react-timeseries-charts";
import TimeSeriesAreaChart from "./components/TimeseriesAreachart";
import { TrackerTime } from "./components/TrackerTime";
import Widget from "./Widget";

const MAX_FONT16_LEN = 40;

/**
 * Wraps the TimeSeriesAreaChart in a widget control. This adds:
 *   - auto-width scaling
 *   - info button for information about the widget
 *   - standard error display
 *   - standard loading indication
 *
 * This will also display a title for the chart, along with tracker
 * and legend display.
 *
 * TODO: The code below has a notion of the series data
 *       being network traffic if only because in the tracker
 *       displays values in bps.
 *       We should pass in a formatting function or string
 *       to handle this so this class is completely general.
 */
export const TimeseriesAreachartWidget = ({
    series,
    overlaySeries,
    title,
    description,
    isLoading,
    error,
    timerange,
    stackUp = [],
    stackDown = [],
    labels,
    style,
    onTrackerChanged,
    onTimeRangeChanged,
}) => {
    const [tracker, setTracker] = useState(null);

    const handleTrackerChanged = (t) => {
        setTracker(t);
        if (onTrackerChanged) {
            onTrackerChanged(t);
        }
    };

    const handleTimeRangeChanged = (tr) => {
        if (onTimeRangeChanged) {
            onTimeRangeChanged(tr);
        }
    };

    const renderTitle = () => {
        if (title) {
            let fontSize = 16;
            if (title.length > MAX_FONT16_LEN) {
                fontSize = 15;
            }
            return <h4 style={{ fontSize }}>{title}</h4>;
        } else {
            return null;
        }
    };

    const infoRowStyle = {
        marginBottom: 10,
    };

    const chartkey = `key-${isLoading}-${error}`;

    let legendCategories = [];

    let displayError = null;

    // if we've finished loading and have no data, or if there was an error,
    // show "no data" / "error" message instead of graph.
    if (!isLoading) {
        if (!series) {
            displayError = "No data.";
        }
        if (!!error) {
            displayError = "An error occurred while trying to fetch data for the chart.";
        }
    }

    if (!isLoading && !displayError) {
        // Legend categories
        const categories = [];

        // Build a list of categories from the stackUp and stackDown props
        stackUp.forEach((column) => categories.push(column));
        stackDown.forEach((column) => categories.push(column));

        // Start a stack list which we add to below
        const stack = categories.map((layer, i) => {
            return {
                pos: i,
                column: layer,
            };
        });

        // Add labels to our stack
        if (!labels) {
            const timeSeriesLabels = series.meta("labels");
            categories.forEach((_, i) => {
                let label = timeSeriesLabels[i];
                if (timeSeriesLabels[i].includes("--")) {
                    label = timeSeriesLabels[i].replace("--", " → ");
                }
                stack[i].label = label;
            });
        } else {
            categories.forEach((_, i) => {
                stack[i].label = labels[stack[i].column];
            });
        }

        // Add values based on the tracker position to our stack
        if (series && tracker) {
            const pos = series.bisect(tracker);
            categories.forEach((_, i) => {
                const v = series.at(pos)?.get(stack[i].column);
                stack[i].value = `${format(".3s")(v)}bps`;
            });
        }

        // Remap the stack to react-timeseries-charts legend categories format
        legendCategories = stack.map((layer) => {
            return {
                key: layer.column,
                label: layer.label,
                value: layer.value,
            };
        });
    }

    return (
        <Row key={chartkey}>
            <Col md={12}>
                <Row style={infoRowStyle}>
                    <Col md={5} style={{ height: 35 }}>
                        {renderTitle()}
                    </Col>

                    <Col md={3} style={{ height: 35 }}>
                        <div style={{ textAlign: "center" }}>
                            <TrackerTime time={tracker} series={series} />
                        </div>
                    </Col>

                    <Col md={4} style={{ height: 35 }}>
                        <div style={{ float: "right" }}>
                            <Legend categories={legendCategories} style={style} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Widget
                            info={description}
                            error={displayError}
                            loading={isLoading}
                            height={275}
                        >
                            <TimeSeriesAreaChart
                                height={275}
                                style={style}
                                timerange={timerange}
                                series={series}
                                overlaySeries={overlaySeries}
                                up={stackUp}
                                down={stackDown}
                                tracker={tracker}
                                onTimeRangeChanged={handleTimeRangeChanged}
                                onTrackerChanged={handleTrackerChanged}
                            />
                        </Widget>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};
