import React from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { Funnel } from "react-bootstrap-icons";
import { useQueryState } from "shared/hooks";

/**
 * Control which wraps a simple input control, but sets the current text on a query variable
 * on the URL. That variable is defined by passing in a `queryVariable` prop (it defaults to
 * "filter").
 */
export const TextFilter = ({
    defaultChoice = "",
    queryVariable = "filter",
    onChange,
    width = "100%",
    toolTip = "",
}) => {
    // Manages the state of the choice on the query string
    const [text, setText] = useQueryState(queryVariable, defaultChoice);

    const filterStyle = {
        width,
    };

    return (
        <InputGroup size="sm" className="mb-3" style={{ filterStyle }}>
            <FormControl
                placeholder="Filter"
                aria-label="Filter"
                title={toolTip}
                value={text}
                onChange={(e) => setText(e.target.value ? e.target.value : null)}
            />
            <InputGroup.Text id="filter-icon">
                <Funnel />
            </InputGroup.Text>
        </InputGroup>
    );
};
