import React from "react";

/**
 * Render a standard user message on the page. Maybe either an error
 * indicated by the `isError` prop being `true`. The message is composed
 * of a `title`, and any children will be rendered as is below.
 */
export const UserMessage = ({ isError = false, title, children }) => {
    const notificationStyle = {
        background: "#EEEEEE",
        borderLeftStyle: "solid",
        borderLeftColor: isError ? "#DC3545" : "#4EC1E0",
        paddingLeft: 10,
        paddingTop: 5,
        paddingBottom: 5,
    };
    return (
        <div style={{ paddingTop: 100 }}>
            <h2 style={notificationStyle}>{title}</h2>
            <p style={{ paddingLeft: 15 }}>{children}</p>
        </div>
    );
};
