import gql from "graphql-tag";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useMemoQuery, useUser } from "shared/hooks";
import { breadcrumbStyle } from "shared/styles/styles";
import _ from "underscore";
import { SiteFlow } from "./SiteFlow";
import { SiteTitle } from "./SiteInfo";
import { SiteTopTalkers } from "./SiteTopTalkers";
import SiteInterfaces from "./SiteInterfaces";

export const GET_SITE_NAME_QUERY = gql`
    query getSiteInfo($shortName: String) {
        networkEntity(shortName: $shortName, entityType: "ESnet Site") {
            id
            type
            shortName
            fullName
        }
    }
`;

// List of prefixes for ESnet Sites where we need to hide flow data
const HIDE_SITE_FLOWS = ["DOE-NNSA", "DOE-IN"];

/**
 * Container page for all Site pages. Renders just the title of the site
 * the bread crumbs and the nav tabs, then uses `page` query string param
 * to switch rendering of the page itself.
 *
 * Navigation options depends on the user logged in. Currently only some
 * users are able to see top talkers.
 */
const SiteView = (props) => {
    const user = useUser();
    const { site, page = "interfaces" } = useParams();

    const { data: entity } = useMemoQuery(
        GET_SITE_NAME_QUERY,
        {
            variables: { shortName: site },
        },
        (d) => d.networkEntity,
        [site]
    );

    const renderHeader = () => {
        if (entity) {
            return (
                <div>
                    <div style={breadcrumbStyle}>
                        <a href="/">HOME</a>
                        <span> › </span>
                        <a href="/sites/list">SITES</a>
                        <span> » </span>
                    </div>
                    <SiteTitle siteName={site} />
                </div>
            );
        } else {
            return null;
        }
    };

    const renderSubpage = () => {
        switch (page) {
            case "interfaces":
                return <SiteInterfaces site={site} {...props} />;
            case "flow":
                return <SiteFlow site={site} {...props} />;
            case "top":
                return <SiteTopTalkers site={site} {...props} />;
            default:
                return <SiteInterfaces site={site} {...props} />;
        }
    };

    // NavItems
    const subSections = [];
    subSections.push({
        key: "Interfaces",
        label: "Interfaces",
        url: `/sites/view/${site}/interfaces`,
    });

    // Don't show flow data for these sites
    if (HIDE_SITE_FLOWS.some((name) => site.toUpperCase().startsWith(name)) === false) {
        subSections.push({
            key: "flow",
            label: "Flow",
            url: `/sites/view/${site}/flow`,
        });
    }

    // Conditionally include top talkers if the user is logged in
    if (user && !user.isAnonymous) {
        subSections.push({
            key: "top",
            label: "Top Talkers",
            url: `/sites/view/${site}/top`,
        });
    }

    let active;
    if (page === "flow") {
        active = "Flow";
    } else if (page === "top") {
        active = "Top Talkers";
    } else if (page === "interfaces" || page === undefined) {
        active = "Interfaces";
    }

    const navItems = _.map(subSections, (section) => {
        const klass = active === section.label ? "active" : "";
        const key = section.key;
        return (
            <li key={key} role="presentation" className={klass}>
                <Link to={section.url}>{section.label}</Link>
            </li>
        );
    });

    return (
        <Row>
            <Col md={1} />
            <Col md={10}>
                <Row>
                    <Col md={12}>
                        <Row>
                            <Col md={7}>{renderHeader()}</Col>
                            <Col md={5} style={{ marginTop: 30 }}>
                                <ul className="nav nav-pills" style={{ float: "right" }}>
                                    {navItems}
                                </ul>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>{renderSubpage()}</Row>
            </Col>
            <Col md={1} />
        </Row>
    );
};

export { SiteView };
