import { useThrottleCallback } from "@react-hook/throttle";
import React, { useState } from "react";
import {
    AreaChart,
    ChartContainer,
    ChartRow,
    Charts,
    LineChart,
    styler,
    TimeRangeMarker,
    YAxis,
} from "react-timeseries-charts";
import _ from "underscore";
import Widget from "./Widget";
import { TimeSeries } from "pondjs";

/**
 * MultiChannelChartWidget is a wrapper around standard charting modes within the Portal, contained
 * within a standard Widget. It uses an object `layout` to control how one or many TimeSeries are
 * displayed.
 *
 * A `layout` is the main prop passed into the component. It is an array, with each member of the
 * array mapping to a single chart row. The row object itself contains its `height` and any `selection`
 * overlay which is a `Timerange` that will be displayed as a selected area over the row. In addition, of
 * course, a row object also defines the `charts` that fill that row. `charts` is an array as well,
 * with each member being a chart object.
 *
 * Chart object contains the `series` (a Pond `TimeSeries`), which will be displayed, and the `type` and `mode`
 * of the chart. `type` maybe either `line` or `area`, while `mode` is used for line charts and may be
 * either `up-down` or `multi-channel`. The former will take `in` and `out` channels of the supplied
 * `series` and render one above the axis and the other below. For area charts this is the assumed mode
 * as of now. In `multi-channel` mode, for line charts only, this will draw multiple line charts, one for each
 * channel in the `series` listed in the `channels` property of the chart object.
 *
 * Each chart also has an `id`, which should be unique. It is also used when automatically creating CSV
 * downloads from the widget (the CSV header names are a combination of id and series column names).
 *
 * Each chart has its own axis with a `label` defined in the chart object.
 *
 * Each chart can also have an `overlay` timeseries. This is useful if you have a primary `series` on
 * the page and want to overlay a trend line or other series, but don't necessarily want to merge
 * the series together (perhaps they are loaded or computed separately). The overlay will always go
 * on top, and is further customized with `overlayColor`, while the channel that will be plotted in
 * the overlay is given by `overlayChannel` (default is "value").
 *
 * Finally, each chart can have its `interpolation` mode customized e.g. "curveNatural" (see chart code).
 */
export const MultiChannelChartWidget = ({
    loading,
    error,
    info,
    height,
    layout,
    timeRange,
    scale = "linear",
    downloadName = "download",
    enableCSVDownload = false,
    enablePNGDownload = false,
    onValuesChange,
    onTrackerChange,
    onTimeRangeChange,
    onClick,
}) => {
    const [tracker, setTracker] = useState(null);

    const numYears = 2;
    const minTime = new Date().getTime() - numYears * 365 * 24 * 60 * 60 * 1000;

    const handleTimeRangeChanged = (tr) => {
        if (onTimeRangeChange) onTimeRangeChange(tr);
    };

    const handleTrackerChanged = useThrottleCallback((t) => {
        const values = {};

        // look up the time on the tracker in the series, giving us
        // a map of id -> { in, out } values
        if (t) {
            layout.forEach((row) => {
                row.charts.forEach(({ id, series }) => {
                    if (series && series instanceof TimeSeries) {
                        if (series.size() > 0) {
                            const index = series.bisect(t);
                            const e = series.at(index);
                            const inValue = e.get("in");
                            const outValue = e.get("out");
                            values[id] = { in: inValue, out: outValue };
                        }
                    }
                });
            });
        }

        // Update the tracker state
        setTracker(t);

        // Call the callbacks
        if (onValuesChange) onValuesChange(values);
        if (onTrackerChange) onTrackerChange(t);
    }, 60);

    const handleClick = () => {
        if (onClick) onClick(tracker);
    };

    const formatDate = (m) =>
        m.getUTCFullYear() +
        "-" +
        ("0" + (m.getUTCMonth() + 1)).slice(-2) +
        "-" +
        ("0" + m.getUTCDate()).slice(-2) +
        " " +
        ("0" + m.getUTCHours()).slice(-2) +
        ":" +
        ("0" + m.getUTCMinutes()).slice(-2) +
        ":" +
        ("0" + m.getUTCSeconds()).slice(-2);

    const generateCSVDownload = (layout) => {
        const columns = [];
        const valueMap = {};
        layout.forEach(({ charts }) => {
            charts.forEach(({ id, series, overlay, overlayChannel }) => {
                if (series && series instanceof TimeSeries) {
                    if (series.size() > 0) {
                        series.columns().forEach((name) => {
                            const columnName = `${id}_${name}`;
                            columns.push(columnName);
                            for (const e of series.events()) {
                                const t = _.isFunction(e.index)
                                    ? e.index()
                                    : formatDate(e.timestamp());
                                if (!_.has(valueMap, t)) {
                                    valueMap[t] = {};
                                }
                                const value = e.get(name);
                                valueMap[t][columnName] = _.isUndefined(value) ? null : value;
                            }
                        });
                    }
                }
                if (overlay) {
                    if (overlay.size() > 0) {
                        const columnName = `${id}_${overlayChannel}`;
                        columns.push(columnName);
                        for (const e of overlay.events()) {
                            const t = _.isFunction(e.index) ? e.index() : formatDate(e.timestamp());
                            if (!_.has(valueMap, t)) {
                                valueMap[t] = {};
                            }
                            const value = e.get(overlayChannel);
                            valueMap[t][columnName] = _.isUndefined(value) ? null : value;
                        }
                    }
                }
            });
        });

        const rows = [["date", ...columns.sort()]];

        Object.keys(valueMap)
            .sort((a, b) => {
                return a - b;
            })
            .forEach((timestamp) => {
                const row = [timestamp];
                columns.sort().forEach((column) => {
                    const v = valueMap[timestamp][column];
                    row.push(_.isUndefined(v) ? null : v);
                });
                rows.push(row);
            });

        return rows;
    };

    const rows = layout.map((row, r) => {
        const charts = [];
        const axes = [];
        row.charts.forEach((chart) => {
            const {
                series,
                id,
                type,
                mode,
                colors,
                label,
                format,
                overlay,
                overlayColor = "#D0D0D0",
                overlayChannel = "value",
                interpolation = "curveLinear",
                channels = ["in", "out"],
            } = chart;

            if (series && series instanceof TimeSeries) {
                if (series.size() > 0) {
                    // Scaling for the YAxis
                    let seriesChannelMax = Math.max(...channels.map((c) => series.max(c)));
                    let overlayMax = overlay ? overlay.max(overlayChannel) : null;
                    let max = Math.max(seriesChannelMax, overlayMax);

                    let min = Math.min(...channels.map((c) => series.min(c)));
                    if (max < 0.25) {
                        max = 0.25;
                    }
                    if (scale === "log" && min <= 0) {
                        min = 0.1;
                    }

                    // Add a chart of a given type to the row
                    switch (type) {
                        case "line":
                            switch (mode) {
                                case "up-down":
                                    // Up/down line chart takes two channels "in" and "out"
                                    const upDownSeries = series.map((e) =>
                                        e.setData({
                                            in: e.get("in"),
                                            out: -e.get("out"),
                                        })
                                    );

                                    const lineChartStyle = styler([
                                        { key: "in", color: colors[0], width: 1 },
                                        { key: "out", color: colors[1], width: 1 },
                                    ]);

                                    charts.push(
                                        <LineChart
                                            key={`${id}-linechart`}
                                            axis={`${id}-axis`}
                                            series={upDownSeries}
                                            columns={["in", "out"]}
                                            style={lineChartStyle}
                                            interpolation={interpolation}
                                        />
                                    );
                                    break;
                                case "multi-channel":
                                    // Multi-channel line charts, will draw multiple lines as
                                    // a single line chart, utilizing different channels of the
                                    // same TimeSeries

                                    const multiLineChartStyle = styler(
                                        channels.map((c, i) => ({
                                            key: c,
                                            color: colors[i],
                                            width: 1,
                                        }))
                                    );

                                    charts.push(
                                        <LineChart
                                            key={`${id}-linechart`}
                                            axis={`${id}-axis`}
                                            series={series}
                                            columns={channels}
                                            style={multiLineChartStyle}
                                            interpolation={interpolation}
                                        />
                                    );

                                    break;
                                default:
                                //pass
                            }

                            break;
                        case "area":
                            const areaChartStyle = styler([
                                { key: "in", color: colors[0] },
                                { key: "out", color: colors[1] },
                            ]);
                            charts.push(
                                <AreaChart
                                    key={`${id}-areachart`}
                                    axis={`${id}-axis`}
                                    series={series}
                                    style={areaChartStyle}
                                    columns={{ up: ["in"], down: ["out"] }}
                                    interpolation={interpolation}
                                />
                            );
                            break;
                        default:
                            break;
                    }

                    if (overlay) {
                        const overlayStyle = styler([
                            { key: overlayChannel, color: overlayColor, width: 1, dashed: true },
                        ]);

                        charts.push(
                            <LineChart
                                key={`${id}-overlay`}
                                axis={`${id}-axis`}
                                series={overlay}
                                columns={[overlayChannel]}
                                style={overlayStyle}
                                interpolation={interpolation}
                            />
                        );
                    }

                    const axisStyle = {
                        labels: {
                            labelColor: colors[0],
                        },
                    };

                    if (mode === "up-down") {
                        axes.push(
                            <YAxis
                                id={`${id}-axis`}
                                key={`${id}-axis`}
                                style={axisStyle}
                                label={label}
                                min={-max}
                                max={max}
                                absolute={true}
                                format={format}
                                width="60"
                                type={scale}
                            />
                        );
                    } else {
                        axes.push(
                            <YAxis
                                id={`${id}-axis`}
                                key={`${id}-axis`}
                                style={axisStyle}
                                label={label}
                                min={min}
                                max={max}
                                absolute={true}
                                format={format}
                                width="60"
                                type={scale}
                            />
                        );
                    }
                }
            }
        });

        if (row.selection) {
            charts.push(
                <TimeRangeMarker
                    key="selection-marker"
                    timerange={row.selection}
                    style={{ fill: "#4EC1E0", opacity: 0.5 }}
                    timeScale={() => null} // |-Note: these props should be optional in the charts code
                    width={1} //              | so they are filled in here to silence errors. Actual values
                    height={1} //             | for these three props are injected from surrounding code.
                />
            );
        }

        return (
            <ChartRow height={row.height} key={r}>
                <Charts>{charts}</Charts>
                {axes}
            </ChartRow>
        );
    });

    return (
        <Widget
            error={error}
            loading={loading}
            info={info}
            height={height}
            downloadName={downloadName}
            downloadableAsImage={enablePNGDownload}
            downloadableCSVData={enableCSVDownload ? () => generateCSVDownload(layout) : undefined}
        >
            <ChartContainer
                enablePanZoom
                timeRange={timeRange}
                onTimeRangeChanged={handleTimeRangeChanged}
                trackerPosition={tracker}
                onTrackerChanged={handleTrackerChanged}
                onBackgroundClick={handleClick}
                transition={300}
                maxTime={new Date()}
                minTime={new Date(minTime)}
            >
                {rows}
            </ChartContainer>
        </Widget>
    );
};
