import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import Immutable from "immutable";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Field, Form, FormEditStates, Schema, TextEdit } from "react-dynamic-forms";
import { SubmitButton, UserMessage } from "shared/components/controls";
import { setHtmlTitle } from "shared/utils/entity-utils";

export const RESET_EMAIL_MUTATION = gql`
    mutation ResetPasswordRequest($email: String) {
        resetEmail(email: $email) {
            success
            title
            message
        }
    }
`;

const initialValues = {
    email: "",
};

const schema = (
    <Schema>
        <Field
            name="email"
            label="Email"
            placeholder="Enter valid email address"
            required={true}
            validation={{ format: "email" }}
        />
    </Schema>
);

const ResetEmailForm = () => {
    // Set HTML title tag
    setHtmlTitle("Reset Password");

    const [value, setValue] = useState(Immutable.fromJS(initialValues));
    const [hasMissing, setHasMissing] = useState(true);
    const [hasErrors, setHasErrors] = useState(true);
    const [wasSubmitted, setSubmitted] = useState(false);
    const [successTitle, setSuccessTitle] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const [submitPasswordChangeRequest, { loading, error }] = useMutation(RESET_EMAIL_MUTATION, {
        onError: () => {
            // Handle GraphQL errors. Errors are returned in the 'error' object.
        },
        onCompleted: (d) => {
            setSuccessTitle(d.resetEmail.title);
            setSuccessMessage(d.resetEmail.message);
            setSubmitted(true);
        },
    });

    const isPending = !!loading;
    const isError = !!error;

    const handleSubmit = (e) => {
        e.preventDefault();

        const trimmedEmail = value.get("email").trim();
        if (!trimmedEmail) {
            return;
        }

        // Call the request for a password reset via a sent email link
        submitPasswordChangeRequest({
            variables: {
                email: trimmedEmail,
            },
        });
    };

    const renderSuccessMessage = () => (
        <UserMessage title={successTitle}>
            <div>{successMessage}</div>
        </UserMessage>
    );

    const renderErrorMessage = () => {
        // Retrieve GraphQL error and render error message.
        const msg = error.graphQLErrors ? error.graphQLErrors[0].message : "";
        // Clear GraphQL error after setting error message.
        error.graphQLErrors = undefined;
        return <span style={{ color: "red", paddingRight: 10 }}>{msg}</span>;
    };

    const renderSubmit = () => (
        <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ flex: "auto", textAlign: "right", paddingRight: 5 }}>
                {isError ? renderErrorMessage() : ""}
            </div>
            <div style={{ float: "right" }}>
                <SubmitButton
                    label="Reset"
                    pending={isPending ? "Resetting..." : null}
                    onClick={handleSubmit}
                    disabled={hasErrors || hasMissing}
                />
            </div>
        </div>
    );

    const renderResetForm = () => (
        <div>
            <h2>Reset Password</h2>
            <hr />
            <Form
                name="basic"
                edit={FormEditStates.ALWAYS}
                labelWidth={200}
                schema={schema}
                value={value}
                onSubmit={handleSubmit}
                onChange={(name, value) => setValue(value)}
                onMissingCountChange={(name, missing) => setHasMissing(missing > 0)}
                onErrorCountChange={(name, errors) => setHasErrors(errors > 0)}
            >
                <TextEdit field="email" width={400} />
            </Form>
            <hr />
            {renderSubmit()}
        </div>
    );

    return wasSubmitted ? renderSuccessMessage() : renderResetForm();
};

/**
 * Used when the user forgets their password. Here they enter their email
 * address and follow instructions in their email. That will take them back
 * to the ResetPassword form.
 */
export const ResetEmail = () => {
    return (
        <Row>
            <Col md={2} />
            <Col md={8}>
                <ResetEmailForm />
            </Col>
            <Col md={2} />
        </Row>
    );
};
