import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Interface } from "./components/interfaces/Interface";
import { InterfaceList } from "./components/interfaces/InterfaceList";
import { InterfaceUtilization } from "./components/interfaces/InterfaceUtilization";
import { Sap } from "./components/saps/Sap";
import { SapList } from "./components/saps/SapList";

const NoMatch = () => (
    <div>
        <h2>Whoops</h2>
        <p>Sorry but this /network URL didn’t find any pages</p>
    </div>
);

const Network = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={`${path}/interfaces/list`}>
                <InterfaceList />
            </Route>

            <Route path={`${path}/devices/:device/interfaces/:iface/utilization`}>
                <InterfaceUtilization />
            </Route>

            <Route path={`${path}/devices/:device/interfaces/:iface`}>
                <Interface />
            </Route>

            <Route path={`${path}/saps/list`}>
                <SapList />
            </Route>

            <Route path={`${path}/devices/:device/saps/:sap`}>
                <Sap />
            </Route>

            <Route path="*">
                <NoMatch />
            </Route>
        </Switch>
    );
};

export default Network;
