/**
 * Handle Django CSRF protection: https://docs.djangoproject.com/en/1.9/ref/csrf/
 * Code from https://github.com/graphql-python/django-graphiql/blob/master/django_graphiql/templates/graphiql/index.html#L22
 */
function getCSRFToken() {
    let csrfToken;
    let cookies = ("; " + document.cookie).split("; csrftoken=");
    if (cookies.length === 2) {
        csrfToken = cookies.pop().split(";").shift();
    }

    return csrfToken;
}

export { getCSRFToken };
