import gql from "graphql-tag";
import React, { useRef } from "react";
import { styler } from "react-timeseries-charts";
import { TimeseriesListAreachartWidget } from "shared/components/widgets";
import { useMemoQuery } from "shared/hooks";
import { combineTimeSeriesLists, toTimeSeriesList } from "shared/utils/timeseries-utils";
import { chartBlue, chartOrange, mutedChartOrange, mutedChartBlue } from "shared/styles/styles";

export const GET_ENTITY_TRAFFIC = gql`
    query getCollaboratorTraffic($collaboratorName: String, $beginTime: String, $endTime: String) {
        networkEntity(shortName: $collaboratorName, entityType: "LHCONE") {
            id
            saps(beginTime: $beginTime, endTime: $endTime) {
                traffic {
                    columns
                    name
                    points
                }
            }
        }
    }
`;

const style = styler([
    { key: "in", color: chartOrange },
    { key: "out", color: chartBlue },
]);

const mutedStyle = styler([
    { key: "in", color: mutedChartOrange },
    { key: "out", color: mutedChartBlue },
]);

// Build a options option which can be passed to the area chart
const TITLE = "Total Collaborator Traffic";
const DESCRIPTION = `
#### Total Traffic\n\n
This chart shows the total traffic flowing in and out of this network entity
based on the SNMP collection of saps facing this LHCONE collaborator. \n
Hovering over the chart with your mouse will provide details about that specific point in time.
`;
const CHANNELS = ["in", "out"];

export const CollaboratorInterfacesChart = ({
    collaboratorName,
    selected,
    timerange,
    timestamp,
    onTrackerChanged,
}) => {
    let totalSeries = useRef(null);
    let seriesList = useRef([]);

    const beginTime = timerange.begin().toISOString();
    const endTime = timerange.end().toISOString();

    const queryOptions = {
        variables: { collaboratorName, beginTime, endTime },
        fetchPolicy: "no-cache",
    };

    const {
        isLoading,
        error,
        data: { timeSeriesList, timeSeries },
    } = useMemoQuery(
        GET_ENTITY_TRAFFIC,
        queryOptions,
        (d) => {
            // Note : Not required since we just show SAP's for LHCONE collaborators
            // const interfaces = toTimeSeriesList(
            //     "interfaces",
            //     d.networkEntity.interfaces,
            //     { name: "Interfaces", channels: CHANNELS },
            //     (d) => d.traffic
            // );
            // Build list of SAP timeseries from GraphQL data
            const saps = toTimeSeriesList(
                "saps",
                d.networkEntity.saps,
                { name: "SAPs", channels: CHANNELS },
                (d) => d.traffic
            );
            // Combine those tegether to get a master list and a total series aggregation of the list
            const { totalSeries: timeSeries, seriesList } = combineTimeSeriesLists([saps], {
                name: "Collaborator saps",
                channels: CHANNELS,
            });

            const timeSeriesList = seriesList;

            return {
                timeSeriesList,
                timeSeries,
            };
        },
        [beginTime, endTime]
    );

    if (timeSeries !== undefined) {
        totalSeries.current = timeSeries;
    }

    if (timeSeriesList !== undefined) {
        seriesList.current = timeSeriesList;
    }

    const errorMsg = error ? "Error: An error occured trying to fetch data" : null;

    return (
        <TimeseriesListAreachartWidget
            title={TITLE}
            description={DESCRIPTION}
            totalSeries={totalSeries.current}
            seriesList={seriesList.current}
            timerange={timerange}
            timestamp={timestamp}
            stackUp={["out"]}
            stackDown={["in"]}
            labels={{ in: "From", out: "To" }}
            selected={selected}
            style={style}
            mutedStyle={mutedStyle}
            onTrackerChanged={onTrackerChanged}
            isLoading={isLoading}
            error={errorMsg}
        />
    );
};
