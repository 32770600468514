import gql from "graphql-tag";
import React, { useRef } from "react";
import { styler } from "react-timeseries-charts";
import { TimeseriesAreachartWidget } from "shared/components/widgets";
import { useMemoQuery } from "shared/hooks";
import { toTimeSeriesNoJSON } from "shared/utils/timeseries-utils";
import { chartBlue, chartOrange } from "shared/styles/styles";
import { useSearchParams } from "shared/hooks/useSearchParams";
import { sidebarRanges } from "shared/constants/time";
import { getChartTimePeriod } from "shared/utils/param-utils";
import { formatDate } from "shared/utils/format-utils";

export const GET_EDGE_TRAFFIC = gql`
    query getEdgeTraffic(
        $edgeName: String
        $edgeType: String
        $beginTime: String
        $endTime: String
    ) {
        mapTopologyEdge(name: $edgeName, edgeType: $edgeType) {
            traffic(beginTime: $beginTime, endTime: $endTime, edgeType: $edgeType)
        }
    }
`;

// Build a options option which can be passed to the area chart
const DESCRIPTION = `
#### Edge Summary Traffic\n\n
This graph shows the traffic flowing across this topology edge. Hovering over the
chart with your mouse will provide details about that specific point in time.
`;

const STYLE = styler([
    { key: "ztoa", color: chartBlue },
    { key: "atoz", color: chartOrange },
]);

const STACK_UP = ["ztoa"];
const STACK_DOWN = ["atoz"];

export const EdgeSummaryChart = ({
    edgeName,
    edgeType,
    timerange,
    timestamp,
    onTrackerChanged,
}) => {
    const beginTime = timerange.begin().toISOString();
    const endTime = timerange.end().toISOString();
    var series = useRef(null);

    const query = useSearchParams();
    const timePeriod = getChartTimePeriod(query, sidebarRanges);
    let title;
    if (typeof timePeriod === "string") {
        title = `Edge Traffic over the last ${timePeriod}`;
    } else {
        title = `Edge Traffic from ${formatDate(timePeriod.begin)} to ${formatDate(
            timePeriod.end
        )}`;
    }

    const queryOptions = {
        variables: { edgeName, edgeType, beginTime, endTime },
        fetchPolicy: "no-cache",
    };

    // prettier-ignore
    const {
        isLoading,
        error,
        data: newSeries,
    } = useMemoQuery(
        GET_EDGE_TRAFFIC,
        queryOptions,
        (d) => {
            // if d.mapTopologyEdge.traffic is defined and truthy
            if (
                Boolean(d) &&
                Boolean(d.mapTopologyEdge) &&
                Boolean(d.mapTopologyEdge.traffic)
            ) {
                // Build list of Interfaces timeseries from GraphQL data
                const ts = JSON.parse(d.mapTopologyEdge.traffic);
                ts["labels"] = ts["labels"].reverse();
                return toTimeSeriesNoJSON(`topologyedge::${edgeName}`, ts);
            } else {
                return null;
            }
        },
        [edgeName, edgeType, beginTime, endTime]
    );

    if (newSeries && newSeries.data !== null) {
        series.current = newSeries;
    }

    return (
        <TimeseriesAreachartWidget
            title={title}
            description={DESCRIPTION}
            series={series.current}
            timerange={timerange}
            timestamp={timestamp}
            onTrackerChanged={onTrackerChanged}
            stackUp={STACK_UP}
            stackDown={STACK_DOWN}
            style={STYLE}
            isLoading={isLoading}
            error={error}
        />
    );
};
