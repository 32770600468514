/**
 * General styles to inline throughout the Portal
 */

export const headerStyle = {
    fontSize: 25,
    fontWeight: 400,
    color: "#222",
    marginTop: 0,
    marginBottom: 5,
};

export const breadcrumbStyle = {
    color: "#337AB7",
    textDecoration: "uppercase",
    fontSize: 12,
    marginBottom: 5,
};

export const selectionColor = "#37B6D3";
export const esnetLightGrey = "#C9CACC";
export const esnetDarkGrey = "#6D6E71";
export const esnetBrightBlue = "#4EC1E0";
export const esnetDarkBlue = "#003A5D";
export const esnetOrange = "#FF4E00";

export const chartBlue = "#448FDD";
export const chartOrange = "#FD8D0D";
export const mutedChartBlue = "#AECFE4";
export const mutedChartOrange = "#FFD0A3";

export const legendInTraffic = "#448FDD";
export const legendOutTraffic = "#FEB068";
export const legendErrors = "#E68885";
export const legendDiscards = "#9467BD";

export const inTraffic = "#4E94DF";
export const outTraffic = "#FD9E43";
export const inErrors = "#D9534F";
export const outErrors = "#D9534F";
export const inDiscards = "#9467BD";
export const outDiscards = "#9467BD";

export const oscarsTraffic = "#4EC1E0";
export const lhconeTraffic = "#1C96B8";
export const otherTraffic = "#D0D0D0";

export const labelStyle = {
    fontSize: 14,
    textTransform: "uppercase",
    color: "rgb(51, 51, 51)",
    opacity: 0.7,
    paddingRight: 8,
    userSelect: "none",
};

export const dropdownStyle = {
    whiteSpace: "nowrap",
};

export const sectionHeaderStyle = {
    // fontWeight: 200,
    // textTransform: "uppercase",
    fontSize: 16,
    color: "rgb(51, 51, 51)",
    opacity: 0.7,
    marginTop: 0,
};

export const timeRangeBkg = {
    backgroundColor: "#ECECED",
    padding: "5px",
};

export const mapOptionsHeaderStyle = {
    fontSize: 21,
    fontWeight: 400,
    color: "#222",
    fontFamily: "Roboto, sans-serif",
    paddingRight: "10px",
    cursor: "pointer",
    paddingLeft: "0px",
    overflow: "visible",
};

export const linkStyle = {
    padding: 5,
    color: "rgb(51, 51, 51)",
    fontFamily: "Roboto, sans-serif",
    opacity: 0.7,
    fontWeight: 400,
    cursor: "pointer",
    fontSize: "14px",
};

export const moreInfoLinkStyle = {
    color: "rgb(51, 51, 51)",
    opacity: 0.7,
    fontWeight: 200,
    cursor: "pointer",
};

export const linkStyleActive = {
    padding: 5,
    color: "#4DC1E1",
    fontWeight: 400,
};

export const linkStyleActiveClickable = {
    padding: 5,
    color: "#4DC1E1",
    fontWeight: 400,
    cursor: "pointer",
};

export const tagStyle = {
    border: "1px solid #DDD",
    borderRadius: "4px",
    padding: "2px 3px",
    fontSize: "smaller",
};

export const filterLabelStyle = {
    textTransform: "uppercase",
    color: "#5F5A5A",
    fontSize: 12,
};

export const cardStyle = {
    borderLeftWidth: 6,
    borderLeftStyle: "solid",
    borderLeftColor: "#EEE",
    padding: 4,
    paddingLeft: 6,
    marginTop: 10,
    marginBottom: 10,
};

export const cardStyleActive = {
    borderLeftWidth: 6,
    borderLeftStyle: "solid",
    borderLeftColor: "rgb(78, 193, 224)",
    padding: 4,
    paddingLeft: 6,
    marginTop: 10,
    marginBottom: 10,
};

export const cardStyleError = {
    borderLeftWidth: 6,
    borderLeftStyle: "solid",
    borderLeftColor: "#A93A3A",
    padding: 4,
    paddingLeft: 6,
    marginTop: 10,
    marginBottom: 10,
};

export const cardInterfacesStyle = {
    marginTop: 15,
    borderTopStyle: "solid",
    borderTopWidth: 1,
    borderTopColor: "#D1D1D1",
    fontSize: 13,
};

export const infoStyle = {
    // background: "#f7f7f7",
    // padding: 10,
    // borderRadius: 10,
};

export const topicStyle = { fontWeight: 100, color: "gray" };

export const clearSelectionButtonStyle = {
    textAlign: "right",
    color: "#9C9C9C",
    fontSize: "0.8em",
    border: "1px solid #E4E4E4",
    padding: "1px 7px",
    background: "#F9F9F9",
    marginBottom: "4px",
    float: "right",
    borderRadius: "4px",
    cursor: "default",
};

export const closeWidgetStyle = {
    color: "white",
    background: "#C7C7C7",
    borderRadius: "10px",
    padding: "2px 3px 3px 3px",
    marginRight: "0.5em",
    lineHeight: "8px",
    display: "inline-block",
    cursor: "default",
};

export const totalRowCountStyle = {
    fontWeight: 400,
};

export const rowCountStyle = {
    display: "block",
    marginBottom: "1.1em",
    paddingTop: "0.3em",
    paddingBottom: "0.3em",
    paddingRight: "0.6em",
    paddingLeft: "0.6em",
    backgroundColor: "#F7F7F7",
    border: "1px solid #DDD",
    borderRadius: "3px",
    fontSize: "0.8rem",
    fontWeight: 700,
};

export const starStyle = {
    marginLeft: "5px",
    marginBottom: "10px",
    cursor: "pointer",
    width: "0.8em",
    height: "0.9em",
};

export const mapLegendStyle = {
    fontSize: 12,
};

export const trackerTimeStyle = {
    fontSize: 13,
    color: "rgb(51, 51, 51)",
    opacity: 0.7,
    borderRightStyle: "none",
    borderRightWidth: 1,
    bordeRightColor: "#D0D0D0",
    paddingRight: 10,
    height: 30,
};

export const buttonContainer = {
    display: "flex",
    width: "100%",
    gridGap: "8px",
};

const buttonBase = {
    paddingLeft: "26px",
    paddingRight: "26px",
    paddingTop: "7px",
    paddingBottom: "6px",
    border: "none",
    cursor: "pointer",
};

export const primaryButtonEnabled = {
    ...buttonBase,
    backgroundColor: esnetBrightBlue,
    color: "#FFF",
};

export const primaryButtonDisabled = {
    ...buttonBase,
    backgroundColor: "#ECECED",
    color: "#FFF",
    cursor: "default",
};

export const secondaryButtonEnabled = {
    ...buttonBase,
    backgroundColor: "#999",
    color: "#FFF",
};

export const secondaryButtonDisabled = {
    ...buttonBase,
    backgroundColor: "#ECECED",
    color: "#FFF",
    cursor: "default",
};
