import React from "react";
import { Col, Row } from "react-bootstrap";
import { useEntitySelection } from "shared/hooks";
import { EdgeCard } from "app/edges/components/EdgeCard";
import { SiteCard } from "app/sites/components/SiteCard";
import { HubCard } from "app/sites/components/HubCard";
import { sectionHeaderStyle } from "shared/styles/styles";

export const DashboardSelection = () => {
    const [mapSelection] = useEntitySelection();
    const { selectionType, selection, edgeType } = mapSelection;

    let selectedCard;
    if (selectionType === "edge") {
        selectedCard = (
            <Col md={12} key="1">
                <EdgeCard edgeName={selection} edgeType={edgeType} isSelected={true} />
            </Col>
        );
    } else if (selectionType === "esnet_site") {
        selectedCard = (
            <Col md={12}>
                <SiteCard siteName={selection} isSelected={true} />
            </Col>
        );
    } else if (selectionType === "hub") {
        selectedCard = (
            <Col md={12}>
                <HubCard hubName={selection} isSelected={true} />
            </Col>
        );
    } else {
        selectedCard = (
            <Col md={12}>
                <div />
            </Col>
        );
    }

    return (
        <div>
            <h4 style={sectionHeaderStyle}>SELECTION</h4>
            <Row>{[selectedCard]}</Row>
        </div>
    );
};
