import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Spinner } from "shared/components/controls";
import { useUser } from "shared/hooks";
import { UPDATE_FAVORITE_MUTATION, star, setHtmlTitle } from "shared/utils/entity-utils";

export const GET_FACILITY_INFO_QUERY = gql`
    query getFacilityInfo($shortName: String) {
        networkEntity(shortName: $shortName, entityType: "Facility") {
            id
            type
            shortName
            fullName
            website
            description
            parent {
                fullName
                shortName
                website
            }
            children {
                fullName
                shortName
                website
            }
            programArea
            programOffice {
                shortName
            }
            hasFlow
            hasSnmp
        }
    }
`;

/**
 * Render a the facility title only
 */
export const FacilityTitle = ({ facilityName }) => {
    // Get the user profile, containing the favorites
    const user = useUser();

    // Get the facility info
    const { loading, data } = useQuery(GET_FACILITY_INFO_QUERY, {
        variables: { shortName: facilityName },
    });

    // Mutation for adding or removing current facility from the user's favorites
    const [updateFavorite] = useMutation(UPDATE_FAVORITE_MUTATION);

    if (loading || !data) {
        return <div height={36} />;
    } else if (data) {
        const facility = data.networkEntity;
        // Set HTML title tag
        setHtmlTitle("Facility", facility.fullName);

        return (
            <Row>
                <Col md={12}>
                    <h2>
                        {user && !user.isAnonymous ? (
                            <span>{star([updateFavorite], user, facility, "Facility")}</span>
                        ) : (
                            ""
                        )}{" "}
                        {facility.fullName}
                    </h2>
                </Col>
            </Row>
        );
    } else {
        return <div />;
    }
};

export const FacilitySummaryInfo = ({ facilityName }) => {
    // Load facility info
    const { loading, data } = useQuery(GET_FACILITY_INFO_QUERY, {
        variables: { shortName: facilityName },
    });

    if (loading || !data) {
        return <Spinner />;
    } else {
        const { networkEntity: facility } = data;
        return (
            <div>
                <dl className="row">
                    <dt className="col-sm-1">Site</dt>
                    <dd className="col-sm-11">
                        {facility.parent != null ? (
                            <a
                                href={`/sites/view/${facility.parent.shortName}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {facility.parent.fullName}
                            </a>
                        ) : (
                            ""
                        )}
                    </dd>

                    <dt className="col-sm-1">Website</dt>
                    <dd className="col-sm-11">
                        <a href={facility.website} target="_blank" rel="noopener noreferrer">
                            {facility.website}
                        </a>
                    </dd>

                    <dt className="col-sm-1">Description</dt>
                    <dd className="col-sm-11">{facility.description}</dd>
                </dl>
            </div>
        );
    }
};
