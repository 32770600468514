import React from "react";
import _ from "underscore";
import { MapLegend, Resizable } from "react-network-diagrams";

import { edgeColorMap } from "shared/styles/map-styles";
import { mapLegendStyle } from "shared/styles/styles";

const DashboardLegend = ({ height, itemsPerColumn, columnWidth = 95, nodeLegendStyle = [] }) => {
    let nodeLegendData = [];
    if (nodeLegendStyle.length === 0) {
        nodeLegendData = [
            {
                color: "#D0D0D0",
                label: "ESnet Site",
                classed: "esnet_site",
                radius: 7,
                shape: "square",
            },
            { color: "#999999", label: "Hub", classed: "hub", radius: 7, shape: "circle" },
        ];
    } else {
        nodeLegendData = nodeLegendStyle;
    }

    // Map the values in the legend to line thickness
    // TODO: does this need to be here?
    // const edgeTypes = _.map(edgeThicknessMapLg, (width, name) => {
    //     return {
    //         text: name,
    //         strokeWidth: width,
    //     };
    // });

    const colorSwatches = _.map(edgeColorMap, (color) => {
        return {
            text: color.label,
            stroke: color.color,
            fill: color.color,
        };
    });

    const nodeTypes = _.map(nodeLegendData, (nodeInfo) => {
        return {
            text: nodeInfo.label,
            stroke: nodeInfo.color,
            fill: nodeInfo.color,
            radius: nodeInfo.radius,
            classed: nodeInfo.classed,
            shape: nodeInfo.shape,
        };
    });

    return (
        <Resizable>
            <svg height={height} style={mapLegendStyle}>
                <MapLegend
                    x={9}
                    y={9}
                    itemsPerColumn={itemsPerColumn}
                    // edgeTypes={edgeTypes}
                    nodeTypes={nodeTypes}
                    colorSwatches={colorSwatches}
                    edgeColor={"#6D6E71"}
                    columnWidth={columnWidth}
                    exampleWidth={20}
                />
            </svg>
        </Resizable>
    );
};

export default DashboardLegend;
