import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { OscarsList } from "./components/OscarsList";
import { OscarsView } from "./components/OscarsView";

const NoMatch = () => (
    <div>
        <h2>Whoops</h2>
        <p>Sorry but this OSCARS URL didn’t find any pages</p>
    </div>
);

const Oscars = () => {
    let { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={`${path}/list`}>
                <OscarsList />
            </Route>
            <Route path={`${path}/view/:connectionId?`}>
                <OscarsView />
            </Route>
            <Route path="*">
                <NoMatch />
            </Route>
        </Switch>
    );
};

export default Oscars;
