import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import Immutable from "immutable";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Field, Form, FormEditStates, Schema, TextEdit } from "react-dynamic-forms";
import { SubmitButton, UserMessage } from "shared/components/controls";
import { useSearchParams } from "shared/hooks";
import { setHtmlTitle } from "shared/utils/entity-utils";

export const REGISTER_USER_MUTATION = gql`
    mutation RegisterUser(
        $firstname: String
        $lastname: String
        $email: String
        $password: String
    ) {
        registerUser(
            firstName: $firstname
            lastName: $lastname
            email: $email
            password: $password
        ) {
            success
            message
        }
    }
`;

const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    password1: "",
    password2: "",
};

const schema = (
    <Schema>
        <Field
            name="firstName"
            label="First name"
            placeholder="Enter first name"
            required={true}
            validation={{ type: "string" }}
        />
        <Field
            name="lastName"
            label="Last name"
            placeholder="Enter last name"
            required={true}
            validation={{ type: "string" }}
        />
        <Field
            name="email"
            label="Email"
            placeholder="Enter valid email address"
            required={true}
            validation={{ format: "email" }}
        />
        <Field name="password1" label="Password" placeholder="Password" required={true} />
        <Field name="password2" label="Re-enter password" placeholder="Password" required={true} />
    </Schema>
);

const RegisterForm = () => {
    const [value, setValue] = useState(Immutable.fromJS(initialValues));
    const [hasMissing, setHasMissing] = useState(true);
    const [hasErrors, setHasErrors] = useState(true);
    const [wasSubmitted, setSubmitted] = useState(false);

    const [registerUser, { loading, error }] = useMutation(REGISTER_USER_MUTATION, {
        onError: () => {
            // Handle GraphQL errors. Errors are returned in the 'error' object.
        },
        onCompleted: () => {
            setSubmitted(true);
        },
    });

    const isPending = !!loading;
    const isError = !!error;

    const handleSubmit = (e) => {
        e.preventDefault();
        registerUser({
            variables: {
                firstname: value.get("firstName"),
                lastname: value.get("lastName"),
                email: value.get("email"),
                password: value.get("password1"),
            },
        });
    };

    const renderSuccessMessage = () => {
        return (
            <UserMessage title="Your registration request has been submitted">
                You will receive an email from tools@es.net with an activation URL. Please visit the
                URL to activate your account. Check your spam filters if you don't see the email
                right away. If you haven't received the email after 15-20 minutes please contact
                trouble@es.net.
            </UserMessage>
        );
    };

    const renderError = () => {
        let msg;
        const pwd1 = value.get("password1");
        const pwd2 = value.get("password2");
        const isPasswordMismatch = pwd1 !== pwd2 && pwd2 !== "";

        if (isPasswordMismatch) {
            msg = "Passwords do not match.";
        } else if (hasErrors) {
            msg = "The registration form has errors.";
        } else if (isError) {
            // Retrieve GraphQL error and set message to be rendered.
            msg = error.graphQLErrors ? error.graphQLErrors[0].message : "";
            // Clear it after setting error message.
            error.graphQLErrors = undefined;
        }

        if (msg) {
            return (
                <span key={msg} style={{ color: "red", paddingLeft: 10 }}>
                    {msg}
                </span>
            );
        } else {
            return <span />;
        }
    };

    const renderSubmit = () => {
        const pwd1 = value.get("password1");
        const pwd2 = value.get("password2");
        const passwordsNotEqual = pwd1 !== pwd2;

        const disableSubmitButton = hasErrors || hasMissing || passwordsNotEqual;

        return (
            <div style={{ float: "right" }}>
                <span style={{ color: "red", paddingRight: 10 }}>{renderError()}</span>
                <SubmitButton
                    label="Register"
                    pending={isPending ? "Submitting..." : null}
                    onClick={handleSubmit}
                    disabled={disableSubmitButton}
                />
            </div>
        );
    };

    const renderForm = () => {
        // Set HTML title tag
        setHtmlTitle("Registration");

        return (
            <div>
                <h2>Registration</h2>
                <hr />
                <Form
                    name="basic"
                    edit={FormEditStates.ALWAYS}
                    labelWidth={200}
                    schema={schema}
                    value={value}
                    onSubmit={handleSubmit}
                    onChange={(name, value) => setValue(value)}
                    onMissingCountChange={(name, missing) => setHasMissing(missing > 0)}
                    onErrorCountChange={(name, errors) => setHasErrors(errors > 0)}
                >
                    <TextEdit field="firstName" width={200} />
                    <TextEdit field="lastName" width={200} />
                    <TextEdit field="email" width={400} />
                    <TextEdit field="password1" type="password" width={200} />
                    <TextEdit field="password2" type="password" width={200} />
                </Form>
                <hr />
                {renderSubmit()}
            </div>
        );
    };

    return <div>{wasSubmitted ? renderSuccessMessage() : renderForm()}</div>;
};

export const Register = () => {
    const searchParams = useSearchParams();
    return (
        <Row>
            <Col md={2} />
            <Col md={8}>
                <RegisterForm redirect={searchParams.get("redirect")} />
            </Col>
            <Col md={2} />
        </Row>
    );
};
