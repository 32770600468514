import { format } from "d3-format";
import gql from "graphql-tag";
import React, { useState, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { Legend, styler } from "react-timeseries-charts";
import { TimePicker } from "shared/components/controls";
import { MultiChannelChartWidget } from "shared/components/widgets";
import { useTimeRange, useMemoQuery } from "shared/hooks";
import { breadcrumbStyle } from "shared/styles/styles";
import { TrackerTime } from "shared/components/widgets/components/TrackerTime";
import _ from "underscore";
import { SapInfo } from "./SapInfo";
import { TimeSeries } from "pondjs";
import { legendInTraffic, legendOutTraffic } from "shared/styles/styles";
import { setHtmlTitle } from "shared/utils/entity-utils";

// Refresh the page every minute
const EVERY_60_SECONDS = 60 * 1000;

const FETCH_SAP_TRAFFIC = gql`
    query getSAPTraffic($device: String, $sap: String, $beginTime: String, $endTime: String) {
        sap(device: $device, sap: $sap) {
            traffic(beginTime: $beginTime, endTime: $endTime) {
                name
                columns
                points
            }
        }
    }
`;

const Sap = () => {
    const { device, sap: sapName, page = "traffic" } = useParams();
    const sap = decodeURIComponent(sapName);
    let trafficSeries = useRef(null);

    // Set HTML title tag
    const pageTitle = device && sap ? `${device} ${sap}` : ``;
    setHtmlTitle("SAP", pageTitle);

    const [values, setValues] = useState({});
    const [timestamp, setTimestamp] = useState(null);

    // Timerange for the page
    const [timerange, setTimerange] = useTimeRange(EVERY_60_SECONDS);

    const beginTime = timerange.begin().toISOString();
    const endTime = timerange.end().toISOString();

    const queryOptions = {
        variables: { device, sap, beginTime, endTime },
        fetchPolicy: "no-cache",
    };

    const { isLoading: trafficStatus, data: traffic } = useMemoQuery(
        FETCH_SAP_TRAFFIC,
        queryOptions,
        (d) => {
            return new TimeSeries(d.sap.traffic);
        },
        [beginTime, endTime]
    );

    if (traffic && traffic.data !== null) {
        trafficSeries.current = traffic;
    }

    const renderSAPHeader = (device, sap) => {
        return (
            <div>
                <div style={breadcrumbStyle}>
                    <a href="/">HOME</a>
                    <span> › </span>
                    <a href="/network/saps/list">SAPS</a>
                    <span> » </span>
                </div>
                <h2>{pageTitle}</h2>
            </div>
        );
    };

    const renderDetails = (device, sap) => {
        return <SapInfo device={device} sap={sap} />;
    };

    const renderLegend = () => {
        const fmt4s = format(".4s");

        const categories = [
            {
                key: "in",
                label: "Traffic (in)",
                value:
                    values && _.has(values, "traffic") ? `${fmt4s(values.traffic.in)}bps` : "- bps",
            },
            {
                key: "out",
                label: "Traffic (out)",
                value:
                    values && _.has(values, "traffic")
                        ? `${fmt4s(values.traffic.out)}bps`
                        : "- bps",
            },
        ];

        const style = styler([
            { key: "in", color: legendInTraffic },
            { key: "out", color: legendOutTraffic },
        ]);

        return <Legend categories={categories} style={style} />;
    };

    const renderChart = () => {
        // Defines the layout of the chart, which is pretty simple in the case of a SAP traffic chart
        const chartLayout = [
            {
                height: 200,
                charts: [
                    {
                        id: "traffic",
                        series: trafficSeries.current,
                        type: "area",
                        label: "Traffic (bps)",
                        colors: ["#4A92DE", "#FD9420"],
                        mode: "up-down",
                        interpolation: "curveLinear",
                    },
                ],
            },
        ];

        const isLoading = trafficStatus === true;

        return (
            <MultiChannelChartWidget
                layout={chartLayout}
                title="SAP traffic"
                description="About this chart"
                timeRange={timerange}
                onTrackerChange={(ts) => setTimestamp(ts)}
                onTimeRangeChange={setTimerange}
                onValuesChange={setValues}
                loading={isLoading}
            />
        );
    };

    // NavItems
    const subSections = [];
    subSections.push({
        key: "Traffic",
        label: "Traffic",
        url: `/network/devices/${encodeURIComponent(device)}/saps/${encodeURIComponent(
            sap
        )}/traffic}`,
    });

    let active;
    if (page === "utilization") {
        active = "Utilization";
    } else if (page === "traffic" || page === "undefined") {
        active = "Traffic";
    }

    const navItems = _.map(subSections, (section) => {
        const className = active === section.label ? "active" : "";
        const key = section.key;
        return (
            <li key={key} role="presentation" className={className}>
                <Link to={section.url}>{section.label}</Link>
            </li>
        );
    });

    return (
        <Col>
            <Row>
                <Col>
                    <Row>
                        <Col md={7}>{renderSAPHeader(device, sap)}</Col>
                        <Col md={5} style={{ marginTop: 20 }}>
                            <ul className="nav nav-pills" style={{ float: "right" }}>
                                {navItems}
                            </ul>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row>
                <Col>
                    <hr />
                </Col>
            </Row>

            <Row>
                <Col>{renderDetails(device, sap)}</Col>
            </Row>

            <Row>
                <Col>
                    <hr />
                </Col>
            </Row>

            <Row>
                <Col md={4} style={{ textAlign: "left" }}>
                    {renderLegend()}
                </Col>
                <Col md={4} style={{ textAlign: "center" }}>
                    <TrackerTime time={timestamp} series={traffic} />
                </Col>
                <Col md={4} style={{ textAlign: "right" }}>
                    <TimePicker />
                </Col>
            </Row>

            <Row>
                <Col md={12}>{renderChart(timerange, traffic, traffic, traffic)}</Col>
            </Row>
        </Col>
    );
};

export { Sap };
