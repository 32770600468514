import React from "react";
import _ from "underscore";
import {
    computePercentDifference,
    dataForMonth,
    getEarlierYearMonth,
} from "shared/utils/volume-utils";
import { TrafficVolumeRow, TrafficVolumeTable } from "./TrafficVolumeTable";

const TOTAL = "#222";

export default ({ currentMonth, data, overlayNames, overlayColors }) => {
    const monthData = dataForMonth(data, currentMonth);
    const prevMonth = getEarlierYearMonth(currentMonth, 1);
    const prevYear = getEarlierYearMonth(currentMonth, 12);
    const prevMonthData = dataForMonth(data, prevMonth);
    const prevYearData = dataForMonth(data, prevYear);

    const totalThisMonth = monthData ? monthData.get("total_in") : "";
    const totalLastMonth = prevMonthData ? prevMonthData.get("total_in") : "";
    const totalLastYear = prevYearData ? prevYearData.get("total_in") : "";

    let overlayTotal = 0;
    let overlayTotalLastMonth = 0;
    let overlayTotalLastYear = 0;
    let monthDifference;
    let yearDifference;

    let breakdownRows = [];

    _.each(overlayNames, (name) => {
        const key = `${name.toLowerCase()}_in`;
        if (monthData) {
            const bytes = monthData.get(key);
            if (!_.isNull(bytes)) {
                const percent = (bytes / totalThisMonth) * 100;
                const bytesLastMonth = prevMonthData.get(key);
                const bytesLastYear = prevYearData.get(key);
                monthDifference = computePercentDifference(bytes, bytesLastMonth);
                yearDifference = computePercentDifference(bytes, bytesLastYear);

                breakdownRows.push(
                    <TrafficVolumeRow
                        key={name}
                        name={name}
                        color={overlayColors[name]}
                        bytes={bytes}
                        percent={percent}
                        monthDifference={monthDifference}
                        yearDifference={yearDifference}
                    />
                );
                overlayTotal += bytes;
                overlayTotalLastMonth += bytesLastMonth;
                overlayTotalLastYear += bytesLastYear;
            }
        }
    });

    if (breakdownRows.length > 0) {
        const bytes = totalThisMonth ? totalThisMonth - overlayTotal : "";
        const percent = totalThisMonth ? (bytes / totalThisMonth) * 100 : "";
        monthDifference = totalLastMonth
            ? computePercentDifference(bytes, totalLastMonth - overlayTotalLastMonth)
            : "";
        yearDifference = totalLastYear
            ? computePercentDifference(bytes, totalLastYear - overlayTotalLastYear)
            : "";

        const name = "Normal traffic";
        breakdownRows.push(
            <TrafficVolumeRow
                key={name}
                name={name}
                bytes={bytes}
                color={"#D0D0D0"}
                percent={percent}
                monthDifference={monthDifference}
                yearDifference={yearDifference}
            />
        );
    }

    monthDifference = totalLastMonth
        ? computePercentDifference(totalThisMonth, totalLastMonth)
        : "";
    yearDifference = totalLastYear ? computePercentDifference(totalThisMonth, totalLastYear) : "";
    breakdownRows.push(
        <TrafficVolumeRow
            key={"Total"}
            name={"Total"}
            color={TOTAL}
            bytes={totalThisMonth}
            percent={null}
            monthDifference={monthDifference}
            yearDifference={yearDifference}
        />
    );

    return <TrafficVolumeTable rows={breakdownRows} />;
};
