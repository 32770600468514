import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { SubmitButton } from "shared/components/controls";
import { useSearchParams } from "shared/hooks";
import { setHtmlTitle } from "shared/utils/entity-utils";

export const LOGIN_USER_MUTATION = gql`
    mutation LoginUser($email: String, $password: String) {
        loginUser(email: $email, password: $password) {
            success
            user {
                email
                firstName
                lastName
                isAnonymous
                favoriteNetworkEntities {
                    id
                    shortName
                    fullName
                    type
                }
                viewTopTalkers {
                    id
                    shortName
                    fullName
                }
            }
        }
    }
`;

const LoginForm = ({ redirect }) => {
    // Set HTML title tag
    setHtmlTitle("Login");

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [loginUser, { loading, error }] = useMutation(LOGIN_USER_MUTATION, {
        onError: () => {
            // Handle GraphQL errors. Errors are returned in the 'error' object.
        },
        onCompleted: () => {
            // If the login is complete, reload the page at the redirect
            // url, or just to the portal front page if no redirect supplied
            window.location.assign(redirect || "/");
        },
    });

    const isPending = !!loading;
    const isError = !!error;

    const handleSubmit = (e) => {
        e.preventDefault();

        const trimmedEmail = email.trim();
        const trimmedPassword = password.trim();
        if (!trimmedEmail || !trimmedPassword) {
            return;
        }

        loginUser({
            variables: {
                email: trimmedEmail,
                password: trimmedPassword,
            },
        });
    };

    const renderErrorMessage = () => {
        // Retrieve GraphQL error and render error message.
        const msg = error.graphQLErrors ? error.graphQLErrors[0].message : "";
        // Clear GraphQL error after setting error message.
        error.graphQLErrors = undefined;
        return <div style={{ color: "red", paddingLeft: 10 }}>{msg}</div>;
    };

    /**
     * If Enter key was pressed, submit Login form
     */
    const checkSubmit = (e) => {
        if (e && e.key === "Enter") {
            handleSubmit(e);
        }
    };

    const renderLoginForm = () => {
        return (
            <div>
                <h2>Login</h2>
                <hr />
                <form style={{ paddingTop: 30 }} onKeyDown={(e) => checkSubmit(e)}>
                    <Form.Group controlId="formControlsEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Email"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="formControlsPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Form.Group>
                    <div style={{ marginTop: 20, float: "right" }}>
                        <SubmitButton
                            label="Login"
                            pending={isPending ? "Logging in..." : null}
                            onClick={(e) => handleSubmit(e)}
                        />
                    </div>
                    {isError ? renderErrorMessage() : ""}
                </form>
            </div>
        );
    };

    return renderLoginForm();
};

/**
 * The main user login page. Optionally takes a redirect query string parameter
 * which we'll navigate to after the user logs in.
 */
export const Login = () => {
    const searchParams = useSearchParams();
    const redirect = searchParams.get("redirect");
    return (
        <Row>
            <Col md={4} />
            <Col md={4}>
                <LoginForm redirect={redirect} />
                <hr />
                Forgot your password? <a href="/user/reset_email">Reset it.</a>
                <br />
                New User? <a href="/user/register">Register here</a>
            </Col>
            <Col md={4} />
        </Row>
    );
};
