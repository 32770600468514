import gql from "graphql-tag";
import React, { useRef } from "react";
import TimeSeriesListBarChartWidget from "shared/components/widgets/TimeseriesListBarchartWidget";
import { useMemoQuery } from "shared/hooks";
import { chartBlue, chartOrange, selectionColor } from "shared/styles/styles";
import { toTimeSeriesList } from "shared/utils/timeseries-utils";

export const FLOW_BREAKDOWN_TRAFFIC_QUERY = gql`
    query getSiteFlowTraffic(
        $siteName: String
        $breakdown: String
        $beginTime: String
        $endTime: String
    ) {
        networkEntity(shortName: $siteName, entityType: "ESnet Site") {
            id
            flow(breakdown: $breakdown, beginTime: $beginTime, endTime: $endTime) {
                traffic
            }
        }
    }
`;

const STYLE = {
    in: { fill: chartBlue },
    out: { fill: chartOrange },
};

const DESCRIPTION = `
#### Traffic breakdown (by flow type)\n\n
After filtering the traffic flowing in and out of this network entity, 
this chart groups the top flows by the breakdown selected such as 
the protocol, country, facility etc. in descending order of volume. 

The set of values returned is then used by the Total Site Traffic chart above to 
display the individual incoming and outgoing traffic.

For example, in the case of the Autonomous Systems (origin) breakdown, we group the top ASN's in 
descending order of the total volume of flows. For a given ASN, we include all the source and destination 
flows caputred under the ASN in that timerange.
`;

const CHANNELS = ["in", "out"];

export const SiteFlowBarChart = ({
    title,
    siteName,
    timerange,
    timestamp,
    breakdown,
    selected,
    onSelectionChanged,
}) => {
    const beginTime = timerange.begin().toISOString();
    const endTime = timerange.end().toISOString();
    let totalSeries = useRef(null);
    let seriesList = useRef([]);

    const queryOptions = {
        variables: { siteName, breakdown, beginTime, endTime },
        fetchPolicy: "no-cache",
    };

    const {
        isLoading,
        isReloading,
        error,
        data: { timeSeriesList, timeSeries },
    } = useMemoQuery(
        FLOW_BREAKDOWN_TRAFFIC_QUERY,
        queryOptions,
        (d) => {
            const { totalSeries, seriesList } = toTimeSeriesList(
                "traffic",
                d.networkEntity.flow,
                { name: "Flow", channels: CHANNELS },
                (d) => JSON.parse(d.traffic)
            );

            return {
                timeSeriesList: seriesList,
                timeSeries: totalSeries,
            };
        },
        [beginTime, endTime, breakdown]
    );

    const chartTitle = title
        ? title
        : breakdown && !isReloading
        ? `Top flows by ${breakdown}`
        : `Top flows`;

    // Pass a list of fields to be shown in the barchart
    let extraFields = [];
    if (breakdown === "ip_version") {
        extraFields = [
            {
                name: "unique_ip_count", // name of the column
                width: "150px", // width of the column
                label: "unique addresses", // text to append for the label
                widthWhenSelected: "143px", // width of the column when row is selected
            },
        ];
    }

    if (timeSeries !== undefined) {
        totalSeries.current = timeSeries;
    }

    if (timeSeriesList !== undefined) {
        seriesList.current = timeSeriesList;
    }

    let errMsg;
    // GraphQL errored
    if (error) {
        errMsg = "Unable to load flow data from server";
    }

    // GraphQL returned an empty list, which probably means Arbor can't look this up
    if (!isLoading && !error && timeSeriesList && timeSeriesList.length === 0) {
        errMsg = "Top Flows data returned by server was empty";
    }

    // Sorting by name since the list returned by GraphQL is in descending order of total volume
    return (
        <TimeSeriesListBarChartWidget
            title={chartTitle}
            description={DESCRIPTION}
            selected={selected}
            onSelectionChanged={onSelectionChanged}
            selectionColor={selectionColor}
            seriesList={seriesList.current}
            totalSeries={totalSeries.current}
            style={STYLE}
            timerange={timerange}
            timestamp={timestamp}
            isLoading={isLoading}
            error={errMsg}
            sortBy={"name"}
            extraFields={extraFields}
        />
    );
};
