import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { ChoiceSelector, TimePicker } from "shared/components/controls";
import { useQueryState, useTimeRange } from "shared/hooks";
import { infoStyle } from "shared/styles/styles";
import { SiteFlowBarChart } from "./SiteFlowBarChart";
import { SiteFlowChart } from "./SiteFlowChart";
import { SiteSummaryInfo } from "./SiteInfo";
import { HOUR, DAY, EVERY_60_SECONDS } from "shared/constants/time";

const FLOW_BREAKDOWNS = [
    { key: "protocol", label: "Protocol" },
    { key: "application", label: "Applications" },
    { key: "as_origin", label: "Autonomous Systems (origin)" },
    { key: "as_peer", label: "Autonomous Systems (peer)" },
    { key: "country", label: "Country" },
    { key: "site", label: "Other Sites" },
    { key: "facility", label: "Facilities" },
    { key: "ip_version", label: "IP Version" },
];

const TIME_RANGES = [
    { key: "1h", label: "1h", duration: 1 * HOUR },
    { key: "6h", label: "6h", duration: 6 * HOUR },
    { key: "1d", label: "day", duration: DAY },
    { key: "7d", label: "week", duration: 7 * DAY },
    { key: "30d", label: "month", duration: 30 * DAY },
];

/**
 * Renders a flow page
 */
export const SiteFlow = ({ site: siteName }) => {
    // Tracker position for the page
    const [timestamp, setTimestamp] = useState(null);

    // Timerange as determined by the URL search params and auto-updating
    const [timerange] = useTimeRange(EVERY_60_SECONDS, TIME_RANGES, "1d");

    // Selected flow within the breakdown
    const [selected, setSelected] = useQueryState("s", null);

    // Breakdown option selection
    const [breakdown] = useQueryState("by", "protocol");

    return (
        <Col md={12}>
            <Row style={infoStyle}>
                <Col>
                    <SiteSummaryInfo siteName={siteName} />
                </Col>
            </Row>
            <hr />
            <Row>
                <Col md={6}>
                    <div style={{ float: "left" }}>
                        <ChoiceSelector
                            label="BREAKDOWN"
                            variableName="by"
                            defaultValue="protocol"
                            options={FLOW_BREAKDOWNS}
                        />
                    </div>
                </Col>
                <Col md={6}>
                    <div style={{ float: "right" }}>
                        <TimePicker ranges={TIME_RANGES} defaultValue="1d" />
                    </div>
                </Col>
            </Row>

            <hr />

            <Row>
                <Col md={12}>
                    <Row>
                        <Col md={12}>
                            <SiteFlowChart
                                siteName={siteName}
                                breakdown={breakdown}
                                timerange={timerange}
                                timestamp={timestamp}
                                selected={selected}
                                onTrackerChanged={(ts) => setTimestamp(ts)}
                            />
                        </Col>
                    </Row>

                    <hr />

                    <Row>
                        <Col md={12}>
                            <SiteFlowBarChart
                                siteName={siteName}
                                breakdown={breakdown}
                                timerange={timerange}
                                timestamp={timestamp}
                                selected={selected}
                                onSelectionChanged={(s) => setSelected(s)}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Col>
    );
};
