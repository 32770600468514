import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Spinner } from "shared/components/controls";
import { useUser } from "shared/hooks";
import { UPDATE_FAVORITE_MUTATION, star, setHtmlTitle } from "shared/utils/entity-utils";

export const GET_SITE_INFO_QUERY = gql`
    query getSiteInfo($shortName: String) {
        networkEntity(shortName: $shortName, entityType: "ESnet Site") {
            id
            type
            shortName
            fullName
            website
            description
            children {
                id
                fullName
                shortName
                website
            }
            programArea
            programOffice {
                shortName
            }
            hasFlow
            hasSnmp
        }
    }
`;

/**
 * Render a the site website and other information
 */
export const SiteTitle = ({ siteName }) => {
    // Get the user profile, containing the favorites
    const user = useUser();

    // Get the site info
    const { loading, data } = useQuery(GET_SITE_INFO_QUERY, {
        variables: { shortName: siteName },
    });

    // Mutation for adding or removing current site from the user's favorites
    const [updateFavorite] = useMutation(UPDATE_FAVORITE_MUTATION);

    if (loading || !data) {
        return <div style={{ height: 38 }} />;
    } else if (data) {
        const site = data.networkEntity;
        // Set HTML title tag
        setHtmlTitle("Site", site.fullName);

        return (
            <Row>
                <Col md={12}>
                    <h2>
                        {user && !user.isAnonymous ? (
                            <span>{star([updateFavorite], user, site, "ESnet Site")}</span>
                        ) : (
                            ""
                        )}{" "}
                        {site.fullName}
                    </h2>
                </Col>
            </Row>
        );
    } else {
        return <div />;
    }
};

/**
 * Render a the site website and other information
 */
export const SiteSummaryInfo = ({ siteName }) => {
    const { loading, data } = useQuery(GET_SITE_INFO_QUERY, {
        variables: { shortName: siteName },
    });

    if (loading || !data) {
        return <Spinner />;
    } else {
        const { networkEntity: site } = data;
        return site.website ? (
            <div>
                <dl className="row">
                    <dt className="col-sm-1">Website</dt>
                    <dd className="col-sm-11">
                        <a href={site.website} target="_blank" rel="noopener noreferrer">
                            {site.website}
                        </a>
                    </dd>
                </dl>
            </div>
        ) : (
            <div />
        );
    }
};
