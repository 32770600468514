import { AxisBottom, AxisLeft } from "@vx/axis";
import { format } from "d3-format";
import { scaleLinear, scaleLog } from "d3-scale";
import { curveMonotoneX, line } from "d3-shape";
import React from "react";

// Styles

const markerStyle = {
    stroke: "gray",
    opacity: 0.2,
    fill: "#FFF",
    strokeWidth: "thin",
};
const triggerStyle = { stroke: "#D0D0D0", strokeWidth: 0.5 };

/**
 * Renders a chart showing percentiles, with the percentile from 0 to 100th pct on the x-axis
 * and the value on the y-axis. For example, 75 on the x axis means 75th percentile, while the
 * corresponding value on the y-axis in the value that 75% of the population falls less than
 * and 25% is more than.
 */
export const UtilizationChart = ({ percentiles, scale, width, height, marker, triggers }) => {
    if (!percentiles) {
        return <div />;
    }

    const isLogScale = scale === "log";

    const xAxisHeight = 80;
    const yAxisWidth = 80;
    const margin = 15;

    const chartWidth = width - 2 * margin - yAxisWidth;
    const chartHeight = height - 2 * margin - xAxisHeight;

    // Scales
    const xScale = isLogScale
        ? scaleLog().domain([0.11, 100]).range([0, chartWidth])
        : scaleLinear().domain([0, 1]).range([0, chartWidth]);
    const yScale = scaleLinear().domain([0, 1]).range([chartHeight, 0]);

    // Labels for the axis
    const xLabel = isLogScale ? "Percent of time" : "Percentiles";

    // Label formatting for the x axis percentile values
    const f = isLogScale ? format(".4") : format(".1%");
    const fp = format(".0%");

    // Function to render the percentile line itself
    const renderPath = (isLogScale, data, xScale, yScale, channel, color) => {
        const pathStyle = { fill: "none", stroke: color, strokeWidth: 2 };

        let path;
        if (isLogScale) {
            path = line()
                .curve(curveMonotoneX)
                .x((d) => {
                    if (1 - d[0] === 0) {
                        return xScale(0.11);
                    }
                    return xScale(100 - d[0] * 100);
                })
                .y((d) => {
                    return yScale(d[channel]);
                })(data);
        } else {
            path = line()
                .curve(curveMonotoneX)
                .x((d) => xScale(d[0]))
                .y((d) => yScale(d[channel]))(data);
        }
        return (
            <g>
                <path d={path} style={pathStyle} />
            </g>
        );
    };

    // Render the vertical markers
    const renderMarker = (isLogScale, pos, xScale, yScale, index, color) => {
        const x = isLogScale ? xScale(100 - pos * 100) : xScale(pos);
        const y1 = yScale(1);
        const y2 = yScale(0);
        return (
            <g>
                <line x1={x} y1={y1} x2={x} y2={y2} style={markerStyle} />
            </g>
        );
    };

    const renderTriggers = (width, isLogScale, pos, triggers, xScale, yScale) => {
        const triggerMarkers = triggers.map((trigger, i) => {
            return (
                <line
                    key={i}
                    x1={0}
                    x2={width}
                    y1={yScale(trigger.value)}
                    y2={yScale(trigger.value)}
                    style={triggerStyle}
                />
            );
        });
        return <g>{triggerMarkers}</g>;
    };

    return (
        <svg width={width} height={height} style={{ display: "block" }}>
            {/* Y axis */}
            <g transform={`translate(${margin},${margin})`}>
                <AxisLeft
                    scale={yScale}
                    left={80}
                    label="Percent of total link capacity"
                    labelProps={{
                        textAnchor: "middle",
                        fontFamily: "Roboto, sans-serif",
                        fontSize: 12,
                        fill: "gray",
                    }}
                    tickFormat={(y) => fp(y)}
                />
            </g>
            {/* X axis */}
            <g transform={`translate(${margin + yAxisWidth},${height - margin - xAxisHeight})`}>
                <AxisBottom
                    top={0}
                    scale={xScale}
                    tickFormat={(x) => f(x)}
                    numTicks={20}
                    label={xLabel}
                    stroke="gray"
                    labelProps={{
                        textAnchor: "middle",
                        fontFamily: "Roboto, sans-serif",
                        fontSize: 12,
                        fill: "gray",
                    }}
                />
            </g>
            <g transform={`translate(${margin + yAxisWidth},${margin})`}>
                {renderPath(isLogScale, percentiles, xScale, yScale, 1, "#4A92DE")}
                {renderPath(isLogScale, percentiles, xScale, yScale, 2, "#FD9420")}
                {marker ? renderMarker(isLogScale, marker, xScale, yScale, 2, "#4A92DE") : null}
                {renderTriggers(
                    width - yAxisWidth - 2 * margin,
                    isLogScale,
                    marker,
                    triggers,
                    xScale,
                    yScale
                )}
            </g>
        </svg>
    );
};
