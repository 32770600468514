import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Spinner, TextFilter } from "shared/components/controls";
import { useEntitySelection, useUser, useQueryState } from "shared/hooks";
import { FacilityCard } from "./FacilityCard";
import FacilityListRow from "./FacilityListRow";
import { FacilityMap } from "./FacilityMap";
import FacilityMapLegend from "./FacilityMapLegend";
import { rowCountStyle, totalRowCountStyle } from "shared/styles/styles";
import { breadcrumbStyle } from "shared/styles/styles";
import { setHtmlTitle } from "shared/utils/entity-utils";

const FACILITY_TOPOLOGY = require("app/facilities/data/facilities-topo.json");
export const BASE_USER_QUERY = gql`
    query {
        user {
            email
            firstName
            lastName
            isAnonymous
            favoriteNetworkEntities {
                id
                shortName
                type
            }
            viewTopTalkers {
                id
                shortName
                fullName
            }
        }
    }
`;

export const GET_FACILITIES_QUERY = gql`
    query {
        networkEntities(entityType: "Facility") {
            id
            type
            shortName
            fullName
            programOffice {
                shortName
            }
            programArea
            parent {
                fullName
                shortName
            }
            tags
        }
    }
`;

export const UPDATE_FACILITY_FAVORITE_MUTATION = gql`
    mutation UpdateFacilityFavorite($shortName: String, $isInterested: Boolean) {
        updateUserNetworkEntityFavorite(
            entityType: "Facility"
            shortName: $shortName
            isInterested: $isInterested
        ) {
            success
            user {
                email
                firstName
                lastName
                isAnonymous
                favoriteNetworkEntities {
                    id
                    shortName
                    type
                }
                viewTopTalkers {
                    id
                    shortName
                    fullName
                }
            }
        }
    }
`;

/**
 * A list of facilities rendered in a table
 */
export const FacilityList = () => {
    // Set HTML title tag
    setHtmlTitle("Facilities");

    // Get the user profile, containing favorites
    const user = useUser();

    // The selection and type from the map
    const [{ selection, selectionType }] = useEntitySelection();

    // Fetch the facility info
    const { loading, data } = useQuery(GET_FACILITIES_QUERY);

    // Fetch the text to filter the list by
    const [text] = useQueryState("filter", "");

    // Mutation for adding or removing a facility from the user's favorites
    const [updateFavorite] = useMutation(UPDATE_FACILITY_FAVORITE_MUTATION);

    // Build a new optimistic response starting with the user object
    // then set that on the Apollo Client cache until the server action has
    // been completed.
    const handleFavoriteChange = (facility, isInterested) => {
        const response = user;

        // If we're not interested in an entity then traverse the
        // list and remove the one we don't want in there anymore, otherwise
        // add it to the end of the list of current favorites
        let newFavorites = [];
        if (!isInterested) {
            newFavorites = response.favoriteNetworkEntities.filter((fav) => fav.id !== facility.id);
        } else {
            newFavorites = [...response.favoriteNetworkEntities, facility];
        }
        response.favoriteNetworkEntities = newFavorites;

        updateFavorite({
            variables: { shortName: facility.shortName, isInterested },
            optimisticResponse: {
                __typename: "Mutation",
                updateFavorite: response,
            },
        });
    };

    const renderFacilityMap = () => {
        return <FacilityMap topology={FACILITY_TOPOLOGY} />;
    };

    const renderFacilityList = () => {
        const renderTotalRows = (rowCount, totalRowCount) => {
            if (rowCount < totalRowCount) {
                return <span style={totalRowCountStyle}>(of {totalRowCount})</span>;
            }
            return <span></span>;
        };

        if (!loading && data) {
            const searchText = text.toLowerCase().trim();
            const { favoriteNetworkEntities = [] } = user;
            const facilityList = data.networkEntities;
            const rows = facilityList.map((facility) => {
                const { shortName, fullName, programArea, programOffice } = facility;
                const hostSite = facility.parent ? facility.parent.fullName : "";
                let isFavorite = false;
                favoriteNetworkEntities.forEach((entity) => {
                    if (facility.shortName === entity.shortName && entity.type === "Facility") {
                        isFavorite = true;
                    }
                });
                if (
                    shortName?.toLowerCase().indexOf(searchText) >= 0 ||
                    fullName?.toLowerCase().indexOf(searchText) >= 0 ||
                    hostSite?.toLowerCase().indexOf(searchText) >= 0 ||
                    programArea?.toLowerCase().indexOf(searchText) >= 0 ||
                    programOffice?.shortName?.toLowerCase().indexOf(searchText) >= 0
                ) {
                    return (
                        <FacilityListRow
                            key={facility.shortName}
                            facility={facility}
                            isFavorite={isFavorite}
                            onFavoriteChange={() => handleFavoriteChange(facility, !isFavorite)}
                        />
                    );
                } else {
                    return null;
                }
            });

            // Rows remaining in the filtered list
            const rowCount = rows.filter((r) => r != null).length;

            return (
                <Row>
                    <Col md={12}>
                        <Row>
                            <Col md={12}>
                                <hr style={{ marginTop: 0 }} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <TextFilter />
                            </Col>
                            <Col />
                        </Row>
                        <Row>
                            <Col md={12}>
                                <span style={rowCountStyle}>
                                    Displaying {rowCount}{" "}
                                    {renderTotalRows(rowCount, data.networkEntities.length)} Results
                                </span>
                            </Col>
                        </Row>
                        <table className="table table-condensed">
                            <thead>
                                <tr>
                                    <th />
                                    <th />
                                    <th>Full Name</th>
                                    <th>Host Site</th>
                                    <th>Program Area</th>
                                    <th>Program Office</th>
                                </tr>
                            </thead>
                            <tbody>{rows}</tbody>
                        </table>
                    </Col>
                </Row>
            );
        } else {
            return (
                <div>
                    <Spinner />
                </div>
            );
        }
    };

    const renderMiniInfo = (selection) => {
        let st;
        FACILITY_TOPOLOGY.nodes.forEach((n) => {
            if (n.name === selection) {
                st = n.type;
            }
        });
        if (st === "facility") {
            let facility = selection.toLowerCase();
            if (facility === "arm-anl" || facility === "arm-ornl" || facility === "arm-pnnl") {
                facility = "arm";
            }
            return (
                <Row style={{ marginTop: 15, height: "100%" }}>
                    <Col sm={12}>
                        <h6>SELECTED</h6>
                        <FacilityCard facilityId={selection} />
                    </Col>
                </Row>
            );
        } else {
            return (
                <Row style={{ marginTop: 15 }}>
                    <div className="col-sm-8">
                        <p style={{ marginTop: "12px" }}>
                            The Office of Science national scientific user facilities provide
                            researchers with the most advanced tools of modern science including
                            accelerators, colliders, supercomputers, light sources and neutron
                            sources, as well as facilities for studying the nanoworld, the
                            environment, and the atmosphere. See the{" "}
                            <a
                                href="https://science.energy.gov/user-facilities/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                DOE website
                            </a>{" "}
                            for more details.
                        </p>
                    </div>
                    <div className="col-sm-4">
                        <h6>LEGEND</h6>
                        <FacilityMapLegend itemsPerColumn={4} height={100} />
                    </div>
                </Row>
            );
        }
    };

    return (
        <Row>
            <Col md={1} />
            <Col md={10}>
                <div>
                    <div style={breadcrumbStyle}>
                        <a href="/">HOME</a>
                        <span> » </span>
                    </div>
                    <div className="esnet-portal-header">
                        <h2>Facilities</h2>
                    </div>
                    {renderFacilityMap()}
                    {renderMiniInfo(selection, selectionType)}
                    {renderFacilityList()}
                </div>
            </Col>
            <Col md={1} />
        </Row>
    );
};
