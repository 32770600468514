import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { BrowserRouter, Route, Switch, useLocation } from "react-router-dom";
// Util
import UserContext from "shared/context/UserContext";
// CSS
import "shared/css/main.css";
// App pages
import { Dashboards } from "./dashboard/Index";
import Edges from "./edges/Index";
import Facilities from "./facilities/Index";
import FAQ from "./faq/FAQ";
import Header from "./Header";
import LHCONE from "./lhcone/Index";
import Network from "./network/Index";
import Oscars from "./oscars/Index";
import Sidebar from "./Sidebar";
import Sites from "./sites/Index";
import Updates from "./updates/Updates";
import User from "./user/Index";
import Volume from "./volume/Index";

// Get the Client version
const esnet = window.ESnet || {};
const clientVersion = esnet.codeVersion || "DEV";

// Default HTML title
window.$htmlTitle = "ESnet Portal";

const POLL_INTERVAL = 15 * 1000;

const USER_QUERY = gql`
    {
        user {
            email
            firstName
            lastName
            isAnonymous
            favoriteNetworkEntities {
                id
                shortName
                fullName
                type
            }
            viewTopTalkers {
                id
                shortName
                fullName
            }
        }
    }
`;

const SITE_QUERY = gql`
    {
        siteInfo {
            codeVersion
        }
    }
`;

const NoMatch = () => {
    let location = useLocation();
    return (
        <div>
            <h3>
                No match for <code>{location.pathname}</code> :-(
            </h3>
        </div>
    );
};

//
// Our top level application
//
const App = () => {
    // If the sidebar is open or not
    const [open, setOpen] = useState(false);

    // Get the user profile from the server (or anonymous if not logged in)
    const { data: userData } = useQuery(USER_QUERY);
    const user = userData?.user;

    // Website information, for detection of out of data client code
    const { data: siteData } = useQuery(SITE_QUERY, { pollInterval: POLL_INTERVAL });

    // Set/reset HTML page title
    document.title = window.$htmlTitle;

    let message = null;

    // Check if the siteInfo code version is the same as what we're running now. If it isn't
    // then we force an update, displaying a message for 5 mins before we reload the page
    const siteInfo = siteData?.siteInfo;
    if (siteInfo) {
        const serverVersion = siteInfo ? siteInfo.codeVersion : null;
        if (serverVersion && clientVersion !== "DEV" && clientVersion !== serverVersion) {
            message =
                "This version of the Portal is now out-of-date and will upgrade in 5 minutes. Click to reload now.";
            setTimeout(() => window.location.reload(true), 5 * 60 * 1000);
        }
    }

    // Render the rest of the App. At the outside we create a context, which makes available
    // the user profile throughout the app. The shortcut to access that is useUser().
    // At the next level is React Router (BrowserRouter). Below that we render a bootstrap
    // container layout, containing the site header, footer and sidebar. The sidebar has the
    // entire rest of the portal as a child, since it needs to manipulate it.
    return (
        <UserContext.Provider value={user}>
            <BrowserRouter>
                <Container fluid>
                    <Header
                        message={message}
                        onMessageClick={() => window.location.reload(true)}
                        onShowSidebar={(open) => setOpen(open)}
                        showingSidebar={open}
                    />
                    <Sidebar showingSidebar={open} onShowSidebar={(open) => setOpen(open)}>
                        <Switch>
                            <Route exact path="/">
                                <Dashboards />
                            </Route>
                            <Route path="/map">
                                <Dashboards />
                            </Route>
                            <Route path="/network">
                                <Network />
                            </Route>
                            <Route path="/sites">
                                <Sites />
                            </Route>
                            <Route path="/edges">
                                <Edges />
                            </Route>
                            <Route path="/facilities">
                                <Facilities />
                            </Route>
                            <Route path="/oscars">
                                <Oscars />
                            </Route>
                            <Route path="/traffic-volume">
                                <Volume />
                            </Route>
                            <Route path="/lhcone">
                                <LHCONE />
                            </Route>
                            <Route path="/faq">
                                <FAQ />
                            </Route>
                            <Route path="/updates">
                                <Updates />
                            </Route>
                            <Route path="/user">
                                <User />
                            </Route>
                            <Route path="*">
                                <NoMatch />
                            </Route>
                        </Switch>
                    </Sidebar>
                    {/* <Footer /> */}
                </Container>
            </BrowserRouter>
        </UserContext.Provider>
    );
};

export default App;
