import React from "react";
import _ from "underscore";
import { useQueryState } from "shared/hooks";

/**
 * Renders a choice list of items that are stacked on top of each other. Used in the Portal for filters.
 * Pass in a list of elements as `choiceList`, each element being an object of { key, label }. The
 * default is passed in as `defaultChoice`. The component will control the URL query string. The variable
 * used there will need to be supplied as the `queryVariable` prop. Finally the `width` can be controlled.
 */
export const ChoiceList = ({ choiceList, defaultChoice, queryVariable, width }) => {
    // Manages the state of the choice on the query string
    const [choice, setChoice] = useQueryState(queryVariable, defaultChoice);

    // You can pass in the width for control of that on the style
    const style = width ? { width } : {};

    const listElements = _.map(choiceList, ({ key, label }) => {
        if (choice === key) {
            return (
                <li
                    style={{
                        cursor: "pointer",
                        backgroundColor: "#4EC1E0",
                        borderColor: "#4EC1E0",
                    }}
                    className="list-group-item active"
                    key={key}
                >
                    {label}
                </li>
            );
        } else {
            return (
                <li
                    style={{
                        cursor: "pointer",
                    }}
                    className="list-group-item"
                    key={key}
                    onClick={() => setChoice(key)}
                >
                    {label}
                </li>
            );
        }
    });

    return (
        <ul className="list-group" style={style}>
            {listElements}
        </ul>
    );
};
