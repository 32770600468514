import gql from "graphql-tag";
import React, { useRef } from "react";
import { styler } from "react-timeseries-charts";
import { TimeseriesAreachartWidget } from "shared/components/widgets";
import { useMemoQuery } from "shared/hooks";
import { toTimeSeriesNoJSON } from "shared/utils/timeseries-utils";
import { oscarsTraffic, lhconeTraffic, otherTraffic } from "shared/styles/styles";
import { useSearchParams } from "shared/hooks/useSearchParams";
import { sidebarRanges } from "shared/constants/time";
import { getChartTimePeriod } from "shared/utils/param-utils";
import { formatDate } from "shared/utils/format-utils";

export const GET_NETWORK_SUMMARY_TRAFFIC = gql`
    query getNetworkSummaryTraffic($beginTime: String, $endTime: String) {
        networkSummary {
            traffic(beginTime: $beginTime, endTime: $endTime) {
                name
                columns
                points
            }
        }
    }
`;

// Build a options option which can be passed to the area chart
const labels = {
    oscars: "OSCARS",
    lhcone: "LHCONE",
    other: "Other",
};

const stackUp = ["oscars", "lhcone", "other"];
const stackDown = [];

const style = styler([
    { key: "oscars", color: oscarsTraffic },
    { key: "lhcone", color: lhconeTraffic },
    { key: "other", color: otherTraffic },
]);

export const DashboardTotalTrafficChart = ({ timerange, timestamp, onTrackerChanged }) => {
    const beginTime = timerange.begin().toISOString();
    const endTime = timerange.end().toISOString();
    let data = useRef(null);

    const query = useSearchParams();
    const timePeriod = getChartTimePeriod(query, sidebarRanges);
    let title;
    let description;
    if (typeof timePeriod === "string") {
        title = `ESnet Traffic over the last ${timePeriod}`;
        description = `This chart shows the total traffic into ESnet over the last ${timePeriod}`;
    } else {
        title = `ESnet Traffic from ${formatDate(timePeriod.begin)} to ${formatDate(
            timePeriod.end
        )}`;
        description = `This chart shows the total traffic into ESnet from ${formatDate(
            timePeriod.begin
        )} to ${formatDate(timePeriod.end)}`;
    }

    const DESCRIPTION = `
        #### ESnet traffic\n
        ${description}.
        The total height of the chart shows the total of all traffic and the three
        colored regions represent the contributions of [LHCONE](/collaborations/lhcone/),
        [OSCARS](/oscars/), and all other traffic.
    `;

    const queryOptions = {
        variables: { beginTime, endTime },
        pollInterval: 1 * 60 * 1000, // 1m
    };

    const {
        isLoading,
        data: newSeries,
        error,
    } = useMemoQuery(
        GET_NETWORK_SUMMARY_TRAFFIC,
        queryOptions,
        (d) => {
            return toTimeSeriesNoJSON("total_traffic", d.networkSummary.traffic);
        },
        [beginTime, endTime]
    );

    if (newSeries && newSeries.data !== null) {
        data.current = newSeries;
    }

    const errorMsg = error ? "Error: An error occured trying to fetch data for the map" : null;

    return (
        <TimeseriesAreachartWidget
            title={title}
            description={DESCRIPTION}
            series={data.current}
            stackUp={stackUp}
            stackDown={stackDown}
            style={style}
            labels={labels}
            timerange={timerange}
            timestamp={timestamp}
            onTrackerChanged={onTrackerChanged}
            isLoading={isLoading}
            error={errorMsg}
        />
    );
};
