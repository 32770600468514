import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { TimePicker } from "shared/components/controls";
import { useTimeRange } from "shared/hooks";
import { breadcrumbStyle } from "shared/styles/styles";
import { OscarsChart } from "./OscarsChart";
import { OscarsConnectionDetails, OscarsConnectionTitle } from "./OscarsDetails";
import { OscarsConnectionDiagram } from "./OscarsDiagram";

const EVERY_15_SECONDS = 15 * 1000;

export const OscarsView = () => {
    // Tracker position for the page
    const [timestamp, setTimestamp] = useState(null);

    // Timerange for the page
    const [timerange] = useTimeRange(EVERY_15_SECONDS);

    const { connectionId } = useParams();

    const renderBreadcrumb = () => {
        if (connectionId) {
            return (
                <div>
                    <div style={breadcrumbStyle}>
                        <a href="/">HOME</a>
                        <span> › </span>
                        <a href="/oscars/list">OSCARS</a>
                        <span> » </span>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    };

    return (
        <Col md={12}>
            <Row>
                <Col>
                    {renderBreadcrumb()}
                    <OscarsConnectionTitle connectionId={connectionId} />
                </Col>
            </Row>
            <hr />
            <Row>
                <Col>
                    <div style={{ float: "right" }}>
                        <TimePicker />
                    </div>
                </Col>
            </Row>
            <hr />
            <Row>
                <Col>
                    <OscarsChart
                        connectionId={connectionId}
                        timerange={timerange}
                        timestamp={timestamp}
                        onTrackerChanged={(t) => setTimestamp(t)}
                    />
                </Col>
            </Row>
            <hr />
            <Row>
                <Col>
                    <OscarsConnectionDiagram connectionId={connectionId} />
                </Col>
            </Row>
            <hr />
            <Row>
                <Col>
                    <OscarsConnectionDetails connectionId={connectionId} />
                </Col>
            </Row>
        </Col>
    );
};
