import moment from "moment";
import React from "react";
import { trackerTimeStyle } from "shared/styles/styles";

/**
 * Displays the passed in `time` in a standard format, or, if not time
 * is suppled then it will display the last updated time, taken from
 * the last event in the passed in series.
 */
export const TrackerTime = ({ time, series }) => {
    // Tracker time
    const trackerTime = time
        ? moment(time).format("dddd, MMMM Do YYYY, hh:mm a")
        : series && series.size && series.size() > 0
        ? `Last updated ${moment(series.end()).format("MMMM Do YYYY, hh:mm a")}`
        : ``;

    return <span style={trackerTimeStyle}>{trackerTime}</span>;
};
