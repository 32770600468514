import React from "react";
import { useQueryState } from "shared/hooks";
import { labelStyle, linkStyle, linkStyleActive } from "shared/styles/styles";

/**
 * Displays a horizontal list of choices, separated by a vertical bar, such as:
 *
 * TREND   Off | Classic | Exponential
 *
 * You can specify the `label` ("trend" in the above example), and the list of
 * `options`. Options is a list of elements, each element being an object of the
 * form { key, label }.
 *
 * The control alters the query string to keep its state and read the current value.
 * The variable it uses the is specified as the `variableName`. A `defaultValue` can
 * also be given.
 */
export const ChoicePicker = ({ label, options, variableName, defaultValue }) => {
    const [value, setValue] = useQueryState(variableName, defaultValue);

    const spans = [];
    options.forEach((option, i) => {
        spans.push(
            <span
                key={i}
                style={value === option.key ? linkStyleActive : linkStyle}
                onClick={() => setValue(option.key)}
            >
                {option.label}
            </span>
        );
        if (i < options.length - 1) {
            spans.push(<span> | </span>);
        }
    });
    return (
        <div>
            <span style={labelStyle}>{label}</span>
            {spans}
        </div>
    );
};
