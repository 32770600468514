import { useHistory, useLocation } from "react-router-dom";
import _ from "underscore";
import { useSearchParams } from "./useSearchParams";

/**
 * useQueryState() is a react hook used to get and set a single variable onto the query string of the URL.
 * the useQueryState returns a tuple like setState, e.g.
 *
 * ```
 * const [ selection, setSelection ] = useQueryState(variableName, defaultValue)
 * ```
 *
 * The source of truth for the state is on the URL's query string, using the query variable specified in
 * the first argument to the hook. The second argument is the default variable. If the value is set to
 * the default then no query variable will be included in the query string. If no variable is on the
 * query string then the default value is of course returned.
 */
export const useQueryState = (variableName, defaultValue) => {
    let query = useSearchParams();

    const history = useHistory();
    const { pathname } = useLocation();

    const state = query.get(variableName) || defaultValue;

    const setState = (value, extras) => {
        const targetQuery = new URLSearchParams(query);
        if (typeof extras != "object") {
            extras = {};
        }
        extras[variableName] = value;
        let keys = Object.keys(extras);
        for (let i = 0; i < keys.length; i++) {
            let value = extras[keys[i]];
            if (_.isUndefined(value) || _.isNull(value) || value === defaultValue) {
                targetQuery.delete(keys[i]);
            } else {
                targetQuery.set(keys[i], value);
            }
        }
        history.push(`${pathname}?${targetQuery.toString()}`);
    };

    return [state, setState];
};
