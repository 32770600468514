import React from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";
import { ChoicePicker } from "shared/components/controls";
import { useQueryState, useUser } from "shared/hooks";
import SiteTopTalkerTable from "./SiteTopTalkersTable";

const directions = [
    { key: "incoming", label: "Incoming" },
    { key: "outgoing", label: "Outgoing" },
];

export const SiteTopTalkers = () => {
    const user = useUser();
    const { site } = useParams();
    const location = useLocation();
    const [direction] = useQueryState("direction", "incoming");

    /**
     * Render a table of top talkers
     */
    const renderTopTalkers = () => {
        const props = {
            siteId: site,
            timeperiod: "1d",
            direction,
        };
        return <SiteTopTalkerTable {...props} />;
    };

    const renderTopTalkersInfo = () => {
        return (
            <div style={{ paddingTop: 15, paddingBottom: 15 }}>
                <h4>Top Talkers</h4>
                Top talkers shows the top organizations that {site} sends to or receives data from
                in the past 24 hours
            </div>
        );
    };

    /**
     * Checks the user object to see if the viewTopTalkers permission
     * list contains this site
     */
    const hasPermission = () => {
        const { viewTopTalkers } = user;

        if (!viewTopTalkers || viewTopTalkers.length === 0) {
            return false;
        }
        return viewTopTalkers.findIndex((x) => x.shortName === site) >= 0;
    };

    const isLoggedIn = () => {
        return user && !user.isAnonymous;
    };

    if (!user) {
        return <div />;
    }

    if (!isLoggedIn()) {
        const url = `/login?redirect=${location.pathname}`;
        return (
            <Col md={12}>
                <Row>
                    <Col md={9}>
                        <Row>
                            <Col md={12}>
                                Please <a href={url}>login</a> to view Top Talkers information for
                                this Site.
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        );
    }

    if (hasPermission()) {
        return (
            <Col md={12}>
                <Row>
                    <Col md={12}>
                        <Row>
                            <Col md={12}>{renderTopTalkersInfo()}</Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col md={12}>
                                <ChoicePicker
                                    label="DIRECTION"
                                    variableName="direction"
                                    defaultValue="incoming"
                                    options={directions}
                                />
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col md={12}>{renderTopTalkers()}</Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        );
    } else {
        return (
            <Col md={12}>
                <Row>
                    <Col md={9}>
                        <Row>
                            <Col md={12}>
                                You do not have permission to view this site's Top Talkers
                                information.
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        );
    }
};
