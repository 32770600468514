import { sum, TimeSeries } from "pondjs";
import _ from "underscore";
/**
 * Set of utility functions mostly related to taking various forms
 * of timeseries data from the server and processing them for the
 * client. This includes straight timeseries as well as lists of
 * timeseries and a utility function to merge a list of timeseries
 * together into a total timeseries by summing.
 *
 * Also includes a utility to convert a timeseries to a csv.
 */

/**
 * Utility function to convert data to a Pond TimeSeries. The result
 * is a `TimeSeries`.
 */
export function toTimeSeries(id, data) {
    if (!id) {
        throw new Error(`Timeseries request had no id`);
    }
    if (!data) {
        throw new Error(`Timeseries returned was null: ${id}`);
    }
    return new TimeSeries(JSON.parse(data));
}

/**
 * Utility function to convert data to a Pond TimeSeries, except without
 * JSON.parse The result is a `TimeSeries`.
 */
export function toTimeSeriesNoJSON(id, data) {
    if (!id) {
        throw new Error(`Timeseries request had no id`);
    }
    if (!data) {
        throw new Error(`Timeseries returned was null: ${id}`);
    }
    return new TimeSeries(data);
}

/**
 * Utility function to convert data to a TimeSeries list. Returns
 * an object with two parts:
 *  - `seriesList`, a list of `TimeSeries`
 *  - `totalSeries`, a `TimeSeries`, which is the sum of the `seriesList`
 *
 * @param {string} id the id used by the store for this data
 * @param {array} data list of data to extract the time series from
 * @param {Object} options contains name and labels for the data
 * @param {function} extract a function that takes each item in the list and
 * returns the JSON string that represents the time series.
 * e.g. `d => d` or `d => d.traffic`.
 * @param {array} filters contains a list of objects to further filter the timeseries data
 * Each object contains the field to refer to in the timeseries and the value to filter on
 */
export function toTimeSeriesList(id, data, options, extract, filters = []) {
    const { name, channels } = options;

    if (!data) {
        throw new Error(`Data being converted to a TimeSeriesList is invalid for id ${id}`);
    }

    // TimeSeries list
    let seriesList = data
        .map((item) => extract(item))
        .filter((item) => !_.isNull(item))
        .map((item, i) => {
            const namedItem = item;
            if (item.name === "") {
                namedItem.name = `${name} ${i}`;
            }
            return namedItem;
        });

    for (const filter of filters) {
        const field = filter["field"];
        const value = filter["value"];
        seriesList = seriesList.filter((item) => value === "all" || item[field] === value);
    }

    seriesList = seriesList.map((item) => new TimeSeries(item));

    // Sum of TimeSeries
    const totalSeries = TimeSeries.timeSeriesListReduce({
        name,
        seriesList,
        reducer: sum(),
        fieldSpec: channels,
    });

    return { seriesList, totalSeries };
}

export function combineTimeSeriesLists(timeSeriesLists, options) {
    const { name, channels } = options;
    return {
        seriesList: _.flatten(timeSeriesLists.map((i) => i.seriesList)),
        totalSeries: TimeSeries.timeSeriesListReduce({
            name,
            seriesList: timeSeriesLists.map((i) => i.totalSeries),
            reducer: sum(),
            fieldSpec: channels,
        }),
    };
}

export function convertTimeSeriesToCSV(timeSeries) {
    const csvData = [];
    if (timeSeries) {
        csvData.push(["time", ...timeSeries.columns()]);
        for (let e of timeSeries.events()) {
            const row = [e.index().toString()];
            for (let f of timeSeries.columns()) {
                const v = e.get(f);
                row.push(v);
            }
            csvData.push(row);
        }
    }
    return csvData;
}

/**
 * Helper function to split a time series name into its
 * individual components: device and interface/SAP.
 * Returns the device and interface/SAP delimited by a space.
 *
 * @export
 * @param {string} seriesName
 * @return {string}
 *
 * Example: The seriesName "devices/anl541b-cr6/interfaces/anl_se-14"
 * returns "anl541b-cr6 anl_se-14"
 */
export function splitSeriesName(seriesName) {
    const [, device, , iface] = seriesName.split("/");
    return `${device} ${decodeURIComponent(iface)}`;
}

/**
 * Helper function that opens the interface/SAP details page
 * in a new browser window, given a series name.
 *
 * @export
 * @param {string} seriesName
 */
export function handleNavigate(seriesName) {
    const name = seriesName.trim();
    // Get curent URL search parameters
    const inQuery = new URLSearchParams(window.location.search);
    // Create an empty object to append selected parameters
    const outQuery = new URLSearchParams();
    const rangeKeys = ["t", "b", "e"];

    // Build a query string by picking only the time range keys
    for (const [key, value] of inQuery) {
        if (rangeKeys.indexOf(key) > -1) {
            if (value) {
                outQuery.append(key, value);
            }
        }
    }
    const query = outQuery.toString() ? `?${outQuery.toString()}` : "";
    const url = `/network/${name}/traffic${query}`;
    if (name) {
        window.open(url, "_blank");
    }
}

/**
 * Helper function to return "Interface" or "SAP"
 * given the series name
 *
 * @export
 * @param {string} seriesName
 * @return {string}
 *
 * Example: The seriesName "devices/anl541b-cr6/interfaces/anl_se-14"
 * returns "Interface"
 */
export function interfaceOrSap(seriesName) {
    const [, , type] = seriesName.split("/");
    let deviceType = "";

    if (type === "interfaces") {
        deviceType = "Interface";
    } else if (type === "saps") {
        deviceType = "SAP";
    }

    return `${deviceType}`;
}
