import gql from "graphql-tag";
import { TimeSeries } from "pondjs";
import React, { useRef } from "react";
import { styler } from "react-timeseries-charts";
import { TimeseriesAreachartWidget } from "shared/components/widgets";
import { useMemoQuery } from "shared/hooks";
import { chartBlue, chartOrange } from "shared/styles/styles";

export const GET_OSCARS_TRAFFIC = gql`
    query getOscarsTraffic($id: String!, $beginTime: String, $endTime: String) {
        oscarsConnection(connectionId: $id) {
            traffic(beginTime: $beginTime, endTime: $endTime) {
                name
                columns
                points
            }
        }
    }
`;

// Build a options option which can be passed to the area chart
const TITLE = "Oscars Traffic";
const DESCRIPTION = `
#### Total Traffic\n\n
This chart shows the traffic flowing in and out of this network entity
based on SNMP collection of interfaces facing this entity. Hovering over the
chart with your mouse will provide details about that specific point in time.
`;

const STACK_UP = ["atoz"];
const STACK_DOWN = ["ztoa"];
const LABELS = { atoz: "A to Z", ztoa: "Z to A" };
const STYLE = styler([
    { key: "atoz", color: chartBlue },
    { key: "ztoa", color: chartOrange },
]);

export const OscarsChart = ({ connectionId, timerange, timestamp, onTrackerChanged }) => {
    const beginTime = timerange.begin().toISOString();
    const endTime = timerange.end().toISOString();
    let data = useRef(null);

    const queryOptions = {
        variables: { id: connectionId, beginTime, endTime },
        fetchPolicy: "no-cache",
    };

    // prettier-ignore
    const {
        isLoading,
        error,
        data: series,
    } = useMemoQuery(
        GET_OSCARS_TRAFFIC,
        queryOptions,
        (d) => {
            // if d.oscarsConnection.traffic is defined and truthy
            if (
                Boolean(d) &&
                Boolean(d.oscarsConnection) &&
                Boolean(d.oscarsConnection.traffic)
            ) {
                return new TimeSeries(d.oscarsConnection.traffic);
            } else {
                return null;
            }
        },
        [connectionId, beginTime, endTime]
    );

    if (series && series.data !== null) {
        data.current = series;
    }

    return (
        <TimeseriesAreachartWidget
            title={TITLE}
            description={DESCRIPTION}
            series={data.current}
            labels={LABELS}
            timerange={timerange}
            timestamp={timestamp}
            onTrackerChanged={onTrackerChanged}
            stackUp={STACK_UP}
            stackDown={STACK_DOWN}
            style={STYLE}
            isLoading={isLoading}
            error={error}
        />
    );
};
