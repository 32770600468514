import { format } from "d3-format";

let dateFormatter;

/**
 * Collection of useful functions related to formatting output
 */

/**
 * Utility function to format a percent with a optional boolean to display leading + sign
 */

export function formatPercent(value, leadingPlus) {
    leadingPlus = leadingPlus || false;
    const absValue = Math.abs(value);

    if (value === 0) {
        return "0.00%";
    } else if (absValue < 0.001) {
        return "~0.00%";
    }
    let s = format(".3g")(value) + "%";

    if (leadingPlus && value > 0) {
        s = "+" + s;
    }

    return s;
}

/**
 * Utility function to format a year and month
 */
export function formatYearMonth(year, month) {
    return year + "-" + ("00" + month).substr(-2, 2);
}

/**
 * Utility function to check whether a value is numeric
 */

function isNumeric(value) {
    return !isNaN(parseFloat(value)) && isFinite(value);
}

/**
 * Utility function to format a value in bytes to a human readable value
 */
export function formatBytes(bytes, decimals = 2, units = "Bytes", base = 1000, abbreviate = true) {
    if (!isNumeric(bytes)) return "";
    if (bytes < 1 && bytes > 0) bytes = 1; // protection for passing in e.g. 0.0002 bytes
    if (bytes === 0) return "0 " + units;

    const dm = decimals < 0 ? 0 : decimals;
    let suffix = units;
    if (abbreviate) {
        suffix = units.charAt(0);
    }
    if (base === 1024) {
        suffix = "i" + suffix;
    }
    const sizes = [
        units,
        "K" + suffix,
        "M" + suffix,
        "G" + suffix,
        "T" + suffix,
        "P" + suffix,
        "E" + suffix,
        "Z" + suffix,
        "Y" + suffix,
    ];
    const i = Math.floor(Math.log10(bytes) / Math.log10(base));
    return parseFloat((bytes / Math.pow(base, i)).toFixed(dm)) + sizes[i];
}

/**
 * Utility function to format a value such as a flow count to a human readable value
 */
export function formatCount(number, decimals = 1) {
    const SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];

    // Determine SI symbol)
    var tier = (Math.log10(Math.abs(number)) / 3) | 0;

    if (tier === 0) return number;

    // get suffix and determine scale
    var suffix = SI_SYMBOL[tier];
    var scale = Math.pow(10, tier * 3);

    // scale the number
    var scaled = number / scale;

    // format number and add suffix
    if (scaled % 1 === 0) {
        // whole number
        return scaled + suffix;
    } else {
        return scaled.toFixed(decimals) + suffix;
    }
}

/**
 * Utility function to format a value in bits per second to a human readable value
 */
export function formatBPS(bps, decimals = 1) {
    let i = -1;
    const byteUnits = [" kbps", " Mbps", " Gbps", " Tbps", " Pbps", " Ebps", " Zbps", " Ybps"];

    do {
        bps = bps / 1000;
        i++;
    } while (bps > 1000);

    return Math.max(bps, 0.1).toFixed(decimals) + byteUnits[i];
}

/**
 * Utility function to format a date in UTC time, otherwise defaulting to en-US medium date style.
 *
 * @param {Date} inDate
 * @return {string}
 */
export function formatDate(inDate) {
    if (!dateFormatter) {
        dateFormatter = Intl.DateTimeFormat("en-US", {
            timeZone: "PST",
            year: "numeric",
            month: "short",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
        });
    }
    return dateFormatter.format(inDate);
}
