import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Spinner } from "shared/components/controls";
import { useUser } from "shared/hooks";
import { UPDATE_FAVORITE_MUTATION, star, setHtmlTitle } from "shared/utils/entity-utils";

export const GET_COLLABORATOR_INFO_QUERY = gql`
    query getLHCONECollaboratorInfo($shortName: String) {
        networkEntity(shortName: $shortName, entityType: "LHCONE") {
            id
            type
            shortName
            fullName
            website
            description
            parent {
                fullName
                shortName
                website
            }
            children {
                fullName
                shortName
                website
            }
            programArea
            programOffice {
                shortName
            }
            hasFlow
            hasSnmp
        }
    }
`;

/**
 * Render a the collaborator's website and other information
 */
export const CollaboratorTitle = (props) => {
    const { collaboratorName } = props;

    // Get the user profile, containing the favorites
    const user = useUser();

    // Load collaborator info
    const { loading, data } = useQuery(GET_COLLABORATOR_INFO_QUERY, {
        variables: { shortName: collaboratorName },
    });

    // Mutation for adding or removing current collaborator from the user's favorites
    const [updateFavorite] = useMutation(UPDATE_FAVORITE_MUTATION);

    if (loading || !data) {
        return <div height={36} />;
    } else if (data) {
        const collaborator = data.networkEntity;
        // Set HTML title tag
        setHtmlTitle("Collaborator", collaborator.fullName);

        return (
            <Row>
                <Col md={12}>
                    <h2>
                        {user && !user.isAnonymous ? (
                            <span>{star([updateFavorite], user, collaborator, "LHCONE")}</span>
                        ) : (
                            ""
                        )}{" "}
                        {collaborator.fullName}
                    </h2>
                </Col>
            </Row>
        );
    } else {
        return <div />;
    }
};

/**
 * Render a the LHCONE collaborator website and other information
 */
export const CollaboratorSummaryInfo = (props) => {
    const { collaboratorName } = props;

    // Load collaborator info
    const { loading, data } = useQuery(GET_COLLABORATOR_INFO_QUERY, {
        variables: { shortName: collaboratorName },
    });

    if (loading || !data) {
        return <Spinner />;
    } else {
        const collaborator = data.networkEntity;
        return collaborator.website ? (
            <div>
                <dl className="row">
                    <dt className="col-sm-1">Website</dt>
                    <dd className="col-sm-11">
                        <a href={collaborator.website} target="_blank" rel="noopener noreferrer">
                            {collaborator.website}
                        </a>
                    </dd>
                </dl>
            </div>
        ) : (
            <div />
        );
    }
};
