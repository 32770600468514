import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Index, TimeSeries } from "pondjs";
import React, { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import regression from "regression";
import {
    ChoicePicker,
    fromDate,
    lastMonth,
    MonthPicker,
    TimePicker,
    toTimerange,
} from "shared/components/controls";
import { MultiChannelChartWidget } from "shared/components/widgets";
import { useQueryState, useTimeRange } from "shared/hooks";
import TrafficVolumeSummary from "./components/TrafficVolumeSummary";
import { setHtmlTitle } from "shared/utils/entity-utils";

const YEAR = 1000 * 60 * 60 * 24 * 365;
const HOURLY = 1000 * 60 * 60;

const scaleOptions = [
    { key: "linear", label: "Linear" },
    { key: "log", label: "Log" },
];

const trendOptions = [
    { key: "none", label: "Off" },
    { key: "linear", label: "Linear" },
    { key: "exponential", label: "Exponential" },
];

const TOTAL = "#222";
const OSCARS = "#FF4E00";
const LHCONE = "#24660A";

const FETCH_VOLUME_HISTORY = gql`
    query getTrafficVolume {
        trafficVolume {
            traffic
        }
    }
`;

const DESCRIPTION = `
## Traffic Volume
This chart shows the total volume of traffic, in bytes, accepted by ESnet over time. This report
is generated at the beginning of the month. 

Clicking the chart with your mouse will select a month and provide further details. You can also use
the MONTH picker to move month by month, or click on the highlighted month to pick the month.

You can control the range of the chart using the TIME picker, either by clicking on a preset or
but using the custom range picker.

The SCALE of the y-axis of the chart can be chosen, showing either Linear or Log. Log is a better choice
to see the growth rate of ESnet traffic over many years.

A TREND line may be added using the trend picker on the top right. When a trend line is displayed the timeline
will adjust to show a year into the future. Currently there are two types of trends: Linear and Exponential.

ESnet has been growing at a linear rate over the past 5 years. Selecting the Linear trend line will visualize
this. Traditionally though, ESnet traffic has grown exponentially. To see a 10 year exponential curve
fitted to the data select the Exponential trend line.

In the past this page also showed a "Classic" trend line is what ESnet, which is the curve ESnet has traditionally
used to show the historical growth of the network. This curve has been removed now as it does not fit the past
10 years of data well.
`;

const calculateRegression = (inputSeries, inputBeginTime, inputEndTime, fieldName, type) => {
    if (!type || !inputSeries) {
        return null;
    }

    // Build a set of input points
    const base = [];
    const inputPoints = [];
    for (const e of inputSeries.events()) {
        if (+e.index().begin() > +inputBeginTime) {
            // `base` is built up to be the original data, with the same index as the
            // input points below. After we do the regression we can merge the two.
            base.push([e.index(), e.get("total_in"), e.get("oscars_in"), e.get("lhcone_in")]);

            // `inputPoints` is built up to be an array specific to doing the regression
            // Any month beyond the "from" date just has null for the value. These will
            // get a regression value.
            if (+e.index().end() < +inputEndTime) {
                inputPoints.push([+e.index().begin(), e.get(fieldName)]);
            } else {
                inputPoints.push([+e.index().begin(), null]);
            }
        }
    }

    // Add additional 12 months of empty points for the extrapolation
    const lastIndex = inputSeries.at(inputSeries.size() - 1).index();
    const [year, month] = lastIndex.toString().split("-");
    let y = +year;
    let m = +month;
    for (let i = 0; i < 12; i++) {
        m++;
        if (m > 12) {
            m = 1;
            y++;
        }
        const futureIndex = new Index(`${y}-${m}`);
        inputPoints.push([+futureIndex.begin(), null]);
        base.push([futureIndex, null, null, null]);
    }

    // Pass the input points to the regression function, to get the output points
    let outputPoints = [];
    if (type === "exponential") {
        const { points } = regression.exponential(inputPoints, {
            order: 2,
            precision: 20,
        });
        outputPoints = points;
    } else if (type === "linear") {
        const { points } = regression.linear(inputPoints);
        outputPoints = points;
    }

    // Make new points for the regression timeseries
    const regressionPoints = [];
    for (let i = 0; i < base.length; i++) {
        const p = [base[i][0], outputPoints[i][1]];
        regressionPoints.push(p);
    }

    return new TimeSeries({
        name: "regression",
        columns: ["index", `${fieldName}_regression`],
        points: regressionPoints,
    });
};

const Volumes = () => {
    // Set HTML title tag
    setHtmlTitle("Volume History");

    // Scale type, either "log" or "linear"
    const [scale] = useQueryState("scale", "log");

    // Show the trend line, either "off", "linear" or "exponential"
    const [trend] = useQueryState("trend", "none");

    // Selected month
    const [month, setMonth] = useQueryState("month", lastMonth());
    const selection = toTimerange(month);

    // Volume data
    const { loading, data } = useQuery(FETCH_VOLUME_HISTORY);
    const volumes = data ? new TimeSeries(JSON.parse(data.trafficVolume.traffic)) : null;

    // Timerange for the page
    const customRanges = [
        { key: "6M", label: "6M", duration: 0.5 * YEAR },
        { key: "1Y", label: "1Y", duration: YEAR },
        { key: "5Y", label: "5Y", duration: 5 * YEAR },
        { key: "15Y", label: "15Y", duration: 15 * YEAR },
        {
            key: "all",
            label: "All",
            duration: volumes && volumes.count() ? volumes.timerange().duration() : 30 * YEAR,
        },
    ];

    // The currently displayed timerange
    const [timerange] = useTimeRange(HOURLY, customRanges, "all", trend === "none" ? 0 : YEAR);

    // Trend line parameters
    const trendType = trend !== "none" ? trend : null;
    const beginTrend =
        trend === "linear"
            ? new Date(+selection.begin() - 5 * YEAR)
            : new Date(+selection.begin() - 10 * YEAR);
    const endTrend = selection.begin();

    // Memorized trend line timeseries
    const trendSeries = useMemo(
        () => calculateRegression(volumes, beginTrend, endTrend, "total_in", trendType),
        [volumes, beginTrend, endTrend, trendType]
    );

    const handleSelectionChange = (t) => {
        if (t) {
            if (+t > volumes.timerange().end()) {
                setMonth(fromDate(volumes.timerange().end()));
            } else {
                setMonth(fromDate(t));
            }
        }
    };

    const layout = [
        {
            height: 400,
            selection,
            charts: [
                {
                    id: "volumes",
                    series: volumes,
                    overlay: trendSeries,
                    overlayChannel: "total_in_regression",
                    type: "line",
                    mode: "multi-channel",
                    channels: ["total_in", "lhcone_in", "oscars_in"],
                    colors: [TOTAL, LHCONE, OSCARS],
                    label: "Volume (bytes)",
                    interpolation: "curveNatural",
                },
            ],
        },
    ];

    return (
        <Col>
            <Row>
                <Col>
                    <h2>Volume History</h2>
                </Col>
            </Row>

            <Row>
                <Col>
                    <hr />
                </Col>
            </Row>

            <Row>
                <Col md={3}>
                    <MonthPicker showMaxMonthButton="true" />
                </Col>
                <Col md={3}>
                    <TimePicker ranges={customRanges} defaultValue="all" />
                </Col>
                <Col md={3}>
                    <ChoicePicker
                        label="SCALE"
                        variableName="scale"
                        defaultValue="log"
                        options={scaleOptions}
                    />
                </Col>
                <Col md={3}>
                    <ChoicePicker
                        label="TREND"
                        variableName="trend"
                        defaultValue="none"
                        options={trendOptions}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <hr />
                </Col>
            </Row>

            <Row>
                <Col md={12}>
                    <MultiChannelChartWidget
                        height={435}
                        loading={loading}
                        layout={layout}
                        info={DESCRIPTION}
                        title="Interface traffic"
                        description="Shows interface traffic for the currently selected month."
                        scale={scale}
                        timeRange={timerange}
                        enablePNGDownload
                        enableCSVDownload
                        onClick={handleSelectionChange}
                    />
                </Col>
            </Row>

            <Row>
                <Col>
                    {loading ? (
                        <div />
                    ) : (
                        <TrafficVolumeSummary
                            data={volumes}
                            currentMonth={month}
                            overlayNames={["OSCARS", "LHCONE"]}
                            overlayColors={{ OSCARS, LHCONE }}
                        />
                    )}
                </Col>
            </Row>
        </Col>
    );
};

export default Volumes;
