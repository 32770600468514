/**
 * Collection of useful functions related to parsing the URL
 */

/**
 * Utility function to get the timeperiod selected for charts.
 *
 * @param {URLSearchParams} query
 * @param {Array<{ label: string, value: string, duration?: string }>} sidebarRanges
 * @returns {string|{begin: Date, end: Date}}
 * @see src/shared/constants/time.sidebarRanges
 */
export function getChartTimePeriod(query, sidebarRanges) {
    let timePeriod = "";
    const queryParams = {
        t: query.get("t"),
        b: query.get("b"),
        e: query.get("e"),
    };
    const { t, b, e } = queryParams;
    if ((t === null || t === "latest") && !b && !e) {
        timePeriod = "24h";
    } else if (b && e) {
        timePeriod = {
            begin: b,
            end: e,
        };
    } else if (t !== null) {
        let val = sidebarRanges.find((d) => d.value === t);
        if (val) {
            timePeriod = val.label;
        } else {
            timePeriod = t;
        }
    }
    return timePeriod;
}
