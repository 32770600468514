import gql from "graphql-tag";
import React from "react";
import Card from "shared/components/cards/Card";
import { Spinner } from "shared/components/controls";
import { useMemoQuery } from "shared/hooks";
import { cardStyle, cardStyleActive, cardStyleError, linkStyle } from "shared/styles/styles";
import { setHtmlTitle } from "shared/utils/entity-utils";

export const HUB_INFO_QUERY = gql`
    query hubInfo($shortName: String) {
        networkEntity(shortName: $shortName, entityType: "Hub") {
            shortName
            fullName
            type
            website
        }
    }
`;

/**
 * Renders a card which shows the full name of the hub and a link to the website from ESDB
 */
export const HubCard = ({ hubName, isSelected }) => {
    const queryOptions = {
        variables: { shortName: hubName },
    };

    // prettier-ignore
    const {
        isLoading,
        error,
        data: entity,
    } = useMemoQuery(HUB_INFO_QUERY, queryOptions, (d) => d.networkEntity, [hubName]);

    if (isLoading) {
        return (
            <Card overLevel={2} outLevel={1} level={1} width="100%" height={100} style={cardStyle}>
                <Spinner />
            </Card>
        );
    } else if (error) {
        return (
            <Card
                overLevel={2}
                outLevel={1}
                level={1}
                width="100%"
                height={50}
                style={cardStyleError}
            >
                Unable to load hub information
            </Card>
        );
    } else {
        // Set HTML title tag
        const pageTitle = `${entity.type} : ${entity.fullName}`;
        setHtmlTitle("Map", pageTitle, 80);

        return (
            <Card
                overLevel={2}
                outLevel={1}
                level={1}
                width="100%"
                height={100}
                style={isSelected ? cardStyleActive : cardStyle}
            >
                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <b>{entity.type}</b>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>{entity.fullName}</b>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {entity.website ? (
                                    <a
                                        style={linkStyle}
                                        href={`${entity.website}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <span> Website » </span>
                                    </a>
                                ) : (
                                    <span />
                                )}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Card>
        );
    }
};
