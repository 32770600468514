import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { breadcrumbStyle } from "shared/styles/styles";
import _ from "underscore";
import { FacilityTitle } from "./FacilityInfo";
import { FacilitySummary } from "./FacilitySummary";

const FacilityView = (props) => {
    const { facility } = useParams();

    const renderHeader = () => {
        if (facility) {
            return (
                <div>
                    <div style={breadcrumbStyle}>
                        <a href="/">HOME</a>
                        <span> › </span>
                        <a href="/facilities/list">FACILITIES</a>
                        <span> » </span>
                    </div>
                    <FacilityTitle facilityName={facility} />
                </div>
            );
        } else {
            return null;
        }
    };

    const renderSubpage = () => {
        return <FacilitySummary facilityName={facility} {...props} />;
    };

    // NavItems
    const subSections = [];
    subSections.push({
        key: "summary",
        label: "Summary",
        url: `/facilities/view/${facility}/`,
    });

    const active = "Summary";

    const navItems = _.map(subSections, (section) => {
        const klass = active === section.label ? "active" : "";
        const key = section.key;
        return (
            <li key={key} role="presentation" className={klass}>
                <Link to={section.url}>{section.label}</Link>
            </li>
        );
    });

    return (
        <Col md={12}>
            <Row>
                <Col md={12}>
                    <Row>
                        <Col md={7}>{renderHeader()}</Col>
                        <Col md={5} style={{ marginTop: 20 }}>
                            <ul className="nav nav-pills" style={{ float: "right" }}>
                                {navItems}
                            </ul>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>{renderSubpage()}</Row>
        </Col>
    );
};

export { FacilityView };
