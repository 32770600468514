import gql from "graphql-tag";
import React, { useRef, useEffect, useState } from "react";
import { Col, Row, Form, Accordion } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { useEntitySelection, useMemoQuery } from "shared/hooks";
import { formatBPS } from "shared/utils/format-utils";
import { getEdgeColor, getDisplayName } from "../utils/utils";
import { Spinner } from "shared/components/controls";
import { useSearchParams } from "shared/hooks/useSearchParams";
import Select, { components } from "react-select";
import Tooltip from "@atlaskit/tooltip";
import { TimePicker } from "shared/components/controls";

import { sidebarRanges } from "shared/constants/time";
import { AggregationTypes } from "shared/constants/constants";

import { mapOptionsHeaderStyle } from "shared/styles/styles";
import "shared/css/accordion.css";

import DashboardLegend from "./DashboardLegend";
import GeoTopologyOptions from "../options/geographic";
import { getAgg, checkIfLatest, getTimePeriod } from "../utils/utils";

import { PubSub } from "esnet-networkmap-panel/src/components/lib/pubsub.js";

export const MAP_TRAFFIC_QUERY_GEO = gql`
    query getMapTopologyEM($beginTime: String, $endTime: String, $isLatest: Boolean, $agg: String) {
        mapTopologyEm(beginTime: $beginTime, endTime: $endTime, isLatest: $isLatest, agg: $agg) {
            traffic {
                name
                columns
                points
            }
        }
    }
`;

export const MAP_TOPOLOGY_GEO = gql`
    query getMapTopology($name: String, $version: String) {
        getTopology(name: $name, version: $version) {
            layers
        }
    }
`;

const Option = (props) => {
    return (
        <Tooltip content={props.data.tooltip}>
            <components.Option {...props} />
        </Tooltip>
    );
};

const GeographicMapSidebar = ({ columnWidth, accordionCollapsed }) => {
    const containerRef = useRef(null);

    const [showCore, setShowCore] = useState(true);
    const [showSites, setShowSites] = useState(false);
    const [isCustomSelected, setIsCustomSelected] = useState(false);

    const query = useSearchParams();
    const history = useHistory();
    const { pathname } = useLocation();

    // Parse out if we're looking at the latest timestamp
    let isLatest = checkIfLatest(query);

    // Parse out aggregation from the query string
    let agg = getAgg(query);

    // Parse out aggregation from the query string
    let timePeriod = getTimePeriod(query);

    const handleAggChange = (agg) => {
        const targetQuery = new URLSearchParams(query);
        targetQuery.delete("agg");
        if (agg === "avg") {
            targetQuery.delete("agg");
        } else {
            targetQuery.set("agg", agg);
        }
        history.push(`${pathname}?${targetQuery.toString()}`);
    };

    /**
     * Render the site's total traffic as sum of interfaces as an area chart.
     */
    const handleChange = (e, selectionType, selection) => {
        if (selectionType === "switchLayer") {
            if (selection === "core") {
                PubSub.global.publish("toggleGeographicMapLayer", {
                    layer: "layer1",
                    visible: !showCore,
                });
                setShowCore(!showCore);
            } else if (selection === "sites") {
                PubSub.global.publish("toggleGeographicMapLayer", {
                    layer: "layer2",
                    visible: !showSites,
                });
                setShowSites(!showSites);
            }
        } else if (selectionType === "switchMap") {
            const targetQuery = new URLSearchParams(query);
            // Don't carry forward edge selection when switching maps
            targetQuery.delete("s");
            targetQuery.delete("st");
            targetQuery.delete("et");
            if (selection === "logical") {
                history.push(`/?${targetQuery.toString()}`);
            } else {
                history.push(`/map/geographic?${targetQuery.toString()}`);
            }
        }
    };

    let activeKey = accordionCollapsed ? "" : "0";

    return (
        <Row>
            <Col md={12}>
                <DashboardLegend itemsPerColumn={4} height={150} columnWidth={columnWidth} />
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey={activeKey}>
                        <Accordion.Header>
                            <span style={mapOptionsHeaderStyle}>Display Options</span>
                        </Accordion.Header>
                        <Accordion.Body>
                            <Form>
                                <hr style={{ marginTop: "0px" }} />
                                <h4 style={{ fontSize: "16px" }}>Visualization</h4>
                                <div key={`switch-map`} className="mb-3">
                                    <Form.Check
                                        checked={false}
                                        type={`radio`}
                                        id={`logical`}
                                        label={`Logical Map`}
                                        onClick={(e) => handleChange(e, "switchMap", "logical")}
                                        onChange={(e) => {}}
                                    />
                                    <Form.Check
                                        checked={true}
                                        type={`radio`}
                                        label={`Geographic Map`}
                                        id={`geographic`}
                                        onClick={(e) => handleChange(e, "switchMap", "geographic")}
                                        onChange={(e) => {}}
                                    />
                                </div>
                                <h4 style={{ fontSize: "16px" }}>Map Layers</h4>
                                <div key={`switch-layer`} className="mb-3">
                                    <Form.Check
                                        checked={showCore}
                                        type="switch"
                                        id={`default-switch-core`}
                                        label={"Core Topology"}
                                        onClick={(e) => handleChange(e, "switchLayer", "core")}
                                        onChange={(e) => {}}
                                    />
                                    <Form.Check
                                        checked={showSites}
                                        type="switch"
                                        id={`default-switch-sites`}
                                        label={"Site Topology"}
                                        onClick={(e) => handleChange(e, "switchLayer", "sites")}
                                        onChange={(e) => {}}
                                    />
                                </div>
                                <h4 style={{ fontSize: "16px" }}>Time Range</h4>
                                <div key={`switch-timerange`} ref={containerRef} className="mb-3">
                                    <div>
                                        <TimePicker
                                            containerRefObj={containerRef.current}
                                            ranges={sidebarRanges}
                                            defaultValue={
                                                sidebarRanges.find((x) => x.value === timePeriod) ||
                                                sidebarRanges.find((x) => x.value === "custom")
                                            }
                                            onDropDownChange={(t) =>
                                                setIsCustomSelected(t === "custom")
                                            }
                                            timePickerStyle="dropdown"
                                        />
                                    </div>
                                </div>
                                {isLatest === false || isCustomSelected ? (
                                    <div key={`switch-agg`} className="mb-3">
                                        <h4 style={{ fontSize: "16px" }}>Edge Color Metric</h4>
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            components={{ Option }}
                                            defaultValue={AggregationTypes.find(
                                                (x) => x.value === agg
                                            )}
                                            name="timerange"
                                            options={AggregationTypes}
                                            onChange={(e) => handleAggChange(e.value)}
                                        />
                                    </div>
                                ) : (
                                    <div />
                                )}
                            </Form>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Col>
        </Row>
    );
};

const GeographicMap = (props) => {
    var mapCanvas = useRef(null);

    const query = useSearchParams();
    const beginTime = props.timerange.begin().toISOString();
    const endTime = props.timerange.end().toISOString();
    let agg = getAgg(query);
    let isLatest = checkIfLatest(query);

    let topo = {};

    useEffect(() => {
        if (!isLoading && data.data !== null && topology.data !== null && mapCanvas) {
            var scopeInstanceGeo = mapCanvas.current;
            scopeInstanceGeo.topology = topo;

            if (
                parseFloat(props.width - 1) !==
                    parseFloat(scopeInstanceGeo.getAttribute("width")) ||
                parseFloat(props.height) !== parseFloat(scopeInstanceGeo.getAttribute("height"))
            ) {
                PubSub.publish(
                    "updateMapDimensions",
                    { width: props.width - 1, height: props.height },
                    scopeInstanceGeo
                );
            }

            PubSub.subscribe("setSelection", handleSelectionChanged, scopeInstanceGeo);
            PubSub.subscribe("clearSelection", handleSelectionChanged, scopeInstanceGeo);
            PubSub.global.subscribe(
                "toggleGeographicMapLayer",
                scopeInstanceGeo.toggleLayer,
                scopeInstanceGeo
            );

            mapCanvas.current.render();
            // if (!mapCanvas.current.map) mapCanvas.current.render();
        }
    });

    var options = GeoTopologyOptions;

    var map_element = React.createElement("esnet-map-canvas", {
        id: "geographicMap",
        options: JSON.stringify(options),
        width: props.width - 1,
        height: props.height,
        ref: mapCanvas,
    });

    // Get the current map selection from the URL query string
    const [{ selection }, setSelection] = useEntitySelection();

    // Collapse "Display Options" accordion if URL has map selection
    useEffect(() => {
        props.setAccordionCollapsed(selection ? true : false);
    });

    // Updates the selection and selection type on the URL when the user
    // selects different entities on the map
    const handleSelectionChanged = (info) => {
        if (info === null) {
            props.setAccordionCollapsed(false);
            setSelection();
        } else {
            props.setAccordionCollapsed(true);
            let entitySelectionType = info.type;
            if (entitySelectionType === "node") {
                let svg = info.selection.meta.svg;

                // TODO: Handle this in a better way
                // OR, ask topology generator to take in args that can
                // specify what shapes to set as default (maybe pass in array)
                if (svg.includes("rect")) {
                    entitySelectionType = "esnet_site";
                } else {
                    entitySelectionType = "hub";
                }

                setSelection(info.selection.name, entitySelectionType);
            } else {
                // For site-hub edges, nodeA is the hub and nodeZ is the site that is returned
                // via the info object by the engagemap
                let entitySelected_nodeA = info.selection.nodeA;
                let entitySelected_nodeZ = info.selection.nodeZ;
                let entitySelected = entitySelected_nodeZ + "--" + entitySelected_nodeA;

                // Find layer of clicked entity
                let layer = info.layer;

                // Name of this layer in the topology
                let layerName = topo["layer" + layer]["layer"];

                let relevantLayer = "";
                if (layerName === "core") {
                    relevantLayer = "hub-hub";
                } else if (layerName === "tail") {
                    relevantLayer = "site-hub";
                }

                // Pass the relevant layer as the "edgeType"
                setSelection(entitySelected, entitySelectionType, relevantLayer);
            }
        }
    };

    // Map topology request variables
    const name = "portal-geographic";
    const version = "1.1.0";
    const queryOptionsTopology = {
        variables: { name, version },
        pollInterval: 1 * 60 * 60 * 1000, // 1h
    };

    // Fetch the map topology
    const {
        isLoading: isLoadingTopology,
        error: topologyError,
        data: topology,
    } = useMemoQuery(MAP_TOPOLOGY_GEO, queryOptionsTopology, (d) => d, [name, version]);

    // Map traffic request variables
    const queryOptions = {
        variables: { beginTime, endTime, isLatest, agg },
        pollInterval: 1 * 60 * 1000, // 1m
    };

    // Fetch the map traffic
    const { isLoading, error, data } = useMemoQuery(MAP_TRAFFIC_QUERY_GEO, queryOptions, (d) => d, [
        beginTime,
        endTime,
        isLatest,
        agg,
        selection,
    ]);

    const errorMsg =
        error || topologyError ? "Error: An error occured trying to fetch data for the map" : null;

    if (errorMsg) {
        return <div>{errorMsg}</div>;
    }

    if (isLoading || isLoadingTopology) {
        const style = {
            width: props.width,
            height: props.height,
            // To center the spinner icon
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        };
        return (
            <Row>
                <Col md={12}>
                    <Spinner style={style} />
                </Col>
            </Row>
        );
    }

    if (data.data !== null && topology.data !== null) {
        let mapTopology = JSON.parse(topology.getTopology.layers);

        // Set default values
        for (let key in mapTopology) {
            mapTopology[key].edges.forEach((edge) => {
                edge.AZdisplayValue = "N/A";
                edge.ZAdisplayValue = "N/A";
                edge.AZvalue += null;
                edge.ZAvalue += null;
            });
        }

        let currentLayer = 1;
        for (let layer in mapTopology) {
            let infIn = [];
            let infOut = [];

            let mapJson = mapTopology[layer];
            let endpointId = options["endpointIdL" + currentLayer];

            mapJson.edges.forEach((edge) => {
                // Find A and Z node
                let nodeA = edge.meta.endpoint_identifiers[endpointId][0];
                let nodeZ = edge.meta.endpoint_identifiers[endpointId][1];

                // create names
                edge.nodeA = getDisplayName(nodeA, mapJson);
                edge.nodeZ = getDisplayName(nodeZ, mapJson);

                edge.AZname = `${nodeA}--${nodeZ}`;
                edge.ZAname = `${nodeZ}--${nodeA}`;

                let matchAZ = data.mapTopologyEm.find((d) => d["traffic"].name === edge.AZname);
                let matchZA = data.mapTopologyEm.find((d) => d["traffic"].name === edge.ZAname);

                if (matchAZ) {
                    // if we get an a-z match, assign inbound and outbound "normally"
                    let traffic = matchAZ["traffic"];

                    edge.AZvalue = traffic["points"][0][1];
                    edge.azColor = getEdgeColor(edge.AZvalue);
                    edge.AZdisplayValue = `${formatBPS(edge.AZvalue, 2)}`;

                    edge.ZAvalue = traffic["points"][0][2];
                    edge.zaColor = getEdgeColor(edge.ZAvalue);
                    edge.ZAdisplayValue = `${formatBPS(edge.ZAvalue, 2)}`;
                }

                if (matchZA) {
                    // if we get a z-a match, flip-flop inbound and outbound
                    let traffic = matchZA["traffic"];

                    edge.ZAvalue = traffic["points"][0][1];
                    edge.zaColor = getEdgeColor(edge.ZAvalue);
                    edge.ZAdisplayValue = `${formatBPS(edge.ZAvalue, 2)}`;

                    edge.AZvalue = traffic["points"][0][2];
                    edge.azColor = getEdgeColor(edge.AZvalue);
                    edge.AZdisplayValue = `${formatBPS(edge.AZvalue, 2)}`;
                }
            });

            for (let row of data.mapTopologyEm) {
                // Get A-Z edges
                let edge = row["traffic"].name;
                let nodeA = edge.split("--")[0];
                let nodeZ = edge.split("--")[1];

                // Incoming into a node is the outgoing from a node
                let indexIn = infIn.findIndex((e) => e.name === nodeA);
                if (indexIn >= 0) {
                    infIn[indexIn].value += row["traffic"]["points"][0][2];
                } else {
                    infIn.push({ name: nodeA, value: row["traffic"]["points"][0][2] });
                }

                let indexOut = infOut.findIndex((e) => e.name === nodeZ);
                if (indexOut >= 0) {
                    infOut[indexOut].value += row["traffic"]["points"][0][2];
                } else {
                    infOut.push({ name: nodeZ, value: row["traffic"]["points"][0][2] });
                }

                // Outgoing from a node is the incoming from a node
                indexOut = infOut.findIndex((e) => e.name === nodeA);
                if (indexOut >= 0) {
                    infOut[indexOut].value += row["traffic"]["points"][0][1];
                } else {
                    infOut.push({ name: nodeA, value: row["traffic"]["points"][0][1] });
                }

                indexIn = infIn.findIndex((e) => e.name === nodeZ);
                if (indexIn >= 0) {
                    infIn[indexIn].value += row["traffic"]["points"][0][1];
                } else {
                    infIn.push({ name: nodeZ, value: row["traffic"]["points"][0][1] });
                }
            }

            mapJson.nodes.forEach((node) => {
                let match1 = infIn.find((d) => d.name === node.name);
                let match2 = infOut.find((d) => d.name === node.name);
                node.inValue = "N/A";
                node.outValue = "N/A";
                if (match1 || match2) {
                    if (match1) {
                        node.inValue = `${formatBPS(match1.value, 2)}`;
                    }
                    if (match2) {
                        node.outValue = `${formatBPS(match2.value, 2)}`;
                    }
                }
            });

            //take this out later
            mapJson.aTest = 0;
            currentLayer = currentLayer + 1;
        }

        // Update topology variable
        topo = mapTopology;

        if (!isLoading && mapCanvas && mapCanvas.current) {
            mapCanvas.current.topology = topo;
            mapCanvas.current.render();
        }
    }

    return (
        <Row>
            <Col md={12}>{map_element}</Col>
        </Row>
    );
};

export { GeographicMap, GeographicMapSidebar };
