import gql from "graphql-tag";
import React, { useRef } from "react";
import TimeSeriesListBarChartWidget from "shared/components/widgets/TimeseriesListBarchartWidget";
import { useMemoQuery } from "shared/hooks";
import { chartBlue, chartOrange, selectionColor } from "shared/styles/styles";
import { combineTimeSeriesLists, toTimeSeriesList } from "shared/utils/timeseries-utils";

export const GET_ENTITY_TRAFFIC = gql`
    query getSiteTraffic($siteName: String, $beginTime: String, $endTime: String) {
        networkEntity(shortName: $siteName, entityType: "ESnet Site") {
            id
            interfaces(beginTime: $beginTime, endTime: $endTime) {
                traffic {
                    columns
                    name
                    points
                    type
                }
            }
            saps(beginTime: $beginTime, endTime: $endTime) {
                traffic {
                    columns
                    name
                    points
                    type
                }
            }
        }
    }
`;

const style = {
    in: { fill: chartOrange },
    out: { fill: chartBlue },
};

const TITLE = "Traffic by Interface";
const DESCRIPTION = `
#### Traffic breakdown \n\n
This chart shows the traffic flowing in and out of this network entity broken down by each interface and SAP facing the site. 
This data is based on the SNMP collections in Stardust. \n
Clicking on a particular row will highlight the corresponding traffic in the Total Site Traffic chart above. 
`;

const CHANNELS = ["in", "out"];

export const SiteInterfacesBarChart = ({
    siteName,
    timerange,
    timestamp,
    onSelectionChanged,
    onNavigate,
    selected,
    traffic,
}) => {
    const beginTime = timerange.begin().toISOString();
    const endTime = timerange.end().toISOString();
    let totalSeries = useRef(null);
    let seriesList = useRef([]);

    const queryOptions = {
        variables: { siteName, beginTime, endTime },
        fetchPolicy: "no-cache",
    };

    const {
        isLoading,
        error,
        data: { timeSeriesList, timeSeries },
    } = useMemoQuery(
        GET_ENTITY_TRAFFIC,
        queryOptions,
        (d) => {
            // Build list of Interfaces timeseries from GraphQL data
            const interfaces = toTimeSeriesList(
                "interfaces",
                d.networkEntity.interfaces,
                { name: "Interfaces", channels: CHANNELS },
                (d) => d.traffic,
                [{ field: "type", value: traffic }]
            );
            // Build list of SAP timeseries from GraphQL data
            const saps = toTimeSeriesList(
                "saps",
                d.networkEntity.saps,
                { name: "SAPs", channels: CHANNELS },
                (d) => d.traffic,
                [{ field: "type", value: traffic }]
            );
            // Combine those together to get a master list and a total series aggregation of the list
            const { totalSeries: timeSeries, seriesList } = combineTimeSeriesLists(
                [interfaces, saps],
                {
                    name: "Site interfaces and saps",
                    channels: CHANNELS,
                }
            );

            const timeSeriesList = seriesList;

            return {
                timeSeriesList,
                timeSeries,
            };
        },
        [beginTime, endTime]
    );

    // Pass a list of fields to be shown in the barchart
    const extraFields = [
        {
            name: "type", // name of the column
            width: "150px", // width of the column
            widthWhenSelected: "143px", // width of the column when row is selected
        },
    ];

    // Usually run into cases where we get timeSeries set to undefined
    // and that breaks the code downstream
    if (timeSeries !== undefined) {
        totalSeries.current = timeSeries;
    }

    if (timeSeriesList !== undefined) {
        seriesList.current = timeSeriesList;
    }

    const errorMsg = error ? "Error: An error occured trying to fetch data" : null;

    // Inverting the columns since we're showing traffic from the site's perspective on this page
    // The traffic we receive from the output of the graphQL query is the opposite
    return (
        <TimeSeriesListBarChartWidget
            title={TITLE}
            description={DESCRIPTION}
            selected={selected}
            onSelectionChanged={onSelectionChanged}
            onNavigate={onNavigate}
            selectionColor={selectionColor}
            seriesList={seriesList.current}
            totalSeries={totalSeries.current}
            style={style}
            timerange={timerange}
            timestamp={timestamp}
            isLoading={isLoading}
            top={100}
            columns={["out", "in"]}
            extraFields={extraFields}
            error={errorMsg}
        />
    );
};
