import gql from "graphql-tag";
import { Map } from "immutable";
import React from "react";
import { useMemoQuery } from "shared/hooks";
import { toTimeSeriesList } from "shared/utils/timeseries-utils";
import _ from "underscore";
import { FacilityMapWidget } from "./FacilityMapWidget";

const DESCRIPTION = `
#### Facility Traffic\n\n
This map shows the average traffic for each facility over the past 15 minutes. 
The icon for each facility is colored based on the maximum average value of the traffic either in or out of the facility.
`;

export const FACILITY_TRAFFIC_QUERY = gql`
    query getFacilityMapTraffic {
        facilitySummary {
            traffic
        }
    }
`;

/**
 * Displays the traffic map for the ESnet facilities. This component is responsible for fetching
 * the data for the `topology` and `traffic` using the `useMemoQuery()` hook, on a one minute
 * polling interval, and then rendering the view component widget `FacilityMapWidget`.
 */
export const FacilityMap = ({ topology }) => {
    // GraphQL query for traffic data returns a map of facility -> TimeSeries
    // prettier-ignore
    const {
        isLoading,
        error,
        data: trafficMap,
    } = useMemoQuery(FACILITY_TRAFFIC_QUERY, { pollInterval: 60 * 1000 }, (d) => {
        const labels = ["Time", "To Facility", "From Facility"];
        const name = "Facility Flow";
        const { seriesList } = toTimeSeriesList(
            "facility-traffic",
            d.facilitySummary.traffic,
            { name, labels },
            (d) => JSON.parse(d)
        );
        const trafficMap = Map(_.map(seriesList, (x) => [x.name(), x]));
        return trafficMap;
    });

    // Convert any error to something which can be displayed to the user
    let displayError = error;
    if (!error && !isLoading && !trafficMap) {
        displayError = "Facility traffic from server contained no data";
    } else if (error) {
        displayError = "Facility traffic could not be loaded from server";
    }

    return (
        <FacilityMapWidget
            description={DESCRIPTION}
            topology={topology}
            traffic={trafficMap}
            error={displayError}
            isLoading={isLoading}
        />
    );
};
