import React from "react";
import ReactDOM from "react-dom";

/**
 * This takes a single child and inserts a prop 'width' and 'height' on it that
 * is the current width of the this container. This is handy if you want to
 * surround a chart or other svg diagram and have this drive the chart width.
 */

function getElementOffset(element) {
    const de = document.documentElement;
    const box = element.getBoundingClientRect();
    const top = box.top + window.pageYOffset - de.clientTop;
    return top;
}

export class Fillable extends React.Component {
    state = {
        width: 0,
        height: 0,
    };

    handleResize = () => {
        const viewSize = ReactDOM.findDOMNode(this.refs.container);
        const offset = getElementOffset(viewSize);
        this.setState({
            width: this.refs.container.offsetWidth,
            height: window.innerHeight - offset,
        });
    };

    componentDidMount() {
        window.addEventListener("resize", this.handleResize); //eslint-disable-line
        this.handleResize();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize); //eslint-disable-line
    }

    render() {
        const props = {
            width: this.state.width,
            height: this.state.height,
        };
        if (this.props.aspect) {
            props.height = this.state.width / this.props.aspect;
        }
        const child = React.Children.only(this.props.children);
        const childElement = this.state.width ? React.cloneElement(child, props) : null;
        return (
            <div ref="container" style={this.props.style}>
                {childElement}
            </div>
        );
    }
}
