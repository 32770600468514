import React from "react";
import _ from "underscore";
import { formatPercent, formatBytes } from "shared/utils/format-utils";

export const TrafficVolumeRow = ({
    monthDifference = "",
    yearDifference = "",
    emphasizeName = true,
    name,
    color,
    percent,
    bytes,
}) => {
    const percentFormatted = !_.isNull(percent) ? formatPercent(percent) : "";
    const nameFomatted = emphasizeName ? (
        <th>
            <span style={{ color }}>▐ </span>
            <span>{name}</span>
        </th>
    ) : (
        <td>
            <span style={{ color }}>▐ </span>
            <span>{name}</span>
        </td>
    );
    return (
        <tr>
            {nameFomatted}
            <td>{formatBytes(bytes)}</td>
            <td>{percentFormatted}</td>
            <td>{monthDifference}</td>
            <td>{yearDifference}</td>
        </tr>
    );
};

const Header = () => (
    <thead>
        <tr>
            <th />
            <th>Bytes</th>
            <th>Percent of Total</th>
            <th>One Month Change</th>
            <th>One Year Change</th>
        </tr>
    </thead>
);

export const TrafficVolumeTable = ({ rows }) => (
    <table className="table">
        <Header />
        <tbody>{rows}</tbody>
    </table>
);
