import gql from "graphql-tag";
import React from "react";
import Card from "shared/components/cards/Card";
import { Spinner } from "shared/components/controls";
import { useMemoQuery } from "shared/hooks";
import { cardInterfacesStyle, cardStyle, cardStyleActive, linkStyle } from "shared/styles/styles";
import { formatCount } from "shared/utils/format-utils";
import { setHtmlTitle } from "shared/utils/entity-utils";
import Widget from "shared/components/widgets/Widget";
import { Accordion } from "react-bootstrap";

const EDGE_INFO_QUERY = gql`
    query edgeInfo($edgeName: String, $edgeType: String) {
        mapTopologyEdge(name: $edgeName, edgeType: $edgeType) {
            name
            attr
        }
    }
`;

/**
 * Displays an info card for an Edge
 */
const EdgeCard = ({ edgeName, edgeType, isSelected }) => {
    // Fetch the edge data
    // prettier-ignore
    const {
        isLoading,
        error,
        data: edge,
    } = useMemoQuery(
        EDGE_INFO_QUERY,
        {
            variables: { edgeName, edgeType },
        },
        (d) => d.mapTopologyEdge,
        [edgeName, edgeType]
    );

    if (!edge) {
        return (
            <div>
                <Widget error={"No data found"} loading={false} height={50}>
                    <Card overLevel={2} outLevel={1} level={1} width="100%" style={cardStyle} />
                </Widget>
            </div>
        );
    } else if (!isLoading && !error && edge && edge.data !== null) {
        const attr = JSON.parse(edge.attr);
        let ifaces = attr.ifaces.map((iface) => (
            <div key={`${iface.device_source}-${iface.iface_source}`} style={{ paddingLeft: 5 }}>
                <a
                    href={`/network/devices/${encodeURIComponent(
                        iface.device_source
                    )}/interfaces/${encodeURIComponent(iface.iface_source)}/traffic/`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <b>{`${iface.is_neaar ? "NEAAR Link: " : ""}`}</b>{" "}
                    {` ${iface.device_source} ${iface.iface_source}`}
                </a>
            </div>
        ));

        let reverse_ifaces = [];
        if (attr && attr.reverse && attr.reverse_ifaces) {
            reverse_ifaces = attr.reverse_ifaces.map((iface) => (
                <div
                    key={`${iface.device_source}-${iface.iface_source}`}
                    style={{ paddingLeft: 5 }}
                >
                    <a
                        href={`/network/devices/${encodeURIComponent(
                            iface.device_source
                        )}/interfaces/${encodeURIComponent(iface.iface_source)}/traffic/`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <b>{`${iface.is_neaar ? "NEAAR Link: " : ""}`}</b>{" "}
                        {` ${iface.device_source} ${iface.iface_source}`}
                    </a>
                </div>
            ));
        }

        const saps = attr.saps.map((sap) => (
            <div key={`${sap.device_source}-${sap.sap_source}`} style={{ paddingLeft: 5 }}>
                <a
                    href={`/network/devices/${encodeURIComponent(
                        sap.device_source
                    )}/saps/${encodeURIComponent(sap.sap_source)}/traffic/`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {` ${sap.device_source} ${sap.sap_source}`}
                </a>
            </div>
        ));

        const renderInterfaces = () => {
            return (
                <span>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header id="edgeCard">INTERFACES</Accordion.Header>
                        <Accordion.Body id="edgeCard">
                            <span>{ifaces}</span>
                        </Accordion.Body>
                    </Accordion.Item>
                </span>
            );
        };

        const renderSaps = () => {
            if (saps.length) {
                return (
                    <span>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header id="edgeCard">SAPS</Accordion.Header>
                            <Accordion.Body id="edgeCard">
                                <span>{saps}</span>
                            </Accordion.Body>
                        </Accordion.Item>
                    </span>
                );
            } else {
                return <span />;
            }
        };

        const renderReverseIfaces = () => {
            if (reverse_ifaces.length) {
                return (
                    <span>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header id="edgeCard">RECIPROCAL INTERFACES</Accordion.Header>
                            <Accordion.Body id="edgeCard">
                                <span>{reverse_ifaces}</span>
                            </Accordion.Body>
                        </Accordion.Item>
                    </span>
                );
            } else {
                return <span />;
            }
        };

        // Set HTML title tag
        const edgeName = edge.name.replace("--", " ⇄ ");
        const pageTitle = `Edge : ${edgeName}`;
        setHtmlTitle("Map", pageTitle, 80);

        return (
            <Card
                overLevel={2}
                outLevel={1}
                level={1}
                width="100%"
                style={isSelected ? cardStyleActive : cardStyle}
            >
                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <div
                                    style={{
                                        fontSize: 20,
                                        fontWeight: 300,
                                        lineHeight: 1.4,
                                        paddingLeft: 5,
                                    }}
                                >
                                    {edgeName}
                                </div>
                                <div style={{ fontSize: 14, paddingLeft: 5 }}>
                                    {attr.total_capacity !== 0
                                        ? formatCount(attr.total_capacity, 1)
                                        : ""}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {" "}
                                <a
                                    style={linkStyle}
                                    href={`/edges/view/${edgeType}/${edge.name}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <span>MORE INFO</span>
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div style={cardInterfacesStyle}>
                                    <Accordion defaultActiveKey="0" flush>
                                        {renderInterfaces()}
                                    </Accordion>
                                    <Accordion defaultActiveKey="0" flush>
                                        {renderSaps()}
                                    </Accordion>
                                    <Accordion defaultActiveKey="0" flush>
                                        {renderReverseIfaces()}
                                    </Accordion>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Card>
        );
    } else {
        return (
            <div>
                <Spinner />
            </div>
        );
    }
};

export { EdgeCard };
