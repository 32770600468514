import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Dashboard } from "./components/Dashboard";

const NoMatch = () => (
    <div>
        <h2>Whoops</h2>
        <p>Sorry but this Sites URL didn’t find any pages</p>
    </div>
);

const Dashboards = () => {
    let { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={`${path}/:page?`}>
                <Dashboard />
            </Route>
            <Route path="*">
                <NoMatch />
            </Route>
        </Switch>
    );
};

export { Dashboard, Dashboards };
